import apiClient from '../../libs/api/libs/apiClient'

const api = {
  async getCoach(id: string): Promise<Coach> {
    const result = await apiClient.get(`/coach/${id}`)
    return result.data
  },
}

export default api
