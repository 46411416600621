import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(
  {
    image: {
      height: 150,
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      backgroundColor: '#fff',
    },
    imageMock: {
      backgroundColor: '#bfbfbf',
      border: 'none',
    },
  },
  {
    classNamePrefix: 'AssetPicture',
  },
)

interface Props {
  asset: Asset
  className?: string
}

function AssetPicture(props: Props) {
  const { asset, className } = props
  const classes = useStyles()

  if (_.isEmpty(asset)) return <div className={classnames(classes.image, classes.imageMock, className)} />

  return <img src={asset.url} alt={'task'} className={classnames(classes.image, className)} />
}

AssetPicture.propTypes = {
  asset: PropTypes.object.isRequired,
}

export default AssetPicture
