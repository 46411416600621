import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import Help from '../../../components/Help'
import PlusButton from '../../../components/buttons/PlusButton'
import InviteCompanyHrModal from '../../../modals/InviteCompanyHrModal'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import SettingsAdminItem from './components/SettingsAdminItem'
import SectionContainer from '../components/SectionContainer'

const useStyles = makeStyles(
  {
    companyInfo: {
      padding: '30px 40px',
      borderRadius: 5,
      border: '1px solid #D4D4D4',
      position: 'relative',
    },
    smallInvite: {
      marginTop: 15,
    },
  },
  {
    classNamePrefix: 'SettingsAdmins',
  },
)

function SettingsAdmins() {
  const dispatch = useDispatch<ReduxDispatch>()
  const classes = useStyles()
  const [isModalVisible, setModalVisible] = useState(false)
  const { me, company } = useSelector((state: ReduxState) => ({ me: state.me, company: state.company }))
  useAsyncFetch(async () => {
    await dispatch(CompanyDispatcher.getAgents())
  })
  const { agents = [] } = company || {}

  const handleDelete = ({ user_id }: { user_id: string }) => dispatch(CompanyDispatcher.deleteAgent(user_id))
  // showModal: (param) => dispatch(modalActions.showModal(param)),
  const handleModalClose = () => setModalVisible(false)

  const handleClickOnInviteHr = () => {
    setModalVisible(true)
  }

  return (
    <SectionContainer>
      <Typography style={{ fontSize: 20, fontWeight: 800, marginBottom: 24 }}>
        System admins <Help title="System admins" text="Invite additional admins to help manage the initiative." />
      </Typography>
      <div className={classes.companyInfo}>
        {_.map(agents, (agent) => (
          <SettingsAdminItem
            agent={agent}
            key={`settings-admin-${agent.user_id}`}
            isMe={agent.user_id === me.user_id}
            onDelete={handleDelete}
          />
        ))}
      </div>

      <Grid container direction={'row'} className={classes.smallInvite}>
        <PlusButton onClick={handleClickOnInviteHr} title={'Invite Admin'} />
      </Grid>
      <InviteCompanyHrModal isVisible={isModalVisible} onClose={handleModalClose} />
    </SectionContainer>
  )
}

export default SettingsAdmins
