import { createIntl, createIntlCache } from '@formatjs/intl'
import _ from 'lodash'
import { useMemo } from 'react'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: 'en-US',
    messages: {},
  },
  cache,
)

function useFormattedNumber(value: number) {
  const valueFormatted = useMemo(() => {
    if (!_.isNumber(value)) return value
    const str = intl.formatNumber(value)
    return str
  }, [value])

  return valueFormatted
}

export default useFormattedNumber
