import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_LIST: '',
    SET_LIST_ITEM: '',
    SET_ORDERS: '',
    DELETE_ITEM: '',
  },
  { prefix: 'STOCKS__' },
)
