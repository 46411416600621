import { Grid } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import EventHeadActions from './components/EventHeadActions'
import EventMainInfo from './components/EventMainInfo'

interface Props {
  event: ReactionEvent
}

function EventPageHead(props: Props) {
  const { event } = props
  const { is_public } = event
  const history = useHistory()

  const handleDelete = () => history.goBack()

  return (
    <Grid container justifyContent={'space-between'} alignItems={'flex-start'}>
      <Grid item>
        <EventMainInfo event={event} />
      </Grid>

      {!is_public ? <EventHeadActions event={event} onDelete={handleDelete} /> : null}
    </Grid>
  )
}

export default EventPageHead
