import { Box, Typography } from '@mui/material'
import React from 'react'
import starFilledIcon from './assets/star-filled.svg'
import starIcon from './assets/star-empty.svg'

const Rating = ({ value, className }) => (
  <Box className={className}>
    {Array.from({ length: Math.floor(value) }).map(() => (
      <img src={starFilledIcon} alt="" />
    ))}
    {Array.from({ length: 5 - Math.floor(value) }).map(() => (
      <img src={starIcon} alt="" />
    ))}
    <Typography
      style={{
        marginLeft: 'auto',
        fontSize: 15,
        textAlign: 'end',
        lineHeight: '32px',
      }}
    >
      <b>{value}</b>
    </Typography>
  </Box>
)

export default Rating
