import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import useDistanceMeasurement, { DistanceMeasurement } from '../../../../hooks/useDistanceMeasurement'

interface Props {
  // onChange: (type: DistanceMeasurement) => any
}
export default function DistanceMeasurementSwitch(props: Props) {
  // const { onChange } = props
  const [distance, setDistance] = useDistanceMeasurement()

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: DistanceMeasurement) => {
    setDistance(newAlignment)
  }

  return (
    <div>
      <ToggleButtonGroup color="primary" value={distance} exclusive onChange={handleChange}>
        <ToggleButton value="km">Km</ToggleButton>
        <ToggleButton value="miles">Miles</ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
