import apiClient from './libs/apiClientOld'
import coachesApi from './coachesApi'
import companyApi from './companyApi'
import employeeApi from './employeeApi'
import playbookApi from './playbookApi'
import playbookTypesApi from './playbookTypesApi'
import programsApi from './programsApi'
import rewardsApi from './rewardsApi'

export const prefix_web = '/web/'
export const prefix_assets = '/assets/'
export const prefix_agent = '/agent/'

export default {
  apiClient,
  ...employeeApi,
  ...companyApi,
  ...coachesApi,
  ...programsApi,
  ...playbookApi,
  ...playbookTypesApi,
  ...rewardsApi,

  /**
   * Delete user employee or coach
   * @param userId - user number id
   * @returns Object - status inside success or filed
   */
  async deleteUser(userId) {
    const result = await apiClient.delete(`${prefix_web}user/${userId}`)
    return result.data
  },
  /**
   * Make logout all user devices
   * @param userId - user number id
   * @returns {Promise<boolean>} - success or false status
   */
  async logoutUserDevices(userId) {
    // const result = apiClient.get('user/'+ userId+ '/devices/logout')
    // return result.data
    console.log(`devices for user ${userId} make logout `)
    return true
  },
  async sendNotificationToUserApplication(userId, message) {
    const result = apiClient.post(`${prefix_web}user/${userId}/notification`, {
      title: message.theme,
      body: message.text,
    })
    return result.data
  },

  // ROADS
  /**
   * Get roads list
   * @returns Object - contain array road inside
   */
  async getRoads() {
    const result = await apiClient.get(`${prefix_web}roads`)
    return result.data
  },

  async uploadImg(file, options) {
    console.warn('uploadImg is deprecated. Use apiAssets.upload')
    const result = await apiClient.post(`${prefix_assets}upload`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })
    return result.data
  },
}
