import { Grid, Typography } from '@mui/material'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import ChatItem from './chatList/ChatItem'

const formatChatDate = date => {
  if (!date) return ''

  const d = moment(date)

  const todayStart = moment().startOf('day')
  const yesterdayStart = moment()
    .subtract(1, 'day')
    .startOf('day')

  let formattedDate = d.format('DD/MM/YYYY')

  if (d > yesterdayStart) {
    formattedDate = 'Yesterday'
  }
  if (d > todayStart) {
    formattedDate = 'Today'
  }

  return formattedDate
}

type OwnProps = {
  type: 'group' | 'direct'
  role?: 'employee' | 'coach'
  search: string
  сhatsFor?: any[]
}
type Props = OwnProps & ExtractConnectType<typeof connectStore>

const ChatsList: React.FC<Props> = ({ search, type, role, chat: { rooms, users }, сhatsFor, createMockDirectRoom }) => {
  let chatItems = Object.values(rooms).filter(room => {
    if (type === 'group') {
      return room.isGroup
    }
    return room.isDirect && users[room.userId]?.customFields.role === role
  })

  if (сhatsFor) {
    const newItems = []

    сhatsFor.forEach(({ _id, name, avatar_url: thumbnail, chat_user_id }) => {
      const existingRoom = chatItems.find(({ userId, mockUserId }) => {
        const chatUserId = userId || mockUserId
        const fakeChatUserId = chat_user_id || _id

        return chatUserId === fakeChatUserId
      })

      if (existingRoom) {
        newItems.push(existingRoom)
      } else {
        newItems.push({
          name,
          thumbnail,
          mockUserId: chat_user_id,
        })
      }
    })

    chatItems = newItems
  }

  const filteredChatItems = chatItems
    .filter(({ name, updatedAt }) =>
      name
        .trim()
        .toLowerCase()
        .includes(search.trim().toLowerCase())
    )
    .sort((a, b) => new Date(b.updatedAt || 0) - new Date(a.updatedAt || 0))

  return (
    <Grid container direction={'column'}>
      <Grid item>
        {filteredChatItems.length ? (
          filteredChatItems.map(({ _id, name, updatedAt, thumbnail, lastMessage, mockUserId, unread }) => {
            return (
              <ChatItem
                type={type}
                unread={unread}
                key={_id}
                id={_id}
                name={name}
                text={lastMessage}
                date={formatChatDate(updatedAt)}
                url={thumbnail}
                mockUserId={mockUserId}
                createMockDirectRoom={createMockDirectRoom}
              />
            )
          })
        ) : (
          <Typography style={{ textAlign: 'center', marginTop: 50 }}>There is no chats yet</Typography>
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
})
const mapDispatchToProps = dispatch => ({
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default compose(connectStore)(ChatsList)
