/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Delete as DeleteIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { EventRecording } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  recording: EventRecording
  onClose?(): any
}

function RecordingMenuActions(props: Props) {
  const { recording, onClose } = props
  // const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()
  const [isMenuVisible, setMenuVisibility] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleDelete = async () => {
    const isConfirmed = confirm('Are you sure want to delete this recording?')
    if (!isConfirmed) return null
    handleCloseMenu()

    await dispatch(EventDispatcher.deleteRecording(recording))
    if (onClose) onClose()
  }

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        size="small"
      >
        <MoreVertIcon
          css={css`
            color: #707579;
          `}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isMenuVisible}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {/* <MenuItem onClick={handleUpdateModalOpen}> */}
        {/*  <ListItemIcon> */}
        {/*    <EditIcon color={'primary'} fontSize={'small'} /> */}
        {/*  </ListItemIcon> */}
        {/*  <Typography variant="inherit">Edit Event</Typography> */}
        {/* </MenuItem> */}

        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit" color={'error'}>
            Delete Recording
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default RecordingMenuActions
