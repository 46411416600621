import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { secondsToTime } from '../../../helpers/utils'
import useFormattedNumber from '../../dashboard/hooks/useFormattedNumber'

const percentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue
}

const useStyles = makeStyles({
  progressBar: {
    backgroundColor: '#F9B22D',
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    height: 12,
    marginTop: 6,
    minWidth: 5,
    opacity: 0.6,
  },
  dailyAverage: {
    alignItems: 'center',
    marginLeft: 'auto',
  },
  dailyAverageValue: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  dailyAverageText: {
    color: '#818181',
    fontSize: 11,
  },
})

const Team = (props) => {
  const { team, teamMaxValue, unitType, type } = props
  const classes = useStyles()
  const isTimeUnit = useMemo(() => unitType === 'time', [unitType])
  const averageValue = useFormattedNumber(team.daily_average)
  const totalValue = useFormattedNumber(team.value_total)
  const progress = percentage(team.daily_average, teamMaxValue?.daily_average)

  return (
    <Box alignItems="center" mt={5} mb={3} pl={4} pr={4} display="flex">
      <Box pr={5} flexGrow={1}>
        <Typography fontSize={14} fontWeight="bold">
          {team.name}
        </Typography>
        <Typography fontSize={12}>
          Total {type}
          {isTimeUnit ? ' Time' : ''}: {isTimeUnit ? secondsToTime(team.value_total) : totalValue}
        </Typography>
        <div className={classnames([classes.progressBar])} style={{ width: `${progress}%` }} />
      </Box>
      <Box className={classes.dailyAverage}>
        <Typography className={classes.dailyAverageValue}>
          {isTimeUnit ? secondsToTime(team.daily_average) : averageValue}
        </Typography>
        <Typography className={classes.dailyAverageText}>Daily average</Typography>
      </Box>
    </Box>
  )
}

export default Team
