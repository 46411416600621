import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import TeamDispatcher from '@reducers/teams/dispatcher'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ModalStyled from '../../../../elements/ModalStyled'
import useAsyncHandler from '../../../../hooks/useAsyncHandler'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  team: any
  isVisible: boolean
  onClose: () => any
}

function TeamUpdateModal(props: Props) {
  const { onClose, team } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const teamUpdateForm = useForm<{ name: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = teamUpdateForm

  const nameField = register('name', {
    validate(value) {
      if (!value) return 'Value is required'
      return true
    },
  })
  const teamUpdateAction = useAsyncHandler(async (data: { name: string }) => {
    await dispatch(TeamDispatcher.updateTeam(team.id, data))
    onClose()
    teamUpdateForm.reset()
  })

  const onSubmit = handleSubmit(async (data: { name: string }) => {
    await teamUpdateAction.onAction(data)
  })

  return (
    <ModalStyled {...props} showCloseButton>
      <Box sx={{ minWidth: 300 }}>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography variant={'h2'} align={'center'}>
            Rename Team
          </Typography>
        </Box>

        <FormControl fullWidth>
          <TextField
            {...nameField}
            defaultValue={team.name}
            label={'Team Name'}
            fullWidth
            style={{ marginBottom: '10px' }}
            error={!!errors['name']?.message}
          />
        </FormControl>

        <Grid container justifyContent={'center'}>
          <Box marginTop={'30px'}>
            <Button variant={'contained'} color={'primary'} onClick={onSubmit} disabled={teamUpdateAction.isLoading}>
              Rename Team
            </Button>
          </Box>
        </Grid>
      </Box>
    </ModalStyled>
  )
}

export default TeamUpdateModal
