/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { CompanyOffice } from '@reaction-club-types/core'
import React from 'react'
import CompanyLogo from '../../../components/CompanyLogo'
import OfficeHeadActions from './components/OfficeHeadActions'

interface Props {
  office: CompanyOffice
}
function OfficeInfo(props: Props) {
  const { office } = props
  const { name, logo, address, country } = office

  return (
    <div>
      <Grid container justifyContent={'space-between'}>
        <div>
          <Typography
            variant={'subtitle1'}
            css={css`
              font-size: 12px;
              color: #979797;
              font-weight: bold;
            `}
          >
            Office
          </Typography>
          <Typography variant={'h1'}>{name}</Typography>
        </div>

        <OfficeHeadActions office={office} />
      </Grid>

      <CompanyLogo url={logo} />

      <div>
        <span>Address: </span>
        <Typography
          css={css({
            fontSize: 12,
            lineHeight: '14px',
            color: '#979797',
          })}
        >
          {address || '-'}
        </Typography>
      </div>

      <Box marginTop={'10px'}>
        <div>
          <span>Country:</span>
          <Typography
            css={css({
              lineHeight: '14px',
            })}
          >
            {country || '-'}
          </Typography>
        </div>
      </Box>
    </div>
  )
}

export default OfficeInfo
