import CoachesDispatcher from '@reducers/coaches/dispatcher'
import CompanyDispatcher from '@reducers/company/dispatcher'
import ListsDispatcher from '@reducers/lists/dispatcher'
import apiErrorUtil from '../../libs/errors/apiErrorUtil'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import CoachesListDispatcher from '../coachesList/dispatcher'
import MeDispatcher from '../me/dispatcher'
import appActions from './actions'
import apiApp from './api'
import { actions } from './reducer'

class Dispatcher {
  install() {
    return async (dispatch) => {
      try {
        // todo looks like it called many times
        await dispatch(MeDispatcher.getMySelf())
        await dispatch(CompanyDispatcher.getCompany())

        dispatch(this.installLazy())
      } catch (e) {
        const { status, data } = apiErrorUtil.getRequestInfo(e)
        console.log('AppDispatcher.install error', status, data)
      }
    }
  }

  installLazy() {
    return async (dispatch) => {
      dispatch(this.fetchUnreadCounters())
      // todo fetch groups
      // await dispatch(CompanyDispatcher.getIN())
      await dispatch(ListsDispatcher.getGroupsCoaches())
      await dispatch(CoachesListDispatcher.getCoaches())

      dispatch(CoachesDispatcher.getOffers())
    }
  }

  fetchUnreadCounters() {
    return async (dispatch) => {
      const data = await apiApp.getUnreadCounters()
      dispatch(actions.setCounters(data))
    }
  }

  getOnboardForm(options?: DispatcherOptions) {
    const { isForce } = options || {}

    return createLazy({
      isForce,
      timeout: storeHelpers.TTL.HOUR,
      onFindStoreUpdatedAt(state) {
        return state.app.onboardStoreUpdatedAt
      },
      async onExecute(dispatch, getState) {
        const { auth } = getState()
        if (!auth.token) return null // not authorized can't get it

        const data = await apiApp.getOnboardForm()
        dispatch(actions.setOnboardForm(data))
      },
    })
  }
}

const AppDispatcher = new Dispatcher()
export default AppDispatcher
