import url from 'url'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@mui/styles'
import invariant from 'invariant'
import JsFileDownloader from 'js-file-downloader'
import _ from 'lodash'
import React from 'react'
import { CSVLink } from 'react-csv'
import { Immutable } from 'seamless-immutable'
import config from '../../config'
import useAuthToken from '../../hooks/useAuthToken'
import { filters } from './ReportsMenu'

const useStyles = makeStyles({
  icon: {
    marginRight: 5,
  },
  link: {
    color: '#217fe8',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

interface Props {
  events: ReactionEvent[] | Immutable<ReactionEvent[]>
  period: string
}

const csvHeaLine = ['Event', 'Type', 'Coach', 'Registered', 'Attended', 'Attended Unknown']
function ReportsExportButton(props: Props) {
  const { events = [], period } = props
  const token = useAuthToken()
  const classes = useStyles()

  if (_.isEmpty(events)) return null

  const csvEventsData = events.map(event => [
    event.name,
    event.workout_type,
    event.coach?.name,
    event.participant_count,
    event.participant_attended_count,
    event.participant_unknown_count,
  ])

  const csvData = [csvHeaLine, ...csvEventsData]

  const handlePress = () => {
    const periodItem = _.find(filters, item => item.value === period)
    invariant(periodItem, 'period item is not found')

    const date = periodItem.getDate()

    const f = new JsFileDownloader({
      url: url.resolve(`${config.api_url}/`, `exports/event/participants?date=${date}`),
      headers: [{ name: 'authorization', value: `${token}` }],
      filename: 'event-participants.xlsx',
      // contentType: 'application/octet-stream',
    })
  }

  return (
    <div>
      {/* <Typography variant={'h4'}>Reports</Typography> */}
      <div>
        <FontAwesomeIcon icon={faFileDownload} className={classes.icon} />
        <CSVLink data={csvData} filename={'events.csv'} className={classes.link}>
          Events
        </CSVLink>
      </div>

      <div>
        <FontAwesomeIcon icon={faFileDownload} className={classes.icon} />
        <span onClick={handlePress} className={classes.link}>
          Participants
        </span>
      </div>
    </div>
  )
}

export default ReportsExportButton
