import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { last } from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { secondsToTime } from '../../../helpers/utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = (type, unitType) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label(yDatapoint) {
          return `${unitType === 'time' ? secondsToTime(yDatapoint.raw) : yDatapoint.formattedValue} avg./ per member`
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      barThickness: 10,
      maxBarThickness: 10,
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
})

const prefillDetails = (details, maxBars) => {
  if (details.length < maxBars) {
    const lastDate = moment(last(details)?.date)
    const prefill: any[] = []

    if (!details.length) {
      details = [
        {
          date: lastDate.format('YYYY-MM-DD'),
          valueAvg: 0,
        },
      ]
    }

    for (let i = 0; i < maxBars - details.length; i++) {
      prefill.push({
        date: lastDate.add(1, 'day').format('YYYY-MM-DD'),
        valueAvg: 0,
      })
    }
    return [...details, ...prefill]
  }

  return details
}

export default function Charts({ type, details, maxBars, backgroundColor, unitType }) {
  const rawData = details.length > maxBars ? details.slice(-maxBars) : prefillDetails(details, maxBars)

  // Create datasets for each day of the week
  const datasets = [
    {
      borderRadius: 2,
      data: rawData.map(({ valueAvg }) => valueAvg),
      backgroundColor,
    },
  ]

  return (
    <Bar
      options={options(type, unitType)}
      data={{
        backgroundColor,
        labels: rawData.map(({ date }) => moment(date).format('MMM DD')), // Use week start dates as labels
        datasets,
      }}
    />
  )
}
