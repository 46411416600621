import { AssetFull } from '@reaction-club-types/core'
import NotificationActionDispatcher from '@reducers/notification/dispatcher'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import coachActions from '../coachesBucket/actions'
import { actions as coachesListActions } from '../coachesList/reducer'
import meActions from '../me/actions'
import actions from './actions'
import apiCompany from './api'

class Dispatcher {
  actions = actions

  getCompany() {
    return async (dispatch) => {
      const company = await apiCompany.getCompany()

      dispatch(actions.set(company || null))
    }
  }

  getAgents() {
    return async (dispatch) => {
      const agents = await apiCompany.getAgents()
      dispatch(actions.setAgents(agents))
    }
  }

  // getAgent(id) {
  //   return async dispatch => {
  //     const agent = await apiCompany.getAgent(id)
  //     dispatch(actions.setAgent(agent))
  //   }
  // }
  deleteAgent(id) {
    return async (dispatch) => {
      const res = await apiCompany.deleteAgent(id)
      if (res === 'OK') {
        dispatch(actions.removeAgent(id))
      }
    }
  }

  deleteCoach(id) {
    return async (dispatch) => {
      const res = await apiCompany.deleteCoach(id)
      if (res === 'OK') {
        dispatch(this.getCoaches())
      }
    }
  }

  /** @deprecated and move to CoachesListDispatcher.getCoaches() */
  getCoaches() {
    return async (dispatch) => {
      const coaches = await apiCompany.getCompanyCoaches()
      dispatch(coachesListActions.setList(coaches)) // redirect to a new reducer
      dispatch(coachActions.setCoaches(coaches))
    }
  }

  getEmployees() {
    return async (dispatch) => {
      const employees = await apiCompany.getCompanyEmployees()
      dispatch(actions.setEmployees(employees))
    }
  }

  createCompany({ name, asset }: { name: string; asset?: AssetFull | null }): DispatcherResponse {
    return async (dispatch) => {
      const result = await apiCompany.createCompany({ name, asset_id: asset?.id })
      await dispatch(meActions.setCompanyId(result?.company_id))
      dispatch(this.getCompany())
    }
  }

  editCompany(company_id, { name, logo }: { name: string; logo: string }) {
    return async (dispatch) => {
      await apiCompany.editCompany({ name, logo })
      // dispatch(MeDispatcher.getMySelf())
      dispatch(this.getCompany())
    }
  }

  updateTimezone(timezone: string) {
    return async (dispatch) => {
      await apiCompany.updateTimezone(timezone)
      NotificationActionDispatcher(dispatch).addSuccessNotify({ message: 'Timezone updated successfully' })
      dispatch(this.getCompany())
    }
  }

  createInviteMemberLink() {
    return async (dispatch) => {
      const link = await apiCompany.createInviteMemberLink()
      dispatch(actions.setLink(link))
    }
  }

  createInviteHrLink() {
    return async (dispatch) => {
      const link = await apiCompany.createInviteHrLink()
      dispatch(actions.setHrLink(link))
    }
  }

  acceptInvitation(id: string) {
    return async (dispatch) => {
      const data = await apiCompany.processInvitationHrLink(id)
      dispatch(this.getCompany())
    }
  }

  getCompanyStickers() {
    return async (dispatch) => {
      const stickers = await apiCompany.getStickers()
      dispatch(actions.setStickers(stickers))
    }
  }

  setTeamSettings(data) {
    return async (dispatch) => {
      await apiCompany.setTeamSettings(data)
      dispatch(this.getCompany())
    }
  }

  updateSticker(sticker_id: string) {
    return async (dispatch) => {
      await apiCompany.updateSticker(sticker_id)
      dispatch(this.getCompanyStickers())
    }
  }

  getEmailList() {
    return async (dispatch) => {
      const emails = await apiCompany.getEmailList()
      dispatch(this.actions.setEmailList(emails))
    }
  }

  addCorporateEmail(email: string) {
    return async (dispatch) => {
      await apiCompany.addCorporateEmail(email)
      dispatch(this.getEmailList())
    }
  }

  deleteCorporateEmail(email: string) {
    return async (dispatch) => {
      await apiCompany.deleteCorporateEmail(email)
      dispatch(this.getEmailList())
    }
  }

  setAuthType(type) {
    return async (dispatch) => {
      await apiCompany.setAuthType(type)
      dispatch(this.getCompany())
    }
  }
}

const CompanyDispatcher = new Dispatcher()
export default CompanyDispatcher
