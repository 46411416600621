import apiClient from '../../libs/api/libs/apiClient'

const apiStats = {
  async getChallengesLeaderBoard() {
    const { data } = await apiClient.get('/challenges/leaderboard')
    return data
  },
  async getChallengesSummary() {
    const { data } = await apiClient.get('/challenges/summary')
    return data
  },
  async getChallengesSummaryDetails(challenge_id: string) {
    const { data } = await apiClient.get(`/challenges/summary/${challenge_id}/details`)
    return data
  },
  async getUsersPoints(params: { offset: number; start_at?: string; end_at?: string }) {
    const { data } = await apiClient.get('/stats/users-points', { params })
    return data
  },
  async getUsersPointsLeaderboard(params: { offset: number }) {
    const { data } = await apiClient.get('/challenges/users-points', { params })
    return data
  },
}

export default apiStats
