import { Grid } from '@mui/material'
import React from 'react'
import Loader from 'react-loader-spinner'

interface Props {
  style?: React.CSSProperties
  marginTop?: number | string
}
function ReactionLoader(props: Props) {
  const { style, marginTop } = props

  const styles: React.CSSProperties = {
    ...style,
  }
  if (marginTop) styles.marginTop = marginTop

  return (
    <Grid container justifyContent="center" style={styles}>
      <Loader type={'ThreeDots'} color={'#F9B22D'} height={60} width={60} />
    </Grid>
  )
}

export default React.memo(ReactionLoader)
