import { chatReducer } from '@reaction-club/rocketchat-redux'
import { combineReducers } from 'redux'
import app from './app/reducer'
import auth from './auth/reducer'
import categories from './categories/reducer'
import challenge from './challenge/reducer'
import challengeLists from './challengeLists/reducer'
import changeProgram from './changeProgram/reducer'
import coaches from './coaches/reducer'
import coachesBucket from './coachesBucket/reducer'
import coachesList from './coachesList/reducer'
import company from './company/reducer'
import configs from './configs/toolkit'
import dashboard from './dashboard/reducer'
import dashboardNext from './dashboardNext/toolkit'
import employees from './employees/reducer'
import events from './events/reducer'
import leaderboard from './leaderboard/reducer'
import lists from './lists/reducer'
import me from './me/reducer'
import modal from './modal/reducer'
import notification from './notification/reducer'
import offices from './offices/reducer'
import playbookInstances from './playbookInstances/reducer'
import playbooks from './playbooks/reducer'
import stocks from './stocks/reducer'
import teams from './teams/reducer'

const AppReducer = combineReducers({
  app,
  company,
  coaches,
  coachesList,
  coachesBucket,
  me,
  categories,
  changeProgram,
  notification,
  auth,
  playbooks,
  playbookInstances,
  modal,
  events,
  employees,
  dashboard,
  dashboardNext,
  leaderboard,
  chat: chatReducer,
  stocks,
  offices,
  teams,
  lists,
  configs,
  challenge,
  challengeLists,
})

export type ReduxState = ReturnType<typeof AppReducer>

export default AppReducer
