import { ReduxState } from '@reducers/index'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const ReNavigator = () => {
  const location = useLocation()
  const history = useHistory()
  const { pathname = '' } = location

  const { company, me } = useSelector((state: ReduxState) => ({ company: state.company, me: state.me }))
  const isLinkPage = pathname.includes('/link/')

  const shouldNavigateToCompany = useMemo(() => {
    const company_id = company.id || me.company_id
    return company.storeUpdatedAt && !company_id
  }, [company])

  if (shouldNavigateToCompany && !pathname.includes('/company/create') && !isLinkPage) {
    return history.push('/company/create')
  }

  return null
}

export default ReNavigator
