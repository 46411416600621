/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import _ from 'lodash'
import React, { useMemo } from 'react'

interface Props {
  event: ReactionEvent
}

function EventDescription(props: Props) {
  const { event } = props
  const { description } = event

  const lines = useMemo(() => {
    return description.split('\n')
  }, [description])

  return (
    <Grid container direction={'column'}>
      <Typography
        variant={'h2'}
        css={css`
          margin-top: 0;
        `}
      >
        Description
      </Typography>
      {_.map(lines, (line, index) => (
        <Typography key={`event-description-${index}`}>
          {line}
        </Typography>
      ))}
    </Grid>
  )
}

export default EventDescription
