/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import PlusButton from '../../../components/buttons/PlusButton'
import useAsyncHandler from '../../../hooks/useAsyncHandler'
import useModalState from '../../../hooks/useModalState'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import EventUserAddModal from '../modals/EventUserAddModal'
import EventParticipantList from './components/EventParticipantList'
import EventViewMore from './elements/EventViewMore'

interface Props {
  event: ReduxStateReactionEventItem
  isEventFinished: boolean
}
function EventParticipants(props: Props) {
  const { event, isEventFinished } = props
  const { participant_count, participant_limit, participants } = event
  const dispatch = useDispatch<ReduxDispatch>()
  const modalAddParticipant = useModalState()
  const [isExpanded, setExpanded] = useState(true)

  const actionParticipants = useAsyncFetch(async () => {
    await dispatch(EventDispatcher.getParticipants(event.id))
  })

  const actionParticipantAdd = useAsyncHandler(async (user_id: string) => {
    await dispatch(EventDispatcher.addParticipant(event.id, { user_id }))
  })

  const handleSelectUser = (user_id: string) => {
    return actionParticipantAdd.onAction(user_id)
  }

  let numTxt = ''
  if (participant_count) numTxt = `${participant_count}`
  if (participant_limit) {
    if (!numTxt) numTxt = '0'
    numTxt += ` of ${participant_limit} max`
  }
  if (numTxt) numTxt = `(${numTxt})`

  return (
    <div
      css={css`
        margin-bottom: 15px;
      `}
    >
      <Box marginBottom={'5px'} marginTop={'15px'}>
        <Divider />
      </Box>

      <EventUserAddModal
        {...modalAddParticipant}
        onUserSelect={handleSelectUser}
        event={event}
        exclude={participants}
      />

      <Grid container justifyContent={'space-between'}>
        <div>
          <Typography variant={'h2'}>Registration {numTxt}</Typography>
          <Typography>Click to mark/unmark attendance</Typography>
        </div>

        <div
          css={css`
            margin-top: 10px;
          `}
        >
          <Grid container alignItems={'center'}>
            <PlusButton onClick={modalAddParticipant.onOpen} />

            {/*<EventViewMore*/}
            {/*  isExpanded={isExpanded}*/}
            {/*  onChange={setExpanded}*/}
            {/*  hidden={!participants || participants.length <= 6}*/}
            {/*/>*/}
          </Grid>
        </div>
      </Grid>

      <div
        css={css`
          margin-top: 10px;
        `}
      >
        <RendererStatusSplit
          statuses={actionParticipants}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          // renderEmpty={() => <Typography>No Participants</Typography>}
          render={() => (
            <EventParticipantList event={event} isEventFinished={isEventFinished} isExpanded={isExpanded} />
          )}
        />
      </div>
    </div>
  )
}

export default EventParticipants
