import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ProgramModeSelectModal from '../../../modals/ProgramModeSelectModal'
import addTrainingProgram from '../assets/actions/addTrainingProgram.svg'
import { CHOOSE_CATEGORY_PAGE } from '../pages/ChooseCategory'
import ActionButton from './components/ActionButton'
import ActionButtonDefault from './components/ActionButtonDefault'

const getMode = (instance: PlaybookInstance) => {
  if (instance.program_id_auto) return 'Program Auto'
  if (instance.is_self_driven) return 'Self driven'
  return 'Manual Mode'
}

const useStyles = makeStyles(
  {
    mode: {
      color: '#F9B22D',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  {
    classNamePrefix: 'PlaybookInstancePlaybook',
  },
)

interface Props {
  instance: PlaybookInstance
}

function PlaybookInstancePlaybook(props: Props) {
  const { instance } = props
  const classes = useStyles()
  const history = useHistory()
  const [isVisible, setVisibility] = useState(false)

  const handleAddTrainingProgram = () => {
    history.push(CHOOSE_CATEGORY_PAGE(instance.id))
  }

  const handleChooseMode = e => {
    e.stopPropagation()
    e.preventDefault()

    setVisibility(true)
  }

  if (!instance.playbook)
    return <ActionButtonDefault icon={addTrainingProgram} text={'Add Playbook'} onClick={handleAddTrainingProgram} />

  const mode = getMode(instance)

  return (
    <>
      <ActionButton
        asset={instance.playbook.asset || { url: instance.playbook.thumb }}
        title={instance.playbook.name}
        description={
          <>
            Program mode:{' '}
            <span onClick={handleChooseMode} className={classes.mode}>
              {mode}
            </span>
          </>
        }
        onClick={handleAddTrainingProgram}
      />
      <ProgramModeSelectModal instance={instance} isVisible={isVisible} onClose={() => setVisibility(false)} />
    </>
  )
}

export default PlaybookInstancePlaybook
