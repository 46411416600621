import { ReduxState } from '@reducers/index'
import { actions as listActions } from '@reducers/lists/reducer'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import apiCompanyOffices from './api'

class Dispatcher {
  getList(options: DispatcherOptions): DispatcherResponse {
    const { isForce } = options || {}
    return createLazy({
      isForce,
      timeout: storeHelpers.TTL.TWO_DAYS,
      onFindStoreUpdatedAt(state: ReduxState) {
        return state.lists.officesUpdatedAt
      },
      onExecute: async (dispatch) => {
        const { offices } = await apiCompanyOffices.getList()
        dispatch(actions.set(offices))
        dispatch(listActions.setOffices(offices))
      },
    })
  }

  get(id: string, { isForce }: DispatcherOptions) {
    return createLazy({
      isForce,
      onFindStoreUpdatedAt(state: ReduxState): StoreUpdatedAt | { storeUpdatedAt: StoreUpdatedAt } {
        return state.offices[id]?.storeUpdatedAt
      },
      timeout: storeHelpers.TTL.DAY,
      async onExecute(dispatch) {
        const office = await apiCompanyOffices.getOffice(id)
        dispatch(actions.set(office))
      },
    })
  }

  createOffice(params: { name: string; address?: string }): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiCompanyOffices.createOffice(params)
      dispatch(this.getList({ isForce: true }))
    }
  }

  deleteOffice(office_id: string) {
    return async (dispatch) => {
      await apiCompanyOffices.deleteOffice(office_id)
      dispatch(actions.delete(office_id))
      dispatch(this.getList({ isForce: true }))
    }
  }
}

const OfficeDispatcher = new Dispatcher()
export default OfficeDispatcher
