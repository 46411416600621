/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
}
function CalendarStyles(props: Props) {
  const { children } = props

  return (
    <div
      css={css`
        .rbc-month-row {
          height: 120px;
          overflow: visible;
        }

        .rbc-show-more {
          margin-left: 15px;
        }

        .rbc-row {
          //height: 120px;
        }

        .rbc-date-cell {
          padding: 14px 0 5px 14px;
        }

        .rbc-now {
          .month-date {
            color: #1b48bb;
          }
        }

        .rbc-event {
          background-color: transparent;
        }

        .rbc-header {
          border: none !important;
        }

        .rbc-month-row + .rbc-month-row {
          border-color: #eeeeee;
        }

        .rbc-month-view {
          border: none;

          .rbc-today {
            border: 4px solid #1b48bb;
            background-color: #edf1fb;
            border-radius: 16px;
            height: 100%;
          }
        }

        .rbc-day-slot {
          .rbc-event {
            padding: 0;
            border: none;
            border-radius: 0;
          }

          .rbc-event-label {
            display: none;
          }

          .rbc-events-container {
            //margin-right: 0;
          }
        }

        .rbc-time-gutter {
          .rbc-timeslot-group {
            border: none;
          }
          .rbc-label {
            font-weight: bold;
            font-family: Poppins;
            color: #717579;
          }
        }

        .rbc-time-view {
          border-left: none;
          border: none;

          .rbc-row {
            min-height: auto;
          }

          .rbc-today {
            background-color: transparent;
          }

          .rbc-header {
            button {
              width: 100%;
              margin-bottom: 10px;
            }
          }

          .rbc-time-header.rbc-overflowing {
            border-color: transparent;
          }

          .rbc-time-header-content {
            border-left: none;
          }
        }
      `}
    >
      {children}
    </div>
  )
}

export default CalendarStyles
