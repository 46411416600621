/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import LogoutIcon from '@mui/icons-material/Logout'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import DashboardNextDispatcher from '@reducers/dashboardNext/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import StyledMenu from '../../../path/elements/StyledMenu'

function CompanyLeaderBoardActions() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const dispatch = useDispatch<ReduxDispatch>()
  const actionLeaveHandler = useAsyncHandler(async () => {
    await dispatch(DashboardNextDispatcher.leaveCompaniesRanking())
  })

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => setAnchorEl(null)

  const handleSelectLeaveRanking = () => {
    handleMenuClose()
    actionLeaveHandler.execute()
  }

  return (
    <div>
      <div
        onClick={handleOpenClick}
        css={css`
          cursor: pointer;
          margin-left: 10px;
        `}
      >
        <MoreVertIcon
          css={css`
            color: #ababab;
          `}
        />
      </div>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleSelectLeaveRanking} disableRipple>
          <LogoutIcon />
          <Typography color={'error'}>Leave Ranking</Typography>
        </MenuItem>
      </StyledMenu>
    </div>
  )
}

export default CompanyLeaderBoardActions
