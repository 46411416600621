/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import TerminateIcon from '@mui/icons-material/Terminate'
import { IconButton, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import theme from '../../app/theme'
import ButtonOrange from '../../components/ButtonOrange'
import ModalStyled from '../../elements/ModalStyled'

const useStyles = makeStyles({
  removeButton: {
    fontFamily: 'Poppins',
    padding: '12px 28px',
    fontSize: 16,
    border: '2px solid #FB3353',
    margin: '20px auto 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: '5px 15px',
    },
  },
  terminate: {
    color: '#E91717',
    textDecoration: 'underline',
  },
})

export const TerminateChallenge = ({ challengeId, reload }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [showTerminateDialog, setShowTerminateDialog] = useState<boolean>(false)

  const handleToggleTerminateDialog = () => {
    setShowTerminateDialog(!showTerminateDialog)
  }

  const handleTerminateChallenge = async (e) => {
    e.preventDefault()
    await dispatch(ChallengeDispatcher.terminateChallenge(challengeId))
    handleToggleTerminateDialog()
    if (reload) {
      window.location.href = '/'
    }
  }

  return (
    <Fragment>
      <a href="#" onClick={handleToggleTerminateDialog}>
        <Typography className={classes.terminate}>Terminate</Typography>
      </a>
      <ModalStyled
        isVisible={showTerminateDialog}
        onClose={handleToggleTerminateDialog}
        showCloseButton
        css={css`
          width: 520px;
        `}
      >
        <DialogTitle textAlign="center" fontSize={21} fontWeight="bold">
          Are you sure?
        </DialogTitle>
        <DialogContent align="center">
          <DialogContentText color="black" textAlign="center">
            After a challenge is terminated, members will see a countdown timer for the next challenge
          </DialogContentText>
          <ButtonOrange className={classes.removeButton} color="transparent" onClick={handleTerminateChallenge}>
            Terminate
          </ButtonOrange>
        </DialogContent>
      </ModalStyled>
    </Fragment>
  )
}
