/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import infoIcon from '../../../../../assets/Info.png'

interface Props {
  onClick(): any
}

export default function EventFieldInfo(props: Props) {
  const { onClick } = props

  return (
    <img
      src={infoIcon}
      onClick={onClick}
      alt={'info'}
      css={css`
        cursor: pointer;
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-left: 5px;
        margin-right: 5px;
      `}
    />
  )
}
