/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

function CalendarMonthHeaderComponent(props) {
  const { date } = props

  const dayMoment = moment(date)
  const dayTxt = dayMoment.format('dddd')

  return (
    <Typography
      css={css`
        color: #717579;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 20px;
      `}
    >
      {dayTxt}
    </Typography>
  )
}

export default CalendarMonthHeaderComponent
