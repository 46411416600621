/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import EventFormLabel from './EventFormLabel'

interface Props extends Omit<TextFieldProps, 'label'> {
  label: string
  onChange: TextFieldProps['onChange']
}

function EventFormInput(props: Props) {
  const { value, label, error, onChange, className } = props

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
      className={'container'}
    >
      <EventFormLabel title={label} />
      <TextField
        {...props}
        label={''} // reset label from props
        value={value}
        onChange={onChange}
        fullWidth
        error={error}
        css={css`
          .MuiInputBase-root {
            border-radius: 10px;
            border-color: #d7d7d7;
            font-family: Poppins;
          }
        `}
        className={className}
      />
    </div>
  )
}

export default EventFormInput
