import { Box, Grid, Stack, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import bookDemoImage from '../assets/book-demo.png'
import contactSupportImage from '../assets/contact-support.png'
import SectionContainer from '../components/SectionContainer'
import { makeStyles } from '@mui/styles'
import ButtonOrange from '../../../components/ButtonOrange'
import classnames from 'classnames'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import mastercardImg from '../assets/mastercard.png'
import visaImg from '../assets/visa.png'
import creditCardFrom from '../../payment/assets/credit-card.png'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import paymentApi from '../../../libs/api/paymentApi'
import LoadingModal from '../../../modals/LoadingModal'
import CompanyDispatcher from '@reducers/company/dispatcher'
import moment from 'moment-timezone'
import secureStripePaymentImg from '../../payment/assets/secure-payment-stripe.png'

const mapBrandToImage = {
  mastercard: mastercardImg,
  visa: visaImg,
}

const useStyles = makeStyles({
  helpImageContainer: {
    width: 136,
    height: 136,
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  helpImage: {
    width: '100%',
  },
  actionButton: {
    fontFamily: 'Poppins',
    padding: '12px 43px',
    fontSize: 18,
    border: '2px solid rgb(249, 178, 45)',
    borderRadius: 8,
    marginTop: 20,
  },
})

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      display: 'block',
      backgroundColor: '#F6F6F6',
      color: '#000000',
      borderRadius: 15,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#C4C4C4',
      },
    },
    invalid: {},
  },
}

const SettingBilling = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false)
  const company = useSelector((state: ReduxState) => state.company)
  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  // stripe items
  const stripe = useStripe()
  const elements = useElements()

  const handleUpgrade = () => {
    mixpanel.track('Upgrade Now Clicked', {
      distinct_id: company?.id,
    })
    history.push('/payment')
  }
  const handleBookDemo = () => {
    window.open('https://calendly.com/d/4mb-wnk-yf9/reaction-s-platform-demo')
  }
  const handleContactSupport = () => {
    window.open('mailto:hello@reaction-club.com')
  }

  const handleChangePaymentMethod = async () => {
    try {
      setLoading(true)
      const cardNumber = elements.getElement(CardNumberElement)
      const paymentToken = await stripe?.createToken(cardNumber)

      const paymentMethod = await paymentApi.addPaymentMethod({ token: paymentToken.token.id })

      await paymentApi.changeSubscriptionPaymentMethod({
        subscriptionId: company?.subscriptionPlan?.subscriptionId,
        paymentMethodId: paymentMethod.paymentMethodId,
      })
      await dispatch(CompanyDispatcher.getCompany())

      alert('Successfully Changed Payment Method')
      setIsUpdatingPaymentMethod(false)
    } catch (error) {
      alert(error.response.data)
    } finally {
      setLoading(false)
    }
  }

  const selectedPaymentMethod = company.subscriptionPlan.paymentMethod

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <LoadingModal isVisible onClose={() => {}} message={`Processing...`} />}
        <SectionContainer>
          <Grid
            container
            spacing={10}
            textAlign="center"
            justifyContent={isFreeTrial ? 'space-around' : 'flex-start'}
            mb={15}
          >
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <PanelContainer>
                    <PanelHeader>
                      <Typography variant="h1" mr="auto">
                        Current Plan
                      </Typography>
                      <ChangePlanButton onClick={handleUpgrade} color="#5b89ff">
                        {isFreeTrial ? 'Upgrade' : 'Change'}
                      </ChangePlanButton>
                    </PanelHeader>

                    <PanelBody>
                      <Grid container>
                        <Grid item xs={4} px={4}>
                          <Typography fontSize={12} fontWeight="500" color="#696969">
                            PLAN NAME
                          </Typography>
                          <Typography fontSize={16} fontWeight="600" color="#2C2C2C">
                            {isFreeTrial ? 'FREE' : company?.subscriptionPlan?.type}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} px={4}>
                          <Typography fontSize={12} fontWeight="500" color="#696969">
                            BILLING CYCLE
                          </Typography>
                          <Typography fontSize={16} fontWeight="600" color="#2C2C2C" textTransform="capitalize">
                            {company?.subscriptionPlan?.intervalUnit}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} px={4}>
                          <Typography fontSize={12} fontWeight="500" color="#696969">
                            PLAN COST
                          </Typography>
                          <Typography fontSize={16} fontWeight="600" color="#2C2C2C">
                            {isFreeTrial ? '$0' : `$${company?.subscriptionPlan?.invoicePrice}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography fontSize={12} fontWeight="500" color="#696969" mt={6}>
                        Usage
                      </Typography>
                      <Typography fontSize={14} fontWeight="500" color="#2C2C2C">
                        {company?.employee_count || 0} out of {company?.subscriptionPlan?.quantity || 3} members
                      </Typography>
                      {!company?.subscriptionPlan?.cancelAt ? null : (
                        <Typography color="error" fontSize={12}>
                          This subscription was cancelled, the subscription will expire at{' '}
                          {moment(company?.subscriptionPlan?.expireAt).format('yyyy, MMMM DD')}
                        </Typography>
                      )}
                      {company?.subscriptionPlan?.renewsAt ? (
                        <Typography fontSize={12}>
                          The subscription will be renewed at{' '}
                          {moment(company?.subscriptionPlan?.renewsAt).format('yyyy, MMMM DD')}
                        </Typography>
                      ) : null}
                    </PanelBody>
                  </PanelContainer>
                </Grid>
                <Grid item xs={6}>
                  {selectedPaymentMethod ? (
                    <PanelContainer>
                      <PanelHeader>
                        <Typography variant="h1">Payment Method</Typography>
                      </PanelHeader>
                      <PanelBody>
                        {isUpdatingPaymentMethod ? (
                          <ChangeCardContainer>
                            <CardContainer>
                              <CreditCardImage src={creditCardFrom} />
                              <Box>
                                <Label>Card Number</Label>
                                <ElementContainer>
                                  <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                                </ElementContainer>
                              </Box>

                              <Stack gap={3} direction="row" mt={1}>
                                <Box width={140}>
                                  <Label>Expiry date</Label>
                                  <ElementContainer>
                                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                                  </ElementContainer>
                                </Box>
                                <Box width={110}>
                                  <Label>Security code</Label>
                                  <ElementContainer>
                                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                                  </ElementContainer>
                                </Box>
                              </Stack>
                            </CardContainer>
                            <SecureStripPaymentImg src={secureStripePaymentImg} />
                            <PurchaseButton onClick={handleChangePaymentMethod}>Change</PurchaseButton>
                            <CancelButton onClick={() => setIsUpdatingPaymentMethod(false)} color="#323232">
                              Cancel
                            </CancelButton>
                          </ChangeCardContainer>
                        ) : (
                          <CreditCard direction="row" gap={4}>
                            {mapBrandToImage[selectedPaymentMethod.brand] ? (
                              <CreditCardImg src={mapBrandToImage[selectedPaymentMethod.brand]} />
                            ) : null}
                            <Box>
                              <Typography fontWeight="600" textTransform="capitalize">
                                {selectedPaymentMethod.brand}
                              </Typography>
                              {company?.subscriptionPlan?.isPaymentFailed ? (
                                <Typography color="error">Payment failed</Typography>
                              ) : null}
                              <Typography fontWeight="600">**** **** **** {selectedPaymentMethod.last4}</Typography>
                              <Typography fontWeight="600" color="#696969" fontSize={12}>
                                Expiry on {selectedPaymentMethod.expMonth}/{selectedPaymentMethod.expYear}
                              </Typography>
                            </Box>
                            <Box sx={{ ml: 'auto' }}>
                              <ChangePaymentMethodButton color="white" onClick={() => setIsUpdatingPaymentMethod(true)}>
                                Change
                              </ChangePaymentMethodButton>
                            </Box>
                          </CreditCard>
                        )}
                      </PanelBody>
                    </PanelContainer>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SectionContainer>
        <SectionContainer>
          <Typography fontSize={21} fontWeight="bold" mb={10} color="#4C535F" fontFamily="Manrope">
            Need help?
          </Typography>
          <Grid container spacing={10}>
            <Grid item>
              <Grid container>
                <Grid item width={136} mr={5}>
                  <Box className={classes.helpImageContainer}>
                    <img src={bookDemoImage} className={classes.helpImage} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography fontSize={16} color="#4C535F" fontFamily="Manrope">
                    Schedule a call with one of our product
                    <br /> experts and discover how you can optimize
                    <br /> your experience on the Reaction platform
                  </Typography>
                  <ButtonOrange
                    className={classnames(classes.actionButton)}
                    color="transparent"
                    onClick={handleBookDemo}
                  >
                    Book a demo
                  </ButtonOrange>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item width={136} mr={5}>
                  <Box className={classes.helpImageContainer}>
                    <img src={contactSupportImage} className={classes.helpImage} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography fontSize={16} color="#4C535F" fontFamily="Manrope">
                    Our support team is here to help
                    <br /> with any questions you may have!
                  </Typography>
                  <ButtonOrange
                    className={classnames(classes.actionButton)}
                    color="transparent"
                    onClick={handleContactSupport}
                  >
                    Contact support
                  </ButtonOrange>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SectionContainer>
      </Grid>
    </Grid>
  )
}

const PanelContainer = styled(Box)`
  border: 1px solid #ececec;
  border-radius: 8px;
  height: 100%;
`

const PanelHeader = styled(Box)`
  padding: 15px;
  background-color: #f8f8f8;
  text-align: left;
  display: flex;
  align-items: center;
`

const PanelBody = styled(Box)`
  padding: 20px;
  text-align: left;
`

const CreditCard = styled(Stack)`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ececec;
`

const CreditCardImg = styled('img')`
  width: 46px;
  height: 100%;
`

const ChangeCardContainer = styled('div')`
  width: 569px;
`

const CardContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding: 140px 25px 25px;
  border: 1px solid #ccc;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  width: 569px;
  height: 329px;
`
const CreditCardImage = styled('img')`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

const PurchaseButton = styled(ButtonOrange)`
  color: white;
  font-size: 15px;
  padding: 10px 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(4)};
`

const ChangePlanButton = styled(ButtonOrange)`
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
`

const ChangePaymentMethodButton = styled(ChangePlanButton)`
  color: #696969;
  border: 1px solid #ececec;
`

const CancelButton = styled(PurchaseButton)``

const Label = styled('label')`
  font-size: 11px;
  margin-bottom: 4px;
  z-index: 10;
  position: relative;
  color: #666666;
  font-family: Poppins;
`

const ElementContainer = styled('div')`
  position: relative;
  z-index: 10;
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 15px;
  margin-top: 4px;
`

const SecureStripPaymentImg = styled('img')`
  height: 22px;
  display: block;
  margin: 16px auto;
`

export default SettingBilling
