import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'
import { birdIcon } from '../../assets/facade'

const useStyles = makeStyles(
  {
    item: {
      width: '48%',
      background: '#F4F4F4',
      borderRadius: 5,
      padding: '10px 15px',
      marginTop: 10,
    },
    name: {
      marginLeft: 10,
      fontSize: 14,
      fontWeight: 800,
    },
    absent: {
      opacity: '0.6',
    },
  },
  {
    classNamePrefix: 'ReportParticipantItem',
  },
)

interface Props {
  participant: Immutable<EventParticipant>
}

function ReportParticipantItem(props: Props) {
  const { participant } = props
  const classes = useStyles()
  const { name, avatar_url, check_in } = participant || {}

  return (
    <Grid item className={classNames(classes.item, { [classes.absent]: !check_in })}>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Grid container direction={'row'} alignItems={'center'}>
            <ReactionAvatar resizeMode={'contain'} asset={{ url: avatar_url }} />
            <Typography className={classes.name}>{name}</Typography>
          </Grid>
        </Grid>
        <Grid>{check_in && <img src={birdIcon} alt="bird" />}</Grid>
      </Grid>
    </Grid>
  )
}

export default ReportParticipantItem
