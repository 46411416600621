import CssBaseline from '@mui/material/CssBaseline'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import AppContext from './AppContext'
import Routes, { history } from './Router'
import StatusListeners from './StatusListeners'
import AppLauncher from './components/AppLauncher'
import Modals from './components/Modals'
import { BugsnagErrorBoundary } from './libs/bugSnag'
import { persistor } from './store'

import './hooks/init'
import './tools/authorizeFirebase'

mixpanel.init('92263f9c2393d7dab5b93d182bd32b15', { debug: true, track_pageview: true, persistence: 'localStorage', ignore_dnt: true })
const urlParams = new URLSearchParams(window.location.search);
const distinctId = urlParams.get('distinct_id');
console.log('distinct ID', urlParams, distinctId, distinctId?.replace('$device:', ''))
console.log("mixpanel distinct id before", mixpanel.get_distinct_id())
mixpanel.track('test-hr-before-identify')
mixpanel.identify(distinctId?.replace('$device:', ''))
console.log("mixpanel distinct id after", mixpanel.get_distinct_id())
mixpanel.track('test-hr-after-identify')

export default () => (
  <BugsnagErrorBoundary>
    <AppContext>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <AppLauncher>
            <StatusListeners />
            <Modals />
            <CssBaseline />

            <Routes />
          </AppLauncher>
        </Router>
      </PersistGate>
    </AppContext>
  </BugsnagErrorBoundary>
)
