/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChallengeListsDispatcher from '@reducers/challengeLists/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Container from '../../components/Container'
import ReactionLoader from '../../components/ReactionLoader'
import { OpenSans } from '../../helpers/commonStyles'
import Challenge from './groups/Challenge'
import ChallengeItem from './groups/ChallengeItem'
import theme from '../../app/theme'

const useStyles = makeStyles(
  {
    rootContainer: {
      padding: '50px 0',
      fontFamily: OpenSans,
      position: 'relative',
      margin: '0 30px',
    },

    itemsContainer: {
      padding: 30,
      [theme.breakpoints.down('md')]: {
        padding: ' 15px 0',
      },
    },

    programsImage: {
      maxWidth: 260,
    },
    freeTrialContainer: {
      padding: '50px 50px 40px',
    },
    break: {
      backgroundColor: '#E2DFDF',
      height: 1,
      margin: '26px 0',
    },
    plank: {
      backgroundColor: '#E2DFDF',
      height: 1,
      margin: '26px 0',
    },
  },
  {
    classNamePrefix: 'Groups',
  },
)

function Groups() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { challenges, challengeApplications } = useSelector((state) => state.challengeLists)

  const dataFetchChallengesAction = useAsyncFetch(async () => {
    await dispatch(ChallengeListsDispatcher.getChallenges())
    await dispatch(ChallengeListsDispatcher.getChallengeApplications())
  })

  return (
    <Container>
      <RendererStatusSplit
        statuses={dataFetchChallengesAction}
        isEmpty={_.isEmpty(challenges)}
        renderLoading={() => <ReactionLoader marginTop={40} />}
        render={() => (
          <React.Fragment>
            {challenges?.length ? (
              <React.Fragment>
                <Typography variant="h1" mb={2}>
                  Active team activities
                </Typography>
                <Grid container className={classes.itemsContainer} gap={4}>
                  {_.map(challenges, (c) => (
                    <Challenge key={c.id} challenge={c} />
                  ))}
                </Grid>
                <div className={classes.break} />
              </React.Fragment>
            ) : null}
            <Typography variant="h1" mb={2}>
              Select team activities
            </Typography>
            <Grid container className={classes.itemsContainer}>
              {_.map(challengeApplications, (a) => (
                <ChallengeItem
                  key={a.id}
                  id={a.id}
                  title={a.title}
                  description={a.description}
                  comingSoon={!a.is_published}
                  icon={a.icon_url}
                />
              ))}
            </Grid>
          </React.Fragment>
        )}
      />
    </Container>
  )
}

export const GROUP_LIST_URL = '/groups'

export default Groups
