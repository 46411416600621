import { ReduxState } from '@reducers/index'
import { actions as listActions } from '@reducers/lists/reducer'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import apiCompanyTeams from './api'

class Dispatcher {
  getList(options: DispatcherOptions): DispatcherResponse {
    const { isForce } = options || {}
    return createLazy({
      isForce,
      timeout: storeHelpers.TTL.TWO_DAYS,
      onFindStoreUpdatedAt(state: ReduxState) {
        return state.lists.teamsUpdatedAt
      },
      onExecute: async (dispatch) => {
        const { teams } = await apiCompanyTeams.getList()
        dispatch(actions.set(teams))
        dispatch(listActions.setTeams(teams))
      },
    })
  }

  get(id: string, { isForce }: DispatcherOptions) {
    return createLazy({
      isForce,
      onFindStoreUpdatedAt(state: ReduxState): StoreUpdatedAt | { storeUpdatedAt: StoreUpdatedAt } {
        return state.teams[id]?.storeUpdatedAt
      },
      timeout: storeHelpers.TTL.DAY,
      async onExecute(dispatch) {
        const team = await apiCompanyTeams.getTeam(id)
        dispatch(actions.set(team))
      },
    })
  }

  createTeam(params: { name: string; description?: string }): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiCompanyTeams.createTeam(params)
      dispatch(this.getList({ isForce: true }))
    }
  }

  updateTeam(team_id: string, params: { name: string }): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiCompanyTeams.updateTeam(team_id, params)
      dispatch(this.getList({ isForce: true }))
    }
  }

  deleteTeam(team_id: string) {
    return async (dispatch) => {
      await apiCompanyTeams.deleteTeam(team_id)
      dispatch(actions.delete(team_id))
      dispatch(this.getList({ isForce: true }))
    }
  }

  moveTeamMembers(id: string, userIds: string[]): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiCompanyTeams.moveTeamMembers(id, userIds)
    }
  }

  removeTeamMembers(userIds: string[]): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiCompanyTeams.removeTeamMembers(userIds)
    }
  }

  getTeamPotentialMembers(id: string, params: { name?: string; teamId: string }): DispatcherResponse<void> {
    return async (dispatch) => {
      return await apiCompanyTeams.getTeamPotentialMembers(id, params)
    }
  }

  getTeamMembers(id: string): DispatcherResponse<void> {
    return async (dispatch) => {
      return await apiCompanyTeams.getTeamMembers(id)
    }
  }
}

const TeamDispatcher = new Dispatcher()
export default TeamDispatcher
