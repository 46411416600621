import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import bellIcon from './sideBar/assets/bell.svg'

const useStyles = makeStyles(
  {
    badge: {
      width: 20,
      height: 20,
      borderRadius: 10,
      backgroundColor: ' #e84848',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      color: '#fff',
    },
  },
  {
    classNamePrefix: 'UnreadCounter',
    flip: false,
  },
)

interface Props {
  unreadCount?: number
  isIconMode?: boolean
  className?: string
}

const UnreadCounter: React.FC<Props> = ({ unreadCount, isIconMode, className }) => {
  const classes = useStyles()

  if (!unreadCount) return null
  const classNameAll = classNames(classes.badge, className)

  if (isIconMode) {
    return (
      <span className={classNameAll}>
        <img src={bellIcon} alt="" width={10} />
      </span>
    )
  }

  return <span className={classNameAll}>{unreadCount}</span>
}

export default UnreadCounter
