import { Coach, Employee, Group } from '@reaction-club-types/core'
import types from './types'

const groupActions = {
  set: (groups: Group | Group[]): ReduxAction => ({
    type: types.SET,
    payload: groups,
  }),
  setPlaybookInstanceEmployees: (payload: { employees: Employee[]; instance_id: string }): ReduxAction => ({
    type: types.SET_PLAYBOOK_INSTANCE_EMPLOYEES,
    payload,
  }),
  setPlaybookInstanceCoaches: (payload: { coaches: Coach[]; instance_id: string }): ReduxAction => ({
    type: types.SET_PLAYBOOK_INSTANCE_COACHES,
    payload,
  }),
  deleteInstance: (instance_id) => ({ type: types.DELETE_PLAYBOOK_INSTANCE, payload: instance_id }),

  // old
  setPlaybookInstance: (playbookInstance) => ({ type: types.SET_PLAYBOOK_INSTANCE, payload: playbookInstance }),
  setPlaybookEmployeesInstances: (playbookEmployeesInstances) => ({
    type: types.SET_PLAYBOOK_EMPLOYEES_INSTANCE,
    payload: playbookEmployeesInstances,
  }),
  setPlaybookCoachesInstances: (playbookCoachesInstances) => ({
    type: types.SET_PLAYBOOK_COACHES_INSTANCE,
    payload: playbookCoachesInstances,
  }),
  updatePlaybookInstance: (newPlaybookInstance) => ({
    type: types.UPDATE_PLAYBOOK_INSTANCE,
    payload: newPlaybookInstance,
  }),
  resetStoredPlaybookInstance: () => ({ type: types.RESET_STORED_PLAYBOOK_INSTANCE }),
  loaded: () => ({ type: types.LOADED }),
  loading: () => ({ type: types.LOADING }),
  setInviteLinks: (links) => ({ type: types.SET_PLAYBOOK_INVITE, payload: links }),
  setInviteCoachLinks: (links) => ({ type: types.SET_PLAYBOOK_COACH_INVITE, payload: links }),
}

export default groupActions
