import { Grid } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(
  {
    container: {
      padding: 10,

      '&:hover': {
        backgroundColor: '#dfdfdf',
      },

      '&:hover $delete': {
        display: 'block',
      },
    },

    delete: {
      color: '#f60707',
      cursor: 'pointer',
      display: 'none',
    },
  },
  {
    classNamePrefix: 'CompanyEmailItem',
  },
)

interface Props {
  email: string
  onDelete(email: string): any
}

function CompanyEmailItem(props: Props) {
  const { email, onDelete } = props
  const classes = useStyles()

  const handleClickDelete = () => {
    onDelete(email)
  }

  return (
    <div className={classes.container}>
      <Grid container justifyContent={'space-between'}>
        <span>{email}</span>

        <span className={classes.delete} onClick={handleClickDelete}>
          delete
        </span>
      </Grid>
    </div>
  )
}

export default CompanyEmailItem
