/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import ShareIcon from '@mui/icons-material/Share'
import { Grid, Typography } from '@mui/material'
import { EventRecording } from '@reaction-club-types/core'
import moment from 'moment'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import DurationTimer from '../../../../components/duration/DurationTimer'
import useModalState from '../../../../hooks/useModalState'
import EventRecordingShareModal from '../../../events/modals/EventRecordingShareModal'
import RecordingMenuActions from './RecordingMenuActions'

interface Props {
  title: string
  recording: EventRecording | Immutable<EventRecording>
}

function EventRecordingItem(props: Props) {
  const { title, recording } = props
  const { url, view_count, duration } = recording
  const modalShareState = useModalState()

  const durationMoment = moment.duration(duration, 'seconds')

  return (
    <div
      css={css`
        margin-bottom: 40px;
        margin-right: 40px;
      `}
    >
      <EventRecordingShareModal {...modalShareState} url={url} />
      <video
        src={url}
        controls
        title={title}
        css={css`
          width: 400px;
          height: 210px;
          background-color: #000;
        `}
      />
      <Grid container alignItems={'center'}>
        <div>
          <Typography>
            Duration: <DurationTimer duration={durationMoment} />
          </Typography>
        </div>
        <div
          css={css`
            margin-left: 10px;
            flex-grow: 1;
          `}
        >
          <Typography>Total views: {view_count}</Typography>
        </div>

        <div>
          <ShareIcon
            onClick={modalShareState.onOpen}
            css={css`
              color: #707579;
              font-size: 18px;
              cursor: pointer;
            `}
          />
        </div>
        <RecordingMenuActions recording={recording} />
      </Grid>
    </div>
  )
}

export default EventRecordingItem
