import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Moment } from 'moment'
import React from 'react'
import EventFormInput from './elements/EventFormInput'

interface Props {
  value: Moment
  onChange(type: Moment | null): any
  isErrored?: boolean
}

export default function EventDateSelect(props: Props) {
  const { value, onChange, isErrored } = props

  const handleChange = (date: Moment | null) => {
    onChange(date)
  }

  return (
    <div>
      <DesktopDatePicker
        label="Date"
        inputFormat="MM/DD/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={(params) => <EventFormInput {...params} error={isErrored} />}
      />
    </div>
  )
}
