// import beActive from '../../../components/Modals/components/createFirstGroup/assets/examples/beActive.png'
// import findYourBalance from '../../../components/Modals/components/createFirstGroup/assets/examples/findYourBalance.png'
// import healthChallenge from '../../../components/Modals/components/createFirstGroup/assets/examples/healthChallenge.png'
// import running from '../../../components/Modals/components/createFirstGroup/assets/examples/running.png'

export default [
  {
    name: 'Be Active',
    description: 'Small changes can make a big difference. Start being more active in your day to day life.',
    asset: { url: '/images/examples/beActive.png' },
    program: 'Stay Active',
  },
  {
    name: 'Find Your Balance',
    description: 'Take a break from your stressful routine. Find calm in your day.',
    asset: { url: '/images/examples/findYourBalance.png' },
    program: 'Find Your Balance',
  },
  {
    name: 'Health Challenge',
    description:
      '7 days of wellness challenges. Try complete a challenge a day over this next week. Earn your wellness points and hit the top of the points table.',
    asset: { url: '/images/examples/healthChallenge.png' },
    program: "Let's get started",
  },
  {
    name: 'Running',
    description: 'Start your running journey or up your game by running longer or faster- or both!',
    asset: { url: '/images/examples/running.png' },
    program: 'Running',
  },
]
