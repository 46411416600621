/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { faTrashCan, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Grid } from '@mui/material'
import { EventParticipant } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import Loader from '../../../../components/Loader'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  user: EventParticipant
  isEventFinished: boolean
}
function EventParticipantItem(props: Props) {
  const { user, isEventFinished } = props
  const { user_id, name, check_in, avatar_url, points, event_id } = user
  const dispatch = useDispatch<ReduxDispatch>()

  const actionCheckIn = useAsyncHandler(async (isCheckedIn: boolean) => {
    await dispatch(EventDispatcher.checkInUser(event_id, { user_id, status: isCheckedIn }))
  })

  const actionRemove = useAsyncHandler(async () => {
    await dispatch(EventDispatcher.removeParticipant(event_id, { user_id }))
  })

  const handleCheckInChange = async (e) => {
    const isCheckedNext = !check_in
    await actionCheckIn.execute(isCheckedNext)
  }

  const handlePressDelete = () => {
    if (!confirm('Are you sure want to remove this user from event?')) return null
    actionRemove.execute()
  }

  return (
    <Grid
      container
      alignItems={'center'}
      onClick={handleCheckInChange}
      css={[
        css`
          background-color: #f4f4f4;
          padding: 10px 20px 10px 10px;
          margin-bottom: 10px;
          margin-right: 40px;
          max-width: 400px;
          cursor: pointer;
          position: relative;
        `,
        !check_in &&
          css`
            opacity: 0.5;
          `,
      ]}
    >
      <div
        css={css`
          width: 25px;
        `}
      >
        {check_in ? (
          <FontAwesomeIcon
            icon={faCheck}
            css={css`
              color: #00c305;
            `}
          />
        ) : null}
      </div>
      <Avatar src={avatar_url} style={{ width: 30, height: 30, marginRight: '10px' }} />
      <div
        css={css`
          width: 1px;
          flex-grow: 1;
        `}
      >
        <span>{name}</span>
      </div>

      <div>
        <Grid
          container
          alignItems={'center'}
          css={css`
            margin-left: 10px;
          `}
        >
          {points ? (
            <span
              css={css`
                color: #3b930c;
                font-size: 12px;
                display: inline-block;
                margin-left: 10px;
              `}
            >
              +{points} Points
            </span>
          ) : null}

          {isEventFinished ? null : (
            <div
              onClick={handlePressDelete}
              aria-hidden="true"
              css={css`
                cursor: pointer;
                margin-left: 10px;
                margin-right: 10px;
                padding: 0 5px;
              `}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                color={'#929292'}
                css={css`
                  font-size: 17px;
                `}
              />
            </div>
          )}
        </Grid>
      </div>
      {actionCheckIn.isLoading && (
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          css={css`
            position: absolute;
            zindex: 100;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.7);
          `}
        >
          <Loader />
        </Grid>
      )}
    </Grid>
  )
}

export default EventParticipantItem
