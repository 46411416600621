import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ResetPasswordForm from './pages/ResetPasswordForm'
import ResetPasswordRequest from './pages/ResetPasswordRequest'

// Example URL from email: https://hr-dev.reaction-club.app/reset-password/892abf12-0ccc-492e-a721-18f2505ab689

const prefix = '/reset-password'
class ResetPassword extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path={`${prefix}/request`} component={ResetPasswordRequest} />
        <Route path={`${prefix}/:id`} component={ResetPasswordForm} />

        <Redirect to={`${prefix}/request`} />
      </Switch>
    )
  }
}

export default ResetPassword
