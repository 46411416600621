import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonOrangeSquare from '../../../modals/components/ButtonOrangeSquare'

function EventCreateButton() {
  const history = useHistory()

  const handlePress = () => {
    history.push('/event-create')
  }

  return (
    <div>
      <ButtonOrangeSquare onClick={handlePress}>+New Event</ButtonOrangeSquare>
    </div>
  )
}

export default EventCreateButton
