import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles({
  item: {
    borderRadius: 8,
    padding: '10px 0',
    width: '100%',
    border: '1px solid #D4D4D4',
    marginTop: 10,
    cursor: 'pointer',
    height: 56,
  },
  innerWrapper: {
    height: '100%',
  },
  caption: {
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 800,
    textDecoration: 'underline',
  },
})

interface Props {
  event: ReactionEventFull
  onClick: () => any
}

function EventItem(props: Props) {
  const { event, onClick } = props
  const classes = useStyles()

  const date = moment(event.time).format('DD.MM.YY')
  const { name, coach, participant_count = 0, participant_attended_count = 0, participant_unknown_count = 0 } = event

  const countTotal = participant_attended_count + participant_unknown_count

  const unknownStr = participant_unknown_count ? `(${participant_unknown_count} unknown)` : ''
  const arrivedStr = `${countTotal} ${unknownStr} / ${participant_count}`

  const coachName = coach?.name

  return (
    <Grid item className={classes.item} key={event.id} onClick={onClick}>
      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={classes.innerWrapper}
      >
        <Grid item xs={3}>
          <Typography className={classes.caption}>{date}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.caption}>{name}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.caption}>{coachName}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classNames(classes.caption, classes.bold)}>{arrivedStr}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EventItem
