/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Grid, Typography } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../components/Loader'
import PlusButton from '../../../../components/buttons/PlusButton'

interface Props {
  employee: Employee
  onSelect: (user_id: string) => any
}

const cssContainer = css`
  margin: 3px 0;
`

function EmployeeListItem(props: Props) {
  const { employee, onSelect } = props
  const { name, avatar_url, user_id, points } = employee

  const addAction = useAsyncHandler(async () => {
    await onSelect(user_id)
  })

  const handleAdd = () => {
    addAction.execute()
  }

  return (
    <div css={cssContainer}>
      <Grid container alignItems={'center'}>
        <Avatar src={avatar_url} />
        <div
          css={css`
            margin-top: 5px;
            margin-left: 20px;
            flex-grow: 1;
          `}
        >
          <Typography variant={'h4'}>{name}</Typography>
        </div>

        <div>
          <RendererStatusSplit
            statuses={addAction}
            renderLoading={() => <Loader scale={0.3} />}
            render={() => <PlusButton onClick={handleAdd} />}
          />
        </div>
      </Grid>
    </div>
  )
}

export default EmployeeListItem
