import { Grid, Typography } from '@mui/material'
import { withStyles, WithStyles } from '@mui/styles'
import { PlaybookInstanceExtended } from '@reducers/playbookInstances/reducer'
import _ from 'lodash'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { smallPlusIcon } from '../assets/facade'
import GroupCoachItem from './GroupCoachItem'

const styles = {
  container: {
    padding: '20px 28px',
    border: '1px solid #D4D4D4',
    borderRadius: 5,
    marginTop: 50,
  },
  listContainer: {
    marginTop: 26,
  },
  caption: {
    fontSize: 16,
    fontWeight: 800,
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
}

interface Props {
  instance: PlaybookInstanceExtended
  handleMakePrimary: (coach_id: string) => any
  handleCoachKick: (coach_id: string) => any
  handleChangeRole: (coach_id: string, role: string) => any
}

function GroupCoachesList(props: Props & WithStyles<typeof styles>) {
  const { instance, classes, handleMakePrimary, handleCoachKick, handleChangeRole, history } = props
  if (!instance.coaches) return
  const { coaches } = instance
  const coachesList = _.filter(coaches, { is_primary: false }) as Coach[]

  return (
    <Grid container direction={'row'} className={classes.container}>
      <Grid item xs={12}>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Typography className={classes.caption}>Group Coaches</Typography>
          <button
            type="button"
            className={classes.button}
            onClick={() => history.push(`/coaches/invite/${instance.id}`)}
          >
            <img src={smallPlusIcon} alt="plus icon" />
          </button>
        </Grid>
      </Grid>
      <Grid className={classes.listContainer} container>
        {coachesList.map(coach => (
          <GroupCoachItem
            key={coach.user_id}
            coach={coach}
            handleMakePrimary={handleMakePrimary}
            handleChangeRole={handleChangeRole}
            handleCoachKick={handleCoachKick}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default compose(React.memo, withStyles(styles), withRouter)(GroupCoachesList) as any
