import { Link } from '@reaction-club-types/core'
import { AxiosRequestConfig } from 'axios'
import apiClient from '../../libs/api/libs/apiClient'

export interface ConfigIdentifiersCheckResponse {
  totalCreated: number
  totalRemoved: number
  usersRemoved: {
    identifier?: string | null
    user_id: string
    name: string
    avatar_url?: string
  }[]
}

const preferences = {
  async setStockEnabled(payload: { status: boolean }) {
    const { data } = await apiClient.post('/company/preferences/set-stocks-enabled', payload)
    return data
  },
}

const apiCompany = {
  preferences,
  async getCompany() {
    const { data } = await apiClient.get('/company')
    return data
  },
  async getCompanyCoaches() {
    const { data } = await apiClient.get('/company/coaches')
    return data
  },
  async deleteCoach(coach_id: string) {
    const { data } = await apiClient.delete(`/company/coach/${coach_id}`)
    return data
  },
  async getCoachGroups(id) {
    const { data } = await apiClient.get(`/coach/${id}/playbook-instances`)
    return data
  },
  async getCompanyEmployees() {
    const { data } = await apiClient.get('/company/employees')
    return data
  },

  async getAgents() {
    const { data } = await apiClient.get('/agents')
    return data
  },
  // async getAgent(id) {
  //   const { data } = await apiClient.get(`/agent/${id}`)
  //   return data
  // },
  async deleteAgent(id) {
    const { data } = await apiClient.delete(`/agent/${id}`)
    return data
  },

  async createCompany(payload: { name: string; asset_id?: string }): Promise<{ company_id: string }> {
    const result = await apiClient.post(`/company/create`, payload)
    return result.data
  },

  async editCompany(company: { name: string; logo: string }): Promise<{ company_id: string }> {
    const result = await apiClient.post(`/company`, company)
    return result.data
  },

  async updateTimezone(timezone: string): Promise<any> {
    const result = await apiClient.post(`/company/tz`, { timezone })
    return result.data
  },

  async createInviteMemberLink(): Promise<Link> {
    const result = await apiClient.post(`/employee/create-invite`)
    return result.data
  },

  async createInviteHrLink(): Promise<Link> {
    const result = await apiClient.post(`/create-invite`)
    return result.data
  },

  async processInvitationHrLink(id): Promise<Link> {
    const result = await apiClient.post(`/link/${id}`)
    return result.data
  },
  async getInvitation(invite_id: string): Promise<Link> {
    const result = await apiClient.get(`/link/${invite_id}`)
    return result.data
  },
  async denyInvitation(): Promise<any> {
    const result = await apiClient.post(`/deny-invitation`)
    return result.data
  },
  async getInvitationHrLinkOverview(link: string): Promise<Link> {
    const result = await apiClient.get(`/link/overview/?link=${link}`)
    return result.data
  },

  async getStickers(): Promise<CompanySticker[]> {
    const result = await apiClient.get(`/company/stickers`)
    return result.data
  },

  async updateSticker(sticker_id: string): Promise<void> {
    const result = await apiClient.post(`/company/sticker`, { asset_id: sticker_id })
    return result.data
  },

  async getEmailList(): Promise<void> {
    const result = await apiClient.get(`/company/email/list`)
    return result.data
  },

  async addCorporateEmail(email: string): Promise<void> {
    const result = await apiClient.post(`/company/email/add`, { email })
    return result.data
  },

  async deleteCorporateEmail(email: string): Promise<void> {
    const result = await apiClient.post(`/company/email/delete`, { email })
    return result.data
  },

  async setAuthType(type: string): Promise<void> {
    const result = await apiClient.post(`/company/config/auth-type`, { type: type || null })
    return result.data
  },

  async getEventConfigs(): Promise<void> {
    const result = await apiClient.get(`/company/event-config`)
    return result.data
  },

  async setPenaltyEnabled(isEnabled: boolean): Promise<void> {
    const result = await apiClient.post(`/company/event-config/set-penalty-enabled`, { status: isEnabled })
    return result.data
  },

  async setPenaltyDays(params: { penalty_duration: number; penalty_start_offset: number }): Promise<void> {
    const result = await apiClient.post(`/company/event-config/set-penalty-days`, params)
    return result.data
  },

  async checkXlsxIdentifiers(
    file: File,
    options?: Partial<AxiosRequestConfig>,
  ): Promise<ConfigIdentifiersCheckResponse> {
    const formData = new FormData()
    formData.append('file', file)

    const result = await apiClient.post('/company/config/identifiers/xlsx-check', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })

    return result.data
  },

  async applyXlsxIdentifiers(file: File, options?: Partial<AxiosRequestConfig>): Promise<void> {
    const formData = new FormData()
    formData.append('file', file)

    const result = await apiClient.post('/company/config/identifiers/xlsx-apply', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })

    return result.data
  },

  async setTeamSettings(data): Promise<any> {
    const result = await apiClient.put(`/company/team-settings`, data)
    return result.data
  },
}

export default apiCompany
