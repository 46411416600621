import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ModalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import PlaybookInstanceActionDispatcher from '@reducers/playbookInstances/dispatcher'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import ChangeEmployeeGroup from '../../../components/modals/components/ChangeEmployeeGroup'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'

const styles = {
  container: {
    marginLeft: 15,
  },
  menuItem: {
    color: '#fff',
    fontSize: 14,
    margin: '5px 0',
    '&:hover': {
      color: '#F89A00',
    },
  },
  red: {
    color: 'red',
    '&:hover': {
      color: 'red',
    },
  },
}

interface Props {
  employees: string[]
  instance_id: string
  handleEmployeeDeleted: (employees: string[]) => any
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
type ComponentProps = WithStyles<typeof styles> &
  Props &
  ExtractConnectType<typeof connectStore> &
  RouteComponentProps<{ instance_id: string }>

class EmployeeActions extends React.PureComponent<ComponentProps> {
  buttonRef

  state = {
    isMenuVisible: false,
  }

  render() {
    const { isMenuVisible } = this.state
    const { classes, employees, instance_id } = this.props

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
          disableFocusRipple
          style={{ padding: 0 }}
          ref={(ref) => (this.buttonRef = ref)}
          className={classes.container}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.buttonRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={isMenuVisible}
          onClose={this.handleCloseMenu}
          PaperProps={{
            style: {
              width: 200,
              backgroundColor: '#2D2D2D',
            },
          }}
        >
          <MenuItem onClick={this.handleChangeGroup}>
            <Typography variant="inherit" className={classes.menuItem}>
              Change group
            </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleKick}>
            <Typography variant="inherit" className={[classes.menuItem, classes.red].join(' ')}>
              Remove from group
            </Typography>
          </MenuItem>
        </Menu>
      </>
    )
  }

  handleChangeGroup = () => {
    const { showModal, employees, instance_id } = this.props

    showModal({ modalType: MODAL_TYPES.CHANGE_EMPLOYEE_GROUP, modalProps: { employees, instance_id } })
    this.handleCloseMenu()
  }

  handleKick = async () => {
    const { employees, match } = this.props
    this.handleCloseMenu()
    if (!window.confirm(`Are you sure you want to kick ${employees.length} employees?`)) return
    await this.props.kickEmployees(match.params.instance_id, employees)
    this.props.handleEmployeeDeleted(employees)
  }

  handleOpenMenu = () => this.setState({ isMenuVisible: true })

  handleCloseMenu = () => this.setState({ isMenuVisible: false })
}

const connectStore = connect(null, (dispatch: ReduxDispatch) => ({
  showModal: (param) => dispatch(ModalActions.showModal(param)),
  kickEmployees: (instance_id: string, employees: string[]) =>
    dispatch(PlaybookInstanceActionDispatcher.kickEmployees(instance_id, employees)),
}))

export default compose(withStyles(styles), connectStore, withRouter)(EmployeeActions)
