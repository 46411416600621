import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import { Manrope } from '../../helpers/commonStyles'
import SettingsNav from './components/SettingsNav'
import SettingBilling from './pages/SettingsBilling'
import SettingsCompany from './pages/SettingsCompany'
import SettingsEvents from './pages/SettingsEvents'
import SettingsLoginAndSecurity from './pages/SettingsLoginAndSecurity'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import config from '../../config'

const prefix = '/settings'

const useStyles = makeStyles(
  {
    container: {
      fontFamily: Manrope,
    },
  },
  {
    classNamePrefix: 'Settings',
  },
)

const stripePromise = loadStripe(config.stripe.key as string) // starts with pk_

function Settings() {
  const classes = useStyles()

  return (
    <PaperContainer className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SettingsNav />
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route path={`${prefix}/account`} component={SettingsCompany} />
            <Route path={`${prefix}/login-and-security`} component={SettingsLoginAndSecurity} />
            <Route path={`${prefix}/events`} component={SettingsEvents} />
            <Route
              path={`${prefix}/billing`}
              component={() => (
                <Elements stripe={stripePromise}>
                  <SettingBilling />
                </Elements>
              )}
            />

            <Redirect to={`${prefix}/account`} />
          </Switch>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

export default Settings
