import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxState } from '@reducers/index'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import arrowDownIcon from './assets/arrow_down.svg'
import personalTrainerIcon from './assets/personal-trainer.svg'
import trustedIcon from './assets/trusted.svg'
import weightliftingIcon from './assets/weightlifting.svg'
import CoachWrapper from './components/CoachWrapper'

interface Props {}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore> {}
const ChooseCoachType = ({ classes, match, setGroup }: PropsInner) => {
  const {
    params: { groupId },
  } = match

  React.useEffect(() => {
    if (groupId) {
      setGroup(groupId)
    }
  }, [groupId])

  return (
    <CoachWrapper title="Add an individual to lead the group" titleWidth={178}>
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Link to={`/coaches/invite/internal`} style={{ textDecoration: 'none', marginBottom: 13 }}>
          <Grid container className={classes.itemWrapper}>
            <Grid item style={{ marginRight: 24, display: 'flex' }}>
              <img src={weightliftingIcon} alt="" />
            </Grid>
            <Grid item className={classes.itemText}>
              <Typography style={{ fontWeight: 'bold' }}>Invite a Champion</Typography>
              <Typography>Let someone in your organization lead a wellness program</Typography>
            </Grid>
            <img src={arrowDownIcon} alt="" className={classes.itemArrow} />
          </Grid>
        </Link>
        <Link to={`/coaches/invite/external`} style={{ textDecoration: 'none', marginBottom: 13 }}>
          <Grid container className={classes.itemWrapper}>
            <Grid item style={{ marginRight: 24, display: 'flex' }}>
              <img src={personalTrainerIcon} alt="" />
            </Grid>
            <Grid item className={classes.itemText}>
              <Typography style={{ fontWeight: 'bold' }}>Find a local expert</Typography>
              <Typography>Match with a local experts for your goals and needs</Typography>
            </Grid>
            <img src={arrowDownIcon} alt="" className={classes.itemArrow} />
          </Grid>
        </Link>
        <Link to={`/coaches/champions/choose/${groupId}`} style={{ textDecoration: 'none' }}>
          <Grid container className={classes.itemWrapper}>
            <Grid item style={{ marginRight: 24, display: 'flex' }}>
              <img src={trustedIcon} alt="" />
            </Grid>
            <Grid item className={classes.itemText}>
              <Typography style={{ fontWeight: 'bold' }}>Choose existing coach</Typography>
              <Typography>Choose already proven coach from your company</Typography>
            </Grid>
            <img src={arrowDownIcon} alt="" className={classes.itemArrow} />
          </Grid>
        </Link>
      </Grid>
    </CoachWrapper>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    offerGroupId: state.coaches.offer.groupId,
  }),
  (dispatch: ReduxDispatch) => ({
    setGroup: (groupId: string) => dispatch(coachesDispatcher.actions.setGroup(groupId)),
  }),
)

const styles = createStyles({
  itemWrapper: {
    border: '1px solid',
    borderColor: '#CCCCCC',
    borderRadius: '8px',
    padding: '44px 59px 44px 40px',
    width: 'fit-content',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#adacac',
    },
  },
  itemText: {
    maxWidth: 300,
  },
  itemArrow: {
    position: 'absolute',
    right: 26,
    top: '50%',
    transform: 'translateY(-50%)',
  },
})

export default connectStore(withStyles(styles)(ChooseCoachType))
