/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { Asset } from '@reaction-club-types/core'
import React, { ReactNode } from 'react'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'
import ActionButtonContainer from './ActionButtonContainer'

interface Props {
  asset: Asset
  title: string
  description: ReactNode
  onClick(): any
}

function ActionButton(props: Props) {
  const { asset, title, description, onClick } = props

  return (
    <ActionButtonContainer onClick={onClick}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <ReactionAvatar
          asset={asset}
          css={css`
            width: 60px;
            height: 60px;
          `}
        />
        <Grid
          item
          flexGrow={1}
          style={{ width: 1 }}
          css={css`
            padding-left: 20px;

            @media (max-width: 500px) {
              padding-left: 10px;
            }
          `}
        >
          <Grid direction={'column'} container alignItems={'flex-start'}>
            <Typography style={{ fontWeight: 600 }}>{title}</Typography>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ActionButtonContainer>
  )
}

export default ActionButton
