import { EventParticipant, EventRecording } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

export interface APIEventCreatePayload {
  name: string
  coach_id: string
  group_id?: string | null
  description: string
  time: string
  duration?: number
  location_formatted: string
  location?: string
  repeat?: string
  participant_limit?: number | null
  workout_type: string
  offices?: string[]
}

export interface APIEventUpdatePayload {
  name: string
  coach_id: string
  description: string
  time: string
  location_formatted: string
  participant_limit?: number
}

const apiEvents = {
  getEvents: async (params?: { dateStart?: string; dateEnd?: string; offset?: number }) => {
    const result = await apiClient.get('/event/list', { params })
    return result.data
  },
  getPublicEvents: async (params?: { dateStart?: string; offset?: number }) => {
    const result = await apiClient.get('/event/list-public', { params })
    return result.data
  },
  getReports: async ({ date }: { date?: string }) => {
    const result = await apiClient.get('/reports', { params: { date } })
    return result.data
  },
  getById: async (event_id: string) => {
    const result = await apiClient.get(`/event/${event_id}`)
    return result.data
  },
  getEmployees: async (event_id: string) => {
    const result = await apiClient.get(`/event/${event_id}/employees`)
    return result.data
  },
  async getEventPenalties(): Promise<any[]> {
    const { data } = await apiClient.get(`/event/penalties`)
    return data
  },
  async eventPenaltyRelease(params: { event_id: string; user_id: string }): Promise<{ deleted: number }> {
    const { data } = await apiClient.post(`/event/penalties/release`, params)
    return data
  },
  async getParticipants(event_id: string): Promise<EventParticipant[]> {
    const { data } = await apiClient.get(`/event/${event_id}/participant/list`)
    return data
  },
  async addParticipant(event_id: string, payload: { user_id: string }) {
    const { data } = await apiClient.post(`/event/${event_id}/participant/add`, payload)
    return data
  },
  async removeParticipant(event_id: string, payload: { user_id: string }) {
    const { data } = await apiClient.post(`/event/${event_id}/participant/remove`, payload)
    return data
  },
  async getRecordings(event_id: string): Promise<EventRecording[]> {
    const { data } = await apiClient.get(`/event/${event_id}/recordings`)
    return data
  },
  async createEvent(payload: APIEventCreatePayload) {
    const { data } = await apiClient.post('/event/create', payload)
    return data
  },
  async updateEvent(event_id: string, payload: APIEventUpdatePayload) {
    const { data } = await apiClient.post(`/event/${event_id}`, payload)
    return data
  },
  async deleteEvent(event_id: string, params?: { spawnNext?: boolean }) {
    const { data } = await apiClient.delete(`/event/${event_id}`, { params })
    return data
  },

  async checkInUsers(
    event_id: string,
    users: { user_id: string; status: boolean } | { user_id: string; status: boolean }[],
  ) {
    const { data } = await apiClient.post(`/event/${event_id}/check-in`, users)
    return data
  },
  async deleteRecording(event_id: string, key: string) {
    const { data } = await apiClient.post(`/event/${event_id}/recording/delete`, null, {
      params: {
        key,
      },
    })
    return data
  },

  async spawnNextEvent(event_id: string) {
    const { data } = await apiClient.post(`/event/${event_id}/spawn-next`)
    return data
  },

  async changeNextTime(event_id: string, payload: { time: string }) {
    const { data } = await apiClient.post(`/event/${event_id}/change-next-time`, payload)
    return data
  },

  async getChildEvent(event_id: string) {
    const { data } = await apiClient.get(`/event/${event_id}/child-event`)
    return data
  },

  async createZakToken(event_id: string): Promise<{ token: string }> {
    const { data } = await apiClient.post(`/event/${event_id}/zak-create`)
    return data
  },
}

export default apiEvents
