import { Grid } from '@mui/material'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import StockItem from './StockItem'

function StockList() {
  const { list } = useSelector((state: ReduxState) => ({
    list: state.stocks.list,
  }))

  return (
    <div>
      <Grid container>
        {_.map(list, item => (
          <StockItem stock={item} key={`stock-item-${item.id}`} />
        ))}
      </Grid>
    </div>
  )
}

export default StockList
