/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Tune } from '@mui/icons-material'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import theme from '../../../app/theme'
import { TerminateChallenge } from '../../groups/TerminateChallenge'
import ProgressBar from '../../groups/groups/ProgressBar'
import rewardIcon from '../assets/reward.png'
import usersIcon from '../assets/users-icon.png'

interface Props {
  type: 'current' | 'future'
  challenge: any
}

const useStyles = makeStyles({
  main: {
    paddingLeft: 70,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
    },
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  container: {
    width: 470,
    height: '100%',
    border: '2px solid #21990D',
    borderRadius: 10,
    padding: '15px 25px 15px 25px',
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '5px 15px 5px 15px',
    },
  },
  containerFuture: {
    borderColor: '#C4C4C4',
  },
  containerScheduled: {
    borderColor: '#F9B22D',
  },
  sideLabel: {
    width: 80,
    backgroundColor: '#21990D',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: '10px 0 0 10px',
    [theme.breakpoints.down('md')]: {
      width: 26,
    },
  },
  sideLabelFuture: {
    backgroundColor: '#C4C4C4',
  },
  sideLabelScheduled: {
    backgroundColor: '#F9B22D',
  },
  sideLabelText: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: 'bold',
    transform: 'rotate(-90deg)',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  icon: {
    width: 32,
  },
  dates: {
    color: '#737373',
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  count: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  type: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  typeText: {
    color: '#737373',
    fontSize: 14,
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  typeIcon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  edit: {
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    color: '#0a58ba',
  },
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 180,
  },
  timerCount: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  timerItem: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
  },
  timerLabel: {
    fontSize: 12,
  },
})

function Challenge(props: Props) {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { challenge, type } = props
  const { teams, company } = useSelector((state: ReduxState) => ({
    teams: state.lists.teams,
    company: state.company,
  }))

  const { minutes, hours, days } = useTimer({
    expiryTimestamp: moment.tz(challenge?.start_date, company.timezone),
  })

  if (!challenge) {
    return null
  }

  const isFuture = type === 'future'

  const isScheduled = challenge.status === 'scheduled' && !isFuture

  const futureEndDate = moment(challenge.start_date).add(challenge.duration - 1, 'days')

  const renderControls = () => {
    if (isFuture) {
      return (
        <Grid item>
          <Link to={`/customize-challenge/edit/${challenge.challenge_id}`} className={classes.edit}>
            <Tune />
            <Typography color="#0a58ba">Edit</Typography>
          </Link>
        </Grid>
      )
    }
    return (
      <Grid item>
        <TerminateChallenge challengeId={challenge.challenge_item_id} reload />
      </Grid>
    )
  }

  return (
    <Grid item className={classes.gridItem}>
      <Box position="relative" className={classes.main} height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classnames(
            classes.sideLabel,
            isFuture && classes.sideLabelFuture,
            isScheduled && classes.sideLabelScheduled,
          )}
        >
          <Typography className={classes.sideLabelText}>{isFuture ? 'Future' : 'Current'}</Typography>
        </Box>
        <Box
          className={classnames(
            classes.container,
            isFuture && classes.containerFuture,
            isScheduled && classes.containerScheduled,
          )}
        >
          {isScheduled && !isFuture ? (
            <Box display="flex" alignItems="center" mt={4} flexDirection="column" justifyContent="center" flex={1}>
              <div>
                <Typography fontSize={isMobile ? 22 : 32} fontWeight="bold" textAlign="center" mb={6}>
                  Next challenge starts in
                </Typography>
              </div>
              <Box className={classes.timerContainer} mb={4}>
                <div className={classes.timerItem}>
                  <Typography className={classes.timerCount}>{days}</Typography>
                  <Typography className={classes.timerLabel}>Days</Typography>
                </div>
                <Typography className={classes.timerCount}>:</Typography>
                <div className={classes.timerItem}>
                  <Typography className={classes.timerCount}>{hours}</Typography>
                  <Typography className={classes.timerLabel}>Hours</Typography>
                </div>
                <Typography className={classes.timerCount}>:</Typography>
                <div className={classes.timerItem}>
                  <Typography className={classes.timerCount}>{minutes}</Typography>
                  <Typography className={classes.timerLabel}>Minutes</Typography>
                </div>
              </Box>
              <div>
                <Typography fontSize={10} display="flex" alignItems="center">
                  <Typography fontSize={10} fontWeight="bold" mr={1}>
                    Starts
                  </Typography>
                  {moment(challenge?.start_date).format('ddd, Dt\\h MMMM')} -
                  <Typography fontSize={10} fontWeight="bold" mr={1} ml={1}>
                    Ends
                  </Typography>
                  {moment(futureEndDate).format('ddd, Dt\\h MMMM')}
                </Typography>
              </div>
            </Box>
          ) : (
            <>
              <Grid container spacing={2} alignItems="center">
                <Grid item flexGrow={1}>
                  <Typography className={classes.title}>
                    {isFuture ? challenge.name : challenge.challenge_item_name}
                  </Typography>
                  <Typography className={classes.dates}>
                    {isFuture ? (
                      <React.Fragment>
                        {moment(challenge.start_date).format('DD MMMM')} - {moment(futureEndDate).format('DD MMMM')}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {moment(challenge.challenge_item_start_at).format('DD MMMM')} -{' '}
                        {moment(challenge.challenge_item_end_at).format('DD MMMM')}
                      </React.Fragment>
                    )}
                  </Typography>
                </Grid>
                {renderControls()}
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12}>
                  {isFuture ? (
                    <ProgressBar
                      startAt={challenge.start_date}
                      endAt={futureEndDate.format('YYYY-MM-DD')}
                      duration={challenge.duration}
                      currentChallengeDay={1}
                      disabled
                      width={isMobile ? 220 : 360}
                    />
                  ) : (
                    <ProgressBar
                      startAt={challenge.challenge_item_start_at}
                      endAt={challenge.challenge_item_end_at}
                      duration={challenge.challenge_item_duration}
                      currentChallengeDay={challenge.currentDay}
                      width={isMobile ? 220 : 360}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container mt={4} spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="flex-start">
                    <img src={usersIcon} className={classes.typeIcon} />
                    <Box>
                      <Typography className={classes.type}>Teams</Typography>
                      <Typography className={classes.typeText}>
                        {isEmpty(teams) ? 'Not set' : teams.map((t) => t.name).join(', ')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="flex-start">
                    <img src={rewardIcon} className={classes.typeIcon} />
                    <Box>
                      <Typography className={classes.type}>Prize</Typography>
                      <Typography className={classes.typeText}>
                        {isFuture ? (
                          <React.Fragment>
                            Points {challenge?.custom_prize ? `, ${challenge?.custom_prize}` : ''}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Points{' '}
                            {challenge?.challenge_item_custom_prize
                              ? `, ${challenge?.challenge_item_custom_prize}`
                              : ''}
                          </React.Fragment>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Grid>
  )
}

export default Challenge
