/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ModalStyled from '../elements/ModalStyled'
import ButtonOrangeSquare from './components/ButtonOrangeSquare'
import useDistanceMeasurement from '../hooks/useDistanceMeasurement'
import { nFormatter } from '../helpers/utils'
import apiChallenge from '@reducers/challenge/api'

interface Props {
  distance: number
  isVisible: boolean
  onClose(): any
  callback(landmarks: ILandmrk[]): void
}

interface ILandmrk {
  name: string
  distance: number
}

function SmartLandmarksModal(props: Props) {
  const [unit] = useDistanceMeasurement()
  const [counter, setCounter] = useState(0)
  const [loading, setLoading] = useState(false)
  const [landmarks, setLandmarks] = useState<ILandmrk[]>([])
  const { isVisible, onClose, callback } = props
  const { control, register, reset, handleSubmit } = useForm({})
  const dispatch = useDispatch()

  const calculateDistance = useCallback(
    (distance: number): number => {
      if (unit === 'miles') {
        return distance / 1.609
      }

      return distance
    },
    [unit],
  )

  const onSubmit = useCallback(
    async (data) => {
      const count = counter + 1
      setCounter(count)
      setLoading(true)

      const { data: landmarks } = await apiChallenge.generateLandmarks({
        address: data.startingPoint,
        distance: props.distance,
        count,
      })

      setLandmarks(
        landmarks.map((l) => ({
          name: l.label,
          distance: l.distance,
        })),
      )
      setLoading(false)
    },
    [counter, props.distance],
  )

  const handleSave = () => {
    callback(landmarks)
    handleClose()
  }

  const handleClose = () => {
    setLandmarks([])
    setCounter(0)
    reset()
    onClose()
  }

  return (
    <ModalStyled isVisible={isVisible} onClose={handleClose} showCloseButton>
      <Stack direction="row" gap={4} p={4}>
        <StyledTextField
          {...register(`startingPoint`, { required: true })}
          label="Starting point (e.g., our office, city, etc.)"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
        <ButtonOrangeSquare onClick={handleSubmit(onSubmit)} isLoading={loading}>
          Suggest {counter > 1 ? `(${counter})` : null}
        </ButtonOrangeSquare>
      </Stack>
      <Stack gap={2} p={4}>
        {landmarks.map((landmark) => (
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography>{landmark.name}</Typography>
            </Box>
            <Box>
              <Typography>
                {nFormatter(calculateDistance(landmark.distance))} {unit || 'km'}
              </Typography>
            </Box>
          </Stack>
        ))}
        {landmarks.length > 0 ? <ButtonOrangeSquare onClick={handleSave}>Save</ButtonOrangeSquare> : null}
      </Stack>
    </ModalStyled>
  )
}

export default SmartLandmarksModal

const StyledTextField = styled(TextField)`
  flex: 1;
  min-width: 300px;
`
