enum ACTION_TYPE {
  SHOW_MODAL = 'MODAL_SHOW_MODAL',
  CLOSE_MODAL = 'MODAL_CLOSE_MODAL',
}

export interface ModalAction<T = ACTION_TYPE> {
  type: T
  payload?: any
}

export default ACTION_TYPE

export enum MODAL_TYPES {
  CREAT_FIRST_GROUP = 'CREAT_FIRST_GROUP',
  INVITE_COACH = 'INVITE_COACH',
  INVITE_GROUP_COACH = 'INVITE_GROUP_COACH',
  CREATE_CHAT = 'CREATE_CHAT',
  FULL_SIZE_IMAGE = 'FULL_SIZE_IMAGE',
  // EVENT_DETAILS = 'EVENT_DETAILS',
  EDIT_COMPANY = 'EDIT_COMPANY',
  CHANGE_EMPLOYEE_GROUP = 'CHANGE_EMPLOYEE_GROUP',
  EXPORT_STEPS = 'EXPORT_STEPS',
  INFO = 'INFO',
  // CHOOSE_TRAINING_MODE = 'CHOOSE_TRAINING_MODE',
  // Not checked yet
  UPDATE_COMPANY_DETAILS = '[UPDATE_COMPANY_DETAILS] Update company name/adress field',
}
