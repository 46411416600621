/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'
import EventModalStyled from '../../modals/elements/EventModalStyled'

interface Props {
  isVisible: boolean
  onClose(): any
}

function EventFieldTypeInfoModal(props: Props) {
  return (
    <EventModalStyled {...props}>
      <div>
        <Typography
          variant={'h2'}
          css={css`
            font-size: 28px;
            margin-top: 30px;
            margin-bottom: 30px;
            font-family: Poppins;
          `}
        >
          Event type
        </Typography>
        <Typography
          css={css`
            font-family: Poppins;
          `}
        >
          <span
            css={css`
              font-size: 16px;
              font-weight: bold;
            `}
          >
            Online events
          </span>{' '}
          are virtual events delivered through Zoom. When you create an online event we generate a zoom link*
          automatically for you, so you won’t need a zoom account or do anything special. The zoom link will be
          available to your members on the app and for you on the event page.
        </Typography>

        <br />

        <Typography
          css={css`
            font-family: Poppins;
          `}
        >
          <span
            css={css`
              font-size: 16px;
              font-weight: bold;
            `}
          >
            On-site events
          </span>{' '}
          will enable you to add a physical location for the event (i.e. meeting room).
        </Typography>
      </div>
    </EventModalStyled>
  )
}

export default EventFieldTypeInfoModal
