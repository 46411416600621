import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import moment from 'moment-timezone'

const MapGoalProgressBar = (props) => {
  const { startDate, start, end } = props
  let width = (start / end) * 100
  width = width > 100 ? 100 : width

  return (
    <Container>
      <Typography fontSize={11}>{moment(startDate).format('MMM D')}</Typography>
      <ProgressBarContainer>
        <CurrentProgress style={{ left: `${width}%` }}>
          <Typography fontWeight="700" fontSize={12}>
            {width.toFixed(0)}%
          </Typography>
        </CurrentProgress>
        <ProgressBar style={{ width: `${width}%` }} />
      </ProgressBarContainer>
      <Typography fontSize={11}>{props.duration} days left</Typography>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

const ProgressBarContainer = styled('div')`
  background-color: #dadada;
  height: 16px;
  border-radius: 20px;
  flex: 1;
  position: relative;
`

const ProgressBar = styled('div')`
  background: linear-gradient(90deg, #56c791 0%, #fab32e 78.49%, #fab32e 100%);
  height: 100%;
  border-radius: 20px;
`

const CurrentProgress = styled('div')`
  position: absolute;
  width: 40px;
  top: -22px;
  text-align: center;
  margin-left: -20px;
`

export default MapGoalProgressBar
