import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import ChooseCoachType from './inviteCoaches/ChooseCoachType'
import ExternalCoaches from './inviteCoaches/ExternalCoaches'
import InternalCoaches from './inviteCoaches/InternalCoaches'

const InviteCoaches = () => {
  return (
    <Switch>
      <Route path={'/coaches/invite/internal'} component={InternalCoaches} />
      <Route path={'/coaches/invite/external'} component={ExternalCoaches} />
      <Route component={ChooseCoachType} />
    </Switch>
  )
}

export default InviteCoaches
