/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import _ from 'lodash'
import React, { useMemo } from 'react'
import EventParticipantItem from './EventParticipantItem'

interface Props {
  event: ReduxStateReactionEventItem
  isEventFinished: boolean
  isExpanded: boolean
}
function EventParticipantList(props: Props) {
  const { event, isEventFinished, isExpanded } = props
  const { participants } = event

  const list = useMemo(() => {
    if (!participants) return []
    if (isExpanded) return participants

    return participants.slice(0, 6)
  }, [participants, isExpanded])

  return (
    <Grid
      container
      // css={css`
      //   overflow-y: scroll;
      //   max-height: 200px;
      // `}Z
    >
      {_.map(list, (user) => (
        <Grid item sm={6} key={`event-participant-${user.user_id}`}>
          <EventParticipantItem user={user} isEventFinished={isEventFinished} />
        </Grid>
      ))}
    </Grid>
  )
}

export default EventParticipantList
