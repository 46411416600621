import { Grid, Typography, Box, Avatar } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import React from 'react'
import { Link } from 'react-router-dom'

type OwnProps = {
  name: string
  text: string
  date: string
  url: string
  id: string
  type: string
  mockUserId?: string
  createMockDirectRoom: any
}
type Props = OwnProps & WithStyles<typeof styles>

const ChatItem: React.FC<Props> = ({
  id,
  classes,
  name,
  text = '...',
  date,
  url,
  type,
  mockUserId,
  createMockDirectRoom,
  unread,
}) => {
  const ChatItemWrapper = mockUserId
    ? ({ children }) => (
      <Box onClick={() => createMockDirectRoom('mock-room-id', mockUserId)}>
          <Link to={`/chats/${type}/mock-room-id`} style={{ textDecoration: 'none' }}>
          {children}
        </Link>
        </Box>
      )
    : ({ children }) => (
      <Link to={`/chats/${type}/${id}`} style={{ textDecoration: 'none' }}>
          {children}
        </Link>
      )

  return (
    <ChatItemWrapper>
      <Grid container alignItems={'center'} justifyContent={'space-between'} className={classes.chatItem}>
        <Grid item xs={3} style={{ paddingRight: 16 }}>
          <Grid container alignItems={'center'} style={{ flexWrap: 'nowrap' }}>
            <Box mr={4}>
              <Avatar src={url} style={{ width: 54, height: 54 }} />
            </Box>
            <Typography className={classes.text}>{name}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={7} style={{ paddingRight: 16 }}>
          <Typography className={classes.message}>
            {text}
            {unread > 0 && <span className={classes.unreadRoomsCount}>{unread}</span>}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.date}>{date}</Typography>
        </Grid>
      </Grid>
    </ChatItemWrapper>
  )
}

const styles = createStyles({
  chatItem: {
    borderBottom: '1px solid #D4D4D4',
    padding: '42px 32px',
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
  },
  text: {
    fontWeight: 800,
    fontSize: 14,
    marginBottom: 5,
  },
  message: {
    fontSize: 14,
    color: '#868686',
    lineHeight: '20px',
  },
  date: {
    textAlign: 'center',
    fontSize: 12,
    marginLeft: 20,
    color: '#868686',
  },
  unreadRoomsCount: {
    marginLeft: 'auto',
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: ' #e84848',
    display: 'block',
    textAlign: 'center',
    fontSize: 12,
    color: '#fff',
  },
})

export default withStyles(styles)(ChatItem)
