import { Typography } from '@mui/material'
import CoachesListDispatcher from '@reducers/coachesList/dispatcher'
import { APIEventCreatePayload } from '@reducers/events/api'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import OfficeDispatcher from '@reducers/offices/dispatcher'
import GroupDispatcher from '@reducers/playbookInstances/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import EventCreateForm from './eventCreate/EventCreateForm'

interface Props extends RouteComponentProps {}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore> {}

function EventCreate(props: PropsInner) {
  const history = useHistory()
  const dispatch = useDispatch<ReduxDispatch>()

  useAsyncFetch(async () => {
    await dispatch(CoachesListDispatcher.getCoaches())
    await dispatch(GroupDispatcher.getPlaybookInstances())
    await dispatch(OfficeDispatcher.getList({ isForce: false }))
  })

  const handleEventCreate = async (payload: APIEventCreatePayload) => {
    await props.createEvent(payload)
    history.push('/events')
  }

  return (
    <PaperContainer>
      <Typography variant={'h1'}>Add New Event</Typography>
      <EventCreateForm onCreate={handleEventCreate} />
    </PaperContainer>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    events: state.events,
    groups: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    createEvent: (payload: APIEventCreatePayload) => dispatch(EventDispatcher.createEvent(payload)),
  }),
)

export default connectStore(EventCreate)
