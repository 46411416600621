import { Box, Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import MyButton from '../../../../components/MyButton'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'
import currencyToSign from '../../../../helpers/currencyToSign'

type OwnProps = {
  coach: any
}
type Props = OwnProps & WithStyles<typeof styles>
const CoachProfile: React.FC<Props> = ({ classes, coach, history, offerId, declineCoach, offers, getOffers }) => {
  const {
    user_id,
    name,
    customFields: { avatar_url },
  } = coach

  React.useEffect(() => {
    if (!offers) {
      getOffers()
    }
  }, [])

  const offer = offers?.find(({ id }) => id === offerId)

  const onDecline = () => {
    declineCoach({ offer_id: offerId, coach_id: user_id })
    history.push('/coaches/work-offers')
  }

  const { type_ids = [], proposed_price, proposed_currency, period } = offer || {}

  return (
    <Box className={classes.profileContainer}>
      <Grid container direction={'column'} justifyContent={'center'}>
        <ReactionAvatar asset={{ url: avatar_url }} style={{ width: 136, height: 136 }} />
        <Typography className={classes.coachName}>{name}</Typography>
        <Typography className={classes.groupName}>{`${type_ids.join()} coach`}</Typography>
        <Typography className={classes.price}>
          {currencyToSign(proposed_currency)}{proposed_price} per {period}
        </Typography>
        <Grid item>
          <Grid container direction={'column'} style={{ marginTop: 73 }}>
            <MyButton linkTo={`/coaches/work-offer/${offerId}/accept/${user_id}`}>Confirm</MyButton>
            <MyButton onClick={onDecline} style={{ backgroundColor: '#D4D4D4', marginTop: 16 }}>
              Decline
            </MyButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = state => ({
  offers: state.coaches.offers,
})
const mapDispatchToProps = dispatch => ({
  getOffers: () => dispatch(coachesDispatcher.getOffers()),
  declineCoach: ({ offer_id, coach_id }) => dispatch(coachesDispatcher.declineCoach({ offer_id, coach_id })),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  profileContainer: {
    padding: '40px 20px',
    background: '#f3f3f3',
    maxWidth: 300,
    borderBottom: '1px solid #d4d4d4',
  },
  coachName: {
    fontSize: 16,
    fontWeight: 800,
    marginTop: 43,
    marginBottom: 10,
    lineHeight: 1,
    fontFamily: 'Lato',
    letterSpacing: '1',
    whiteSpace: 'nowrap',
  },
  price: {
    fontSize: 14,
    fontWeight: 800,
    marginTop: 18,
  },
  groupName: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Lato',
    letterSpacing: '0.6px',
  },
})

export default compose(connectStore, withStyles(styles), withRouter)(CoachProfile)
