import { Drawer, List, Grid, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import modalActions from '@reducers/modal/actions'
import React, { useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import useModalState from '../hooks/useModalState'
import LogoutConfirmModal from '../modals/LogoutConfirmModal'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import SideBarCompany from './sideBar/SideBarCompany'
import chatIcon from './sideBar/assets/chat.svg'
import coachIcon from './sideBar/assets/coach.svg'
import coachesIcon from './sideBar/assets/coaches.svg'
import eventIcon from './sideBar/assets/event.svg'
import exitIcon from './sideBar/assets/exit.svg'
import groupsIcon from './sideBar/assets/groups.svg'
import rewardsIcon from './sideBar/assets/rewards.svg'
import settingsIcon from './sideBar/assets/settings.svg'
import BurgerMenu from './sideBar/components/BurgerMenu'
import LeftArrow from './sideBar/components/LeftArrow'
import SideBarLink from './sideBar/components/SideBarLink'

const getLinks = (isAdmin: boolean) => [
  {
    title: 'Programs',
    url: '/groups',
    icon: groupsIcon,
  },
  {
    title: 'Events',
    url: '/events',
    icon: eventIcon,
  },
  {
    title: 'Members & Teams',
    url: '/members',
    icon: coachesIcon,
  },
  {
    title: 'Rewards',
    url: '/stocks',
    icon: rewardsIcon,
    premium: true,
  },
  {
    title: 'Settings',
    url: '/settings',
    icon: settingsIcon,
  },
  ...(!isAdmin
    ? []
    : [
        {
          title: 'Chats',
          url: '/chats',
          icon: chatIcon,
        },
        {
          title: 'Coaches',
          url: '/coaches',
          icon: coachIcon,
        },
      ]),
]

interface Props extends ExtractConnectType<typeof connectStore> {
  open: boolean
  toggleOpen: () => void
}

const SideBar: React.FC<Props> = ({ open, toggleOpen, showModal, isAdmin }) => {
  const classes = useStyles({ open })
  const logoutModalState = useModalState()

  const { counters, chat, offers, company } = useSelector((state: ReduxState) => ({
    counters: state.app.counters,
    chat: state.chat,
    offers: state.coaches.offers,
    company: state.company,
  }))

  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  const isCompanyExists = !!company.id

  const chatUnreadCount = useMemo(() => {
    const count = Object.values(chat?.rooms || {}).filter(({ unread }) => unread).length
    return count
  }, [chat])

  const coachUnreads = useMemo(() => {
    return offers?.find(
      ({ pending_count, matchedCoaches }) =>
        pending_count > 0 ||
        matchedCoaches?.find(
          (c) => Object.values(chat?.rooms || {}).find((r) => r.userId === c.chat_user_id)?.unread > 0,
        ),
    )
  }, [offers])

  return (
    <Drawer open anchor="left" variant="persistent" classes={{ paper: classes.drawerPaper }}>
      <LogoutConfirmModal {...logoutModalState} />
      <Grid container className={classes.container} direction="column" justifyContent="space-between" wrap={'nowrap'}>
        <Grid>
          {isCompanyExists && <SideBarCompany open={open} />}
          {open ? <LeftArrow onClick={toggleOpen} /> : <BurgerMenu onClick={toggleOpen} />}
          <List className={classes.links}>
            {getLinks(isAdmin).map(({ title, url, icon, premium }) => {
              let unread = 0
              if (url.includes('stock')) {
                unread = counters.company_stock_pending_count || 0
              }

              if (url === '/chats') {
                unread = chatUnreadCount
              }

              if (url === '/coaches') {
                unread = coachUnreads
              }

              return (
                <SideBarLink
                  open={open}
                  key={title}
                  title={title}
                  url={url}
                  icon={icon}
                  unreadCount={unread}
                  isLocked={!isCompanyExists}
                  isPremium={premium && isFreeTrial}
                />
              )
            })}

            <Box mt={6}>
              <SideBarLink fake open={open} title="Logout" icon={exitIcon} onClick={logoutModalState.onOpen} />
            </Box>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  )
}

type StylesProp = {
  open: boolean
}
const useStyles = makeStyles({
  drawerPaper: {
    transition: 'width 200ms',
    backgroundColor: '#2D2D2D',
    width: (props: StylesProp) => (props.open ? 230 : 59),
    overflowX: (props: StylesProp) => (props.open ? 'none' : 'hidden'),
    minHeight: 750,
    maxHeight: '100vh',
    position: 'fixed',
  },
  container: {
    paddingTop: 50,
    height: '100%',
    flexGrow: 1,
  },
  links: {
    padding: 0,
    margin: '30px 0',
  },
})

const connectStore = connect(
  (state: ReduxState) => ({
    isAdmin: state.me?.role === 'admin',
  }),
  (dispatch: ReduxDispatch) => ({
    showModal: (param) => dispatch(modalActions.showModal(param)),
  }),
)

export default compose(connectStore)(SideBar)
