/* eslint-disable no-underscore-dangle */
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Chat from './components/Chat'

const DirectChat = ({
  chat: { rooms, users },
  getUser,
  sendMessage,
  match: {
    params: { id },
  },
  getRoomMembers,
  getGroupMessages,
}) => {
  const room = rooms[id]

  React.useEffect(() => {
    getGroupMessages(id)
  }, [])

  React.useEffect(() => {
    if (room && (!room.members || !room.members.length)) {
      getRoomMembers(id)
    }
  }, [rooms])

  React.useEffect(() => {
    room?.members?.forEach(({ _id }) => {
      if (!users[_id]) {
        getUser(_id)
      }
    })
  }, [rooms, users])

  if (!room || !room.members) {
    return null
  }

  const members = room.members.map(m => users[m._id]).filter(m => Boolean(m))
  return <Chat room={room} users={users} members={members} sendMessage={(text, asset) => sendMessage(room._id, text, asset)} />
}

const mapStateToProps = state => ({
  chat: state.chat,
})
const mapDispatchToProps = dispatch => ({
  getRoomMembers: roomId => dispatch(ChatDispatcher.getRoomMembers(roomId)),
  getUser: userId => dispatch(ChatDispatcher.getUser({ userId }, { isForce: false })),
  sendMessage: (roomId, text, asset) => dispatch(ChatDispatcher.sendMessage(roomId, text, asset)),
  getGroupMessages: roomId => dispatch(ChatDispatcher.getGroupMessages(roomId, { isReplace: true })),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default compose(connectStore)(DirectChat)
