/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import AuthActionDispatcher from '@reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import React, { useEffect, useMemo } from 'react'
import IFrameGuard from 'react-iframe-guard'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import Login from './components/Login'

interface Props extends ExtractConnectType<typeof connectStore> {}

function Auth(props: Props) {
  const { loginForAdmin, token } = props

  const history = useHistory()
  const {
    location: { search },
  } = history

  const email = useMemo(() => {
    const urlParams = new URLSearchParams(search)
    const login = urlParams.get('login') || '' // old @deprecated
    const emailParam = urlParams.get('email') || ''

    return emailParam || login
  }, [])

  async function checkAuthAndRedirect() {
    const urlParams = new URLSearchParams(search)
    const adminToken = urlParams.get('token')

    if (adminToken) {
      await loginForAdmin({
        token: adminToken,
        // chatToken: adminChatToken,
        // chatUserId: adminChatUserId,
      })

      return history.push('/')
    }

    if (token) {
      return history.push('/')
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const skipCheckAuth = urlParams.get('skipCheckAuth')

    // const adminChatToken = new URLSearchParams(search).get('chatToken')
    // const adminChatUserId = new URLSearchParams(search).get('chatUserId')
    if (!skipCheckAuth) {
      checkAuthAndRedirect()
    }
  }, [])

  return (
    <IFrameGuard>
      <div
        css={css({
          flexGrow: 1,
          backgroundSize: 'contain',
          backgroundPositionY: 'center',
          height: '100%',
          backgroundRepeat: 'no-repeat',
        })}
      >
        <Login email={email} />
      </div>
    </IFrameGuard>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    token: state.auth.token,
  }),
  (dispatch: ReduxDispatch) => ({
    loginForAdmin: (authData) => dispatch(AuthActionDispatcher.loginForAdmin(authData)),
  }),
)

export default connectStore(Auth)
