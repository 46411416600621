/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { Coach } from '@reaction-club-types/core'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import * as React from 'react'
import { connect, useSelector } from 'react-redux'
import MyButton from '../../../components/MyButton'
import arrowDownIcon from '../assets/arrow_right.svg'
import Row from '../components/Row'

interface Props {
  data: Coach
  isChooseMode?: boolean
  chooseCoach(user_id: string): any
  sizes: any
}

const InfoLine = ({ data, sizes, createMockDirectRoom, isChooseMode, chooseCoach }: Props) => {
  const { chat_user_id, user_id, name, avatar_url } = data

  const handleChoose = (e) => {
    e.preventDefault()
    chooseCoach(user_id)
  }

  // tmp before we refactor most coaches code
  const { groupNames } = useSelector((state: ReduxState) => {
    const pairs = _.filter(state.lists.groupsCoaches, (item) => item.coach_id === user_id)
    let groups = _.map(pairs, (pair) => state.playbookInstances[pair.group_id])
    return {
      groupNames: _.compact(groups).map((item) => item.name),
    }
  })

  return (
    <Row
      to={`/coaches/accepted-coach/${user_id}`}
      // to={`/chats/direct/mock-room-id`}
      onClick={() => {
        createMockDirectRoom('mock-room-id', chat_user_id)
      }}
      sizes={sizes}
      css={css`
        cursor: pointer;
        border: 1px solid #d4d4d4;
        &:hover {
          border-color: #000000;
        }
      `}
      data={[
        <Grid container alignItems={'center'} style={{ flexWrap: 'nowrap' }}>
          <Avatar src={avatar_url} style={{ marginRight: 12, height: 30, width: 30 }} />
          <Typography>{name}</Typography>
        </Grid>,
        <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {groupNames.join(', ')}
        </Typography>,
        <Grid container alignItems={'center'}>
          {/*{employee_count}*/}
          <Box ml={'auto'}>
            {isChooseMode ? <MyButton onClick={handleChoose}>Choose</MyButton> : <img src={arrowDownIcon} alt="" />}
          </Box>
        </Grid>,
      ]}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(null, mapDispatchToProps)

export default connectStore(InfoLine)
