import { Avatar, Button, TextField, CircularProgress, Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete'
import { withStyles, WithStyles } from '@mui/styles';
import { GetPlaybooksParam } from '@reducers/playbooks/api'
import PlaybookActionDispatcher from '@reducers/playbooks/dispatcher'
import React, { ChangeEventHandler } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import fileExplorer from 'web-file-explorer'
import apiAssets from '../../libs/api/apiAssets'
import { ReduxState } from '../../reducers'
import PlaybookInstanceDispatcher from '../../reducers/playbookInstances/dispatcher'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import { GROUP_LIST_URL } from './Groups'
import { GROUP_PAGE } from './Group'
import cameraIcon from './assets/camera.svg'
import initExamples from './groupCreate/examples'

interface Props
  extends WithStyles<typeof styles>,
    ExtractConnectType<typeof connectStore>,
    RouteComponentProps<{ instance_id?: string }> {
  onCreate?: () => void
  btnLabel?: string
  title?: string
}

interface State {
  name: string
  description: string
  asset: AssetFull | null
  isUploading: boolean
  isCreating: boolean
  errorName?: string
  errorDescription?: string
  instance_id?: string
  program?: string
  examples?: any[]
  isExamplesReady: boolean
  error?: string
}

class GroupCreate extends React.PureComponent<Props, State> {
  state: State = {
    name: '',
    description: '',
    asset: null,
    isUploading: false,
    isCreating: false,
    program: undefined,
    examples: [],
    isExamplesReady: false,
    error: '',
  }

  render() {
    const { classes, title } = this.props
    const { name, description, errorName, errorDescription, instance_id, examples, error } = this.state

    return (
      <Grid container direction="column" alignItems="center">
        <Typography variant={'h1'} className={classes.title}>
          {title || (instance_id ? 'Edit group' : 'Create a group')}
        </Typography>

        <Grid>
          {this.renderAvatar()}
          <Typography variant={'subtitle1'} className={classes.imageTitle}>
            Choose image
          </Typography>
        </Grid>

        <Grid>
          <Autocomplete
            options={instance_id ? [] : examples}
            getOptionLabel={example => example.name}
            style={{ width: 300 }}
            inputValue={name}
            onChange={this.handleExampleNameChange}
            onInputChange={this.handleNameChange}
            freeSolo
            renderInput={params => (
              <TextField
                {...params}
                error={!!errorName}
                InputLabelProps={{ className: classes.autocompleteLabel }}
                variant={'outlined'}
                label="Name your group"
              />
            )}
          />
        </Grid>

        <div>
          <TextField
            value={description}
            onChange={this.handleDescriptionChange}
            label="Group description"
            multiline
            variant={'outlined'}
            className={classes.inputDescription}
            rows={8}
            InputLabelProps={{ className: classes.autocompleteLabel }}
            InputProps={{
              style: {
                width: 300,
                color: '#323232',
              },
            }}
            error={!!errorDescription}
          />
        </div>

        <div>
          <span className={classes.error}>{error}</span>
        </div>
        {this.renderSubmitButton()}
      </Grid>
    )
  }

  renderAvatar() {
    const { classes } = this.props
    const { asset, isUploading } = this.state

    const image_url: string = asset ? asset.url : ''

    return (
      <Grid className={classes.imageContainer} container justifyContent={'center'} alignItems={'center'}>
        {isUploading ? (
          <CircularProgress color="primary" />
        ) : (
          <Avatar src={image_url} className={classes.image} alt={'group picture'} onClick={this.handleClickAvatar}>
            {asset ? null : <img src={cameraIcon} alt={'choose your avatar'} />}
          </Avatar>
        )}
      </Grid>
    )
  }

  renderSubmitButton() {
    const { classes, btnLabel } = this.props
    const { isCreating, instance_id, isUploading, error } = this.state

    if (isCreating) {
      return <CircularProgress color={'primary'} />
    }

    return (
      <Button
        disabled={isUploading}
        variant={'contained'}
        color={'primary'}
        className={classes.buttonCreate}
        onClick={this.handleClickCreate}
      >
        {btnLabel || (!instance_id ? 'Create' : 'Save')}
      </Button>
    )
  }

  handleNameChange: ChangeEventHandler<HTMLInputElement> = e => {
    const text = e?.target?.value || ''
    this.setState({ name: text, errorName: '' })
  }

  handleExampleNameChange = async (e, value) => {
    const { name, description, asset, program } = value
    this.setState({
      isUploading: true,
      name,
      program,
      description,
      errorName: '',
      errorDescription: '',
    })

    fetch(asset.url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], name, {
          type: 'image/png',
        })

        return apiAssets.upload(file)
      })
      .then(asset => {
        this.setState({
          asset,
          isUploading: false,
        })
      })
  }

  handleDescriptionChange: ChangeEventHandler<HTMLInputElement> = e => {
    const text = e.target.value || ''
    this.setState({ description: text, errorDescription: '' })
  }

  handleClickAvatar = async () => {
    let file = await fileExplorer.getFile()
    if (!file) return null

    this.setState({ isUploading: true })
    try {
      const asset: AssetFull = await apiAssets.upload(file)
      this.setState({ asset })
    } finally {
      this.setState({ isUploading: false })
    }
  }

  handleClickCreate = async () => {
    if (this.state.isUploading || this.state.isCreating) return null
    if (!this.isValid()) return null

    const { onCreate, history, createInstance, editInstance, addPlaybookToInstance, setSelfDrivenMode } = this.props
    const { name, description, asset, instance_id, program } = this.state

    this.setState({ isCreating: true, error: '' })
    try {
      if (!instance_id) {
        const { playbook_instance_id } = await createInstance({ name, description, asset_id: asset?.id })
        if (!playbook_instance_id) {
          return history.replace(GROUP_LIST_URL)
        }

        if (program) {
          await addPlaybookToInstance(playbook_instance_id, program)
        }
        history.replace(GROUP_PAGE(playbook_instance_id))
      } else {
        await editInstance(instance_id, { name, description, asset_id: asset?.id })
        history.replace(GROUP_PAGE(instance_id))
      }
      if (onCreate) onCreate()
    } catch (e) {
      this.setState({ error: e.message })
    } finally {
      this.setState({ isCreating: false })
    }
  }

  isValid = (): boolean => {
    const { name, description } = this.state

    let isValid = true
    if (!name) {
      this.setState({ errorName: 'Name is required' })
      isValid = false
    }

    if (!description) {
      this.setState({ errorDescription: 'Description is required' })
      isValid = false
    }

    return isValid
  }

  componentDidMount() {
    const {
      getPlaybooks,
      playbookInstances,
      match: {
        params: { instance_id },
      },
    } = this.props

    getPlaybooks()

    if (!instance_id) {
      return
    }

    const { name, description, asset } = playbookInstances[instance_id]
    this.setState({ instance_id, name, description, asset })
  }

  componentDidUpdate(prevProps): void {
    const { playbooks } = this.props
    const { isExamplesReady } = this.state
    if (isExamplesReady) {
      return
    }

    const playbookIds = Object.keys(playbooks)
    if (playbookIds.length) {
      this.setState({
        isExamplesReady: true,
        examples: initExamples.map(({ program, ...rest }) => {
          const programId = playbookIds.find(id => format(playbooks[id].name) === format(program))

          return { ...rest, program: programId }
        }),
      })
    }
  }
}

const format = str => str.trim().toLowerCase()
export const GROUP_EDIT = (instance_id: string) => `/groups/${instance_id}/edit`

const connectStore = connect(
  (state: ReduxState) => ({
    company: state.company,
    playbooks: state.playbooks,
    playbookInstances: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    getPlaybooks: (param?: GetPlaybooksParam) => dispatch(PlaybookActionDispatcher.getPlaybooks(param)),
    createInstance: (payload: Parameters<typeof PlaybookInstanceDispatcher.createInstance>[0]) =>
      dispatch(PlaybookInstanceDispatcher.createInstance(payload)),
    addPlaybookToInstance: (instance_id: string, playbook_id: string) =>
      dispatch(PlaybookInstanceDispatcher.addPlaybookToInstance(instance_id, playbook_id)),
    editInstance: (instance_id: string, payload: Parameters<typeof PlaybookInstanceDispatcher.editInstance>[1]) =>
      dispatch(PlaybookInstanceDispatcher.editInstance(instance_id, payload)),
    setSelfDrivenMode: instance_id =>
      dispatch(
        PlaybookInstanceDispatcher.updateMode({
          instance_id,
          mode: 'self',
        }),
      ),
  }),
)

const styles = {
  autocompleteLabel: {
    color: '#323232',
    fontSize: 16,
  },

  title: {
    marginBottom: 45,
    fontSize: 24,
    lineHeight: '56px',
  },

  imageContainer: {
    width: 100,
    height: 100,
    margin: 'auto',
  },
  image: {
    width: 100,
    height: 100,
    cursor: 'pointer',
  },

  imageTitle: {
    fontWeight: 800,
    marginTop: 15,
    fontSize: 18,
    marginBottom: 50,
  },

  inputName: {
    width: 300,
    height: 50,
    color: '#323232',
    fontSize: 16,
  },

  inputDescription: {
    height: 182,
    marginTop: 30,
    marginBottom: 25,
  },

  buttonCreate: {
    width: 300,
    height: 50,
    borderRadius: 25,
    fontSize: 18,
  },

  error: {
    color: 'red',
    fontSize: 12,
    marginBottom: 10,
    display: 'block',
  },
}

export default compose(withRouter, connectStore, withStyles(styles))(GroupCreate as any)
