/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import OfficeDispatcher from '@reducers/offices/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import { RouteComponentProps } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import OfficeInfo from './office/OfficeInfo'

interface Props extends RouteComponentProps<{ id: string }> {}

function OfficePage(props: Props) {
  const { match } = props
  const { id } = match.params

  const { office } = useSelector((state: ReduxState) => ({
    office: state.offices[id],
  }))
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(OfficeDispatcher.get(id, { isForce: false }))
  })

  return (
    <PaperContainer>
      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={!office}
        renderLoading={() => <ReactionLoader />}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderEmpty={() => <div>Not Found</div>}
        render={() => <OfficeInfo office={office} />}
      />
    </PaperContainer>
  )
}

export default OfficePage
