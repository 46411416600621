import { Box, Grid, Typography } from '@mui/material'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import CoachesListDispatcher from '@reducers/coachesList/dispatcher'
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import ProfileHeader from '../components/ProfileHeader'
import ReactionLoader from '../components/ReactionLoader'
import currencyToSign from '../helpers/currencyToSign'
import CoachProfileWrapper from './acceptedCoachProfile/CoachProfileWrapper'
import agreementIcon from './acceptedCoachProfile/assets/agreement.svg'
import startDateIcon from './acceptedCoachProfile/assets/startDate.svg'
import SendMsgField from './chats/components/chat/SendMsgField'

const AcceptedCoachProfile = ({
  coaches,
  offers,
  getOffers,
  playbookInstances,
  getCoaches,
  history,
  createMockDirectRoom,
  match: {
    params: { id, offerId },
  },
}) => {
  React.useEffect(() => {
    if (!coaches) {
      getCoaches()
    }
    if (!offers && offerId) {
      getOffers()
    }
  }, [])

  const coach = coaches?.find(({ user_id }) => user_id === id)
  const offer = offers?.find(({ id }) => id === offerId)

  const sendMessage = (message, asset) => {
    createMockDirectRoom('mock-room-id', coach.chat_user_id)
    history.push(`/chats/direct/mock-room-id?${message ? `&message=${message}` : ''}${asset ? `&asset=${asset}` : ''}`)
  }

  if (!coach) {
    return <ReactionLoader />
  }

  const groups =
    playbookInstances &&
    (offer
      ? [playbookInstances[offer.playbook_instance_id]]
      : coach.playbook_instances.map((p) => playbookInstances[p]).filter((g) => !!g))

  return (
    <Grid container style={{ height: '100%' }}>
      <ProfileHeader />
      <Grid xs={12} item style={{ margin: '0 50px 50px 50px' }}>
        <CoachProfileWrapper data={coach}>
          <Grid item xs={9} container style={{ padding: '74px 16px 74px 116px' }}>
            {offer && (
              <Grid item container style={{ marginBottom: 74 }}>
                <Grid container alignItems={'center'} style={{ marginRight: 78, width: 'fit-content' }}>
                  <img src={startDateIcon} style={{ marginRight: 24 }} alt="" />
                  <Box>
                    <Typography>Start Date</Typography>
                    <Typography>{moment(offer.start_at).format('Do MMM YYYY')}</Typography>
                  </Box>
                </Grid>
                <Grid container alignItems={'center'} style={{ width: 'fit-content' }}>
                  <img src={agreementIcon} style={{ marginRight: 24 }} alt="" />
                  <Box>
                    <Typography>Agreement</Typography>
                    <Typography>
                      {currencyToSign(offer.agreed_currency)}
                      {offer.agreed_price} per {offer.period}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Typography style={{ marginBottom: 17 }}>
                <b>Groups: </b>
              </Typography>
              {groups?.map((g) => (
                <Typography style={{ fontSize: 14, marginBottom: 17 }}>
                  <b>{g.name} - </b> Members: {g?.employee_count} Start date:{' '}
                  {moment(g?.created_at).format('D MMM YYYY')}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </CoachProfileWrapper>
        <SendMsgField
          withAttachments={false}
          sendMessage={sendMessage}
          style={{
            marginTop: 15,
            borderRadius: 5,
            overflow: 'hidden',
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          }}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  coaches: state.coachesList,
  offers: state.coaches.offers,
  playbookInstances: state.playbookInstances,
})
const mapDispatchToProps = (dispatch) => ({
  getOffers: () => dispatch(coachesDispatcher.getOffers()),
  getCoaches: () => dispatch(CoachesListDispatcher.getCoaches()),
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default connectStore(AcceptedCoachProfile)
