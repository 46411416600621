/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import apiChallenge from '@reducers/challenge/api'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import { IChallengeApp } from '@reducers/challenge/types'
import ChallengeListsDispatcher from '@reducers/challengeLists/dispatcher'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useCallback, useMemo, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../components/ButtonOrange'
import ReactionLoader from '../../components/ReactionLoader'
import Asset from '../../components/assets/Asset'
import { arrowBackIcon } from '../../components/paperContainer/assets/facade'

interface Props {
  match: any
}

const spacing = 5

const useStyles = makeStyles({
  image: {
    width: 100,
    height: 100,
    borderRadius: 5,
  },
  content: {
    borderTop: '1px solid #E2DFDF',
    marginTop: 55,
  },
  backImage: {
    width: 7,
    height: 13,
    marginRight: 15,
  },
  backText: {
    fontSize: 14,
    color: '#000',
    lineHeight: '100%',
  },
  backContainer: {
    cursor: 'pointer',
  },
  challengeImage: {
    maxWidth: '100%',
  },
  title: {},
})

const DashboardEnable: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { company, challenges } = useSelector((state: ReduxState) => ({
    company: state.company,
    challenges: state.challengeLists.challenges,
  }))
  const history = useHistory()
  const classes = useStyles()
  const { match } = props
  const { id: challengeApplicationId } = match.params || {}
  const [challenge, setChallenge] = useState<IChallengeApp>()

  const dataFetchChallengesAction = useAsyncFetch(async () => {
    await dispatch(TeamDispatcher.getList({ isForce: false }))
    const res = await apiChallenge.getChallengeApplicationById(challengeApplicationId)
    if (!challenges.length) {
      await dispatch(ChallengeListsDispatcher.getChallenges())
    }
    setChallenge(res)
  })

  const isUniqType = useMemo(() => {
    return !challenges.find((i) => i.type_data.name === challenge?.type)
  }, [challenges, challenge])

  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  const handleSelectChallenge = useCallback(async () => {
    if (isFreeTrial && challenge?.is_premium) {
      history.push('/payment')
    } else {
      const startDate = challenge?.start_date || moment().add(1, 'day').format('YYYY-MM-DD')
      const duration = challenge?.end_date ? moment(challenge?.end_date, 'YYYY-MM-DD').diff(startDate, 'days') : 7
      const repeat = !(challenge?.start_date && challenge?.end_date)
      await dispatch(
        ChallengeDispatcher.updateChallenge(challengeApplicationId, {
          duration,
          startDate,
          repeat,
        }),
      )
      history.push('/')
    }
  }, [isFreeTrial, challenge])

  const renderMedia = () => {
    if (challenge?.image_url) {
      return <img src={challenge.image_url} className={classes.challengeImage} />
    }

    if (challenge?.video_url) {
      return (
        <iframe
          width="560"
          height="315"
          src={challenge?.video_url}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      )
    }

    return (
      <ReactHlsPlayer
        src={'https://s.reaction-club.app/-/_/onboarding/Onboarding_stepvideo2.m3u8'}
        autoPlay={false}
        controls
        width="100%"
        height="auto"
      />
    )
  }

  const renderCTA = () => {
    let label = 'Activate'

    if (!isUniqType) {
      return (
        <ButtonOrange disabled onClick={() => {}}>
          <Tooltip title={'Challenge with such type has already added to this company.'}>
            <Typography textTransform="uppercase" fontWeight="600">
              {label}
            </Typography>
          </Tooltip>
        </ButtonOrange>
      )
    }

    if (challenge?.is_published) {
      if (isFreeTrial && challenge?.is_premium) {
        label = 'Upgrade'
      }
    } else {
      label = 'Coming soon'
    }

    return (
      <ButtonOrange
        color={!challenge?.is_published ? '#DADADA' : undefined}
        onClick={!challenge?.is_published ? null : handleSelectChallenge}
      >
        <Typography textTransform="uppercase" fontWeight="600">
          {label}
        </Typography>
      </ButtonOrange>
    )
  }

  return (
    <RendererStatusSplit
      statuses={dataFetchChallengesAction}
      isEmpty={_.isEmpty(challenge)}
      renderLoading={() => <ReactionLoader marginTop={40} />}
      render={() => {
        if (_.isEmpty(challenge)) {
          return null
        }

        return (
          <Paper
            css={css`
              padding: 80px;
              box-shadow: 0px 2px 4px rgba(156, 156, 156, 0.25);
              border-radius: 5px;

              @media (max-width: 520px) {
                margin: 0;
                padding: 10px;
              }
            `}
          >
            <Grid container spacing={spacing}>
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  className={classes.backContainer}
                  onClick={history.goBack}
                >
                  <img src={arrowBackIcon} alt="back icon" className={classes.backImage} />
                  <Typography className={classes.backText}>Back</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item flexBasis={135}>
                    <Asset resizeMode={'cover'} className={classes.image} asset={{ url: challenge?.icon_url }} />
                  </Grid>
                  <Grid item flexGrow={1} paddingTop={2}>
                    <Typography
                      variant="h1"
                      mb={1}
                      fontWeight="bold"
                      fontSize={21}
                      display="flex"
                      alignItems="flex-end"
                    >
                      {challenge?.title}
                      {challenge?.is_premium ? (
                        <Typography fontWeight="400" fontSize={18} ml={3}>
                          (Premium)
                        </Typography>
                      ) : null}
                    </Typography>
                    <Typography fontSize={16} mb={2}>
                      {challenge.provider ? `By ${challenge.provider}` : ''}
                    </Typography>
                    {renderCTA()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.content}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box mb={7} pr={6}>
                      <Typography mt={2} fontSize={18} lineHeight={1.6}>
                        {challenge?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {renderMedia()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )
      }}
    />
  )
}

export default DashboardEnable
