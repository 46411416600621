import { Grid, Typography, Box, Button } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import tickIcon from '../assets/tick.svg'
import CoachWrapper from '../components/CoachWrapper'

const SubmitChampionRequest = ({ classes, postOffer, isOfferNew, history }) => {
  React.useEffect(() => {
    if (isOfferNew) {
      history.push('/coaches/work-offers')
    }
  }, [isOfferNew])

  const onSubmit = () => {
    postOffer()
    history.push('/coaches/work-offers')
  }

  return (
    <CoachWrapper title="Submit a champion request" titleWidth={121}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content}>
            <Box>
              <Typography className={classes.title}>When you submit your request: </Typography>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>you’ll be matched with the coaches who meet your job needs.</Typography>
              </Box>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>You’re not committed to anything at the point.</Typography>
              </Box>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>Coaches will review your request and contact you with next steps.</Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Button className={classes.btn} onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  isOfferNew: Object.keys(state.coaches.offer).length === 0,
})
const mapDispatchToProps = dispatch => ({
  postOffer: () => dispatch(coachesDispatcher.postOffer()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    maxWidth: 570,
    padding: '114px 16px 0 16px',
    margin: '0 auto',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 37,
    fontSize: '17px',
  },
  infoLine: {
    display: 'flex',
    marginBottom: 31,
  },
  tick: {
    marginRight: 14,
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#f8b84e',
    },
  },
})

export default compose(connectStore, withStyles(styles))(SubmitChampionRequest)
