import { Coach } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

type State = ReduxStateCoach[]
export interface ReduxStateCoach extends Coach {
  // ...
}

const initialState: State = []

const slice = createSlice({
  name: 'coachesList',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    set(state, action: PayloadAction<Coach>) {
      let coach = action.payload

      let index = _.findIndex(state, { user_id: coach.user_id })
      if (index === -1) {
        state.push(coach)
      } else {
        state[index] = {
          ...state[index],
          ...coach,
        }
      }
    },
    setList(state, action: PayloadAction<Coach | Coach[]>) {
      let arr = action.payload
      if (!_.isArray(arr)) arr = [arr]

      const listNext = _.map(arr, (coach) => {
        let stateItem = _.find(state, { user_id: coach.user_id })

        return {
          ...stateItem,
          ...coach,
        }
      })

      return listNext // here we overwrite array
    },
    // setStepsPath(state, action: PayloadAction<State['stepsPath']>) {
    //   state.stepsPath = action.payload
    // },
  },
})

export const { actions, reducer } = slice
export default reducer
