import React from 'react'
import EventFormLabel from './elements/EventFormLabel'
import EventFormSelect from './elements/EventFormSelect'

const repeatOptions = [
  { value: '', label: '-' },
  { value: 'week', label: 'Every week' },
  { value: 'month', label: 'Every month' },
]

interface Props {
  value: string | undefined
  onChange(type: string | undefined): any
  isErrored?: boolean
}

export default function EventRepeatSelect(props: Props) {
  const { value, onChange, isErrored } = props

  return (
    <div>
      <EventFormLabel title={'Repeat (optional)'} />
      <EventFormSelect value={value} options={repeatOptions} onChange={onChange} isErrored={isErrored} />
    </div>
  )
}
