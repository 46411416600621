import { Box, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PlusButton from '../../../../components/buttons/PlusButton'
import CompanyEmailCreateModal from '../../../../modals/CompanyEmailCreateModal'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import CompanyEmailItem from './CompanyEmailItem'

const connectStyles = withStyles({
  container: {
    minHeight: 100,
  },
  noEmailsText: {
    color: '#777',
    marginTop: 30,
  },
})

interface Props {}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles>, ExtractConnectType<typeof connectStore> {}

class SettingsCompany extends React.PureComponent<PropsInner, {}> {
  state = {
    isModalVisible: false,
  }

  render() {
    const { classes } = this.props
    const { isModalVisible } = this.state

    return (
      <Box mb={4} padding={4}>
        <Typography variant={'h2'}>Corporate emails</Typography>
        <div className={classes.container}>{this.renderList()}</div>

        <PlusButton onClick={this.handleAdd} title="Add email" />
        <CompanyEmailCreateModal isVisible={isModalVisible} onClose={this.handleModalClose} />
      </Box>
    )
  }

  renderList() {
    const { company, classes } = this.props
    const { emails } = company

    if (_.isEmpty(emails))
      return (
        <Grid container justifyContent={'center'}>
          <span className={classes.noEmailsText}>No emails</span>
        </Grid>
      )

    return _.map(emails, (email) => <CompanyEmailItem email={email.domain} onDelete={this.handleDelete} />)
  }

  handleModalClose = () => {
    this.setState({ isModalVisible: false })
  }

  handleAdd = () => {
    this.setState({
      isModalVisible: true,
    })
  }

  handleDelete = (email: string) => {
    if (!confirm(`Are you sure want to delete email: ${email}?`)) return null
    this.props.deleteEmail(email)
  }

  async fetchData() {
    this.props.getEmails()
  }

  componentDidMount() {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    company: state.company,
  }),
  (dispatch: ReduxDispatch) => ({
    getEmails: () => dispatch(CompanyDispatcher.getEmailList()),
    deleteEmail: (email: string) => dispatch(CompanyDispatcher.deleteCorporateEmail(email)),
  }),
)

export default compose<PropsInner, Props>(connectStyles, connectStore)(SettingsCompany)
