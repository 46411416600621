import appTypes from '@reducers/app/types'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface ReduxStatePlaybook extends Playbook {
  programs?: Program[]
}

interface State {
  [key: string]: ReduxStatePlaybook
}

const initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_PLAYBOOKS: {
      const { playbooks } = action.payload

      _.each(playbooks, playbook => {
        let statePlaybook = state[playbook.id]
        if (statePlaybook) statePlaybook = statePlaybook.merge(playbook)

        state = state.merge({ [playbook.id]: statePlaybook ? statePlaybook.asMutable({ deep: true }) : playbook })
      })

      return state
    }
    case types.SET_PLAYBOOK_PROGRAMS: {
      const { playbook_id, programs } = action.payload

      let statePlaybook = state[playbook_id]
      if (!statePlaybook) return state.merge({ [playbook_id]: { programs } })
      statePlaybook = statePlaybook.merge({ programs })
      state = state.merge({ [playbook_id]: statePlaybook.asMutable({ deep: true }) })
      return state
    }

    // OLD

    case types.SET_PLAYBOOK_DETAIL: {
      return state.merge({
        ...state,
        current: action.payload,
      })
    }
    case types.LOADING: {
      return state.merge({
        isLoaded: false,
      })
    }
    case types.LOADED: {
      return state.merge({
        isLoaded: true,
      })
    }

    default:
      return state
  }
}
