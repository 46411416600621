import { makeStyles } from '@mui/styles'
import AuthDispatcher from '@reducers/auth/dispatcher'
import _ from 'lodash'
import qs from 'qs'
import React from 'react'
import IFrameGuard from 'react-iframe-guard'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import AuthRegistration from './components/AuthRegistration'
import LoginContainer from './components/LoginContainer'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundSize: 'contain',
    backgroundPositionY: 'center',
    height: '100%',
    backgroundRepeat: 'no-repeat',
  },
})

interface Props extends RouteComponentProps {}

function SignupPage(props: Props) {
  const { history, location } = props

  const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()

  const handleSignUp = async (credentials: { email: string; password: string }) => {
    const { search = '' } = location
    await dispatch(AuthDispatcher.signupWithEmailAndPassword(credentials))

    const query = qs.parse(search.replace('?', ''))

    let redirectAddress = '/'
    if (_.isString(query.redirect)) {
      redirectAddress = query.redirect
      delete query.redirect
    }

    // if (document.referrer === 'https://www.reaction-club.com/') {
    //   redirectAddress = '/groups'
    // }

    if (!_.isEmpty(query)) {
      redirectAddress += `?${qs.stringify(query)}`
    }

    history.replace(redirectAddress)
  }

  return (
    <IFrameGuard>
      <div className={classes.root}>
        <LoginContainer>
          <AuthRegistration onSignUp={handleSignUp} />
        </LoginContainer>
      </div>
    </IFrameGuard>
  )
}

export default SignupPage
