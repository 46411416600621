import appTypes from '@reducers/app/types'
import SeamlessImmutable from 'seamless-immutable'
import types from './types'

type Offer = {
  groupId?: string // playbook_instance_id
  category?: string // no need
  type_ids: string[]
  serviceType?: string // type (onsite, online, onsite+online)
  location?: string // location
  language?: string // language
  coordinates?: any // coordinates
  description?: string // description
  currency?: string // proposed_currency (usd, eur, ils)
  period?: string // period
  startDate?: string // start_at
  budget?: number // proposed_price
  isNew: boolean
}

interface State {
  offer: Offer
  offers?: any
  fullCoaches: { [id: string]: any }
}

const initialState: State = {
  offer: {
    isNew: true,
  },
  fullCoaches: {},
}

export default function reducer(state = initialState, action: ReduxAction): State {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_OFFER_GROUP: {
      const groupId = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          groupId,
        },
      }
    }
    case types.SET_FULL_COACH: {
      const coach = action.payload
      return {
        ...state,
        fullCoaches: {
          ...state.fullCoaches,
          [coach.user_id]: coach,
        },
      }
    }
    case types.SET_OFFER_IS_NEW: {
      const isNew = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          isNew,
        },
      }
    }
    case types.SET_OFFER_CATEGORY: {
      const category = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          category,
        },
      }
    }
    case types.SET_OFFER_SUBCATEGORY: {
      const type_ids = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          type_ids,
        },
      }
    }
    case types.SET_OFFER_SERVICE_TYPE: {
      const serviceType = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          serviceType,
        },
      }
    }
    case types.SET_OFFER_LOCATION: {
      const location = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          location,
        },
      }
    }
    case types.SET_OFFER_LANGUAGE: {
      const language = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          language,
        },
      }
    }
    case types.SET_OFFER_COORDINATES: {
      const coordinates = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          coordinates,
        },
      }
    }
    case types.SET_OFFER_DESCRIPTION: {
      const description = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          description,
        },
      }
    }
    case types.SET_OFFER_CURRENCY: {
      const currency = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          currency,
        },
      }
    }
    case types.SET_OFFER_PERIOD: {
      const period = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          period,
        },
      }
    }
    case types.SET_OFFER_START_DATE: {
      const startDate = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          startDate,
        },
      }
    }
    case types.SET_OFFER_BUDGET: {
      const budget = action.payload
      return {
        ...state,
        offer: {
          ...state.offer,
          budget,
        },
      }
    }
    case types.RESET_OFFER: {
      return {
        ...state,
        offer: { ...initialState.offer },
      }
    }
    case types.SET_OFFERS: {
      const offers = action.payload
      return {
        ...state,
        offers,
      }
    }
    case types.SET_OFFER_MATCHED_COACHES: {
      const { id, matchedCoaches } = action.payload
      return {
        ...state,
        offers: state.offers.map(offer => (offer.id === id ? { ...offer, matchedCoaches } : offer)),
      }
    }

    default:
      return state
  }
}
