import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import OrderItem from './OrderItem'

const useStyles = makeStyles(
  {
    heading: {
      background: '#323232',
      borderRadius: 8,
      padding: '10px 0',
      width: '100%',
      marginTop: 10,
    },
    headingCaption: {
      fontSize: 14,
      fontWeight: 800,
      color: '#fff',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  {
    classNamePrefix: 'OrdersList',
  },
)

function OrdersList() {
  const { orders } = useSelector((state: ReduxState) => ({
    orders: state.stocks.orders,
  }))
  const classes = useStyles()

  return (
    <div>
      <Grid item className={classes.heading}>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={3}>
            <Typography className={classes.headingCaption}>User</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.headingCaption}>Reward</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography className={classes.headingCaption} align={'center'}>
              Quantity
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.headingCaption} align={'center'}>
              Date
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.headingCaption} align={'center'}>
              Status
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div>
        {_.map(orders, item => (
          <OrderItem order={item} key={`stock-order-item-${item.id}`} />
        ))}
      </div>
    </div>
  )
}

export default OrdersList
