import {prefix_agent} from "./api"

export default {

  async getRewardsSettings() {
    const result = await this.apiClient.get('rewards/settings')
    return result.data
  },
  async updateRewardsSettings(data){
    const result = await this.apiClient.put('rewards/settings', data)
    return result.data
  },
  async getRewardsCountries () {
    const result = await this.apiClient.get('rewards/countries')
    return result.data
  },
  async getRewardsList (page, country) {
    let url = `rewards/list?page=${page}`
    if (country) url += `&country=${country}`
    const result = await this.apiClient.get(url)
    return result.data
  },
}
