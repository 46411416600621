/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import LeaderboardDispatcher from '@reducers/leaderboard/dispatcher'
import _ from 'lodash'
import { Moment } from 'moment'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import UserLeaderboardItem from '../components/UserLeaderboardItem'

interface Props {
  startMoment: Moment
  endMoment: Moment
}

function LeaderboardListSteps(props: Props) {
  const { startMoment, endMoment } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(
      LeaderboardDispatcher.getUsersPointsLeaderboard()
    )
  }, [startMoment, endMoment])

  const { list } = useSelector((state: ReduxState) => ({
    list: state.leaderboard.userPointsList,
  }))

  return (
    <Grid container>
      <Grid item xs={6}>
        <RendererStatusSplit
          statuses={fetchAction}
          isEmpty={_.isEmpty(list)}
          renderError={(error) => (
            <Typography variant={'h2'} align={'center'} color={'error'}>
              {error}
            </Typography>
          )}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderEmpty={() => <Typography textAlign={'center'}>No users found</Typography>}
          render={() => (
            <div>
              <Grid
                container
                justifyContent={'center'}
                css={css`
                  position: relative;
                  z-index: 10;
                `}
              >
                {fetchAction.isLoading && <Loader type={'absolute'} />}
              </Grid>

              {_.map(list, (item) => (
                <UserLeaderboardItem user={item} value={item.points} key={`leaderboard-points-${item.user_id}`} />
              ))}
            </div>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default LeaderboardListSteps
