import appTypes from '@reducers/app/types'
import { IChallengeApp, IChallengeData } from '@reducers/challenge/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  challenges: IChallengeData[]
  challengeApplications: IChallengeApp[]
}

const initialState: State = {
  challenges: [],
  challengeApplications: [],
}

const slice = createSlice({
  name: 'challengeLists',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setChallenges(state, action: PayloadAction<IChallengeData[]>) {
      const challenges = action.payload
      state.challenges = challenges
    },

    setChallengeApplications(state, action: PayloadAction<IChallengeApp[]>) {
      const challengeApplications = action.payload
      state.challengeApplications = challengeApplications
    },
  },
})

export const { actions, reducer } = slice
export default reducer
