import apiClient from '../../libs/api/libs/apiClient'

export type GetPlaybooksParam = {
  category?: string
  name?: string
  type_ids?: string[]
  offset: number
}

const apiPlaybooks = {
  getPlaybooks: async (
    payload: GetPlaybooksParam,
  ): Promise<{ playbooks: Playbook[]; total_count: number; offset: number }> => {
    const result = await apiClient.get(`/playbooks`, { params: payload })
    return result.data
  },

  getOwnPlaybooks: async (payload?: { category?: string }): Promise<Playbook[]> => {
    const result = await apiClient.get(`/company/playbooks`, { params: payload })
    return result.data
  },

  getPlaybookById: async (playbook_id: string): Promise<Playbook> => {
    const result = await apiClient.get(`/playbook/${playbook_id}`)
    return result.data
  },
  getProgramsByPlaybookId: async (playbook_id: string): Promise<Program[]> => {
    const result = await apiClient.get(`/playbook/${playbook_id}/programs`)
    return result.data
  },
}

export default apiPlaybooks
