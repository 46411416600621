/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'
import Asset from '../../components/assets/Asset'

interface Props {
  item: {
    name: string
    asset: Asset
  }
  innerProps: any
}

export default function SelectAssetOption(props: Props) {
  const { item, innerProps } = props
  const { asset, name } = item

  // const options: SelectProps['options'] = [{ value: '123', label: 'Coach1' }]

  return (
    <Grid
      container
      css={css`
        position: relative;
        z-index: 100;
        padding: 2px;
        cursor: pointer;
        '&:hover': {
          background-color: #eeeded;
        }
      `}
      {...innerProps}
    >
      <div
        css={css`
          margin-right: 10px;
          width: 60px;
          height: 40px;
        `}
      >
        <Asset
          asset={asset}
          css={css`
            width: 60px;
            height: 40px;
          `}
          resizeMode={'cover'}
        />
      </div>
      <span>{name}</span>
    </Grid>
  )
}
