import appTypes from '@reducers/app/types'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  [key: string]: ReduxCoachItem
}

interface ReduxCoachItem extends Coach {}

const initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_COACH: {
      const coach: Coach = action.payload

      return state.merge({
        [coach.user_id]: coach,
      })
    }

    case types.SET_COACHES: {
      const coaches: Coach[] = action.payload

      _.map(coaches, coach => {
        state = state.merge({
          [coach.user_id]: coach,
        })
      })

      return state
    }

    default:
      return state
  }
}
