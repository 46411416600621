import { OnboardForm } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

const apiApp = {
  async getUnreadCounters(): Promise<{
    company_stock_pending_count: number
  }> {
    const { data } = await apiClient.get('/unread-counters')
    return data
  },

  async getOnboardForm(): Promise<OnboardForm | null> {
    const { data } = await apiClient.get('/onboard')
    return data
  },
}

export default apiApp
