import { Grid, Typography } from '@mui/material'
import { withStyles, WithStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import config from '../../../config'
import ButtonOrange from '../../../components/ButtonOrange'
import groupEditDispatcher from '../../../reducers/changeProgram/dispatcher'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { fitnessIcon, mindfulnessIcon, nutritionIcon } from '../assets/facade'
import { SELECT_PLAYBOOK_PAGE } from './PlaybookSelect'

const styles = {
  caption: {
    fontWeight: 800,
    fontSize: 24,
    marginBottom: 20,
  },
  grey: {
    color: '#7E7E7E',
    marginTop: 10,
  },
  categoryContainer: {
    marginTop: 40,
    border: '1px solid #D4D4D4',
    borderRadius: 5,
    width: '30%',
    padding: 35,
    height: 300,
    cursor: 'pointer',
    marginRight: 15,
    paddingBottom: 0,
  },
  categoryName: {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: 1.3,
    marginTop: 20,
    marginBottom: 10,
  },
  categoryDescription: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
  },
}

class ChooseCategory extends React.PureComponent<
  ExtractConnectType<typeof connectStore> & WithStyles<typeof styles> & RouteComponentProps<{ instance_id: string }>
> {
  render() {
    const { classes } = this.props

    return (
      <div>
        <Grid container justifyContent={'flex-end'}>
          <ButtonOrange onClick={this.handlePressCreate}>Create Playbook</ButtonOrange>
        </Grid>
        <Grid container direction={'column'} alignItems={'center'}>
          <Typography className={classNames(classes.caption, classes.grey)}>Add Playbook</Typography>
          <Typography className={classes.caption}>Choose Category:</Typography>
          <Grid item xs={12} md={10}>
            <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
              {this.renderCategories()}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderCategories = () => {
    const { classes } = this.props
    const categories = [
      {
        name: 'Nutrition',
        description: 'Explore trusted wellness plans written by world’s top nutritional experts',
        icon: nutritionIcon,
      },
      {
        name: 'Fitness',
        description: 'Coach fitness plans you can trust thanks to the guidance or renowned fitness professionals',
        icon: fitnessIcon,
      },
      {
        name: 'Mindfulness',
        description: 'Explore trusted wellness plans written by world’s top nutritional experts',
        icon: mindfulnessIcon,
      },
    ]
    return categories.map(category => (
      <Grid
        style={{ textAlign: 'center' }}
        className={classes.categoryContainer}
        onClick={this.handleSelectCategory(category.name)}
        key={`name-${category.name}`}
      >
        <img src={category.icon} alt={category.name} style={{ width: 93, height: 78 }} />
        <Typography className={classes.categoryName}>{category.name}</Typography>
        <Typography className={classes.categoryDescription}>{category.description}</Typography>
      </Grid>
    ))
  }

  handleSelectCategory = (categoryName: string) => async () => {
    const { instance_id } = this.props.match.params
    await this.props.setCategory(categoryName)
    this.props.history.push(SELECT_PLAYBOOK_PAGE(instance_id))
  }

  handlePressCreate = () => {
    const { token } = this.props
    const href = `${config.playbooks_url}?session=${token}`

    window.open(href, '_blank')
  }
}

export const CHOOSE_CATEGORY_PAGE = (instance_id: string) => `/groups/${instance_id}/training-program/categories`

const connectStore = connect(
  (state: ReduxState) => ({ token: state.auth.token }),
  (dispatch: ReduxDispatch) => ({
    setCategory: (categoryName: string) => dispatch(groupEditDispatcher.setCategory(categoryName)),
  }),
)

export default compose(withRouter, connectStore, withStyles(styles))(ChooseCategory) as any
