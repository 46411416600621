/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import AppDispatcher from '@reducers/app/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Loader from '../../components/Loader'
import PaperContainer from '../../components/PaperContainer'
import useModalState from '../../hooks/useModalState'
import ButtonOrangeSquare from '../../modals/components/ButtonOrangeSquare'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import CompanyQuestion from './create/CompanyQuestion'
import CreateCompanyModal from './modals/CreateCompanyModal'

function CompanyCreatePage(props: RouteComponentProps) {
  const modalStateCompanyCreate = useModalState()
  const { company, onboard } = useSelector((state: ReduxState) => ({
    company: state.company,
    onboard: state.app.onboard,
  }))

  const history = useHistory()
  const dispatch = useDispatch<ReduxDispatch>()

  const onboardFetch = useAsyncFetch(
    async () => {
      await dispatch(AppDispatcher.getOnboardForm())
    },
    { maxTries: 3 },
  )

  useEffect(() => {
    if (company.id) {
      props.history.replace('/')
    }
  }, [company])

  const handlePressOnboard = () => {
    history.push('/onboard')
    // location.href = config.links.onboard
  }

  return (
    <div>
      <CreateCompanyModal {...modalStateCompanyCreate} />
      <PaperContainer>
        <RendererStatusSplit
          statuses={onboardFetch}
          isEmpty={_.isEmpty(onboard)}
          renderEmpty={() => <CompanyQuestion onCreateCompany={modalStateCompanyCreate.onOpen} />}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderError={(error) => (
            <Typography color={'error'} textAlign={'center'}>
              {error}
            </Typography>
          )}
          render={() => (
            <div>
              <Typography variant={'h1'}>Please finish onboard</Typography>

              <div
                css={css`
                  padding-top: 30px;
                `}
              >
                <ButtonOrangeSquare onClick={handlePressOnboard}>Continue</ButtonOrangeSquare>
              </div>
            </div>
          )}
        />
      </PaperContainer>
    </div>
  )
}

export default CompanyCreatePage
