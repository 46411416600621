import { Duration } from 'moment'
import React from 'react'
import getFormattedTime from '../time/getFormattedTime'

interface Props {
  duration: Duration
  className?: string
  isZeroMinutesVisible?: boolean
  isZeroHoursVisible?: boolean
  isZeroDaysVisible?: boolean
}
function DurationTimer(props: Props) {
  let { duration, className, isZeroMinutesVisible, isZeroHoursVisible, isZeroDaysVisible } = props

  // this also exists in getFormattedTime()
  if (isZeroDaysVisible) isZeroHoursVisible = true
  if (isZeroHoursVisible) isZeroMinutesVisible = true

  let hours: number | string = Math.floor(duration.asHours() % 24)
  let days: number | string = Math.floor(duration.asDays())
  let minutes: number | string = Math.floor(duration.asMinutes() % 60)
  let seconds: number | string = Math.floor(duration.asSeconds() % 60)

  const formattedTime = getFormattedTime({
    days,
    hours,
    minutes,
    seconds,
    isZeroMinutesVisible,
    isZeroHoursVisible,
    isZeroDaysVisible,
  })

  return <span className={className}>{formattedTime}</span>
}

export default DurationTimer
