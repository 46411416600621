import { ReactionEvent } from '@reaction-club-types/core'
import moment from 'moment'
import { Immutable } from 'seamless-immutable'

function getEventTimings(event: ReactionEvent | Immutable<ReactionEvent>) {
  const { time, duration } = event

  const timeStartMoment = moment(time as string)
  const timeEndMoment = timeStartMoment.clone().add(duration, 'minutes')
  const timePreparationStartMoment = timeStartMoment.clone().add(-5, 'minutes')

  const nowMoment = moment()

  const isActive = timeStartMoment.isBefore(nowMoment) && nowMoment.isBefore(timeEndMoment)
  const isFinished = nowMoment.isAfter(timeEndMoment)
  const isPreparation = nowMoment.isAfter(timePreparationStartMoment) && nowMoment.isBefore(timeStartMoment)

  return {
    isActive,
    isFinished,
    isPreparation,
  }
}

export default {
  getEventTimings,
}
