import apiClient from './libs/apiClient'
import { prefix_agent } from './api'

export default {
  async getSubscriptionConfig() {
    const result = await apiClient.get(`subscription-config`)
    return result.data
  },
  async addPaymentMethod(data) {
    const result = await apiClient.post(`payment-methods`, data)
    return result.data
  },
  async getPaymentMethods() {
    const result = await apiClient.get(`payment-methods`)
    return result.data
  },
  async getSubscription() {
    const result = await apiClient.get(`subscribe-plan`)
    return result.data
  },
  async createSubscription(data) {
    const result = await apiClient.post(`subscribe-plan`, data)
    return result.data
  },
  async cancelSubscription(data) {
    const result = await apiClient.post(`subscribe-plan/cancel`, data)
    return result.data
  },
  async changeSubscriptionPaymentMethod(data) {
    const result = await apiClient.post(`subscribe-plan/change-payment-method`, data)
    return result.data
  },
  async changeSubscriptionPlan(data) {
    const result = await apiClient.post(`change-plan`, data)
    return result.data
  },
  async getPromoCode(code) {
    const result = await apiClient.get(`promo-code/${code}`)
    return result.data
  },
}
