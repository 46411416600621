/** @jsx jsx */
import { jsx } from '@emotion/react'
import { css } from '@emotion/react'
import { Typography, Grid, Box, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import StockDispatcher from '@reducers/stocks/dispatcher'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import rewardsImage from '../../assets/rewards.png'
import PaperContainer from '../../components/PaperContainer'
import UnreadCounter from '../../components/UnreadCounter'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import StockButtonCreate from './stocks/StockButtonCreate'
import StockList from './stocks/StockList'
import StocksPreference from './stocks/StocksPreference'
import ButtonOrange from '../../components/ButtonOrange'
import mixpanel from 'mixpanel-browser'

const useStyles = makeStyles(
  {
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
      marginRight: 10,
    },
    headContainer: {
      marginBottom: 32,
    },
    ordersContainer: {
      marginRight: 20,
    },
    badge: {
      marginRight: 5,
    },
    rewardImage: {
      maxWidth: 260,
    },
    freeTrialContainer: {
      padding: '50px 10px 40px',
    },
  },
  {
    classNamePrefix: 'Stocks',
  },
)

interface Props {}

function Stocks(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()
  const history = useHistory()
  const isDesktop = useMediaQuery('(min-width: 996px)')

  const { company, unread_count } = useSelector((state: ReduxState) => ({
    company: state.company,
    unread_count: state.app.counters.stock_order_pending_count,
  }))

  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  async function fetchData() {
    dispatch(StockDispatcher.getStocks())
    dispatch(StockDispatcher.getOrders())
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleUpgrade = () => {
    mixpanel.track('Upgrade Now Clicked', {
      distinct_id: company?.id,
    })
    history.push('/payment')
  }

  return (
    <PaperContainer>
      {isFreeTrial ? (
        <Box className={classes.freeTrialContainer} textAlign="center">
          <Typography
            variant="h1"
            css={css`
              font-family: Poppins;
              font-weight: 700;
              line-height: 50px;
              font-size: 38px;
              text-align: center;
            `}
            mb={5}
          >
            Motivate long-term engagement <br />
            with points & rewards
          </Typography>
          <ButtonOrange
            styles={{ width: 250, paddingTop: 18, paddingBottom: 18, marginBottom: 40 }}
            onClick={handleUpgrade}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 21px;
              `}
            >
              Upgrade
            </Typography>
          </ButtonOrange>

          {!isDesktop ? (
            <img
              src={rewardsImage}
              alt="Rewards"
              css={css`
                max-width: 100%;
              `}
            />
          ) : (
            <Box bgcolor="#E7E4E4" padding={6} maxWidth={996} marginX="auto">
              <Box bgcolor="#FFFFFF" padding={10}>
                <img
                  src={rewardsImage}
                  alt="Rewards"
                  css={css`
                    max-width: 100%;
                  `}
                />
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <React.Fragment>
          <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.headContainer}>
            <Grid item>
              <Grid container alignItems={'center'}>
                <Typography className={classes.title}>Offer Rewards</Typography>
                <StocksPreference />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems={'center'}>
                <div className={classes.ordersContainer}>
                  <Grid container alignItems={'center'}>
                    <UnreadCounter unreadCount={unread_count} className={classes.badge} />
                    <Link to={'/stock/orders'}>
                      <Typography>orders</Typography>
                    </Link>
                  </Grid>
                </div>
                <StockButtonCreate />
              </Grid>
            </Grid>
          </Grid>
          <StockList />
        </React.Fragment>
      )}
    </PaperContainer>
  )
}

export default Stocks
