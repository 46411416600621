import { Box, Grid, Paper, Typography, Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import Categories from '../workOffer/coachProfile/Categories'

const CoachProfileWrapper = ({ data, classes, children, deleteCoach, history }) => {
  const [isMenuVisible, setIsMenuVisible] = React.useState(false)
  const buttonRef = React.useRef(null)
  const { user_id, name, location, categories, types, avatar_url, customFields } = data

  const avatar = avatar_url || customFields?.avatar_url
  const loc = typeof location === 'string' ? location : location?.formatted_address

  return (
    <Paper className={classes.contentWrapper}>
      <Grid container className={classes.content}>
        <Grid
          item
          xs={3}
          container
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
          className={classes.sidebar}
        >
          <Box className={classes.sidebarDecor} />
          <Avatar src={avatar} className={classes.avatar} />
          <Box className={classes.label}>Coach</Box>
          <Typography className={classes.title}>{name}</Typography>
          <Typography className={classes.location}>{loc}</Typography>
          <Categories categories={categories} types={types} withIcon={false} />
        </Grid>
        {children}
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => setIsMenuVisible(true)}
          disableFocusRipple
          // style={{ padding: 0 }}
          ref={buttonRef}
          className={classes.dotMenu}
          size="large">
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          // style={{ transform: 'translate(-10px, 0)' }}
          keepMounted
          open={isMenuVisible}
          onClose={() => setIsMenuVisible(false)}
          PaperProps={{
            style: {
              backgroundColor: '#2D2D2D',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              deleteCoach(user_id)
              history.goBack()
            }}
          >
            <Typography variant="inherit" className={classes.menuItem}>
              Remove
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Paper>
  );
}

const connectStore = connect(null, (dispatch: ReduxDispatch) => ({
  deleteCoach: id => dispatch(CompanyDispatcher.deleteCoach(id)),
}))

const styles = createStyles({
  contentWrapper: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  content: {
    flexWrap: 'nowrap',
    position: 'relative',
  },
  avatar: { height: 164, width: 164, marginBottom: 40 },
  label: {
    background: '#323232',
    borderRadius: 25,
    borderTopLeftRadius: 0,
    color: '#fff',
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 8,
    padding: '4px 16px',
  },
  category: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  sidebar: {
    backgroundColor: '#F9B22D',
    padding: '54px 16px',
    width: '100%',
    position: 'relative',
  },
  sidebarDecor: {
    position: 'absolute',
    borderRadius: 5,
    width: 35,
    height: 35,
    transform: 'rotate(45deg) translateX(47%)',
    right: 0,
    top: 54,
    backgroundColor: '#F9B22D',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: 12,
  },
  location: {
    fontSize: '16px',
    marginBottom: 50,
  },
  dotMenu: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  menuItem: {
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      color: '#a1a1a1',
    },
  },
})

export default withRouter(connectStore(withStyles(styles)(CoachProfileWrapper)))
