/* eslint-disable no-underscore-dangle */
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import Chat from './components/Chat'

type PathParams = { offerId: string; id: string }
type Props = ExtractConnectType<typeof connectStore> & RouteComponentProps<PathParams>
const DirectCoachChat: React.FC<Props> = ({
  chat: { rooms, users },
  match: {
    params: { offerId, id },
  },
  deleteRoom,
  history,
  getUser,
  getDirectChat,
  sendDirectMessage,
  getDirectMessages,
}) => {
  const room = rooms[id]
  if (!room) {
    return null
  }

  const [messageToSend, setMessageToSend] = React.useState()
  const handleSendMessage = (text, asset) => {
    if (room.isMock) {
      getDirectChat(room.userId)
      setMessageToSend({ text, asset })
    } else {
      sendDirectMessage(room._id, text, asset)
    }
  }

  React.useEffect(() => {
    const { search } = location

    const params = new URLSearchParams(search)
    const message = params.get('message')

    if (id === 'mock-room-id') {
      if (!room) {
        history.push(`/coaches/work-offers`)
      }
    } else {
      getDirectMessages(id)
    }

    handleSendMessage(message)
  }, [])

  React.useEffect(() => {
    if (!room) return

    const usernames = Object.values(users).map(({ username }) => username)
    const userIds = Object.values(users).map(({ userId }) => userId)

    room.usernames?.forEach(username => {
      if (!usernames.find(n => n === username)) {
        getUser({ username })
      }
    })
    if (!userIds.find(userId => userId === room.userId)) {
      getUser({ userId: room.userId })
    }
  }, [rooms, users])

  React.useEffect(() => {
    if (!room?.isMock) return

    const realRoom = Object.values(rooms).find(({ isMock, userId }) => !isMock && userId === room.userId)
    if (realRoom) {
      deleteRoom(room._id)
      getDirectMessages(realRoom._id)
      if (messageToSend) {
        sendDirectMessage(realRoom._id, messageToSend.text, messageToSend.asset)
      }

      history.replace(`/coaches/work-offer/${offerId}/chat/${realRoom._id}`)
    }
  }, [rooms])

  const coach = users[room.userId]
  return <Chat room={room} users={users} coach={coach} offerId={offerId} sendMessage={handleSendMessage} />
}

const mapStateToProps = state => ({
  chat: state.chat,
})
const mapDispatchToProps = dispatch => ({
  getUser: param => dispatch(ChatDispatcher.getUser(param, { isForce: false })),
  getDirectChat: userId => dispatch(ChatDispatcher.getDirectChat(userId)),
  sendDirectMessage: (roomId, text, asset) => dispatch(ChatDispatcher.sendDirectMessage(roomId, text, asset)),
  getDirectMessages: roomId => dispatch(ChatDispatcher.getDirectMessages(roomId, { isReplace: true })),
  deleteRoom: roomId => dispatch(ChatDispatcher.actions.deleteRoom(roomId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default compose(connectStore)(DirectCoachChat)
