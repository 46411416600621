/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Download as DownloadIcon } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import QRCode from 'qrcode.react'
import React, { useRef, useState } from 'react'
import { useTimeout } from 'usehooks-ts'

interface Props {
  link: string
}

function InviteQR(props: Props) {
  const { link } = props
  const [isCopied, setCopied] = useState(false)
  const canvasContainerRef = useRef<HTMLDivElement>()
  const downloadRef = useRef<HTMLAnchorElement>()

  useTimeout(() => setCopied(false), isCopied ? 5000 : null)

  const handleClickDownload = (e) => {
    if (!canvasContainerRef.current) return null
    if (!downloadRef.current) return null
    const canvas = canvasContainerRef.current.querySelector('canvas')
    if (!canvas) return null

    downloadRef.current.href = canvas.toDataURL()
    downloadRef.current.download = 'inviteQR.png'
  }

  return (
    <div>
      <div ref={canvasContainerRef}>
        <QRCode value={link} renderAs="canvas" />
      </div>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <div
          onClick={handleClickDownload}
          css={css`
            margin-top: 10px;
            color: #4a6cf3;
            cursor: pointer;
          `}
        >
          <a ref={downloadRef}>
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Typography fontSize={'14px'} color={'#4a6cf3'}>
                Download
              </Typography>
              <DownloadIcon
                css={css`
                  margin-left: 5px;
                  font-size: 20px;
                `}
              />
            </Grid>
          </a>
        </div>
      </Grid>
    </div>
  )
}

export default InviteQR
