/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormLabel, Grid } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  title: string
  children?: ReactElement
}

export default function EventFormLabel(props: Props) {
  const { title, children } = props

  return (
    <Grid
      container
      alignItems={'center'}
      css={css`
        min-height: 28px;
        margin-bottom: 3px;
      `}
    >
      <FormLabel
        css={css`
          font-family: Poppins;
          color: #717579;
          font-size: 14px;
          line-height: 22px;
          margin-left: 5px;
        `}
      >
        {title}
      </FormLabel>
      {children}
    </Grid>
  )
}
