/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement | string
  className?: string
}

function EventModalTitle(props: Props) {
  const { children, className } = props

  return (
    <Typography
      variant={'h2'}
      css={css`
        font-size: 28px;
        line-height: 32px;
        margin-top: 30px;
        margin-bottom: 30px;
        font-family: Poppins;
      `}
      className={className}
    >
      {children}
    </Typography>
  )
}

export default EventModalTitle
