import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import _ from 'lodash'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import ReportParticipantItem from './components/ReportParticipantItem'
import ReportParticipantsExportButton from './components/ReportParticipantsExportButton'

const useStyles = makeStyles(
  {
    container: {
      marginTop: 50,
    },
    title: {},
  },
  { classNamePrefix: 'ReportParticipantList' },
)

interface Props {
  event: Immutable<ReduxStateReactionEventItem>
}

function ReportParticipantList(props: Props) {
  const { event } = props
  const { participants = [] } = event

  const classes = useStyles()

  return (
    <Grid container justifyContent={'space-between'} className={classes.container}>
      <Grid container justifyContent={'space-between'}>
        <Typography className={classes.title}>Event Participants</Typography>
        <ReportParticipantsExportButton event={event} />
      </Grid>
      {_.map(participants, participant => (
        <ReportParticipantItem participant={participant} key={`report-participant-item-${participant.user_id}`} />
      ))}
    </Grid>
  )
}

export default ReportParticipantList
