import keyManager from 'constant-manager'

const appTypes = keyManager(
  {
    RESET: '',
  },
  { prefix: 'APP__' },
)

export default appTypes
