/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'
import EventModalStyled from '../../modals/elements/EventModalStyled'

interface Props {
  isVisible: boolean
  onClose(): any
}

function EventFieldFilterInfoModal(props: Props) {
  return (
    <EventModalStyled {...props}>
      <div>
        <Typography
          variant={'h2'}
          css={css`
            font-size: 28px;
            margin-top: 30px;
            margin-bottom: 30px;
            font-family: Poppins;
          `}
        >
          Filter
        </Typography>
        <Typography
          css={css`
            font-family: Poppins;
          `}
        >
          <span
            css={css`
              font-size: 16px;
              font-weight: bold;
            `}
          >
            Offices
          </span>{' '}
          enable you to create an event only for people in a specific office. To create an office visit the setting
          section. As you create offices, your members will be asked to select the office they are part of.
        </Typography>

        <br />
        <Typography
          css={css`
            font-family: Poppins;
          `}
        >
          <span
            css={css`
              font-size: 16px;
              font-weight: bold;
            `}
          >
            Programs
          </span>{' '}
          enabling you to create an event only to people that participate in a specific program. For example, if you
          want to create a running event and show it only to the people that selected the running program on the app.
        </Typography>
      </div>
    </EventModalStyled>
  )
}

export default EventFieldFilterInfoModal
