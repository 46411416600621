/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import ConfigDispatcher from '@reducers/configs/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import SectionContainer from '../components/SectionContainer'
import SettingsEventsPenalty from './events/SettingsEventsPenalty'
import SettingsEventsUserPenalties from './events/SettingsEventsUserPenalties'

function SettingsEvents(props: {}) {
  const dispatch = useDispatch<ReduxDispatch>()
  const { eventsConfig } = useSelector((state: ReduxState) => ({
    eventsConfig: state.configs.eventsConfig,
  }))
  const { is_penalty_enabled } = eventsConfig || {}

  const fetchConfig = useAsyncFetch(async () => {
    await dispatch(ConfigDispatcher.getEventConfigs())
  })

  return (
    <div>
      <SectionContainer>
        <RendererStatusSplit
          statuses={fetchConfig}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          render={() => (
            <React.Fragment>
              <Grid item>
                <Typography
                  variant={'h2'}
                  fontWeight={'bold'}
                  css={css`
                    font-size: 18px;
                  `}
                >
                  Event Policies
                </Typography>
                {/* <Typography className={classes.subText}>Here you can do some changed for events functionality</Typography> */}
              </Grid>

              {eventsConfig && <SettingsEventsPenalty eventsConfig={eventsConfig} />}
            </React.Fragment>
          )}
        />
      </SectionContainer>

      {is_penalty_enabled && <SettingsEventsUserPenalties />}
    </div>
  )
}

export default SettingsEvents
