/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'

function CalendarMonthDateHeaderComponent(props) {
  return (
    <div>
      <Typography
        className={'month-date'}
        css={css`
          font-family: Poppins;
          font-size: 24px;
          color: #303134;
          font-weight: bold;
          text-align: left;
        `}
      >
        {props.label}
      </Typography>
    </div>
  )
}

export default CalendarMonthDateHeaderComponent
