import { OnboardForm } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  counters: {
    stock_order_pending_count?: number
  }
  onboard?: OnboardForm | null
  onboardStoreUpdatedAt?: StoreUpdatedAt
}

const initialState: State = {
  counters: {},
}

const slice = createSlice({
  name: 'app',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setCounters(state, action: PayloadAction<State['counters']>) {
      state.counters = action.payload
    },
    setOnboardForm(state, action: PayloadAction<State['onboard']>) {
      state.onboard = action.payload || null
      state.onboardStoreUpdatedAt = Date.now()
    },
  },
})

export const { actions, reducer } = slice
export default reducer
