import { IChallengeApp, IChallengeData } from '@reducers/challenge/types'
import apiClient from '../../libs/api/libs/apiClient'

const api = {
  async getChallenges(): Promise<IChallengeData[]> {
    const { data } = await apiClient.get<IChallengeData[]>('/challenges/v2/list')
    return data
  },
  async getChallengeApplications(): Promise<IChallengeApp[]> {
    const { data } = await apiClient.get<IChallengeApp[]>('/challenges/v2/challenge-apps')
    return data
  },
}

export default api
