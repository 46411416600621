/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import React, { useMemo } from 'react'
import UserAvatar from '../../../components/UserAvatar'
import useFormattedNumber from '../../dashboard/hooks/useFormattedNumber'

interface Props {
  user: Employee
  value: number
}

function UserLeaderboardItem(props: Props) {
  const { user, value } = props
  const { name } = user

  // const rankColor = useMemo(() => {
  //   if (rank === 1) return '#F5962C'
  //   if (rank === 2) return '#D9D9D9'
  //   if (rank === 3) return '#863C3E'
  //
  //   return null
  // }, [rank])

  const value_formatted = useFormattedNumber(value)

  return (
    <div
      css={css`
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 7px 10px 7px 20px;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
      `}
    >
      <Grid container alignItems={'center'}>
        {/*{rankColor && (*/}
        {/*  <div*/}
        {/*    style={{ backgroundColor: rankColor }}*/}
        {/*    css={css`*/}
        {/*      position: absolute;*/}
        {/*      left: 0;*/}
        {/*      top: 0;*/}
        {/*      bottom: 0;*/}
        {/*      width: 10px;*/}
        {/*    `}*/}
        {/*  />*/}
        {/*)}*/}
        <div
          css={css`
            margin-right: 10px;
            font-family: 'Mulish';
          `}
        >
          <UserAvatar user={user} size={40} />
        </div>

        <Grid
          item
          flexGrow={1}
          css={css`
            width: 1px;
          `}
        >
          <Typography
            fontWeight={'bold'}
            css={css`
              font-size: 14px;
              font-family: 'Mulish';
            `}
          >
            {name}
          </Typography>
        </Grid>

        <Typography
          variant={'caption'}
          fontWeight={'bold'}
          css={css`
            padding-left: 10px;
            font-size: 14px;
          `}
        >
          {value_formatted}
        </Typography>
      </Grid>
    </div>
  )
}

export default UserLeaderboardItem
