import { CompanyStock, CompanyStockFull, CompanyStockOrder } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  list: CompanyStock[]
  orders: CompanyStockOrder[]
}

const initialState: State = {
  list: [],
  orders: [],
}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_LIST: {
      const list: CompanyStock[] = action.payload

      return state.merge({
        list,
      })
    }

    case types.SET_ORDERS: {
      const orders: CompanyStockOrder[] = action.payload

      return state.merge({
        orders,
      })
    }

    case types.SET_LIST_ITEM: {
      const stock: CompanyStockFull = action.payload
      let { list } = state
      const stockIndex = _.findIndex(list, item => item.id === stock.id)
      if (stockIndex === -1) {
        list = list.concat(stock)
        state = state.merge({ list })
      } else {
        state = state.setIn(['list', stockIndex], stock)
      }

      return state
    }

    case types.DELETE_ITEM: {
      const stock_id = action.payload

      const list = state.list.filter(item => item.id !== stock_id)

      return state.merge({
        list,
      })
    }

    default:
      return state
  }
}
