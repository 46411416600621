import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { ReduxState } from '@reducers/index'
import { ReduxDispatch } from 'src/typings/ReduxDispatch'
import modalActions from '@reducers/modal/actions'
import { compose } from 'recompose'
import { MODAL_TYPES } from '@reducers/modal/types'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;

  b {
    font-weight: 700;
    margin: 0 10px;
  }
`

const Button = styled.button`
  cursor:  pointer;
  color: black;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 700;
  word-wrap: break-word;
  padding: 9px 25px;
  border-radius: 20px;
  border: none;
  background: #F9B22D;
`

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`

const TabLink = styled(NavLink)`
  border-bottom: 2px solid #717B8C;
  padding: 9px 12px;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  color: #717B8C;


  &:not(:last-child) {
    margin-right: 12px;
  }

  &.active {
    color: rgba(37, 40, 61, 0.80);
    border-bottom: 2px solid rgba(37, 40, 61, 0.80);
    font-weight: 700;
  }
`

const Tab = ({ showModal }) => {
  const match = useRouteMatch()

  const OpenModal = () => showModal({ modalType: MODAL_TYPES.ADD_BALANCE, modalProps: {} })


  return (
    <Container>
      <TabContainer>
        <TabLink to={match.path} exact>Overview</TabLink>
        <TabLink to={`${match.path}/balance`}>Balance</TabLink>
        <TabLink to={`${match.path}/settings`}>Settings</TabLink>
      </TabContainer>
      <BudgetContainer>
        <span>Available Balance</span>
        <b>$28</b>
        <Button onClick={OpenModal}>Add Funds</Button>
      </BudgetContainer>
    </Container>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    isAdmin: state.auth.isAdmin,
  }),
  (dispatch: ReduxDispatch) => ({
    showModal: (param) => dispatch(modalActions.showModal(param)),
  }),
)

export default compose(connectStore)(Tab)