import HelpIcon from '@mui/icons-material/Help'
import { IconButton } from '@mui/material'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const Help = ({ title, subtitle, text }) => {
  const dispatch = useDispatch()

  const handleOpenModal = useCallback(() => {
    dispatch(modalActions.showModal({ modalType: MODAL_TYPES.INFO, modalProps: { title, subtitle, text } }))
  }, [title, subtitle, text])

  return (
    <IconButton color="secondary" onClick={handleOpenModal}>
      <HelpIcon />
    </IconButton>
  )
}

export default Help
