import { Button, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import { Link } from '@reaction-club-types/core'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import classNames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactionLoader from '../../../components/ReactionLoader'
import useActionHandler from '../../../hooks/useAsyncHandler'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'

const useStyles = makeStyles(
  {
    button: {
      width: 165,
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 18,
      height: 40,
      padding: 0,
      textAlign: 'center',
      textTransform: 'capitalize',
      border: '2px solid',
    },
    buttonYes: {
      color: '#eaaa09',
      background: '#fff',
      borderColor: '#eaaa09',
      marginRight: 32,
      '&:hover': {
        color: '#ee7c25',
        borderColor: '#ee7c25',
      },
    },
    buttonNo: {
      color: '#fff',
      background: '#eaaa09',
      borderColor: '#fff',
      '&:hover': {
        background: '#ee7c25',
      },
    },
  },
  {
    classNamePrefix: 'LinkModalButtons',
  },
)

interface Props {
  invite: Link
  onClose(): any
}

const LinkModalButtons: React.FC<Props> = (props: Props) => {
  const { invite, onClose } = props
  const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()
  const { isLoading, onAction } = useActionHandler(async () => {
    await dispatch(CompanyDispatcher.acceptInvitation(invite.id))
  })

  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))

  const handleConfirm = async () => {
    await onAction()
    onClose()
  }

  const { id } = invite?.company || {}

  if (id && id === company.id) {
    return (
      <Grid container direction={'column'} alignItems={'center'}>
        <Alert severity="success">You already joined this company!</Alert>
        <Button variant={'contained'} color={'primary'} size={'small'} style={{ marginTop: 10 }} onClick={onClose}>
          Close
        </Button>
      </Grid>
    )
  }

  if (isLoading)
    return (
      <Grid item>
        <ReactionLoader />
      </Grid>
    )

  return (
    <Grid container justifyContent="space-around">
      <Button onClick={handleConfirm} className={classNames(classes.button, classes.buttonYes)}>
        Yes
      </Button>
      <Button onClick={onClose} className={classNames(classes.button, classes.buttonNo)}>
        No
      </Button>
    </Grid>
  )
}

export default LinkModalButtons
