import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
  },
  { prefix: 'CHALLENGE__' },
)

export interface IChallengeData {
  challenge_id: string
  challenge_item_id: string
  start_date: string
  icon_url?: string
  image_url?: string
  provider: IChallengeProvider
  config: Record<string, any>
  background_color?: string | null
  type: IChallengeType
  enabled: boolean
  name: string
  start_at: string
  end_at: string
  status: string
  currentDay: string
  custom_prize: string
  repeat: boolean
  startDate: string
  timezone: string
  valueTotal: string
  valueAvg: string
  leaderboard: []
  participantCount: string
  duration: number
  application_id: string
}

export interface IChallenge {
  id: string
  name: string
  customPrize: string
  repeat: boolean
  startDate: string
  timezone: string
  duration: number
  application_id: string
}

export interface IChallengeApp {
  id: string
  name: string
  is_active: boolean
  created_at: string
  updated_at: string
  config: Record<string, any>
  created_by: string
  type_data: IChallengeType
  type: string
  provider: IChallengeProvider
  icon_url?: string | null
  image_url?: string | null
  result_type?: 'time' | 'integer'
  video_url?: string | null
  title?: string | null
  description?: string | null
  background_color?: string | null
  start_date?: string | null
  end_date?: string | null
  is_verified: boolean
  is_premium: boolean
  is_published: boolean
  is_enabled: boolean
}

export interface IChallengeProvider {
  id: string
  name: string
  label: string
}

export interface IChallengeType {
  id: string
  name: string
  label: string
}
