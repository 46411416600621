/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Chip } from '@mui/material'
import { CompanyOffice } from '@reaction-club-types/core'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Select, { components, Props as SelectProps } from 'react-select'
import EventGroupSelectOption from './components/EventGroupSelectOption'
import EventFormSelect from './elements/EventFormSelect'

interface Props {
  selected?: string[]
  onChange(offices: string[]): any
  isErrored?: boolean
}

interface SelectOptionCustom {
  value: string[]
  label: string
  item?: CompanyOffice
}

export default function EventOfficeSelect(props: Props) {
  const { selected = [], onChange, isErrored } = props

  const { offices } = useSelector((state: ReduxState) => ({
    offices: state.lists.offices,
  }))

  // const officesSelected = _.map(offices)
  const officesSelected = useMemo(() => {
    return _.filter(offices, (item) => {
      return _.includes(selected, item.id)
    })
  }, [offices, selected])
  const officesFiltered = useMemo(() => {
    return _.filter(offices, (item) => {
      return !_.includes(selected, item.id)
    })
  }, [offices, selected])

  let options: SelectOptionCustom[] = _.map(officesFiltered, (instance) => ({
    value: instance.id,
    label: instance.name,
    item: instance,
  }))

  // options.unshift({ label: 'all', value: null })

  const handleChange = (office_id: string) => {
    if (!office_id) return null
    const listNext = selected.concat(office_id)
    onChange(listNext)
  }

  const createDeleteHandler = (id: string) => () => {
    const listNext = _.filter(selected, (item) => item !== id)
    onChange(listNext)
  }

  // const itemSelected = _.find(options, { value })

  return (
    <div>
      <EventFormSelect
        placeholder={'Select Office'}
        // value={value}
        onChange={handleChange}
        options={options}
        isErrored={isErrored}
        components={{
          Option: (optionProps) => {
            const { data, innerProps } = optionProps
            const { item }: { item: CompanyOffice } = data

            if (!item) {
              return <components.Option {...optionProps} />
            }

            return <EventGroupSelectOption item={item} innerProps={innerProps} />
          },
        }}
      />

      <Box marginTop={'5px'}>
        {_.map(officesSelected, (item) => {
          const { id, name } = item

          return (
            <Chip
              label={name}
              variant="outlined"
              onDelete={createDeleteHandler(id)}
              key={`event-office-selected-${id}`}
              css={css`
                margin-right: 5px;
              `}
            />
          )
        })}
      </Box>
    </div>
  )
}
