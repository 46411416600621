import apiCompany from '@reducers/company/api'
import api from './api'
import { actions } from './reducer'

class Dispatcher {
  actions = actions

  getCoach(id: string) {
    return async (dispatch) => {
      const coach = await api.getCoach(id)
      dispatch(actions.set(coach))
    }
  }

  getCoaches() {
    return async (dispatch) => {
      const coaches = await apiCompany.getCompanyCoaches()
      dispatch(actions.setList(coaches))
    }
  }
}

const CoachesListDispatcher = new Dispatcher()
export default CoachesListDispatcher
