/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography, Input } from '@mui/material'
import ConfigDispatcher from '@reducers/configs/dispatcher'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import ButtonOrange from '../../../../../components/ButtonOrange'
import Loader from '../../../../../components/Loader'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'

interface Props {
  eventsConfig: any
}
function SettingsEventsPenalty(props: Props) {
  const { eventsConfig } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const [isSaved, setIsSaved] = useState(false)

  const actionSetPenaltyDays = useAsyncHandler(
    async (params: { penalty_duration: number; penalty_start_offset: number }) => {
      await dispatch(ConfigDispatcher.setPenaltyDays(params))
      setIsSaved(true)
    },
  )

  const { penalty_duration, penalty_start_offset } = eventsConfig || {}

  const form = useForm<{ duration: string; start_offset: string }>({
    defaultValues: {
      duration: `${penalty_duration / 24}`,
      start_offset: `${penalty_start_offset}`,
    },
  })
  const { register } = form

  const handlePressSave = form.handleSubmit((data) => {
    const params = {
      penalty_duration: parseInt(data.duration) * 24, // because value should be in hours
      penalty_start_offset: parseInt(data.start_offset),
    }

    if (!params.penalty_duration || !params.penalty_start_offset) return null
    if (params.penalty_duration === penalty_duration && params.penalty_start_offset === penalty_start_offset)
      return null

    actionSetPenaltyDays.execute(params)
  })

  return (
    <div
      css={css`
        margin-top: 10px;

        input {
          text-align: center;
        }
      `}
    >
      <div>
        <Typography display={'inline'}>The cancellation window is </Typography>
        <Input
          {...register('start_offset', {
            onChange: () => {
              if (isSaved) setIsSaved(false)
            },
          })}
          css={css`
            width: 60px;
            text-align: center;
            font-weight: bold;
          `}
        />
        <Typography display={'inline'}> hours.</Typography>
      </div>
      <div>
        <Typography display={'inline'}>
          If members do not cancel their registration before that time or don't show up, then they will not be able to
          register for another event for the next{' '}
        </Typography>
        <Input
          {...register('duration', {
            onChange: () => {
              if (isSaved) setIsSaved(false)
            },
          })}
          css={css`
            width: 60px;
            text-align: center;
            font-weight: bold;
          `}
        />
        <Typography display={'inline'}> days.</Typography>
      </div>

      <Grid
        container
        justifyContent={'flex-end'}
        css={css`
          margin-top: 20px;
        `}
      >
        <RendererStatusSplit
          statuses={actionSetPenaltyDays}
          renderLoading={() => <Loader scale={0.4} />}
          render={() =>
            isSaved ? (
              <Typography
                css={css`
                  color: #008003;
                  font-weight: bold;
                  font-size: 12px;
                `}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  css={css`
                    margin-right: 7px;
                  `}
                />
                Saved
              </Typography>
            ) : (
              <ButtonOrange onClick={handlePressSave}>Save</ButtonOrange>
            )
          }
        />
      </Grid>
    </div>
  )
}

export default SettingsEventsPenalty
