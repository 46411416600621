import { makeStyles } from '@mui/styles'
import React from 'react'
import { useLocation } from 'react-router-dom'
import SettingsNavItem from './SettingsNavItem'

const useStyles = makeStyles(
  {
    container: {
      display: 'flex',
    },
  },
  {
    classNamePrefix: 'SettingsNav',
  },
)

function SettingsNav() {
  const classes = useStyles()
  const location = useLocation()

  return (
    <div className={classes.container}>
      <SettingsNavItem location={location} href={'/settings/account'} title={'Account Settings'} />
      <SettingsNavItem location={location} href={'/settings/login-and-security'} title={'Login & Security'} />
      <SettingsNavItem location={location} href={'/settings/events'} title={'Events'} />
      <SettingsNavItem location={location} href={'/settings/billing'} title={'Billing'} />
    </div>
  )
}

export default SettingsNav
