import { Employee } from '@reaction-club-types/core'
import _ from 'lodash'
import React, { useMemo } from 'react'
import EmployeeListItem from './EmployeeListItem'

interface Props {
  list: Employee[]
  nameFilter?: string
  exclude?: { user_id: string }[]
  onSelect: (user_id: string) => any
}

function EmployeeList(props: Props) {
  const { list, nameFilter, exclude, onSelect } = props

  const userListAfterExclude = useMemo(() => {
    if (_.isEmpty(exclude)) return list
    return _.filter(list, (user) => {
      const isExcluded = _.find(exclude, (item) => item.user_id === user.user_id)
      return !isExcluded
    })
  }, [list, exclude])

  const userListFiltered = useMemo(() => {
    if (!nameFilter) return userListAfterExclude
    const searchLow = nameFilter.toLowerCase()
    return _.filter(userListAfterExclude, (user) => {
      const userName = user.name || ''
      return userName.toLowerCase().includes(searchLow)
    })
  }, [userListAfterExclude, nameFilter])

  const listResult = userListFiltered

  return (
    <div>
      {_.map(listResult, (user) => (
        <EmployeeListItem key={`modal-user-select-${user.user_id}`} employee={user} onSelect={onSelect} />
      ))}
    </div>
  )
}

export default EmployeeList
