import { Grid, Box } from '@mui/material'
import { createStyles, WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import React, { KeyboardEventHandler, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import CloseButton from './CloseButton'
import ReactionLoader from './ReactionLoader'

/**
 * @deprecated
 */
const MODAL_COMPONENTS = {
  [MODAL_TYPES.CREAT_FIRST_GROUP]: React.lazy(() => import('./modals/components/CreateFirstGroup')),
  [MODAL_TYPES.INVITE_COACH]: React.lazy(() => import('./modals/components/InviteCoach')),
  [MODAL_TYPES.INVITE_GROUP_COACH]: React.lazy(() => import('./modals/components/InviteGroupCoach')),
  [MODAL_TYPES.CREATE_CHAT]: React.lazy(() => import('./modals/components/CreateChat')),
  [MODAL_TYPES.FULL_SIZE_IMAGE]: React.lazy(() => import('./modals/components/FullSizeImage')),
  [MODAL_TYPES.EDIT_COMPANY]: React.lazy(() => import('./modals/components/EditCompany')),
  [MODAL_TYPES.EXPORT_STEPS]: React.lazy(() => import('./modals/components/ExportSteps')),
  [MODAL_TYPES.INFO]: React.lazy(() => import('./modals/components/Info')),
  [MODAL_TYPES.CHANGE_EMPLOYEE_GROUP]: React.lazy(() => import('./modals/components/ChangeEmployeeGroup')),
  // Not checked yet
  [MODAL_TYPES.UPDATE_COMPANY_DETAILS]: React.lazy(() => import('./modals/components/UpdateCompany')),
}

type OwnProps = {}
type Props = OwnProps & ExtractConnectType<typeof connectStore> & WithStyles<typeof styles>

const Modals: React.FC<Props> = ({ modal, closeModal, classes }) => {
  const activeModals = Object.keys(modal)

  const upperModalType = activeModals[activeModals.length - 1]
  const upperModalProps = modal[upperModalType] || {}
  const closeUpperModal = () => closeModal(upperModalType)

  const { closable = true } = upperModalProps

  const escFunction: KeyboardEventHandler = (e) => {
    if (e.keyCode === 27 && closable) {
      closeUpperModal()
    }
  }

  React.useEffect(() => {
    if (activeModals.length) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [modal])

  React.useEffect(() => {
    if (activeModals.length) {
      document.addEventListener('keydown', escFunction)
    }

    return () => {
      document.removeEventListener('keydown', escFunction)
    }
  }, [modal])

  if (!activeModals.length) return null

  const UpperModal = MODAL_COMPONENTS[upperModalType]

  return ReactDOM.createPortal(
    <Suspense fallback={<ReactionLoader />}>
      <Box className={classes.bg} onClick={closable && closeUpperModal} />
      <Grid container justifyContent="center" alignItems="center" className={classes.modal}>
        <Grid item>
          {closable && <CloseButton onPress={closeUpperModal} />}
          <UpperModal {...upperModalProps} closeModal={closeUpperModal} />
        </Grid>
      </Grid>
    </Suspense>,
    document.getElementById('modal'),
  )
}

const mapStateToProps = (state) => ({
  modal: state.modal,
})
const mapDispatchToProps = (dispatch) => ({
  closeModal: (modalType) => dispatch(modalActions.closeModal(modalType)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  bg: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    background: `rgba(0, 0, 0, 0.4)`,
    zIndex: 2,
  },
  modal: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    width: 'auto !important',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    padding: 48,
    background: '#fff',
    zIndex: 3,
  },
})

export default compose(connectStore, withStyles(styles))(Modals)
