import { Box, Button, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CompanyDispatcher from '@reducers/company/dispatcher'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

const getTimeZoneOptions = () => {
  const timezones = moment.tz.names()
  const timezoneOptions: { label: string; value: string }[] = timezones.map((tz) => {
    const tzOffset = moment.tz(tz).format('Z')
    return {
      label: `${tz} (GMT${tzOffset})`.replace(/_/g, ' '),
      value: tz,
    }
  })
  return timezoneOptions
}

const Timezone = ({ company }) => {
  const dispatch = useDispatch()
  const [timezone, setTimezone] = useState<{ label: string; value: string }>()
  const timezones = useMemo(() => getTimeZoneOptions(), [])

  useEffect(() => {
    const tz = timezones.find((tz) => tz.value === company.timezone)
    setTimezone(tz)
  }, [])

  const handleUpdate = (tz) => {
    dispatch(CompanyDispatcher.updateTimezone(tz.value))
    setTimezone(tz)
  }

  return (
    <Box>
      <Typography mb={2} fontSize={16} fontFamily="Manrope">
        Main Timezone
      </Typography>
      <Autocomplete
        options={timezones}
        disableClearable
        getOptionLabel={(option) => option.label}
        value={{ label: timezone?.label || '' }}
        onChange={(e, tz) => handleUpdate(tz)}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Box>
  )
}

export default Timezone
