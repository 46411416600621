/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Button, Grid, Typography } from '@mui/material'
import AuthActionDispatcher from '@reducers/auth/dispatcher'
import React from 'react'
import { useDispatch } from 'react-redux'
import logoutIcon from '../assets/Logout/logout_icon.png'
import ModalStyled from '../elements/ModalStyled'
import { ReduxDispatch } from '../typings/ReduxDispatch'

interface Props {
  isVisible: boolean
  onClose(): any
}

const cssButton = css`
  max-width: 165px;
  flex-grow: 1;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  height: 36px;
  padding: 0px;
  text-align: center;
  text-transform: capitalize;
  border: 2px solid #eaaa09;

  margin-right: 30px;

  @media (max-width: 500px) {
    margin-right: 15px;
  }
`

const InviteCompanyHrModal: React.FC<Props> = (props: Props) => {
  const { onClose, isVisible } = props

  const dispatch = useDispatch<ReduxDispatch>()

  const handlePressOk = async () => {
    onClose()
    dispatch(AuthActionDispatcher.logout())
  }

  return (
    <ModalStyled {...props}>
      <Grid container direction="column" justifyContent="space-between" alignItems="center">
        <Grid
          item
          css={css`
            padding-top: 30px;
            margin-bottom: 32px;
          `}
        >
          <Avatar src={logoutIcon} />
        </Grid>
        <Grid
          item
          css={css({
            fontSize: 28,
            textAlign: 'center',
            marginBottom: 32,
          })}
        >
          <Typography>Are you sure you want to logout?</Typography>
        </Grid>
        <Grid
          item
          css={css`
            width: 100%;
            padding-left: 15px;
          `}
        >
          <Grid container justifyContent="space-between">
            <Button
              onClick={handlePressOk}
              css={[
                cssButton,
                css({
                  color: '#eaaa09',
                  background: '#fff',
                  '&:hover': {
                    color: '#ee7c25',
                    borderColor: '#ee7c25',
                  },
                }),
              ]}
            >
              Ok
            </Button>
            <Button
              onClick={onClose}
              css={[
                cssButton,
                css({
                  color: '#fff',
                  background: '#eaaa09',
                  '&:hover': {
                    background: '#ee7c25',
                  },
                }),
              ]}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalStyled>
  )
}

export default InviteCompanyHrModal
