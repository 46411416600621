/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { RouteComponentProps } from 'react-router-dom'
import Loader from '../../components/Loader'
import ReactionAvatar from '../../components/assets/ReactionAvatar'
import { ReduxState } from '../../reducers'
import PlaybookInstanceDispatcher from '../../reducers/playbookInstances/dispatcher'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import GroupEmployees from './GroupEmployees'
import { groupIcon } from './assets/facade'
import GroupActions from './group/GroupActions'
import PlaybookInstanceCoach from './group/PlaybookInstanceCoach'
import PlaybookInstancePlaybook from './group/PlaybookInstancePlaybook'

interface RouterProps {
  instance_id: string
}

interface Props extends RouteComponentProps<RouterProps> {}

function Group(props: Props) {
  const { match } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const { instance_id: group_id } = match.params || {}

  const { group } = useSelector((state: ReduxState) => ({
    group: state.playbookInstances[group_id],
  }))

  const groupFetch = useAsyncFetch(async () => {
    await dispatch(PlaybookInstanceDispatcher.get(group_id, { isForce: false }))
  }, [group_id])

  const groupExtraFetch = useAsyncFetch(async () => {
    if (group_id) return null
    if (!groupFetch.isDone) return null
    await dispatch(PlaybookInstanceDispatcher.getInstanceCoaches(group_id))
  }, [group_id, groupFetch.isDone])

  return (
    <RendererStatusSplit
      statuses={groupFetch}
      isEmpty={_.isEmpty(group)}
      renderLoading={() => (
        <Grid container justifyContent={'center'}>
          <Loader />
        </Grid>
      )}
      renderEmpty={() => <Typography>Group is not found</Typography>}
      renderError={(error) => (
        <Typography color={'error'} textAlign={'center'}>
          {error}
        </Typography>
      )}
      render={() => {
        const { name, description } = group

        return (
          <React.Fragment>
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
              <Grid
                item
                css={css`
                  width: 1px;
                  flex-grow: 1;
                `}
              >
                <Grid container direction="row" alignItems="flex-start">
                  <ReactionAvatar
                    asset={group.asset || group.playbook?.asset || { url: group.thumb || group.playbook?.thumb }}
                    css={css`
                      margin-right: 20px;
                      margin-bottom: 15px;
                    `}
                    style={{
                      width: 96,
                      height: 96,
                    }}
                  />
                  <Grid
                    css={css`
                      margin-top: 10px;
                      max-width: 85%;
                    `}
                  >
                    <Grid direction={'row'} container>
                      <img src={groupIcon} alt="Group" />
                      <Typography
                        css={css({
                          display: 'inline-block',
                          position: 'relative',
                          left: -43,
                          // top: -5,
                          color: '#fff',
                          fontSize: 11,
                          fontWeight: 800,
                        })}
                      >
                        Group
                      </Typography>
                    </Grid>
                    <Typography
                      css={css`
                        font-size: 22px;
                        font-weight: 800;
                        margin-top: 5px;
                        margin-bottom: 10px;
                      `}
                    >
                      {name}
                    </Typography>
                    <Typography
                      css={css`
                        font-size: 14px;
                        word-wrap: break-word;
                      `}
                    >
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <GroupActions group={group} />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              css={css`
                margin-top: 30px;
              `}
            >
              <Grid
                item
                xs={12}
                md={6}
                css={css`
                  padding-right: 40px;
                  min-height: 485px;

                  @media (max-width: 899px) {
                    padding-right: 0;
                    min-height: 0;
                  }
                `}
              >
                <RendererStatusSplit
                  statuses={groupExtraFetch}
                  renderLoading={() => (
                    <Grid container justifyContent={'center'}>
                      <Loader />
                    </Grid>
                  )}
                  render={() => (
                    <Grid container>
                      <PlaybookInstancePlaybook instance={group} />
                      <PlaybookInstanceCoach instance={group} />
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GroupEmployees group={group} />
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }}
    />
  )
}

export const GROUP_PAGE = (id: string) => `/groups/${id}`

export default Group
