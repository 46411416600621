/** @jsx jsx */
import { jsx } from '@emotion/react'
import { TabContext, TabPanel } from '@mui/lab'
import { Grid, Typography, Box } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import moment, { Moment } from 'moment'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import PaperContainer from '../../components/PaperContainer'
import LeaderboardTabs from './LeaderboardTabs'
import LeaderboardListPoints from './leaderboards/LeaderboardListPoints'
import LeaderboardListSteps from './leaderboards/LeaderboardListSteps'
import ButtonOrange from '../../components/ButtonOrange'

interface Props {}

function LeaderboardPage(props: Props) {
  const dispatch = useDispatch()
  const [tab, setTab] = React.useState('steps')
  const [range] = React.useState<DateRange<Moment>>(() => [moment().add(-1, 'month'), moment()])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const handleOpenExportSteps = (type) => () => {
    dispatch(
      modalActions.showModal({ modalType: MODAL_TYPES.EXPORT_STEPS, modalProps: { challengeType: 'steps', type } }),
    )
  }

  const { startMoment, endMoment } = useMemo(() => {
    let [rangeStart, rangeEnd] = range

    return {
      startMoment: rangeStart,
      endMoment: rangeEnd,
    }
  }, [range])

  return (
    <TabContext value={tab}>
      <PaperContainer>
        <div>
          <Grid container alignItems={'center'}>
            <Grid container sm={6} justifyContent={'space-between'}>
              <Typography variant={'h1'} mr={20}>
                Leaderboard
              </Typography>

              <div>
                <LeaderboardTabs onChange={handleTabChange} />
              </div>
            </Grid>
            <Grid sm={6} container justifyContent="flex-end">
              <ButtonOrange onClick={handleOpenExportSteps('points')} styles={{ marginRight: 5 }}>
                Export Points
              </ButtonOrange>
              <ButtonOrange onClick={handleOpenExportSteps('users')} styles={{ marginRight: 5 }}>
                Export Data
              </ButtonOrange>
              <ButtonOrange onClick={handleOpenExportSteps('teams')}>Export Teams Steps</ButtonOrange>
            </Grid>
          </Grid>

          <Box pt={4}>
            <TabPanel value="points">
              <LeaderboardListPoints startMoment={startMoment} endMoment={endMoment} />
            </TabPanel>
            <TabPanel value="steps">
              <LeaderboardListSteps />
            </TabPanel>
          </Box>
        </div>
      </PaperContainer>
    </TabContext>
  )
}

export default LeaderboardPage
