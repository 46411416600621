/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, ListItem, ListItemText, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { OpenSans } from '../../../helpers/commonStyles'
import UnreadCounter from '../../UnreadCounter'

const useStyles = makeStyles<any, { open: boolean }>(
  {
    avatarContainer: {
      marginRight: 20,
      minWidth: 15,
      marginLeft: (props) => (props.open ? 10 : 0),
    },
    icon: {
      width: 17,
      height: 17,
      resizeMode: 'contain',
    },
    title: {
      color: '#A4A6B3',
      fontSize: 16,
      textDecoration: 'none',
      fontWeight: 100,
      fontFamily: OpenSans,
      marginTop: 0,
      marginBottom: 0,
      display: 'flex',
    },
    link: {
      display: 'block',
      textDecoration: 'none',
      cursor: 'pointer',
    },

    linkSelected: {
      background: 'rgb(159, 162, 180, 0.04)',
    },
    badge: {
      marginLeft: 14,
    },
    // lockContainer: {
    //   position: 'absolute',
    //   top: 5,
    //   bottom: 5,
    //   left: 0,
    //   right: 0,
    //   backgroundColor: 'rgba(255,255,255, 0.2)',
    // },
    lockIcon: {
      fontSize: '16px',
      // marginLeft: 45,
    },
  },
  {
    classNamePrefix: 'SideBarLink',
  },
)

interface Props {
  url?: string
  title: string
  icon: string
  open: boolean
  fake?: boolean
  onClick?: () => any
  unreadCount?: number
  isLocked?: boolean
  isPremium?: boolean
}

const SideBarLink: React.FC<Props> = (props: Props) => {
  let { open, url, title, icon, onClick, fake, unreadCount, isLocked, isPremium } = props
  if (isLocked) {
    fake = true
  }
  const classes = useStyles({ open })
  const Wrapper = fake ? Box : NavLink
  const isIconModeBadge = url === '/coaches'

  const wrapperActiveClasses: { activeClassName?: string } = {}
  if (!fake) wrapperActiveClasses.activeClassName = classes.linkSelected

  return (
    <Wrapper {...wrapperActiveClasses} className={classes.link} to={url} onClick={onClick || undefined}>
      <ListItem
        css={[
          css`
            padding: 7px 0;
          `,
          open &&
            css`
              padding-left: 15px;
            `,
        ]}
      >
        <Grid
          container
          justifyContent={'center'}
          css={css`
            width: 60px;
            min-width: 60px;
            max-width: 60px;
          `}
        >
          {isLocked ? (
            <FontAwesomeIcon
              icon={faLock}
              color={'#fff'}
              css={css`
                font-size: 16px;
              `}
            />
          ) : (
            <img className={classes.icon} src={icon} alt={title} />
          )}
        </Grid>
        <ListItemText
          primary={
            <span className={classes.title}>
              {title}
              <UnreadCounter unreadCount={unreadCount} isIconMode={isIconModeBadge} className={classes.badge} />
            </span>
          }
        />
        <Grid
          container
          justifyContent={'center'}
          css={css`
            width: 60px;
            min-width: 60px;
            max-width: 60px;
          `}
        >
          {isPremium ? (
            <FontAwesomeIcon
              icon={faUnlock}
              color={'#fff'}
              css={css`
                font-size: 16px;
              `}
            />
          ) : null}
        </Grid>
      </ListItem>
    </Wrapper>
  )
}

export default SideBarLink
