import {prefix_web} from "./api"
import {prefix_agent} from "./api"
import apiClient from './libs/apiClientOld'

export default {
  async getPlaybookTypes() {
    const result = await apiClient.get(prefix_agent + 'playbook_types')
    return result.data
  },
}
