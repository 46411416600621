import { CompanyOffice } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

const apiCompanyOffices = {
  async getList(): Promise<{ offices: CompanyOffice[] }> {
    const { data } = await apiClient.get('/office/list')
    return data
  },
  async getOffice(id: string): Promise<CompanyOffice> {
    const { data } = await apiClient.get(`/office/${id}`)
    return data
  },

  async createOffice(params: { name: string; address?: string }): Promise<{ office_id: string }> {
    const { data } = await apiClient.post('/office/create', params)
    return data
  },

  async deleteOffice(id: string): Promise<{ deleted: number }> {
    const { data } = await apiClient.delete(`/office/${id}`)
    return data
  },
}

export default apiCompanyOffices
