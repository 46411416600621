import { ReactionEvent } from '@reaction-club-types/core'
import { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import eventUtils from './eventUtils'

function useEventTiming(event: ReactionEvent) {
  const [timings, setTiming] = useState(() => eventUtils.getEventTimings(event))

  let timeout = timings.isFinished ? null : 10000 // null for disabling interval
  useInterval(() => {
    const timingNext = eventUtils.getEventTimings(event)
    setTiming(timingNext)
  }, timeout)

  return timings
}

export default useEventTiming
