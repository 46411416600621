// EMPLOYEES METHODS
import { Employee } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

export default {
  /** @deprecated. This method fetches all users */
  getEmployees: async () => {
    const result = await apiClient.get('/employees')
    return result.data
  },

  getList: async (params: { offset: number; name?: string }): Promise<{ employees: Employee[] }> => {
    const result = await apiClient.get('/employee/list', {
      params,
    })
    return result.data
  },

  async kickEmployees(employees: string[]): Promise<void> {
    const result = await apiClient.delete(`/employees`, { params: { employees } })
    return result.data
  },

  async resetPoints(employees: string[]): Promise<void> {
    const result = await apiClient.post(`/reset-points`, { employees })
    return result.data
  },
}
