/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { Link, useLocation } from 'react-router-dom'
import Loader from '../../../components/Loader'
import config from '../../../config'

const useStyles = makeStyles(
  {
    buttonContainer: {
      marginBottom: 10,
    },

    button: {
      border: '1px solid #DEDEDE',
      borderRadius: 10,
      width: '70%',
      height: 42,
      backgroundColor: '#fff',
      outline: 'none',
      marginLeft: '15%',
      userSelect: 'none' as const,
      minWidth: 200,
    },

    buttonText: {
      color: '#1A1A1A',
      fontSize: 12,
      textAlign: 'center' as const,
      width: '100%',
    },

    loginErrorText: {
      width: '70%',
      marginLeft: '15%',
    },

    buttonInteractions: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f7f8ff',
      },
    },

    loader: {
      transform: 'scale(0.5)',
      marginTop: -10,
    },

    loginTextField: {
      width: '70%',
      minWidth: 200,
      marginLeft: '15%',
      marginBottom: 10,
    },

    loginForgotPassword: {
      color: '#1A1A1A',
      fontSize: 12,
      width: '70%',
      minWidth: 200,
      marginLeft: '15%',
      marginBottom: 10,
    },
  },
  { classNamePrefix: 'LoginCredentialsForm' },
)

// TODO: This is the copy of login button and will be refactored.
// TODO: Basic form validation will be revisited and improved.
// TODO: Login buttons are planned to be removed completely leaving the this single login option.

interface Props {
  email?: string
  onLogin(credentials: { login: string; password: string }): Promise<any>
}

function LoginCredentialsForm(props: Props) {
  const { onLogin, email: emailPreset } = props
  const classes = useStyles()
  const [email, setEmail] = useState(emailPreset || '')
  const [password, setPassword] = useState('')
  const [validationError, setValidationError] = useState('')

  const location = useLocation()

  const actionLogin = useAsyncHandler(async () => {
    await onLogin({ login: email, password })
  })

  const handleSubmit = () => {
    setValidationError('')

    if (!email || !password) {
      setValidationError('Fields are required')
      return null
    }

    return actionLogin.execute()
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setEmail(event.target.value || '')

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPassword(event.target.value || '')

  useEffect(() => {
    const { search } = location
    const qParams = new URLSearchParams(search)
    const login = qParams.get('login') || qParams.get('email')
    if (login) setEmail(login)
  }, [])

  const isErrored = actionLogin.isErrored || !!validationError

  return (
    <Grid container direction={'column'} justifyContent={'center'}>
      <TextField
        label="Email"
        type="email"
        error={isErrored}
        disabled={actionLogin.isLoading}
        value={email}
        onChange={handleChangeEmail}
        className={classes.loginTextField}
      />
      <TextField
        label="Password"
        type="password"
        error={isErrored}
        disabled={actionLogin.isLoading}
        value={password}
        onChange={handleChangePassword}
        className={classes.loginTextField}
      />

      <FormControl className={classes.buttonContainer} error={isErrored}>
        <button
          type={'button'}
          onClick={handleSubmit}
          className={classNames(classes.button, {
            [classes.buttonInteractions]: !actionLogin.isLoading,
          })}
          disabled={actionLogin.isLoading}
        >
          {actionLogin.isLoading ? (
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Loader />
            </Grid>
          ) : (
            <Grid container direction={'row'} alignItems={'center'}>
              <span className={classes.buttonText}>Login</span>
            </Grid>
          )}
        </button>
        {actionLogin.isErrored && <FormHelperText className={classes.loginErrorText}>Failed to sign in</FormHelperText>}
      </FormControl>
      <Grid container justifyContent={'center'}>
        <a
          css={css`
            margin-bottom: 10px;
            text-decoration: none;
            cursor: pointer;
          `}
          href={config.links.onboard}
          target={'_blank'}
          rel="noreferrer"
        >
          <Typography
            css={css`
              color: #0a58ba;
              font-size: 12px;
              text-decoration: none;
            `}
          >
            create a company
          </Typography>
        </a>
      </Grid>

      <Link className={classes.loginForgotPassword} to={'/reset-password'}>
        I forgot my password.
      </Link>
    </Grid>
  )
}

export default LoginCredentialsForm
