import React from 'react'

const lineStyle = { width: 18, height: 3, backgroundColor: '#fff', marginBottom: 3 }

const BurgerMenu = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      cursor: 'pointer',
      top: 20,
      right: 20,
      position: 'absolute',
    }}
  >
    <div style={lineStyle} />
    <div style={lineStyle} />
    <div style={lineStyle} />
  </div>
)

export default BurgerMenu;