import { PlaybookCategory, PlaybookType } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface PlaybookCategoryExtended extends PlaybookCategory {
  types?: PlaybookType[]
}

interface State {
  [key: string]: PlaybookCategoryExtended
}

const initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_CATEGORIES: {
      const { payload: categories } = action
      _.each(categories, (category) => {
        let stateCategory = state[category.id]
        if (stateCategory) stateCategory = stateCategory.merge(category)

        state = state.merge({ [category.id]: stateCategory ? stateCategory.asMutable({ deep: true }) : category })
      })
      return state
    }
    case types.SET_CATEGORY_TYPES: {
      const { category_id, types: categoryTypes } = action.payload
      let stateCategory = state[category_id]
      if (stateCategory) stateCategory = stateCategory.merge({ types: categoryTypes })

      state = state.merge({
        [category_id]: stateCategory ? stateCategory.asMutable({ deep: true }) : { types: categoryTypes },
      })
      return state
    }

    default:
      return state
  }
}
