import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import EULAModal from './modals/EULAModal'
import { ExtractConnectType } from './typings/ReduxExtractor'

class StatusListeners extends React.PureComponent<ExtractConnectType<typeof connectStore>> {
  render() {
    const { is_eula_accepted, isAuthorized } = this.props
    if (!isAuthorized) return null

    return (
      <>
        {/* */}
        {/* <EULAModal isVisible={!is_eula_accepted} /> */}
      </>
    )
  }
}

const connectStore = connect((state: ReduxState) => ({
  isAuthorized: !!state.auth.token,
  is_eula_accepted: state.auth.is_eula_accepted,
}))

export default connectStore(StatusListeners)
