import { AssetFull } from '@reaction-club-types/core'
import { AxiosRequestConfig } from 'axios'
import apiClient from './libs/apiClientAssets'

export default {
  async upload(file: File, options?: Partial<AxiosRequestConfig>): Promise<AssetFull> {
    const formData = new FormData()
    formData.append('file', file)

    const result = await apiClient.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      ...options,
    })

    return result.data
  },
}
