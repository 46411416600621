import moment from 'moment'
import React from 'react'
import { View } from 'react-big-calendar'
import CalendarToolbar from '../calendar/CalendarToolbar'
import { styled } from '@mui/material/styles'

interface Props {
  onTabChange(tab: View): any
}

function Discover(props: Props) {
  const { onTabChange } = props

  return (
    <Container>
      <CalendarToolbar tab={'agenda'} date={moment()} onTabChange={onTabChange} />

      <iframe src="https://reaction-club.com/shop-homepage/" height="100%" width="100%" />
    </Container>
  )
}

const Container = styled('div')`
  height: calc(100vh - 220px);
`

export default Discover
