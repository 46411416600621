/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement | string
}

function EventModalText(props: Props) {
  const { children } = props

  return (
    <Typography
      css={css`
        font-family: Poppins;
      `}
    >
      {children}
    </Typography>
  )
}

export default EventModalText
