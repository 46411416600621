import { Box, Grid, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { withStyles, WithStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import { PlaybookInstanceExtended } from '@reducers/playbookInstances/reducer'
import _ from 'lodash'
import React from 'react'
import { WithStylesProps } from 'react-jss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import ReactionAvatar from '../../../components/assets/ReactionAvatar'
import { coronaIcon } from '../assets/facade'

const styles = {
  caption: {
    fontSize: 24,
    fontWeight: 800,
    marginBottom: 50,
    width: '100%',
  },
  coronaIcon: {
    position: 'absolute',
    right: 21,
    top: 15,
  },
  primaryCoachContainer: {
    padding: 30,
    paddingLeft: 20,
    border: '1px solid #D4D4D4',
    borderRadius: 5,
    position: 'relative',
  },
  headCoachCaption: {
    fontSize: 18,
    fontWeight: 800,
  },
  coachTextContainer: {
    height: 70,
    padding: '10px 0',
    marginLeft: 15,
  },
  threeDotsButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
}

interface Props {
  instance: PlaybookInstanceExtended
}

function PrimaryCoach(props: Props & WithStylesProps<typeof styles>) {
  const { instance, classes, createMockDirectRoom } = props
  if (!instance.coaches) return
  const { coaches } = instance
  const primaryCoach = _.find(coaches, { is_primary: true }) as Coach
  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'space-between'}
      className={classes.primaryCoachContainer}
      alignItems={'center'}
    >
      <img src={coronaIcon} alt="coronaIcon" className={classes.coronaIcon} />
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <Box
            onClick={() => createMockDirectRoom('mock-room-id', primaryCoach.chat_user_id)}
            style={{ width: 'fit-content' }}
          >
            <Link to={`/chats/direct/mock-room-id`} style={{ textDecoration: 'none' }}>
              <ReactionAvatar asset={{ url: primaryCoach.avatar_url }} style={{ width: 70, height: 70 }} />
            </Link>
          </Box>
          <Grid item>
            <Grid container direction={'column'} justifyContent={'space-around'} className={classes.coachTextContainer}>
              <Typography className={classes.headCoachCaption}>Head Coach</Typography>
              <Typography className={classes.coachName}>{primaryCoach.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <button type="button" className={classes.threeDotsButton}>
          <MoreVertIcon htmlColor={'#AEAEAE'} />
        </button>
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => ({
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(null, mapDispatchToProps)
export default compose(React.memo, withStyles(styles as any), connectStore)(PrimaryCoach) as any
