/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement } from 'react'
import ModalStyled from '../../../../elements/ModalStyled'

interface Props {
  isVisible: boolean
  onClose(): any
  children: ReactElement
  className?: string
}

function EventModalStyled(props: Props) {
  const { isVisible, onClose, children, className } = props

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      className={className}
      css={css`
        min-width: 400px;
        max-width: 570px;
        border-radius: 20px;
        box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
      `}
    >
      <div
        css={css`
          padding: 10px 20px;
          position: relative;
        `}
      >
        <div>
          <FontAwesomeIcon
            onClick={onClose}
            icon={faXmark}
            color={'#D7D7D7'}
            css={css`
              font-size: 28px;
              position: absolute;
              top: 20px;
              right: 0px;
              padding: 5px;
              cursor: pointer;
            `}
          />
        </div>

        {children}
      </div>
    </ModalStyled>
  )
}

export default EventModalStyled
