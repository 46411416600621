import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { plusIcon } from '../../pages/settings/assets/facade'
import LockIcon from '@mui/icons-material/Lock'

const useStyles = makeStyles(
  {
    box: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
    },
    icon: {
      cursor: 'pointer',
      width: 26,
      height: 26,
    },
    iconBig: {
      width: 30,
      height: 30,
    },
    caption: {
      fontSize: 15,
      fontWeight: 800,
      marginLeft: 5,
    },
  },
  {
    classNamePrefix: 'PlusButton',
  },
)

interface Props {
  title?: string
  lock?: boolean
  className?: string
  onClick?: () => void
}

function PlusButton(props: Props) {
  const { title, onClick, className, lock } = props

  const classes = useStyles()

  return (
    <Box mr={4} className={classNames([classes.box, className])} onClick={lock ? () => {} : onClick}>
      <img src={plusIcon} alt="Plus" className={classNames(classes.icon, { [classes.iconBig]: !title })} />
      {title && <Typography className={classes.caption}>{title}</Typography>}
      {lock ? <LockIcon /> : null}
    </Box>
  )
}

export default PlusButton
