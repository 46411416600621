import { Agent } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State extends Agent {}

const initialState: State = {} as any

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const me = action.payload
      return state.merge(me)
    }

    case types.SET_COMPANY_ID: {
      const company_id = action.payload
      return state.merge({ company_id })
    }

    default:
      return state
  }
}
