/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { ReactNode, useCallback } from 'react'
import Loader from './Loader'
import lockIcon from '../assets/lock.svg'
import config from '../config'
import { useSelector } from 'react-redux'
import { ReduxState } from '@reducers/index'
import mixpanel from 'mixpanel-browser'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(
  {
    small: {
      width: 45,
      fontSize: 10,
      padding: '3px 5px',
    },
    large: {
      width: 120,
      fontSize: 14,
    },
    disabled: {
      cursor: 'pointer',
      opacity: '0.7',
    },
    lockIcon: {
      width: 15,
      height: 15,
      marginLeft: 4,
    },
  },
  {
    classNamePrefix: 'ButtonOrange',
  },
)

interface Props {
  children: ReactNode
  color?: string
  size?: 'small' | 'mid' | 'large'
  onClick: () => void
  disabled?: boolean
  styles?: any
  className?: string
  isLoading?: boolean
  locked?: boolean
}

function ButtonOrange(props: Props) {
  const {
    children,
    color,
    onClick,
    size,
    disabled = false,
    styles: customStyles,
    className = '',
    isLoading,
    locked,
  } = props

  const history = useHistory()

  const company = useSelector((state: ReduxState) => state.company)
  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'
  const classes = useStyles()

  const handleUpgrade = useCallback(() => {
    mixpanel.track('Upgrade Now Clicked', {
      distinct_id: company?.id,
    })
    history.push('/payment')
  }, [])

  if (isLoading) return <Loader />

  const buttonStyle = {
    backgroundColor: color,
    ...customStyles,
  }

  const buttonStyles = [className]

  if (size === 'small') buttonStyles.push(classes.small)
  if (size === 'large') buttonStyles.push(classes.large)
  if (disabled) buttonStyles.push(classes.disabled)

  return (
    <Button
      onClick={disabled ? () => ({}) : locked && isFreeTrial ? handleUpgrade : onClick}
      css={css({
        backgroundColor: '#b8b8b8',
        borderRadius: 50,
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '22px',
        padding: '6px 20px',
        textTransform: 'initial',
        minWidth: 90,
      })}
      className={buttonStyles.join(' ')}
      style={buttonStyle}
    >
      {children}
      {locked && isFreeTrial ? <img src={lockIcon} className={classes.lockIcon} /> : null}
    </Button>
  )
}

ButtonOrange.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}

ButtonOrange.defaultProps = {
  color: '#F9B22D',
}

export default ButtonOrange
