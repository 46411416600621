import { CompanyOffice, GroupCoach } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompanyTeam } from '@reducers/teams/types'

type State = {
  offices: CompanyOffice[]
  teams: ICompanyTeam[]
  officesUpdatedAt?: StoreUpdatedAt
  teamsUpdatedAt?: StoreUpdatedAt
  groupsCoaches: GroupCoach[]
  groupsCoachesUpdatedAt?: StoreUpdatedAt
  eventPenalties?: any[]
  eventPenaltiesUpdatedAt?: StoreUpdatedAt
}

const initialState: State = {
  offices: [],
  groupsCoaches: [],
}

const slice = createSlice({
  name: 'lists',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setOffices(state, action: PayloadAction<CompanyOffice[]>) {
      const offices = action.payload
      state.offices = offices
      state.officesUpdatedAt = Date.now()
    },

    setTeams(state, action: PayloadAction<ICompanyTeam[]>) {
      const teams = action.payload
      state.teams = teams
      state.teamsUpdatedAt = Date.now()
    },

    setGroupsCoaches(state, action: PayloadAction<State['groupsCoaches']>) {
      const list = action.payload
      state.groupsCoaches = list
      state.groupsCoachesUpdatedAt = Date.now()
    },

    setEventPenalties(state, action: PayloadAction<State['eventPenalties']>) {
      const list = action.payload
      state.eventPenalties = list
      state.eventPenaltiesUpdatedAt = Date.now()
    },
  },
})

export const { actions, reducer } = slice
export default reducer
