import { EventParticipant, EventRecording, ReactionEvent, ReactionEventFull } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

interface State {
  [key: string]: ReduxStateReactionEventItem
}

export interface ReduxStateReactionEventItem extends ReactionEventFull {
  participants: EventParticipant[]
  participantsUpdatedAt?: StoreUpdatedAt
  recordings?: EventRecording[]
  childEvent?: ReactionEvent
  zak_token?: string
}

const initialState: State = {
  // [id: string]: Event
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setEvents(state, action: PayloadAction<ReactionEvent | ReactionEvent[]>) {
      let events = action.payload
      if (!_.isArray(events)) events = [events]

      _.each(events, (event) => {
        state[event.id] = {
          ...state[event.id],
          ...event,
        }
      })
    },

    deleteEvent(state, action: PayloadAction<string>) {
      let event_id = action.payload
      delete state[event_id] // not sure it works this way. Found example in issues
    },

    setParticipants(state, action: PayloadAction<{ event_id: string; participants: EventParticipant[] }>) {
      const { event_id, participants } = action.payload
      state[event_id].participants = participants
      state[event_id].participantsUpdatedAt = Date.now()
    },

    setRecordings(state, action: PayloadAction<{ event_id: string; recordings: EventRecording[] }>) {
      const { event_id, recordings } = action.payload
      state[event_id].recordings = recordings
    },

    setChildEvent(state, action: PayloadAction<{ event_id: string; event: ReactionEvent }>) {
      const { event_id, event } = action.payload
      state[event_id].childEvent = event
    },

    setZakToken(state, action: PayloadAction<{ event_id: string; zak_token: string }>) {
      const { event_id, zak_token } = action.payload
      state[event_id].zak_token = zak_token
    },
  },
})

export const { actions, reducer } = eventsSlice
export default reducer
