import { makeStyles } from '@mui/styles'
import React from 'react'
import { useLocation } from 'react-router-dom'
import SettingsNavItem from './SettingsNavItem'

const useStyles = makeStyles(
  {
    container: {
      display: 'flex',
    },
  },
  {
    classNamePrefix: 'NavContainer',
  },
)

function NavContainer() {
  const classes = useStyles()
  const location = useLocation()

  return (
    <div className={classes.container}>
      <SettingsNavItem location={location} href={'/members/members'} title={'Members'} />
      <SettingsNavItem location={location} href={'/members/teams'} title={'Teams'} />
    </div>
  )
}

export default NavContainer
