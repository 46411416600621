import { Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxState } from '@reducers/index'
import * as React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect, NavLink, Link } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import bellIcon from '../../components/sideBar/assets/bell.svg'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import PlusIcon from './assets/filled-plus.svg'
import Champions from './components/Champions'
// import Externals from './coaches/Externals'
import WorkOffers from './components/WorkOffers'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: 32,
  },
  tabBtn: {
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    backgroundColor: '#C4C4C4',
    color: '#fff',
    padding: '14px',
    minWidth: 150,
    borderRadius: '8px',
    fontWeight: 'bold',
    '&:not(:last-child)': {
      marginRight: 12,
    },
    '&:hover': {
      backgroundColor: '#F9B22D',
    },
  },
  activeTabBtn: {
    color: '#323232',
    backgroundColor: '#F9B22D',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
  btnWrapper: {
    display: 'flex',
    marginBottom: 40,
  },
  findCoach: {
    display: 'flex',
    cursor: 'pointer',
    textDecoration: 'none',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  badge: {
    position: 'absolute',
    top: -8,
    left: 8,
    width: 22,
    height: 22,
    borderRadius: 10,
    backgroundColor: ' #e84848',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#fff',
  },
})

const tabs = [
  // {
  //   label: 'Externals',
  //   to: '/coaches/externals',
  // },
  {
    label: 'Coaches',
    to: '/coaches/champions',
  },
  {
    label: 'Work offers',
    to: '/coaches/work-offers',
  },
]

const Coaches = () => {
  const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()

  const { chat, offers } = useSelector((state: ReduxState) => ({
    chat: state.chat,
    offers: state.coaches.offers,
  }))

  useAsyncFetch(async () => {
    await dispatch(coachesDispatcher.getOffers())
  })

  return (
    <PaperContainer>
      <Typography className={classes.title}>Your coaches</Typography>
      <Box className={classes.btnWrapper}>
        {tabs.map(({ label, to }) => {
          return (
            <NavLink to={to} className={classes.tabBtn} activeClassName={classes.activeTabBtn}>
              {label}
              {to === '/coaches/work-offers' &&
                offers?.find(
                  ({ pending_count, matchedCoaches }) =>
                    pending_count > 0 ||
                    matchedCoaches?.find(
                      (c) => Object.values(chat?.rooms || {}).find((r) => r.userId === c.chat_user_id)?.unread > 0,
                    ),
                ) && (
                  <span className={classes.badge}>
                    <img src={bellIcon} alt="" width={10} />
                  </span>
                )}
            </NavLink>
          )
        })}
        <Link to="/coaches/invite" className={classes.findCoach}>
          <img src={PlusIcon} alt="" style={{ marginRight: 12 }} />
          <Typography>
            <b>Find new coach</b>
          </Typography>
        </Link>
      </Box>
      <Switch>
        <Route path={'/coaches/work-offers'} component={WorkOffers} />
        <Route path={'/coaches/champions'} component={Champions} />
        {/* <Route path={'/coaches/externals'} component={Champions} /> */}
        {/*<Redirect to={'/coaches/champions'} />*/}
      </Switch>
    </PaperContainer>
  )
}

export default Coaches
