import axios from 'axios'
import config from '../../../config'

const instance = axios.create({
  baseURL: config.api_url,
  // Egor local server
  // baseURL: 'http://localhost:3333/',
  // Max local server
  // baseURL: 'http://192.168.1.92:3333/',
  timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
})

patchMethod('get')
patchMethod('post')
patchMethod('delete')

export default instance

function patchMethod(methodName) {
  const originName = `${methodName}Original`
  instance[originName] = instance[methodName]
  instance[methodName] = async (...args) => {
    try {
      return await instance[originName](...args)
    } catch (e) {
      const logArgs = ['[ERROR] API']
      if (e.response) {
        const { status, data } = e.response
        if (status === 401) {
          console.log('apiClientOld.ts: logout ignore')
          // logOut()
        }
        logArgs.push(status)
        logArgs.push(data)
      } else logArgs.push(e.message)
      console.log(...logArgs)
      throw e
    }
  }
}

function logOut() {
  localStorage.removeItem('token')
  window.location.reload()
}
