import { Button } from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'

const MyButton = ({ children, onClick, disabled, linkTo, style = {} }) => {
  const fullStyle = {
    display: 'block',
    textAlign: 'center',
    backgroundColor: disabled ? '#D4D4D4' : '#F9B22D',
    padding: '12px 16px',
    minWidth: 144,
    borderRadius: '25px',
    fontWeight: 'bold',
    color: disabled ? '#797979' : '#000',
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    pointerEvents: 'all',
    ...style,
  }

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        onClick={e => {
          if (disabled) {
            e.preventDefault()
          } else if (onClick) {
            onClick()
          }
        }}
        style={fullStyle}
      >
        {children}
      </Link>
    )
  }

  return (
    <Button disabled={disabled} onClick={onClick} style={fullStyle}>
      {children}
    </Button>
  )
}

export default MyButton
