import AuthDispatcher from '@reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import _ from 'lodash'
import qs from 'qs'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import AuthSection from './AuthSection'
import LoginContainer from './LoginContainer'

interface Props {
  email?: string
}

interface PropsInner extends Props, ExtractConnectType<typeof connectToStore>, RouteComponentProps {}

interface State {
  isLoading: boolean
}

class Login extends React.PureComponent<PropsInner, State> {
  state = {
    isLoading: false,
  }

  render() {
    const { isLoading } = this.state
    const { email } = this.props

    return (
      <LoginContainer>
        <AuthSection
          onFacebookLogin={this.loginWithFacebook}
          onGoogleLogin={this.handleLoginWithGoogle}
          onEmailAndPasswordLogin={this.handleLoginWithEmailAndPassword}
          email={email}
        />
      </LoginContainer>
    )
  }

  handleFirebaseLogin = async () => {
    console.log('handleFirebaseLogin', this.props)
    const { loginWithFirebaseToken, history } = this.props

    try {
      const { currentUser } = firebase.auth()
      console.log('currentUser', currentUser)
      if (!currentUser) {
        return null
      }

      console.log('getIdToken')
      const token = await currentUser.getIdToken()
      console.log('token', token)
      if (!token) {
        return null
      }

      await loginWithFirebaseToken(token)
      history.push('/')
    } catch (e) {
      console.log('ERR [handleFirebaseLogin]:', e)
    }
  }

  loginWithFacebook = async () => {
    console.log('loginWithFacebook')
    try {
      console.log('Facebook signOut')
      await firebase.auth().signOut()
      const provider = new firebase.auth.FacebookAuthProvider()
      console.log('Facebook signInWithPopup')
      await firebase.auth().signInWithPopup(provider)
      console.log('Facebook handleFirebaseLogin')
      await this.handleFirebaseLogin()
    } catch (e) {
      console.log('ERR [loginWithFacebook]:', e)
    }
  }

  handleLoginWithGoogle = async () => {
    await this.logoutGoogleSafe()

    console.log('Google signInWithPopup')
    const provider = new firebase.auth.GoogleAuthProvider()
    await firebase.auth().signInWithPopup(provider)

    console.log('Google handleFirebaseLogin')
    await this.handleFirebaseLogin()
  }

  logoutGoogleSafe = async () => {
    try {
      console.log('Google signOut')
      await firebase.auth().signOut()
    } catch (e) {
      console.log('ERROR [logoutGoogle]', e)
    }
  }

  handleLoginWithEmailAndPassword = async (credentials: { login: string; password: string }) => {
    const { login, password } = credentials
    const { loginWithEmailAndPassword, history, location } = this.props
    const { search = '' } = location
    await loginWithEmailAndPassword(login, password)

    const query = qs.parse(search.replace('?', ''))

    let redirectAddress = '/'
    if (_.isString(query.redirect)) {
      redirectAddress = query.redirect
      delete query.redirect
    }

    if (!_.isEmpty(query)) {
      redirectAddress += `?${qs.stringify(query)}`
    }

    history.replace(redirectAddress)
  }
}

const connectToStore = connect(
  (state: ReduxState) => ({}),
  (dispatch: ReduxDispatch) => ({
    loginWithFirebaseToken: (token: string) => dispatch(AuthDispatcher.loginWithFirebaseToken(token)),
    loginWithEmailAndPassword: (email: string, password: string) =>
      dispatch(AuthDispatcher.loginWithEmailAndPassword(email, password)),
  }),
)

export default compose<PropsInner, Props>(connectToStore, withRouter)(Login)
