import {prefix_web} from "./api"
import {prefix_agent} from "./api"

export default {

  async getPrograms() {
    const result = await this.apiClient.get(prefix_agent + 'programs')
    return result.data
  },
  async createProgram(playBookId, programObj) {
    const result = await this.apiClient.post(prefix_agent + 'playbook/'+ playBookId + '/program',  programObj)
    return result.data
  },
  async updateProgram(programId, programObj){
    const result = await this.apiClient.post(prefix_agent + 'program/' + programId, programObj)
    return result.data
  },
}
