/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import _ from 'lodash'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import EventRecordingItem from './components/EventRecordingItem'

interface Props {
  event: ReduxStateReactionEventItem | Immutable<ReduxStateReactionEventItem>
}

function ReportRecordings(props: Props) {
  const { event } = props
  let { name, recordings } = event

  // recordings = [
  //   {
  //     user_id: 'fe9aa29d-7ddd-4637-9a36-766fc3050da9',
  //     meeting_id: '85709227745',
  //     event_id: 'ede8e2fb-a877-43db-a9d5-31c8ffdb4620',
  //     key: 'zoom/1bb9ddad-aab2-4d82-a1c1-2468d596a179.mp4',
  //     url: 'https://s.reaction-club.app/zoom/1bb9ddad-aab2-4d82-a1c1-2468d596a179.mp4',
  //     file_size: 797341737,
  //     view_count: 0,
  //     duration: 40861,
  //   },
  //   {
  //     user_id: null,
  //     meeting_id: '85306953452',
  //     event_id: null,
  //     key: 'zoom/696f8409-3341-4009-a8ef-037f28c3db48.mp4',
  //     url: 'https://s.reaction-club.app/zoom/696f8409-3341-4009-a8ef-037f28c3db48.mp4',
  //     file_size: 186708969,
  //     view_count: 0,
  //     duration: 3960,
  //   },
  // ]

  if (_.isEmpty(recordings)) return null

  return (
    <div>
      <Box marginBottom={'15px'} marginTop={'15px'}>
        <Divider />
      </Box>
      <Typography variant={'h2'}>Session Recordings</Typography>
      <Grid container alignItems={'center'}>
        {_.map(recordings, (recording, i) => (
          <EventRecordingItem
            recording={recording}
            title={`${name} - recording ${i + 1}`}
            key={`event-recording-item-${recording.key}`}
          />
        ))}
      </Grid>
    </div>
  )
}

export default ReportRecordings
