import apiCompany from '@reducers/company/api'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import apiStocks, { ApiPayloadStockCreate } from './api'

export { ApiPayloadStockCreate }

class Dispatcher {
  getStocks(): DispatcherResponse {
    return async dispatch => {
      const list = await apiStocks.getStocks()
      dispatch(actions.setStocksList(list))
    }
  }

  getStock(stock_id: string): DispatcherResponse {
    return async dispatch => {
      const item = await apiStocks.getStock(stock_id)
      dispatch(actions.setStockListItem(item))
      return item
    }
  }

  setStockVisibility(stock_id: string, isVisible: boolean): DispatcherResponse {
    return async dispatch => {
      await apiStocks.setVisibility(stock_id, { is_visible: isVisible })
      dispatch(this.getStock(stock_id))
    }
  }

  updateStock(stock_id: string, payload: ApiPayloadStockCreate): DispatcherResponse {
    return async dispatch => {
      await apiStocks.updateStock(stock_id, payload)
      dispatch(this.getStock(stock_id))
    }
  }

  getOrders(): DispatcherResponse {
    return async dispatch => {
      const list = await apiStocks.getOrders()
      dispatch(actions.setStocksOrders(list))
    }
  }

  createStock(payload: ApiPayloadStockCreate) {
    return async dispatch => {
      const data = await apiStocks.create(payload)
      dispatch(this.getStocks())
      return data
    }
  }

  setStocksPreference(isEnabled: boolean) {
    return async dispatch => {
      const data = await apiCompany.preferences.setStockEnabled({ status: isEnabled })
      dispatch(CompanyDispatcher.getCompany())
      return data
    }
  }

  cancelOrder(order_id: string) {
    return async dispatch => {
      const data = await apiStocks.cancelOrder(order_id)
      dispatch(this.getOrders())
      return data
    }
  }

  completeOrder(order_id: string) {
    return async dispatch => {
      const data = await apiStocks.completeOrder(order_id)
      dispatch(this.getOrders())
      return data
    }
  }

  deleteStock(stock_id: string) {
    return async dispatch => {
      const data = await apiStocks.deleteStock(stock_id)
      dispatch(actions.deleteStock(stock_id))
      return data
    }
  }
}

const StockDispatcher = new Dispatcher()
export default StockDispatcher
