/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import React, { Fragment, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useTimeout } from 'usehooks-ts'
import EmailWrapper from '../../components/EmailWrapper'
import ReactionLoader from '../../components/ReactionLoader'
import copyLink from '../../components/modals/components/inviteMember/copyLink.svg'
import emailInviteMember from '../../components/modals/components/inviteMember/emailInviteMember.svg'
import ModalStyled from '../../elements/ModalStyled'
import { employeeShortLinks } from '../../libs/const/shortLinks'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import InviteQR from './components/InviteQR'
import ReactHlsPlayer from 'react-hls-player'

const useStyles = makeStyles(
  {
    caption: {
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 5,
    },
    description: {
      fontSize: 11,
      marginBottom: 5,
      lineHeight: 1.5,
    },
    actionText: {
      marginLeft: 15,
    },
    actionButton: {
      width: '100%',
      cursor: 'pointer',
      borderRadius: 5,
      padding: '20px 15px',
      background: 'transparent',
      border: '1px solid #D4D4D4',
      '&:not(:last-child)': {
        marginBottom: 10,
      },
      outline: 'none',
    },
    linkCopied: {
      color: '#37AF1D',
    },
    separator: {
      height: 1,
      width: '100%',
      backgroundColor: '#DADADA',
    },
  },
  {
    classNamePrefix: 'InviteEmployeesModal',
  },
)

interface Props {
  isVisible: boolean
  onClose(): any
}

function InviteEmployeesModal(props: Props) {
  const { isVisible, onClose } = props
  const classes = useStyles()
  const [isCopied, setCopied] = useState(false)
  const dispatch = useDispatch<ReduxDispatch>()

  useTimeout(() => setCopied(false), isCopied ? 5000 : null)

  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))
  const { link: linkObject } = company || {}

  const fetchLink = useAsyncFetch(async () => {
    if (!isVisible) return null
    await dispatch(CompanyDispatcher.createInviteMemberLink())
  }, [isVisible])

  const emailSubject = 'Reaction Club Invitation'
  const emailBody = `
Hey 👋 

It would be awesome if you would join our ${company.name} company!

First, download Reaction Club app.
AppStore: ${employeeShortLinks.apple}
Google Play: ${employeeShortLinks.google}


Once you have the app, tap on the link below to join: ${linkObject?.link}  


Let’s do this, 
`

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        width: 520px;
        padding: 15px 35px;
      `}
    >
      <Grid container>
        <Typography className={classes.caption}>Invite members to {company.name} app</Typography>
        <Typography className={classes.description}>
          Share the link or QR below with your team. After scanning the QR code or clicking on the link, your team
          members will receive an invitation to download the app and join your company
        </Typography>
        <RendererStatusSplit
          statuses={fetchLink}
          isEmpty={!linkObject}
          renderEmpty={() => (
            <Typography textAlign={'center'}>No link. Try again or contact support for help.</Typography>
          )}
          renderError={(error) => (
            <Typography textAlign={'center'} color={'error'}>
              {error}
            </Typography>
          )}
          renderLoading={() => <ReactionLoader />}
          render={() => (
            <Fragment>
              <Grid container pt={2} pb={2} pr={8} pl={8}>
                <InviteQR link={linkObject?.link as string} />

                <Grid
                  item
                  flexGrow={1}
                  css={css`
                    padding-left: 30px;
                  `}
                >
                  <EmailWrapper body={emailBody} subject={emailSubject} className={classes.actionButton}>
                    <Grid container direction={'row'} alignItems={'center'}>
                      <img src={emailInviteMember} alt="email invite" />
                      <Typography className={classes.actionText}>Email</Typography>
                    </Grid>
                  </EmailWrapper>
                  <CopyToClipboard text={linkObject?.link || ''} onCopy={() => setCopied(true)}>
                    <button type="button" className={classes.actionButton}>
                      <Grid container direction={'row'} alignItems={'center'}>
                        <img src={copyLink} alt="copy link" />
                        {!isCopied ? (
                          <Typography className={classes.actionText}>Copy Link</Typography>
                        ) : (
                          <Typography className={[classes.actionText, classes.linkCopied].join(' ')}>
                            Link Copied!
                          </Typography>
                        )}
                      </Grid>
                    </button>
                  </CopyToClipboard>
                </Grid>
              </Grid>
              <div className={classes.separator} />
              <Grid container pt={4} pb={2} pr={8} pl={8}>
                <ReactHlsPlayer
                  src="https://s.reaction-club.app/-/_/onboarding/Onboarding_stepvideo2.m3u8"
                  autoPlay={false}
                  controls
                  width="100%"
                  height="auto"
                />
              </Grid>
            </Fragment>
          )}
        />
      </Grid>
    </ModalStyled>
  )
}

export default InviteEmployeesModal
