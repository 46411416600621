import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../../components/ButtonOrange'

function StockButtonCreate() {
  const history = useHistory()
  const handlePress = () => {
    history.push('/stock-create')
  }

  return <ButtonOrange onClick={handlePress}>Create</ButtonOrange>
}

export default StockButtonCreate
