/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Typography, Grid, Box, Button, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlusButton from '../../components/buttons/PlusButton'
import useModalState from '../../hooks/useModalState'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import TeamCreateModal from '../settings/pages/teams/TeamCreateModal'

const useStyles = makeStyles(
  {
    addTeam: {
      padding: 0,
      marginBottom: 16,
    },
    team: {
      border: '1px solid #979797',
      borderRadius: 10,
      padding: '10px 12px',
      marginBottom: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    teamsContainer: {
      padding: '0 15px',
    },
  },
  {
    classNamePrefix: 'CompanyTeams',
  },
)

function CompanyTeams({ optional }) {
  const classes = useStyles()
  const dispatch = useDispatch<ReduxDispatch>()
  const modalCreate = useModalState()
  const { teams } = useSelector((state: ReduxState) => ({
    teams: state.lists.teams,
  }))

  const handleRemoveTeam = async (id) => {
    const isConfirmed = confirm('Are you sure want to delete this team?')
    if (isConfirmed) {
      await dispatch(TeamDispatcher.deleteTeam(id))
    }
  }

  return (
    <Box width={360} style={{ margin: '0 auto' }} align="left">
      <TeamCreateModal {...modalCreate} />

      <Button onClick={modalCreate.onOpen} className={classes.addTeam}>
        <PlusButton />
        <Typography variant="body1" textTransform="none">
          Add team {optional ? ' (optional)' : ''}
        </Typography>
      </Button>

      <Box marginBottom={'10px'}>
        <Grid container className={classes.teamsContainer}>
          {_.map(teams, (team) => (
            <Grid item xs={12} key={team.id} className={classes.team}>
              <Box>
                <Typography>{team.name}</Typography>
                <Typography
                  variant={'subtitle1'}
                  css={css({
                    fontSize: 12,
                    lineHeight: '14px',
                    color: '#979797',
                  })}
                >
                  {team.description || '-'}
                </Typography>
              </Box>
              <Box>
                <IconButton aria-label="delete" onClick={() => handleRemoveTeam(team.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default CompanyTeams
