import { FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMapState } from 'react-use-object-state'
import Loader from '../../../components/Loader'
import useActionHandler from '../../../hooks/useAsyncHandler'
import { ExtractStyles } from '../../../typings/stylesExtractor'

const connectStyles = withStyles({
  buttonContainer: {
    marginBottom: 10,
  },

  button: {
    border: '1px solid #DEDEDE',
    borderRadius: 10,
    width: '70%',
    height: 42,
    backgroundColor: '#fff',
    outline: 'none',
    marginLeft: '15%',
    userSelect: 'none' as const,
    minWidth: 200,
  },

  buttonText: {
    color: '#1A1A1A',
    fontSize: 12,
    textAlign: 'center' as const,
    width: '100%',
  },

  loginErrorText: {
    width: '70%',
    marginLeft: '15%',
  },

  buttonInteractions: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f8ff',
    },
  },

  loader: {
    transform: 'scale(0.5)',
    marginTop: -10,
  },

  loginTextField: {
    width: '70%',
    minWidth: 200,
    marginLeft: '15%',
    marginBottom: 10,
  },

  loginForgotPassword: {
    color: '#1A1A1A',
    fontSize: 12,
    width: '70%',
    minWidth: 200,
    marginLeft: '15%',
    marginBottom: 10,
  },

  signupLink: {
    color: '#0a58ba',
    fontSize: 12,
    marginBottom: 10,
    textDecoration: 'none',
  },
})

interface Props {
  onLogin(credentials: { email: string; password: string }): Promise<any>
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

function SignUpForm(props: PropsInner) {
  const { classes, onLogin } = props
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const location = useLocation()

  const errorStates = useMapState<'emailError' | 'passwordError', string>()

  const { isLoading, isDone, onAction, error } = useActionHandler(
    async () => {
      await onLogin({ email: login, password })
    },
    {
      processError(errorRaw: AxiosError) {
        const { status } = errorRaw.response || {}
        if (status === 409) return 'Account already exists.'
        return errorRaw.message
      },
    },
  )

  const emailError = errorStates.state.get('emailError')
  const passwordError = errorStates.state.get('passwordError')
  const isErrored = error || emailError || passwordError

  const isValid = () => {
    let isOk = true
    if (!login) {
      errorStates.set('emailError', 'Email is required')
      isOk = false
    }

    if (!password) {
      errorStates.set('passwordError', 'Password is required')
      isOk = false
    }

    if (password !== password2) {
      errorStates.set('passwordError', 'Passwords are different')
      isOk = false
    }

    return isOk
  }

  const handleSubmit = async () => {
    if (!isValid()) return null
    await onAction()
  }

  const handleChangeLogin = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLogin(event.target.value)
    errorStates.set('emailError', '')
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
    errorStates.set('passwordError', '')
  }
  const handleChangePassword2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword2(event.target.value)
    errorStates.set('passwordError', '')
  }

  return (
    <Grid container direction={'column'} justifyContent={'center'}>
      <TextField
        id="login"
        label="Email"
        type="email"
        error={!!emailError}
        disabled={isLoading}
        value={login}
        onChange={handleChangeLogin}
        className={classes.loginTextField}
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        error={!!passwordError}
        disabled={isLoading}
        value={password}
        onChange={handleChangePassword}
        className={classes.loginTextField}
      />

      <TextField
        id="password2"
        label="Repeat Password"
        type="password"
        error={!!passwordError}
        disabled={isLoading}
        value={password2}
        onChange={handleChangePassword2}
        className={classes.loginTextField}
      />

      <FormControl className={classes.buttonContainer} error={!!isErrored}>
        <button
          type={'button'}
          onClick={handleSubmit}
          className={classNames(classes.button, {
            [classes.buttonInteractions]: !isLoading,
          })}
          disabled={isLoading}
        >
          {isLoading ? (
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Loader />
            </Grid>
          ) : (
            <Grid container direction={'row'} alignItems={'center'}>
              <span className={classes.buttonText}>Create an Account</span>
            </Grid>
          )}
        </button>
        {isErrored && (
          <FormHelperText className={classes.loginErrorText}>{error || 'Failed to sign in'}</FormHelperText>
        )}
      </FormControl>
      <Grid container justifyContent={'center'}>
        <Link className={classes.signupLink} to={`/login${location.search}`}>
          login with existing account
        </Link>
      </Grid>
    </Grid>
  )
}

export default connectStyles(SignUpForm)
