import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_PLAYBOOK_INVITE: '',
    SET_PLAYBOOK_INSTANCE_EMPLOYEES: '',
    SET_PLAYBOOK_INSTANCE_COACHES: '',
    SET_PLAYBOOK_COACH_INVITE: '',
    DELETE_PLAYBOOK_INSTANCE: '',
    SET_PLAYBOOK_INSTANCE: '[SET_PLAYBOOK_INSTANCE] Set playbook instance',
    SET_PLAYBOOK_EMPLOYEES_INSTANCE: '[SET_PLAYBOOK_EMPLOYEES_INSTANCE] Set playbook employees instance',
    SET_PLAYBOOK_COACHES_INSTANCE: '[SET_PLAYBOOK_COACHES_INSTANCE] Set playbook coaches instance',
    UPDATE_PLAYBOOK_INSTANCE: '[UPDATE_PLAYBOOK_INSTANCE] Update playbook instance',
    RESET_STORED_PLAYBOOK_INSTANCE: '[RESET_STORED_PLAYBOOK_INSTANCE] Reset stored playbook instance',
    LOADED: '[LOADED] Playbook instances loaded',
    LOADING: '[LOADING] Playbook instances are loading',
    SET_INVITE_LINKS_FOR_INSTANCE: '[SET_INVITE_LINKS_FOR_INSTANCE] set invite links for playbook instance',
  },
  { prefix: 'PlaybookInstances__' },
)
