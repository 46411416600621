import { Divider, Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import React from 'react'
import { compose } from 'redux'

type OwnProps = {
  date: string
}
type Props = OwnProps & WithStyles<typeof styles>

const MessageDivider: React.FC<Props> = ({ classes, date }) => {
  return (
    <Grid container alignItems={'center'} style={{ marginTop: '-30px' }}>
      <Grid item className={classes.dividerContainer}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      <Grid item className={classes.dateContainer}>
        <Typography className={classes.date}>{date}</Typography>
      </Grid>
      <Grid item className={classes.dividerContainer}>
        <Divider style={{ width: '100%' }} />
      </Grid>
    </Grid>
  )
}

const styles = createStyles({
  dividerContainer: {
    flexGrow: 1,
  },
  date: {
    fontSize: 12,
    color: '#868686',
  },
  dateContainer: {
    margin: '20px',
  },
})

export default compose(withStyles(styles))(MessageDivider)
