/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'

const Row = ({ to, data, style, className, sizes, onClick = (e) => !to && e.preventDefault() }) => {
  const Wrapper = to
    ? ({ children }) => (
        <Link to={to} onClick={onClick} style={{ textDecoration: 'none', color: '#000', width: '100%' }}>
          {children}
        </Link>
      )
    : ({ children }) => children

  return (
    <Wrapper>
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        className={className}
        justifyContent={'space-between'}
        style={{
          ...style,
        }}
        css={css`
          border-radius: 8px;
          margin-bottom: 10px;
          padding: 12px 40px;

          @media (max-width: 500px) {
            padding: 10px 10px;
          }
        `}
      >
        {data.map((d, i) => (
          <Grid item xs={sizes[i]}>
            {d}
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  )
}

export default Row
