import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'

type OwnProps = {
  text: string
  img: string
  date: string
  userId: string
}
type Props = OwnProps & ExtractConnectType<typeof connectStore> & WithStyles<typeof styles>

const MessageItem: React.FC<Props> = ({ classes, date, text, img, chat: { users }, userId, getUser, showModal }) => {
  const user = users[userId]

  const showFullSizeImage = () => {
    showModal({ modalType: MODAL_TYPES.FULL_SIZE_IMAGE, modalProps: { img } })
  }

  React.useEffect(() => {
    if (!user) {
      getUser(userId)
    }
  }, [])

  return (
    <Grid container className={classes.messageContainer}>
      <ReactionAvatar style={{ width: 54, height: 54 }} asset={{ url: user?.customFields?.avatar_url }} />
      <Grid item>
        <Grid container direction={'column'} style={{ marginLeft: 22, flexWrap: 'nowrap' }}>
          <Grid item>
            <Typography className={classes.name}>{user?.name}</Typography>
            <Typography className={classes.date}>{date}</Typography>
          </Grid>
          {text && (
            <Grid item>
              <Typography className={classes.message}>{text}</Typography>
            </Grid>
          )}
          {img && (
            <Grid item>
              <img src={img} alt="" className={classes.image} onClick={showFullSizeImage} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
})
const mapDispatchToProps = dispatch => ({
  showModal: param => dispatch(modalActions.showModal(param)),
  getUser: userId => dispatch(ChatDispatcher.getUser({ userId }, { isForce: false })),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  messageContainer: {
    flexWrap: 'nowrap',
    '&:not(:last-child)': {
      marginBottom: 48,
    },
  },
  name: {
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 800,
    marginRight: 9,
    marginBottom: 8,
  },
  date: {
    display: 'inline-block',
    color: '#5F6164',
    fontSize: 14,
  },
  message: {
    fontSize: 14,
    letterSpacing: '0.1',
    marginBottom: 8,
    whiteSpace: 'pre-line',
  },
  image: {
    cursor: 'pointer',
    height: 100,
    borderRadius: 5,
  },
})

export default compose(withStyles(styles), connectStore)(MessageItem)
