import React, { useState } from 'react'
import { Avatar, Box, CircularProgress, Grid, Typography } from '@mui/material'
import addImageIcon from '../../../groups/assets/gallery-add.svg'
import { makeStyles } from '@mui/styles'
import fileExplorer from 'web-file-explorer'
import apiAssets from '../../../../libs/api/apiAssets'

const useStyles = makeStyles(
  {
    imageContainer: {
      width: 130,
      height: 130,
      borderRadius: '50%',
    },
    image: {
      width: 130,
      height: 130,
      cursor: 'pointer',
      borderRadius: '50%',
      background: '#FAFBFC',
      border: '1px dashed #4C535F',
    },
  },
  {
    classNamePrefix: 'UploadCompanyLogo',
  },
)

const UploadCompanyLogo = ({ logoUrl, setLogoUrl }) => {
  const [isUploading, setIsUploading] = useState(false)
  const classes = useStyles()

  const handleClickAvatar = async () => {
    let file = await fileExplorer.getFile({ acceptImage: true })

    setIsUploading(true)
    try {
      const asset = await apiAssets.upload(file)
      setLogoUrl(asset.url)
    } finally {
      setIsUploading(false)
    }
  }

  const renderAvatar = () => {
    return (
      <Grid className={classes.imageContainer} container justifyContent="center" alignItems="center">
        {isUploading ? (
          <CircularProgress color="primary" />
        ) : (
          <Avatar src={logoUrl} className={classes.image} alt="Company logo" onClick={handleClickAvatar}>
            {logoUrl ? null : (
              <Box align="center">
                <img src={addImageIcon} alt="Upload your photo" />
                <Typography color="#4C535F" fontSize={12} mt={3}>
                  Upload your
                  <br />
                  photo
                </Typography>
              </Box>
            )}
          </Avatar>
        )}
      </Grid>
    )
  }

  return (
    <Grid>
      <Typography mb={2} fontSize={16} fontFamily="Manrope">
        Company logo
      </Typography>
      {renderAvatar()}
    </Grid>
  )
}

export default UploadCompanyLogo
