import { Grid, Box, Button } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import PlaybookInstanceDispatcher from '@reducers/playbookInstances/dispatcher'
import CN from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import CoachWrapper from '../components/CoachWrapper'

const ChooseGroup = ({ classes, offerGroupId, setGroup, playbookInstances }) => {
  const groups = Object.values(playbookInstances)

  return (
    <CoachWrapper title="Choose group" titleWidth={178}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content} justifyContent="center" alignItems="end" direction="column">
            <Box style={{ marginBottom: 40 }}>
              {groups.map(({ id, name }) => {
                return (
                  <Button
                    className={CN(classes.groupBtn, { [classes.activeGroupBtn]: id === offerGroupId })}
                    onClick={() => setGroup(id)}
                  >
                    {name}
                  </Button>
                )
              })}
            </Box>
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Link
            className={classes.btn}
            to="/coaches/invite/external/choose-category"
            style={{ textDecoration: 'none', color: '#323232' }}
            onClick={e => {
              if (!offerGroupId) {
                e.preventDefault()
              }
            }}
          >
            Next
          </Link>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  offerGroupId: state.coaches.offer.groupId,
  playbookInstances: state.playbookInstances,
})
const mapDispatchToProps = dispatch => ({
  setGroup: (groupId: string) => dispatch(coachesDispatcher.actions.setGroup(groupId)),
  getPlaybookInstances: () => dispatch(PlaybookInstanceDispatcher.getPlaybookInstances()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 28,
    fontSize: '17px',
  },
  groupBtn: {
    backgroundColor: '#F4F4F4',
    padding: '17px',
    minWidth: 140,
    minHeight: 60,
    borderRadius: '8px',
    fontWeight: 'bold',
    marginBottom: 12,
    '&:not(:last-child)': {
      marginRight: 12,
    },
    '&:hover': {
      backgroundColor: '#F9B22D',
    },
  },
  activeGroupBtn: {
    backgroundColor: '#F9B22D',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(ChooseGroup)
