import { Agent } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

type AuthData = { token: string }

export default {
  async login(username: string, password: string): Promise<AuthData> {
    const result = await apiClient.post(`/signin`, { username, password })

    return result.data
  },
  async sendSms(phone: string): Promise<void> {
    const result = await apiClient.post(`/auth/phone-send-sms`, { phone })

    return result.data
  },
  async phoneAuthorize(phone: string, code: string): Promise<AuthData> {
    const result = await apiClient.post(`/auth/phone-authorize`, { phone, code })

    return result.data
  },
  async loginWithFirebaseToken(token: string): Promise<AuthData> {
    const result = await apiClient.post(`/auth/login`, { accessToken: token })

    return result.data
  },
  async loginWithEmailAndPassword(email: string, password: string): Promise<AuthData> {
    const result = await apiClient.post(`/auth/login-local`, { email, password })

    return result.data
  },
  async signup(params: { email: string; password: string }): Promise<AuthData> {
    const result = await apiClient.post(`/auth/signup`, params)

    return result.data
  },
  async resetPasswordRequest(email: string): Promise<void> {
    await apiClient.post(`/auth/reset-password-request`, { email })
  },
  async resetPassword(id: string, password: string): Promise<void> {
    await apiClient.post(`/auth/reset-password`, { id, password })
  },
  async me(): Promise<Agent> {
    const result = await apiClient.get(`/me`)

    return result.data
  },
  async acceptUserAgreement(): Promise<AuthData> {
    const result = await apiClient.post(`/account/accept-user-terms`)
    return result.data
  },
}
