import React from 'react'
import SettingVerification from './SettingVerification'
import SettingsAdmins from './SettingsAdmins'

const SettingsLoginAndSecurity = () => {
  return (
    <>
      <SettingsAdmins />
      <SettingVerification />
    </>
  )
}

export default SettingsLoginAndSecurity
