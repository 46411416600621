import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import api from './apiEmployees'

class Dispatcher {
  getEmployees(): DispatcherResponse {
    return async (dispatch) => {
      const employees = await api.getEmployees()
      dispatch(actions.setEmployees(employees))
    }
  }

  kickEmployees(employees: string[]) {
    return async (dispatch) => {
      await api.kickEmployees(employees)
      dispatch(this.getEmployees())
    }
  }

  resetPoints(employees: string[]) {
    return async (dispatch) => {
      await api.resetPoints(employees)
      dispatch(this.getEmployees())
    }
  }
}

const EmployeeDispatcher = new Dispatcher()
export default EmployeeDispatcher
