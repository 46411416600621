import * as React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import ChooseGroup from './internalCoaches/ChooseGroup'
import SubmitChampionRequest from './internalCoaches/SubmitChampionRequest'

const InternalCoaches = () => {
  return (
    <Switch>
      <Route path={'/coaches/invite/internal/choose-group'} component={ChooseGroup} />
      <Route path={'/coaches/invite/internal/submit-champion-request'} component={SubmitChampionRequest} />
      <Redirect to={`/coaches/invite/internal/submit-champion-request`} />
    </Switch>
  )
}

export default InternalCoaches
