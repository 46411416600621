/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { withStyles, WithStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import PlaybookInstanceDispatcher from '@reducers/playbookInstances/dispatcher'
import playbooskDispatcher from '@reducers/playbooks/dispatcher'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import ButtonOrange from '../../../components/ButtonOrange'
import PriceAndStars from '../../../components/PriceAndStars'
import ReactionLoader from '../../../components/ReactionLoader'
import Asset from '../../../components/assets/Asset'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { GROUP_PAGE } from '../Group'
import { clockIcon, listIcon } from '../assets/facade'

const styles = {
  asset: {
    width: 200,
    height: 114,
    marginRight: 20,
    borderRadius: 5,
  },
  container: {
    // flexWrap: 'nowrap',
  },
  name: {
    fontSize: 20,
    fontWeight: 800,
    flexBasis: '100%',
  },
  description: {
    fontSize: 16,
    lineHeight: 1.4,
    flexBasis: '100%',
  },

  programsContainer: {
    marginTop: 40,
  },
  programContainer: {
    borderRadius: 5,
    border: '2px solid #F1F1F1',
    marginBottom: 15,
    height: 97,
    maxWidth: 648,
  },
  programCaption: {
    fontSize: 16,
    fontWeight: 800,
    marginBottom: 11,
  },
  programCaptionText: {
    fontSize: 14,
    fontWeight: 800,
    marginBottom: 4,
  },
  containerText: {
    width: 'calc(75% - 100px)',
  },
  text: {
    fontSize: 12,
    height: 40,
    overflow: 'hidden',
    width: '100%',
  },
  durationContainer: {
    backgroundColor: '#F1F1F1',
    padding: '0 21px',
  },
  icon: {
    marginRight: 10,
  },
  containerPadding: {
    paddingTop: 19,
    paddingLeft: 25,
  },
}

class Playbook extends React.PureComponent<
  RouteComponentProps<{ playbook_id: string }> & ExtractConnectType<typeof connectToStore> & WithStyles<typeof styles>
> {
  state = {
    isLoading: false,
    isProgramsLoading: false,
  }

  render() {
    const { playbook, classes, instance, playbook_id } = this.props
    const { isLoading } = this.state
    if (isLoading) return <ReactionLoader />

    // if (!playbook_id) return <Redirect to={SELECT_PLAYBOOK_PAGE(instance_id)} />

    if (!playbook)
      return (
        <Typography variant={'body1'} align={'center'}>
          Playbook is not found
        </Typography>
      )

    const { asset, thumb, name, description } = playbook

    return (
      <Grid container>
        <Grid item xs={9}>
          <Grid container className={classes.container}>
            <Asset className={classes.asset} asset={asset || { url: thumb }} resizeMode={'cover'} />
            <Grid container className={classes.containerText}>
              <Typography className={classes.name}>{name}</Typography>
              <Typography className={classes.description}>{description}</Typography>
            </Grid>
          </Grid>
          {this.renderPlans()}
        </Grid>
        <Grid item xs={3}>
          <Grid container direction={'column'} alignItems={'center'}>
            <ButtonOrange styles={{ width: 124, height: 45, fontSize: 14 }} onClick={this.handleAddClick}>
              {instance?.playbook ? 'Replace' : 'ADD'}
            </ButtonOrange>
            <Grid
              css={css`
                margin-top: 15px;
              `}
            >
              <PriceAndStars playbook={playbook} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderPlans = () => {
    const { playbook, classes } = this.props
    const { isProgramsLoading } = this.state
    const { programs } = playbook
    if (isProgramsLoading || !programs) return <ReactionLoader />
    return (
      <Grid container className={classes.programsContainer}>
        <Grid item xs={12}>
          <Typography className={classes.programCaption}>Plans in this program:</Typography>
          {programs.map((program) => this.renderProgram(program))}
        </Grid>
      </Grid>
    )
  }

  renderProgram = (program: Program) => {
    const { classes } = this.props

    const period =
      program.period_total > 7 && program.period_total % 7 === 0
        ? `${program.period_total / 7} weeks`
        : `${program.period_total} days`
    return (
      <Grid container key={program.id} className={classes.programContainer}>
        <Grid item xs={8} className={[classes.containerPadding].join(' ')}>
          <Grid container direction={'column'} justifyContent={'space-around'}>
            <Typography className={classes.programCaptionText}>{program.name}</Typography>
            <Typography className={classes.text}>{program.description}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} className={[classes.durationContainer].join(' ')}>
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{ height: '100%' }}
          >
            <Grid item style={{ width: '100%', marginBottom: 10 }}>
              <Grid container direction={'row'} alignItems={'center'}>
                <img className={classes.icon} src={clockIcon} alt="clock" />
                <Typography style={{ fontSize: 12 }}>{period}</Typography>
              </Grid>
            </Grid>

            {this.renderTaskCount(program)}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderTaskCount = (program: Program) => {
    const { classes } = this.props
    if (_.isEmpty(program?.milestones)) return null

    return (
      <Grid item style={{ width: '100%' }}>
        <Grid container direction={'row'} alignItems={'center'}>
          <img className={classes.icon} src={listIcon} alt="list" />
          <Typography style={{ fontSize: 12 }}>{program.milestones.length} tasks / week</Typography>
        </Grid>
      </Grid>
    )
  }

  handleAddClick = async () => {
    await this.props.addPlaybookToInstance()
    await this.props.setSelfDrivenMode()
    this.props.history.push(GROUP_PAGE(this.props.instance.id))
  }

  fetchData = async () => {
    const { playbook, instance } = this.props
    this.setState({ isLoading: _.isEmpty(playbook) })
    try {
      if (!playbook) {
        await this.props.getPlaybook()
      }
      if (!instance) {
        await this.props.getInstance()
      }
    } finally {
      this.setState({ isLoading: false })
    }
    if (!playbook?.programs) {
      this.setState({ isProgramsLoading: true })
      try {
        await this.props.getPlaybookPrograms()
      } finally {
        this.setState({ isProgramsLoading: false })
      }
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectToStore = connect(
  (state: ReduxState, { match }: RouteComponentProps<{ playbook_id: string; instance_id: string }>) => ({
    playbook: state.playbooks[match.params.playbook_id],
    instance: state.playbookInstances[match.params.instance_id],
  }),
  (dispatch: ReduxDispatch, { match }: RouteComponentProps<{ playbook_id: string; instance_id: string }>) => ({
    getInstance: () => dispatch(PlaybookInstanceDispatcher.get(match.params.instance_id, { isForce: false })),
    getPlaybook: () => dispatch(playbooskDispatcher.getPlaybookById(match.params.playbook_id)),
    getPlaybookPrograms: () => dispatch(playbooskDispatcher.getPlaybookPrograms(match.params.playbook_id)),
    addPlaybookToInstance: () =>
      dispatch(PlaybookInstanceDispatcher.addPlaybookToInstance(match.params.instance_id, match.params.playbook_id)),
    setSelfDrivenMode: () =>
      dispatch(
        PlaybookInstanceDispatcher.updateMode({
          instance_id: match.params.instance_id,
          mode: 'self',
        }),
      ),
  }),
)

export default compose(connectToStore, withStyles(styles))(Playbook) as any
