enum ACTION_TYPE {
  SET_BREAKDOWN = 'DASHBOARD_SET_BREAKDOWN',
  SET_PARTICIPATION = 'DASHBOARD_SET_PARTICIPATION',
  SET_REVIEWS = 'DASHBOARD_SET_REVIEWS',
  SET_ACTIVE_MEMBERS = 'DASHBOARD_SET_ACTIVE_MEMBERS',
  SET_TOP_MOMENTS = 'DASHBOARD_SET_TOP_MOMENTS',
}

export interface DashboardAction {
  type: ACTION_TYPE
  payload?: any
}

export default ACTION_TYPE

// SET_PARTICIPATION
// export enum WORKOUT_TYPES {
//   ON_SITE = 'on_site',
//   LIVE_STREAMING = 'live_streaming',
// }
//
// export type ParticipationItem = {
//   workout_type: keyof typeof WORKOUT_TYPES
//   participant_count: number
//   participants_created_at: string
// }

export type ParticipationItemData = {
  date: string
  count: number
}

export type ParticipationItem = {
  total_count: number
  dates?: ParticipationItemData[]
}

export type GetParticipationParam = {
  from?: string
  to?: string
}

// SET_BREAKDOWN
// export enum ACTIVITY_TYPES {
//   BASIC = 'basic',
//   TRAIN = 'train',
//   MEDITATION = 'meditation',
// }
//
// export type BreakdownItem = {
//   type: keyof typeof ACTIVITY_TYPES
//   tasks_count: number
// }
//
// export type GetBreakdownParam = {
//   from?: string
//   to?: string
// }
export type BreakdownItem = {
  id: string
  name: string
  count: number
}

// SET_REVIEWS
export type ReviewsItemData = {
  now: number
  before: number
}

export type ReviewsItem = {
  points: ReviewsItemData
  tasks: ReviewsItemData
}

export enum ReviewPeriod {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type GetReviewsParam = {
  from?: string
  to?: string
  period?: ReviewPeriod
}

// SET_ACTIVE_MEMBERS
export type ActiveMembersItem = {
  user_id: string
  count_total: string
  avatar_url: string
  group_name: string | null
  name: string
}

export type GetActiveMembersParam = {
  from?: string
  to?: string
  limit?: number
}

// SET_TOP_MOMENTS
export type TopMomentsItemAttachment = {
  id: string
  url: string
  width: number
  height: number
  url_small: string
}

export type TopMomentsItem = {
  id: string
  attachments: TopMomentsItemAttachment[]
  activity_rate: number
}

export type GetTopMomentsParam = {
  limit?: number
}
