/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Box, Checkbox, Grid, IconButton, Typography } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { Close } from '@mui/icons-material'

interface Props {
  employee: Employee
  subText?: string | 'Without instance'
  onSelect?: (user_id: string) => any
  onRemove?: (user_id: string) => any
  selected?: boolean
}

interface PropsInner extends ExtractConnectType<typeof connectStore>, Props {}

function EmployeeItem(props: PropsInner) {
  const { subText, selected, onSelect, onRemove, createMockDirectRoom, employee } = props
  const { user_id, name, points, avatar_url, chat_user_id } = employee

  const isWithoutInstance = subText === 'Without instance'

  const handleSelect = () => onSelect(user_id)

  return (
    <Grid
      container
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      css={css`
        padding: 16px;
        background-color: #f4f4f4;
        margin: 5px 0;
        border-radius: 5px;
      `}
    >
      <Grid item flexGrow={1}>
        <Grid container direction={'row'} alignItems={'center'}>
          <Box onClick={() => createMockDirectRoom('mock-room-id', chat_user_id)} style={{ width: 'fit-content' }}>
            <Link to={`/chats/direct/mock-room-id`} style={{ textDecoration: 'none' }}>
              <Avatar
                src={avatar_url}
                css={css`
                  width: 44px;
                  height: 44px;
                `}
              />
            </Link>
          </Box>
          <Box
            css={css`
              margin: 0 10px;
              flex: 1;
            `}
          >
            <Typography
              css={css`
                font-size: 14px;
                font-weight: 800;
                text-overflow: ellipsis;
              `}
            >
              {name}
            </Typography>
            {subText && (
              <Typography
                css={[
                  css`
                    font-size: 12px;
                    font-weight: bold;
                  `,
                  isWithoutInstance &&
                    css`
                      color: red;
                    `,
                ]}
              >
                {subText}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={'row'} alignItems="center">
          <Typography
            css={css`
              font-size: 14px;
              font-weight: 800;
              margin-right: 10px;
            `}
          >
            {points}
          </Typography>
          {onSelect ? <Checkbox checked={selected} onClick={handleSelect} /> : null}
          {onRemove ? (
            <IconButton onClick={() => onRemove(user_id)}>
              <Close color="error" />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

const connectStore = connect(null, (dispatch) => ({
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
}))

export default connectStore(EmployeeItem)
