/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import { Employee } from '@reaction-club-types/core'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { ReduxState } from '@reducers/index'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import _ from 'lodash'
import React, { ChangeEventHandler } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ReactionLoader from '../../components/ReactionLoader'
import GroupFilter from '../../components/filters/GroupFilter'
import { Manrope } from '../../helpers/commonStyles'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import EmployeeItem from '../groups/employees/EmployeeItem'
import SectionContainer from './components/SectionContainer'
import EmployeeActions from './employee/EmployeeActions'
import ButtonOrange from '../../components/ButtonOrange'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'

const styles = {
  inviteMembersText: {
    marginTop: 15,
    fontSize: 18,
    fontWeight: 800,
  },

  membersCount: {
    fontSize: 17,
    fontWeight: 800,
    marginBottom: 16,
  },
  captionInvite: {
    fontSize: 15,
    fontWeight: 800,
    marginLeft: 5,
  },
  smallInvite: {
    cursor: 'pointer',
  },
  inputName: {
    width: 225,
    marginRight: 15,
    color: '#323232',
    fontSize: 16,
    height: 40,
  },
}

class Employees extends React.PureComponent<WithStyles<typeof styles> & ExtractConnectType<typeof connectStore>> {
  state = { selectedEmployee: [] as string[], isLoading: false, selectedInstanceId: '', name: '' }

  render() {
    const { classes, employees, instances, openExportModal } = this.props
    const sortedEmployees = this.getFilteredEmployees(employees)
    const { selectedEmployee, isLoading, name } = this.state
    const isActionsActive = selectedEmployee.length > 0

    if (isLoading)
      return (
        <SectionContainer>
          <Typography variant={'h2'} fontSize={21} fontFamily={Manrope}>
            Members
          </Typography>
          <ReactionLoader />
        </SectionContainer>
      )
    return (
      <SectionContainer>
        <Typography variant={'h2'} fontSize={21} fontFamily={Manrope}>
          Members
        </Typography>
        <Grid container>
          <Grid item>
            <Grid container alignItems={'center'}>
              <TextField
                value={name}
                className={classes.inputName}
                onChange={this.handleNameChange}
                placeholder={'Search'}
                variant={'outlined'}
                InputProps={{
                  style: {
                    height: 40,
                    color: '#323232',
                  },
                }}
              />
              <GroupFilter
                instances={instances}
                onInstanceChange={(instance_id) => this.setState({ selectedInstanceId: instance_id })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={'column'}
          alignItems={'flex-start'}
          css={css`
            border: 1px solid #d4d4d4;
            outline: none;
            border-radius: 5px;
            padding: 30px;

            @media (max-width: 520px) {
              padding: 10px 10px;
            }
          `}
        >
          <Grid container direction={'row'} justifyContent={'space-between'}>
            <Typography className={classes.membersCount}>
              {`${sortedEmployees.length} Member${sortedEmployees.length === 1 ? '' : 's'}`}
            </Typography>
            <Grid item>
              <Grid container direction={'row'} justifyContent="center">
                <Grid item>
                  <ButtonOrange onClick={openExportModal}>Export To CSV</ButtonOrange>
                </Grid>
                <Grid item>
                  <Button onClick={this.handleSelectAll}> Select all</Button>
                </Grid>
                {/* <Grid item> */}
                {/*  <Button onClick={this.handleUnselectAll}> Unselect all </Button> */}
                {/* </Grid> */}
                <Grid item>
                  {isActionsActive && (
                    <EmployeeActions
                      employees={selectedEmployee}
                      handleEmployeeDeleted={this.handleEmployeeDeleted}
                      csvData={this.getCSVData(selectedEmployee)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {sortedEmployees &&
              sortedEmployees.length !== 0 &&
              sortedEmployees.map((employee) => this.renderEmployeeItem(employee))}
          </Grid>
        </Grid>
      </SectionContainer>
    )
  }

  handleSelectAll = () => {
    const { employees } = this.props
    const sortedEmployees = this.getFilteredEmployees(employees)

    // sortedEmployees.forEach(({ user_id }) => this.handleAddEmployee(user_id)())
    this.setState({ selectedEmployee: sortedEmployees.map(({ user_id }) => user_id) })
  }

  // handleUnselectAll = () => {
  //   this.setState({ selectedEmployee: [] })
  // }

  renderEmployeeItem = (employee: Employee) => {
    const { selectedEmployee } = this.state

    const isSelected = !!selectedEmployee.find((user_id) => user_id === employee.user_id)

    return (
      <EmployeeItem
        key={`employees-list-item-${employee.user_id}`}
        employee={employee}
        // subText={instanceName}
        selected={isSelected}
        onSelect={this.handleAddEmployee}
      />
    )
  }

  handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const text = e.target.value || ''
    this.setState({ name: text })
  }

  handleAddEmployee = (employee_id: string) => {
    const { selectedEmployee } = this.state
    if (selectedEmployee.length === 0) return this.setState({ selectedEmployee: [employee_id] })
    if (selectedEmployee.includes(employee_id)) {
      const updatedSelectedEmployee = selectedEmployee.filter((id) => employee_id !== id)
      this.setState({ selectedEmployee: updatedSelectedEmployee })
      return
    }
    const updatedSelectedEmployee = selectedEmployee.concat([employee_id])
    this.setState({ selectedEmployee: updatedSelectedEmployee })
  }

  handleEmployeeDeleted = () => this.props.getEmployees()

  getCSVData = (employeeIds) => {
    const { employees, instances } = this.props
    const header = ['Name', 'Group', 'Email']
    if (!employeeIds || employeeIds.length === 0) return [header]
    const data = employeeIds
      .map((id) => employees.find(({ user_id }) => user_id === id))
      .map((employee) => [
        employee.name,
        instances[employee.company_playbook_id]?.name || 'Without group',
        employee.data?.email,
      ])
    // const result = header.concat
    return [header, ...data]
  }

  getFilteredEmployees = (employees: Employee[]) => {
    const { selectedInstanceId, name } = this.state
    let filteredEmployees = _.orderBy(employees, 'points', 'desc')
    if (selectedInstanceId) filteredEmployees = _.filter(filteredEmployees, { company_playbook_id: selectedInstanceId })

    if (name && name.length >= 3)
      filteredEmployees = _.filter(filteredEmployees, (employee) =>
        employee.name.toLowerCase().includes(name.toLowerCase()),
      )

    return filteredEmployees
  }

  fetchData = async () => {
    this.setState({ isLoading: true })
    try {
      await Promise.all([this.props.getPlaybookInstances(), this.props.getEmployees()])
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    employees: state.employees.list,
    instances: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    openExportModal: () =>
      dispatch(modalActions.showModal({ modalType: MODAL_TYPES.EXPORT_STEPS, modalProps: { type: 'points' } })),
    getEmployees: () => dispatch(EmployeeDispatcher.getEmployees()),
    getPlaybookInstances: () => dispatch(PlaybookInstancesDispatcher.getPlaybookInstances()),
  }),
)

export default compose(withStyles(styles), connectStore)(Employees)
