/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import React, { useMemo } from 'react'
import Loader from '../../../../../components/Loader'

interface Props {
  date: Moment
  view: string
  isLoading?: boolean
  onNavigate(action: string, newDate: any) // moment date is ok
}
function EventsCalendarMonth(props: Props) {
  const { date, view, onNavigate, isLoading } = props

  const dateFormatted = useMemo(() => {
    return date.format('MMMM YYYY')
  }, [date, view])

  const isNavigationEnabled = view === 'month'

  const handleClickLeft = () => {
    const nextDate = moment(date).add(-1, 'month')
    onNavigate('back', nextDate)
  }

  const handleClickRight = () => {
    const nextDate = moment(date).add(1, 'month')
    onNavigate('forward', nextDate)
  }

  return (
    <div
      css={css`
        svg {
          font-size: 16px;
        }

        .btn {
          padding: 5px 7px;
          cursor: pointer;
        }
      `}
    >
      <Grid container alignItems={'center'}>
        {isNavigationEnabled && (
          <div className={'btn'} onClick={handleClickLeft}>
            <FontAwesomeIcon icon={faCaretLeft} color={'#1B48BB'} />
          </div>
        )}

        <Typography
          css={css`
            font-family: Poppins;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #303134;
          `}
        >
          {dateFormatted}
        </Typography>

        {isNavigationEnabled && (
          <div className={'btn'} onClick={handleClickRight}>
            <FontAwesomeIcon icon={faCaretRight} color={'#1B48BB'} />
          </div>
        )}
      </Grid>

      <Grid
        container
        justifyContent={'flex-start'}
        css={css`
          padding-top: 10px;
        `}
      >
        {isLoading ? <Loader scale={0.4} type={'absolute'} css={css``} /> : null}
      </Grid>
    </div>
  )
}

export default EventsCalendarMonth
