/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import useModalState from '../../../../hooks/useModalState'
import EventFieldFilterInfoModal from '../modals/EventFieldFilterInfoModal'
import EventGroupSelect from './EventGroupSelect'
import EventOfficeSelect from './EventOfficeSelect'
import EventFieldInfo from './elements/EventFieldInfo'
import EventFormLabel from './elements/EventFormLabel'

interface Props {
  group_id: string | null
  offices: string[]
  onGroupSelect: (group_id: string | undefined) => any
  onOfficeSelect: (office_id: string[]) => any
}

function EventCreateForm(props: Props) {
  const { group_id, onGroupSelect, onOfficeSelect, offices } = props
  const [filterType, setFilterType] = useState<'group' | 'office'>('group')
  const infoModal = useModalState()

  const handleGroupSelect = () => {
    setFilterType('group')
    onOfficeSelect([])
  }
  const handleOfficeSelect = () => {
    setFilterType('office')
    onGroupSelect(undefined)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: any = (event.target as HTMLInputElement).value
    if (type === 'office') return handleOfficeSelect()
    return handleGroupSelect()
  }

  return (
    <div>
      <EventFieldFilterInfoModal {...infoModal} />
      <div>
        <EventFormLabel title={'Filter (Optional):'}>
          <React.Fragment>
            <EventFieldInfo onClick={infoModal.onOpen} />

            <RadioGroup value={filterType} onChange={handleChange}>
              <Grid
                container
                css={css`
                  .MuiSvgIcon-root {
                    font-size: 18px;
                  }
                  .MuiFormControlLabel-label {
                    font-size: 14px;
                  }
                  .MuiRadio-root {
                    padding: 5px;
                  }
                  .MuiFormControlLabel-root {
                    margin: 0 0 0 7px;
                  }
                `}
              >
                <FormControlLabel value="group" control={<Radio />} label="Programs" />
                <FormControlLabel value="office" control={<Radio />} label="Offices" />
              </Grid>
            </RadioGroup>
          </React.Fragment>
        </EventFormLabel>
      </div>
      {filterType === 'group' && <EventGroupSelect value={group_id} onChange={onGroupSelect} />}
      {filterType === 'office' && <EventOfficeSelect selected={offices} onChange={onOfficeSelect} />}
    </div>
  )
}

export default EventCreateForm
