import { ReduxState } from '@reducers/index'
import React, { ReactChild, ReactElement } from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ExtractConnectType } from '../typings/ReduxExtractor'

interface Props extends RouteComponentProps, ExtractConnectType<typeof connectStore> {
  children: ReactElement
}

const AuthGuard = ({ token, location, history, children }: Props) => {
  const { search, pathname = '' } = location
  const isLogin =
    pathname.includes('login') || pathname.includes('reset-password') || pathname.includes('mobile-warning')

  if (!token && !isLogin) {
    const isLink = pathname.includes('/link/')

    let query = `?redirect=${pathname}`
    if (search) query += search.replace('?', '&')
    let redirectPage = '/login'
    if (isLink) redirectPage = '/signup'

    return <Redirect to={`${redirectPage}${query}`} />
  }

  return children
}

const connectStore = connect((state: ReduxState) => ({
  token: state.auth.token,
}))

export default compose(withRouter, connectStore)(AuthGuard)
