import { Typography, Grid, Box, Radio, RadioGroup, FormControlLabel, TextField } from '@mui/material'
import { useDebounce } from 'use-debounce'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import PlusButton from '../../../../components/buttons/PlusButton'
import useModalState from '../../../../hooks/useModalState'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import SectionContainer from '../../components/SectionContainer'
import TeamCreateModal from './TeamCreateModal'
import CompanyTeamItem from './components/CompanyTeamItem'
import Help from '../../../../components/Help'
import { Manrope } from '../../../../helpers/commonStyles'
import CompanyDispatcher from '@reducers/company/dispatcher'

function CompanyTeams() {
  const { teams, company } = useSelector((state: ReduxState) => ({
    teams: state.lists.teams,
    company: state.company,
  }))
  const dispatch = useDispatch<ReduxDispatch>()
  const modalCreate = useModalState()
  const [maxUsersPerTeam, setMaxUsersPerTeam] = useState(company?.max_users_per_team)
  const [canUsersChooseTeam, setCanUsersChooseTeam] = useState(company?.can_users_choose_teams)
  const [debouncedMaxUsersPerTeam] = useDebounce(maxUsersPerTeam, 500)
  useAsyncFetch(
    async () => {
      await dispatch(TeamDispatcher.getList({ isForce: false }))
    },
    { maxTries: 3 },
  )
  useEffect(() => {
    dispatch(
      CompanyDispatcher.setTeamSettings({
        can_users_choose_teams: !!canUsersChooseTeam,
        max_users_per_team: parseInt(debouncedMaxUsersPerTeam, 10) || null,
      }),
    )
  }, [debouncedMaxUsersPerTeam, canUsersChooseTeam])

  return (
    <SectionContainer>
      <Typography variant={'h2'} fontSize={21} fontFamily={Manrope}>
        Teams{' '}
        <Help
          title="Company teams"
          subtitle="Boosting engagement with teams and competition"
          text="Teams are a great way to introduce some healthy competition between different groups within your organization. When you create teams, employees will receive a pop-up notification on the app, prompting them to choose which team they would like to join (they can only select one team). As employees join teams, you can track their progress and see their names listed on the challenge leaderboard."
        />
      </Typography>

      <Grid container>
        <Grid item xs={8}>
          <Box marginBottom={'10px'} display="flex" gap={4} flexWrap="wrap">
            {_.map(teams, (item) => (
              <CompanyTeamItem team={item} key={`settings-team-${item.id}`} />
            ))}
            <TeamCreateModal {...modalCreate} />
            <PlusButton onClick={modalCreate.onOpen} title="Add team" />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ borderLeft: '1px solid grey', pl: 10 }}>
          <Typography variant={'h2'} fontSize={21} fontFamily={Manrope}>
            Teams Settings
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="users"
            name="radio-buttons-group"
            sx={{ gap: 0 }}
            onChange={(e) => {
              setCanUsersChooseTeam(e.target.value === 'true')
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Users choose their team"
              checked={!!canUsersChooseTeam}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Admins assign users to teams"
              checked={!canUsersChooseTeam}
            />
          </RadioGroup>
          <FormControlLabel
            value={maxUsersPerTeam}
            onChange={(e) => {
              setMaxUsersPerTeam(e.target.value)
            }}
            control={<TextField placeholder="Unlimited" type="number" min={0} sx={{ ml: 1 }} />}
            label="Max users per team: "
            style={{ fontWeight: 'bold' }}
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default CompanyTeams
