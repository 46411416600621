/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useClipboard } from 'use-clipboard-copy'
import EventModalStyled from './elements/EventModalStyled'
import EventModalTitle from './elements/EventModalTitle'

interface Props {
  url: string
  // event: ReduxStateReactionEventItem
  isVisible: boolean
  onClose(): any
}

function EventRecordingShareModal(props: Props) {
  const { url } = props
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  })

  const handlePressCopy = () => {
    clipboard.copy(url)
  }

  return (
    <EventModalStyled
      {...props}
      css={css`
        min-width: 500px;
      `}
    >
      <div>
        <EventModalTitle
          css={css`
            font-size: 20px;
          `}
        >
          Share the session recording
        </EventModalTitle>
        <Grid container alignItems={'center'}>
          <Grid
            item
            css={css`
              width: 1px;
              flex-grow: 1;
            `}
          >
            <span
              css={css`
                font-family: Poppins;
                color: #1b48bb;
              `}
            >
              {url}
            </span>
          </Grid>

          <Grid item>
            <div
              css={css`
                margin-left: 10px;
              `}
            >
              {clipboard.copied ? (
                <Typography>Copied</Typography>
              ) : (
                <ContentCopyIcon
                  onClick={handlePressCopy}
                  css={css`
                    cursor: pointer;
                    color: #434343;
                  `}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </EventModalStyled>
  )
}

export default EventRecordingShareModal
