import { Grid, Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CompanyStockOrder } from '@reaction-club-types/core'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Immutable } from 'seamless-immutable'
import OrderItemMenu from './OrderItemMenu'

const useStyles = makeStyles(
  {
    container: {
      marginTop: 10,
      marginBottom: 10,
    },
    name: {
      fontWeight: 'bold',
      marginLeft: 15,
      display: 'inline-block',
    },
    value: {
      fontWeight: 'bold',
    },

    image: {
      width: 70,
      height: 50,
      borderRadius: 6,
    },

    menuContainer: {
      position: 'absolute',
      right: 0,
      top: '50%',
      marginTop: -12,
    },

    statusContainer: {
      position: 'relative',
    },

    statusPending: {
      color: '#4585de',
    },

    statusCompleted: {
      color: '#36b758',
    },
  },
  {
    classNamePrefix: 'StockItem',
  },
)

interface Props {
  order: CompanyStockOrder | Immutable<CompanyStockOrder>
}

function OrderItem(props: Props) {
  const { order } = props
  const { name, employee, quantity, status, created_at, contacts } = order
  const { name: userName, data } = (employee as Employee) || {}
  let { email } = contacts || {}
  const classes = useStyles()

  // tmp solution
  if (!email) email = data?.email

  let statusFormatted = status
  let statusClassName = ''
  if (status === 'pending') {
    statusClassName = classes.statusPending
  }
  if (status === 'completed') {
    statusFormatted = 'prize sent'
    statusClassName = classes.statusCompleted
  }

  const dateFormatted = useMemo(() => {
    return moment(created_at as Date).format('DD MMM, HH:mm')
  }, [created_at])

  return (
    <div className={classes.container}>
      <Grid container alignItems={'center'}>
        <Grid item sm={3}>
          <Typography>{userName}</Typography>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Grid>

        <Grid item sm={3}>
          <Grid container alignItems={'center'}>
            {/* <AssetPicture asset={asset} className={classes.image} /> */}
            <div className={classes.name}>{name}</div>
          </Grid>
        </Grid>

        <Grid item sm={1}>
          <Typography className={classes.value} align={'center'}>
            {quantity}
          </Typography>
        </Grid>

        <Grid item sm={3}>
          <Typography className={classes.value} align={'center'}>
            {dateFormatted}
          </Typography>
        </Grid>

        <Grid item sm={2} className={classes.statusContainer}>
          <Typography align={'center'} className={statusClassName}>
            {statusFormatted}
          </Typography>
          <div className={classes.menuContainer}>
            <OrderItemMenu order={order} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderItem
