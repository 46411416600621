/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Paper, Typography, Box } from '@mui/material'
import apiChallenge from '@reducers/challenge/api'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import { capitalize, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../components/ButtonOrange'
import Header from '../../components/Header'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import durationIcon from './assets/duration.png'
import rewardIcon from './assets/reward.png'
import startDateIcon from './assets/start-date.png'
import teamsIcon from './assets/teams.png'

interface Props {
  match: any
}

const spacing = 15

const cssIcon = css`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

const cssMetricItem = css`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`
const cssMetricTitle = css`
  font-weight: bold;
  margin-left: 10px;
  margin-right: 15px;
  font-size: 18px;
`

const cssMetricValue = css`
  font-size: 14px;
`

const CustomizeChallenge: React.FC<Props> = (props) => {
  const history = useHistory()
  const { match } = props
  const { id } = match.params || {}
  const [challenge, setChallenge] = useState<any>()

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    const res = await apiChallenge.getChallenge(id)
    setChallenge(res)
  })

  useAsyncFetch(
    async () => {
      await dispatch(TeamDispatcher.getList({ isForce: false }))
    },
    { maxTries: 3 },
  )

  const { teams } = useSelector((state: ReduxState) => ({
    teams: state.lists.teams,
  }))

  const handleEditChallenge = () => {
    history.push(`/customize-challenge/edit/${id}`)
  }

  const renderPremium = () => {
    const settingsDurationDays = challenge?.duration
    const settingsDurationType = settingsDurationDays % 7 === 0 ? 'weeks' : 'days'
    const settingsDurationLength = settingsDurationDays % 7 === 0 ? settingsDurationDays / 7 : settingsDurationDays

    return (
      <RendererStatusSplit
        statuses={fetchAction}
        renderLoading={() => <ReactionLoader />}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        render={() => (
          <Grid container spacing={spacing}>
            <Grid item xs={6}>
              <Box mb={7}>
                <Typography variant="h1" fontSize={24}>
                  Current challenge: {challenge?.name || `${capitalize(challenge?.type_data.label)} challenge`}
                </Typography>
              </Box>
              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={startDateIcon} alt="Start date" />
                <Typography css={cssMetricTitle}>Start date</Typography>
                <Typography css={cssMetricValue}>
                  {challenge?.challenge_item_start_at
                    ? moment(challenge?.challenge_item_start_at).format('YYYY-MM-DD')
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={startDateIcon} alt="End date" />
                <Typography css={cssMetricTitle}>End date</Typography>
                <Typography css={cssMetricValue}>
                  {challenge?.challenge_item_end_at
                    ? moment(challenge?.challenge_item_end_at).format('YYYY-MM-DD')
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={teamsIcon} alt="Teams" />
                <Typography css={cssMetricTitle}>Teams</Typography>
                <Typography css={cssMetricValue}>
                  {isEmpty(teams) ? 'Not set' : teams.map((t) => t.name).join(', ')}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={rewardIcon} alt="Prize" />
                <Typography css={cssMetricTitle}>Prize</Typography>
                <Typography css={cssMetricValue}>
                  Points{challenge?.challenge_item_custom_prize ? `, ${challenge?.challenge_item_custom_prize}` : ''}
                </Typography>
              </Grid>

              <hr />

              <Box mt={7} mb={7}>
                <Typography variant="h1" fontSize={24}>
                  Upcoming challenge: {challenge?.name || `${capitalize(challenge?.type_data.label)} challenge`}
                </Typography>
              </Box>
              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={startDateIcon} alt="Start date" />
                <Typography css={cssMetricTitle}>Start date</Typography>
                <Typography css={cssMetricValue}>
                  {challenge?.start_date ? moment(challenge?.start_date).format('YYYY-MM-DD') : 'N/A'}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={durationIcon} alt="Duration" />
                <Typography css={cssMetricTitle}>Duration</Typography>
                <Typography css={cssMetricValue}>
                  {settingsDurationLength} {settingsDurationType} {challenge?.repeat ? '(repeated)' : ''}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={teamsIcon} alt="Teams" />
                <Typography css={cssMetricTitle}>Teams</Typography>
                <Typography css={cssMetricValue}>
                  {isEmpty(teams) ? 'Not set' : teams.map((t) => t.name).join(', ')}
                </Typography>
              </Grid>

              <Grid container css={cssMetricItem}>
                <img css={cssIcon} src={rewardIcon} alt="Prize" />
                <Typography css={cssMetricTitle}>Prize</Typography>
                <Typography css={cssMetricValue}>
                  Points{challenge?.custom_prize ? `, ${challenge?.custom_prize}` : ''}
                </Typography>
              </Grid>

              <Box mt={10}>
                <ButtonOrange
                  styles={{
                    width: 230,
                    paddingTop: 17,
                    paddingBottom: 17,
                  }}
                  onClick={handleEditChallenge}
                  size="large"
                >
                  <Typography
                    css={css`
                      font-weight: 700;
                      font-size: 16px;
                      margin-right: 4px;
                    `}
                  >
                    Edit
                  </Typography>
                </ButtonOrange>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <ReactHlsPlayer
                src="https://s.reaction-club.app/-/_/onboarding/Onboarding_stepvideo2.m3u8"
                autoPlay={false}
                controls
                width="100%"
                height="auto"
              />
            </Grid>
          </Grid>
        )}
      />
    )
  }

  return (
    <Grid container justifyContent="center">
      <Header />
      <Grid xs={12} item>
        <Paper
          css={css`
            margin: 20px 50px 50px 50px;
            padding: 80px;
            box-shadow: 0px 2px 4px rgba(156, 156, 156, 0.25);
            border-radius: 5px;

            @media (max-width: 520px) {
              margin: 0;
              padding: 10px;
            }
          `}
        >
          {renderPremium()}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CustomizeChallenge
