/**
 * process.env.API_URL should be only this style.
 * When there other way it can be not replaced. For example: const { API_URL } = process.env
 */
import invariant from 'invariant'
import _ from 'lodash'
import configDev from './config.dev'

const isTest = process.env.NODE_ENV === 'test'
const version = process.env.VERSION
const app = process.env.APP

console.log(`
********************************************
******  APP: ${app} (${version})
********************************************
`)

export const ApiEncSec = '25b8f0c8-1693-46b2-bf68-bb5956b4d08b' // do not log with config object

let config = {
  version,
  env: process.env.APP_ENV,
  api_url: process.env.API_URL as string,
  chat_api_url: process.env.CHAT_URL as string,
  playbooks_url: process.env.PLAYBOOKS_URL as string,
  assets_api_url: process.env.ASSETS_API_URL as string,

  timeout: process.env.APP_ENV === 'production' ? 120000 : 30000,

  PORT: 3366,
  bugsnag_api_key: 'ebd11b4c918e44660bfbb45a021e393e',
  google_api_key: 'AIzaSyCaUJ8uieZIvOAlQGAcOOo99meQrbOa5MQ',

  links: {
    termsOfUse: 'https://www.reaction-club.com/termsofuse',
    privatePolicy: 'https://www.reaction-club.com/privacypolicy',
    onboard: 'https://www.reaction-club.com/getstarted/',
    payment: 'https://www.reaction-club.com/upgrade',
  },

  onboard: {
    url: 'https://hr-onboard.reaction-club.app/onboard',
  },

  loggers: {
    isStoreEnabled: !isTest,
  },

  chat: {
    isLogOutDisabled: !!process.env.CHAT_LOGOUT_DISABLE,
  },

  stripe: {
    key: process.env.STRIPE_KEY,
  },
}

if (!isTest) {
  console.log('env', config.env)
  console.log('config', config)
}

if (config.env !== 'production') {
  config = _.merge(config, configDev)
}

invariant(config.api_url, 'config.api_url is not set')
invariant(config.chat_api_url, 'config.chat_api_url is not set')

// @ts-ignore
window.config = config

export default config
