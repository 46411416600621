/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Grid, Typography, Button } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../../components/Loader'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'

interface Props {
  item: Employee & { event_id: string; expired_at: string }
}
function UserPenaltyItem(props: Props) {
  const { item } = props
  const { user_id, event_id, name, avatar_url, expired_at } = item

  console.log('item', item)
  const dispatch = useDispatch<ReduxDispatch>()

  const actionRelease = useAsyncHandler(async () => {
    await dispatch(
      EventDispatcher.releasePenalty({
        user_id,
        event_id,
      }),
    )
  })

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={12} sm={6} flexGrow={1}>
        <Grid container alignItems={'center'}>
          <Avatar src={avatar_url} />
          <Typography
            css={css`
              margin-left: 10px;
              flex-grow: 1;
              width: 1px;
            `}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={3}>
        <Typography
          css={css`
            font-size: 12px;
            color: #727272;
            margin-right: 20px;
            max-width: 100px;
            line-height: 16px;
          `}
        >
          <Moment format={'LLL'}>{expired_at}</Moment>
        </Typography>
      </Grid>

      <Grid item xs={6} sm={3}>
        <Grid container justifyContent={'flex-end'}>
          <RendererStatusSplit
            statuses={actionRelease}
            renderLoading={() => (
              <Loader
                scale={0.3}
                type={'absolute'}
                css={css`
                  margin-right: 40px;
                `}
              />
            )}
            render={() => <Button onClick={actionRelease.execute}>Release</Button>}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserPenaltyItem
