/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ArrowForward, Edit } from '@mui/icons-material'
import { Box, Grid, Typography, Tooltip, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import classnames from 'classnames'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import theme from '../../../app/theme'
import UserAvatar from '../../../components/UserAvatar'
import InviteEmployeesButton from '../../../components/invite/InviteEmployeesButton'
import { secondsToTime } from '../../../helpers/utils'
import SectionContainer from '../../dashboard/components/elements/SectionContainer'
import avgStepsIcon from '../assets/avg-steps-icon.png'
import breathIcon from '../assets/breath-icon.svg'
import plankIcon from '../assets/plank-icon.svg'
import squatsIcon from '../assets/squats-icon.png'
import stepsIcon from '../assets/steps-icon.png'
import usersIcon from '../assets/users-icon.png'
import Charts from './Charts'
import ProgressBar from './ProgressBar'

interface Props {
  challenge: any
}

const useStyles = makeStyles({
  gridItem: {
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  container: {
    width: 350,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  icon: {
    width: 32,
  },
  dates: {
    color: '#737373',
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  count: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  type: {
    fontSize: 12,
    color: '#737373',
  },
  typeIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  winner: {
    marginRight: 5,
    marginLeft: 5,
    width: 56,
    height: 56,
    borderRadius: '50%',
    border: '3px solid #F9B22D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  winnerPosition: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: '#F9B22D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -3,
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
  },
  winnerImage: {
    width: '100%',
    height: '100%',
  },
  winnerSecond: {
    borderColor: '#C0C0C0',
  },
  winnerPositionSecond: {
    background: '#C0C0C0',
  },
  winnerThird: {
    borderColor: '#CD7F32',
  },
  winnerPositionThird: {
    backgroundColor: '#CD7F32',
  },
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 180,
  },
  timerCount: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  timerItem: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
  },
  timerLabel: {
    fontSize: 12,
  },
  inviteButton: {
    fontSize: 16,
    color: 'white',
  },
  details: {
    borderRadius: 100,
    border: '2px solid #F9B22D',
    padding: '2px 10px',
  },
})

const getIcon = (type) => {
  const { name, icon_url } = type
  if (icon_url) {
    return icon_url
  }
  if (name === 'squats') {
    return squatsIcon
  }
  if (name === 'breath') {
    return breathIcon
  }
  if (name === 'plank') {
    return plankIcon
  }
  return stepsIcon
}

function Challenge(props: Props) {
  const { challenge } = props
  const classes = useStyles()
  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))
  const { minutes, hours, days, start } = useTimer({
    expiryTimestamp: moment.tz(challenge?.start_date, company.timezone),
    autoStart: false,
  })
  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    if (challenge?.status === 'scheduled') {
      start()
    }
  }, [challenge?.status])

  const startDate = challenge?.start_at || challenge?.start_date
  const endDate = challenge?.end_at || moment(startDate).add(challenge.duration, 'days')

  const renderEdit = () => {
    // TODO: define better way to define if hr admin can control this
    if (challenge?.general_start_date && challenge.general_end_date) return null

    return (
      <Link to={`/customize-challenge/edit/${challenge.challenge_id}`}>
        <Edit />
      </Link>
    )
  }

  return (
    <Grid item className={classes.gridItem}>
      <SectionContainer className={classes.container}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={getIcon(challenge.type_data)} className={classes.icon} />
          </Grid>
          <Grid item flexGrow={1}>
            <Typography className={classes.title}>{challenge?.name}</Typography>
            <Typography className={classes.dates}>
              {moment(startDate).format('DD MMMM')} - {moment(endDate).format('DD MMMM')}
            </Typography>
          </Grid>
          <Grid item>{renderEdit()}</Grid>
        </Grid>
        <Grid container mt={2} pr={2} pl={2} spacing={1}>
          <Grid item xs={6}>
            <Typography className={classes.count}>{challenge?.participantCount || 0}</Typography>
            <Box display="flex" alignItems="center">
              <img src={usersIcon} className={classes.typeIcon} />
              <Typography className={classes.type}>Members</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.count}>
              {challenge.type_data.name === 'breath' || challenge.type_data.name === 'plank'
                ? secondsToTime(challenge.valueAvg)
                : challenge?.valueAvg || 0}
            </Typography>
            <Box display="flex" alignItems="center">
              <img
                src={challenge.type_data.name === 'squats' ? squatsIcon : avgStepsIcon}
                className={classes.typeIcon}
              />
              <Typography className={classes.type}>Avg. / member</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item xs={12}>
            <ProgressBar
              startAt={startDate}
              endAt={endDate}
              duration={challenge?.duration || challenge.duration}
              currentChallengeDay={challenge?.currentDay || 0}
              width={isMobile ? 240 : 280}
            />
          </Grid>
        </Grid>
        {challenge?.participantsCount < 2 ? (
          <Box display="flex" alignItems="center" mt={8} flexDirection="column" justifyContent="center">
            <Typography color="red" fontSize={14} fontWeight="600" mb={5} textAlign="center">
              To activate the challenge, you need to invite more members to join...
            </Typography>
            <InviteEmployeesButton title="Invite" type="button" className={classes.inviteButton} />
          </Box>
        ) : challenge?.status === 'scheduled' ? (
          <Box display="flex" alignItems="center" mt={4} flexDirection="column" justifyContent="center" flex={1}>
            <div>
              <Typography fontSize={16} fontWeight="bold" mb={4}>
                Starts in
              </Typography>
            </div>
            <div className={classes.timerContainer}>
              <div className={classes.timerItem}>
                <Typography className={classes.timerCount}>{days}</Typography>
                <Typography className={classes.timerLabel}>Days</Typography>
              </div>
              <Typography className={classes.timerCount}>:</Typography>
              <div className={classes.timerItem}>
                <Typography className={classes.timerCount}>{hours}</Typography>
                <Typography className={classes.timerLabel}>Hours</Typography>
              </div>
              <Typography className={classes.timerCount}>:</Typography>
              <div className={classes.timerItem}>
                <Typography className={classes.timerCount}>{minutes}</Typography>
                <Typography className={classes.timerLabel}>Minutes</Typography>
              </div>
            </div>
          </Box>
        ) : (
          <React.Fragment>
            <Grid container mt={8}>
              <Grid item xs={12} display="flex" justifyContent="Center">
                {challenge.leaderboard.slice(0, 3).map((participant, index) => (
                  <Tooltip title={participant.name}>
                    <Box
                      className={classnames(
                        classes.winner,
                        index === 1 && classes.winnerSecond,
                        index === 2 && classes.winnerThird,
                      )}
                    >
                      <UserAvatar user={{ avatar_url: participant.avatar_url }} className={classes.winnerImage} />
                      <Typography
                        className={classnames(
                          classes.winnerPosition,
                          index === 1 && classes.winnerPositionSecond,
                          index === 2 && classes.winnerPositionThird,
                        )}
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                  </Tooltip>
                ))}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} mt={4} ml={-1} mr={-1}>
                <Charts
                  unitType={challenge.result_type}
                  type={challenge.type_data.name}
                  details={challenge.dailyReports || []}
                  maxBars={isMobile ? 6 : 8}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Grid container justifyContent="flex-end" mt={4}>
          <Link to={`/challenge/${challenge.challenge_id}`}>
            <Grid container alignItems={'center'} className={classes.details}>
              <Typography fontSize={'14px'} fontWeight={600} mr={1}>
                View Details
              </Typography>
              <ArrowForward fontSize="small" />
            </Grid>
          </Link>
        </Grid>
      </SectionContainer>
    </Grid>
  )
}

export default Challenge
