/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Group } from '@reaction-club-types/core'
import React, { useRef } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useModalState from '../../../hooks/useModalState'
import PlaybookInstanceDispatcher from '../../../reducers/playbookInstances/dispatcher'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { GROUP_LIST_URL } from '../Groups'
import { GROUP_EDIT } from '../GroupCreate'

const menuItemCss = css({
  color: '#fff',
  fontSize: 14,
  // margin: '5px 0',
  paddingLeft: 23,
  '&:hover': {
    color: '#F89A00',
  },
})

interface Props {
  group: Group
}

function GroupActions(props: Props) {
  const buttonRef = useRef<any>()
  const { group } = props
  const { id, employee_count } = group || {}
  const menuState = useModalState(false)
  const history = useHistory()
  const dispatch = useDispatch<ReduxDispatch>()

  const deleteGroupAction = useAsyncHandler(async () => {
    if (employee_count) {
      if (!confirm(`There are ${employee_count} users in group. Are you sure you want to delete group?`)) return
    } else if (!confirm('Are you sure you want to delete group?')) return

    try {
      await dispatch(PlaybookInstanceDispatcher.deleteInstance(id))
      history.push(GROUP_LIST_URL)
    } catch (e) {
      alert('instance is not deleted')
    }
  })

  const handleClickDelete = () => history.push(GROUP_EDIT(id))

  return (
    <Grid>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={menuState.onOpen}
        disableFocusRipple
        style={{ padding: 0 }}
        ref={buttonRef}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ transform: 'translate(-10px, 0)' }}
        keepMounted
        open={menuState.isVisible}
        onClose={menuState.onClose}
        PaperProps={{
          style: {
            width: 193,
            height: 120,
            backgroundColor: '#2D2D2D',
          },
        }}
        MenuListProps={{
          style: {
            position: 'relative',
            top: '50%',
            transform: 'translate(0, -50%)',
            padding: '27px 0',
          },
        }}
      >
        <MenuItem onClick={handleClickDelete}>
          <Typography variant="inherit" css={menuItemCss} style={{ marginBottom: 17 }}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={deleteGroupAction.execute}>
          <Typography variant="inherit" css={menuItemCss}>
            Delete Group
          </Typography>
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export const GROUP_PAGE = (id: string) => `/groups/${id}`

export default GroupActions
