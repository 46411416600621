/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Company } from '@reaction-club-types/core'
import { CompanyBoardRanking } from '@reducers/dashboardNext/toolkit'
import _ from 'lodash'
import React, { useMemo } from 'react'
import CompaniesBoardItem from './CompaniesBoardItem'

const BOARD_VISIBLE_ITEMS = 5

interface Props {
  list: CompanyBoardRanking[]
  company: Company
  unitType: 'time' | 'integer'
}

function LeaderBoardList(props: Props) {
  let { list, company, unitType } = props

  const index = useMemo(() => {
    return _.findIndex(list, (item) => item.id === company.id)
  }, [list, company])

  const listNear = useMemo(() => {
    if (index === -1) return list.slice(0, BOARD_VISIBLE_ITEMS)

    let sliceStart = index - 3
    if (sliceStart < 0) sliceStart = 0
    let items = list.slice(sliceStart, sliceStart + BOARD_VISIBLE_ITEMS)

    // @note not sure why it is needed. Probably mistake. It also creates duplicates, because use items from same array.
    // if (items.length < BOARD_VISIBLE_ITEMS) {
    //   let itemsNext = items.slice(index, index + BOARD_VISIBLE_ITEMS - items.length)
    //   items = items.concat(itemsNext)
    // }

    return items
  }, [list, index])

  return (
    <div>
      {_.map(listNear, (item) => (
        <CompaniesBoardItem
          company={item}
          unitType={unitType}
          isOwn={item.id === company.id}
          key={`company-board-item-${item.id}`}
        />
      ))}
    </div>
  )
}

export default LeaderBoardList
