import { Company } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CompanyBoardRanking = Company & { steps_avg: number; rank: string | number; is_public: boolean }

interface State {
  userStepsDailyList?: any[]
  companiesBoardRanking?: CompanyBoardRanking[]
  companyBoardRanking?: CompanyBoardRanking
  stepsPath?: {
    company_id: string
    start_at: string
    steps: number
    steps_avg: number
    user_count: number
  }
}

export type ReduxStateDashboard = State

const initialState: State = {
  // userStepsDailyList,
}

const dashboardNextSlice = createSlice({
  name: 'dashboard-next',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setUsersDailyList(state, action: PayloadAction<any[]>) {
      state.userStepsDailyList = action.payload
    },
    setStepsPath(state, action: PayloadAction<State['stepsPath']>) {
      state.stepsPath = action.payload
    },
    setCompaniesBoard(state, action: PayloadAction<State['companiesBoardRanking']>) {
      state.companiesBoardRanking = action.payload
    },
    setMyCompanyStepsRanking(state, action: PayloadAction<State['companyBoardRanking']>) {
      state.companyBoardRanking = action.payload
    },
  },
})

export const { actions, reducer } = dashboardNextSlice
export const dashboardActions = actions
export const dashboardReducer = reducer
export default reducer
