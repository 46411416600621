/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { If, Then } from 'react-if'
import { useDispatch } from 'react-redux'
import UserAvatar from '../../../../../components/UserAvatar'
import useLimitedArray from '../../../../../hooks/useLimittedArray'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'

interface Props {
  event: ReduxStateReactionEventItem
}

function AgendaEventParticipants(props: Props) {
  const { event } = props
  const { id, participants, participant_limit, participant_count } = event
  const dispatch = useDispatch<ReduxDispatch>()

  const fetchParticipants = useAsyncFetch(
    async () => {
      await dispatch(EventDispatcher.getParticipants(id, { isForce: false }))
    },
    { maxTries: 3 },
    [id],
  )

  const isMax = useMemo(() => {
    if (!participant_limit) return false
    return participant_count >= participant_limit
  }, [participant_count, participant_limit])

  const participantsToDisplay = useLimitedArray(participants, 4)

  const lengthHidden = participant_count - participantsToDisplay.length

  return (
    <div>
      <Grid
        container
        alignItems={'center'}
        css={css`
          padding-left: 7px;
          margin-top: 10px;
        `}
      >
        {_.map(participantsToDisplay, (user) => {
          return (
            <UserAvatar
              user={user}
              css={css`
                width: 30px;
                height: 30px;
                border: 1px solid #fff;
                margin-left: -7px;
              `}
            />
          )
        })}

        <If condition={lengthHidden > 0}>
          <Then>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              css={css`
                width: 30px;
                height: 30px;
                border: 1px solid #fff;
                margin-left: -7px;
                background-color: #1b48bb;
                border-radius: 50%;
                z-index: 10;
              `}
            >
              <Typography
                css={css`
                  font-size: 10px;
                  color: #fff !important;
                  text-align: center;
                `}
              >
                +{lengthHidden}
              </Typography>
            </Grid>
          </Then>
        </If>

        <If condition={isMax}>
          <Then>
            <Typography
              css={css`
                color: #1b48bb !important;
                font-size: 12px;
                display: inline-block;
                margin-left: 15px;
                font-weight: 600;
              `}
            >
              Event is full
            </Typography>
          </Then>
        </If>
      </Grid>
    </div>
  )
}

export default AgendaEventParticipants
