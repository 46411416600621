import api from './api'
import { actions } from './reducer'

class Dispatcher {
  getGroupsCoaches() {
    return async (dispatch) => {
      const list = await api.getGroupsCoaches()
      dispatch(actions.setGroupsCoaches(list))
    }
  }

  // getAgents() {
  //   return async (dispatch) => {
  //     const agents = await apiCompany.getAgents()
  //     dispatch(actions.setAgents(agents))
  //   }
  // }
  //
  // getCoaches() {
  //   return async (dispatch) => {
  //     const coaches = await apiCompany.getCompanyCoaches()
  //     coaches.forEach(({ user_id }) => dispatch(this.getCoachGroups(user_id)))
  //     dispatch(actions.setCoaches(coaches))
  //     dispatch(coachActions.setCoaches(coaches))
  //   }
  // }
  //
  // getEmployees() {
  //   return async (dispatch) => {
  //     const employees = await apiCompany.getCompanyEmployees()
  //     dispatch(actions.setEmployees(employees))
  //   }
  // }
  //
  // getCompanyStickers() {
  //   return async (dispatch) => {
  //     const stickers = await apiCompany.getStickers()
  //     dispatch(actions.setStickers(stickers))
  //   }
  // }
  //
  // getEmailList() {
  //   return async (dispatch) => {
  //     const emails = await apiCompany.getEmailList()
  //     dispatch(actions.setEmailList(emails))
  //   }
  // }
}

const ListsDispatcher = new Dispatcher()
export default ListsDispatcher
