import React from 'react'
import { Props as SelectProps } from 'react-select'
import EventFormLabel from './elements/EventFormLabel'
import EventFormSelect from './elements/EventFormSelect'

const durationOptions: SelectProps['options'] = [
  { value: '15', label: '15 minutes' },
  { value: '30', label: '30 minutes' },
  { value: '45', label: '45 minutes' },
  { value: '60', label: '1 hour' },
  { value: '120', label: '2 hours' },
  { value: '180', label: '3 hours' },
  { value: '240', label: '4 hours' },
]

interface Props {
  value: string
  onChange(type: string | undefined): any
  isErrored?: string
}

export default function EventDurationSelect(props: Props) {
  const { value, onChange, isErrored } = props

  return (
    <div>
      <EventFormLabel title={'Duration'} />
      <EventFormSelect value={value} options={durationOptions} onChange={onChange} isErrored={isErrored} />
    </div>
  )
}
