import { Avatar, Box, Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import CN from 'classnames'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactionLoader from '../../components/ReactionLoader'
import Rating from './Rating'
import messageIcon from './assets/message.svg'
import Categories from './coachProfile/Categories'

const tabs = ['Experience', 'Education']

const CoachProfile = ({ classes, createMockDirectRoom, userId, offer, price, chat, fullCoaches, getCoach }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0])
  const coach = fullCoaches[userId]

  React.useEffect(() => {
    if (!coach) {
      getCoach(userId)
    }
  }, [userId])

  if (!coach) {
    return <ReactionLoader />
  }

  const isNewMessage = Object.values(chat?.rooms || {}).find(r => r.userId === coach.chat_user_id)?.unread > 0

  const {
    name,
    avatar_url,
    chat_user_id,
    location: { formatted_address },
    profile: { summary },
    experience,
    education,
    rating,
    categories,
    types,
  } = coach

  return (
    <Grid item xs className={classes.profileWrapper}>
      <Grid container wrap={'nowrap'} className={classes.row}>
        <Avatar src={avatar_url} className={classes.avatar} />
        <Grid item>
          <Typography className={classes.name}>
            <b>{name}</b>
          </Typography>
          <Typography className={classes.location}>{formatted_address}</Typography>
          <Categories categories={categories} types={types} className={classes.categoryWrapper} />
          <Box className={classes.priceRatingWrapper}>
            <Typography className={classes.price}>
              <b>{price}</b>
            </Typography>
            <Rating value={rating} className={classes.rating} />
          </Box>
        </Grid>
      </Grid>
      <Typography className={`${classes.row} ${classes.description}`}>{summary}</Typography>
      {offer.status === 'pending' &&
        (isNewMessage ? (
          <Box className={`${classes.row} ${classes.btn} ${classes.btnWithMsg}`}>
            <Box className={classes.msgWrapper}>
              <img src={messageIcon} alt="" />
            </Box>
            <Typography>
              <b>You have a new message</b>
            </Typography>
          </Box>
        ) : (
          <Box className={classes.row} onClick={() => createMockDirectRoom('mock-room-id', chat_user_id)}>
            <Link to={`/coaches/work-offer/${offer.id}/chat/mock-room-id`} className={classes.btn}>
              <b>Contact coach</b>
            </Link>
          </Box>
        ))}
      <Box>
        <Box className={classes.tabWrapper}>
          {tabs.map(tab => (
            <Typography
              className={CN(classes.tab, { [classes.tabActive]: activeTab === tab })}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Typography>
          ))}
        </Box>
        {activeTab === tabs[0] &&
          experience?.map(({ company_name, position, date_from, date_to }) => (
            <Box className={classes.experience}>
              <Typography>
                <b>{position}</b> {company_name}
              </Typography>
              <Typography>
                {moment(date_from).format('MMM Do YY')}
                {date_to ? ` - ${moment(date_to).format('MMM Do YY')}` : ''}
              </Typography>
            </Box>
          ))}
        {activeTab === tabs[1] &&
          education?.map(({ school, degree, date_from, date_to }) => (
            <Box className={classes.experience}>
              <Typography>
                <b>{degree}</b> {school}
              </Typography>
              <Typography>
                {moment(date_from).format('MMM Do YY')}
                {date_to ? ` - ${moment(date_to).format('MMM Do YY')}` : ''}
              </Typography>
            </Box>
          ))}
      </Box>
    </Grid>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
  fullCoaches: state.coaches.fullCoaches,
})
const mapDispatchToProps = dispatch => ({
  getCoach: userId => dispatch(coachesDispatcher.getCoach(userId)),
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  profileWrapper: {
    padding: '36px 54px',
    fontSize: 13,
    position: 'relative',
  },
  row: {
    marginBottom: 40,
  },
  avatar: {
    height: 140,
    width: 140,
    marginRight: 48,
  },
  name: {
    fontSize: '22px',
    marginBottom: 8,
  },
  location: {
    marginBottom: 20,
    fontSize: 13,
  },
  categoryWrapper: {
    marginBottom: 16,
  },
  priceRatingWrapper: {
    position: 'absolute',
    top: 36,
    right: 53,
    textAlign: 'end',
  },
  price: {
    fontSize: 13,
    marginBottom: 8,
  },
  rating: {
    marginBottom: 4,
  },
  btn: {
    background: '#F9B22D',
    borderRadius: '25px',
    padding: '11px 42px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      background: '#f8c466',
    },
  },
  btnWithMsg: {
    padding: '11px',
  },
  msgWrapper: {
    backgroundColor: '#E91717',
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: 8,
  },
  tabWrapper: {
    display: 'flex',
  },
  tab: {
    width: 'fit-content',
    paddingBottom: '10px',
    marginBottom: 32,
    marginRight: 24,
    color: '#979797',
    cursor: 'pointer',
    borderBottom: '2px solid #979797',
  },
  tabActive: {
    color: '#323232',
    borderBottom: '2px solid #F9B22D',
  },
  experience: {
    '&:not(:last-child)': {
      marginBottom: 28,
    },
  },
  description: {
    fontSize: 13,
  },
})

export default connectStore(withStyles(styles)(CoachProfile))
