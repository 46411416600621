import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import SignUpForm from './SignUpForm'

const useStyles = makeStyles(
  {
    title: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 20,
      lineHeight: '24px',
    },

    description: {
      fontSize: 12,
      marginBottom: 30,
    },

    container: {
      marginTop: '20px',
    },
  },
  {
    classNamePrefix: 'AuthRegistration',
  },
)

interface Props {
  onSignUp(credentials: { email: string; password: string }): Promise<any>
}

function AuthRegistration(props: Props) {
  const { onSignUp } = props
  const classes = useStyles()

  return (
    <Grid container direction={'column'} justifyContent={'center'} className={classes.container}>
      <Box>
        <Typography variant={'h1'} className={classes.title}>
          Welcome to the
          <br />
          Reaction Admin
        </Typography>

        <Typography variant={'body1'} className={classes.description} align={'center'}>
          Create your admin account for company.
        </Typography>
      </Box>

      <Grid container direction={'column'}>
        <SignUpForm onLogin={onSignUp} />
      </Grid>
    </Grid>
  )
}

export default AuthRegistration
