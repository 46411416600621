import { Coach, Employee, Link, Group } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import { setItemOrArray } from '../../libs/reducerUtils'
import types from './types'

export interface ReduxStateGroupsItem extends Group, StoreUpdatedObject {
  link?: Link
  coachLink?: Link
  employees?: Employee[]
  coaches?: Coach[]
}

type State = Record<string, ReduxStateGroupsItem>

const initialState: State = {
  // { [key]: PlaybookInstance }
}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const items = action.payload
      return setItemOrArray(state, items)
    }

    case types.SET_PLAYBOOK_INVITE: {
      const { link, instance_id } = action.payload

      let stateInstance = state[instance_id]
      if (stateInstance) stateInstance = stateInstance.merge({ link })

      state = state.merge({ [instance_id]: stateInstance.asMutable({ deep: true }) })

      return state
    }

    case types.SET_PLAYBOOK_COACH_INVITE: {
      const { link, instance_id } = action.payload

      let stateInstance = state[instance_id]
      if (stateInstance) stateInstance = stateInstance.merge({ coachLink: link })

      state = state.merge({ [instance_id]: stateInstance.asMutable({ deep: true }) })

      return state
    }

    case types.SET_PLAYBOOK_INSTANCE_EMPLOYEES: {
      const { employees, instance_id } = action.payload

      let stateInstance = state[instance_id]
      if (stateInstance) stateInstance = stateInstance.merge({ employees })

      state = state.merge({ [instance_id]: stateInstance.asMutable({ deep: true }) })

      return state
    }

    case types.SET_PLAYBOOK_INSTANCE_COACHES: {
      const { coaches, instance_id } = action.payload

      let stateInstance = state[instance_id]
      if (stateInstance) stateInstance = stateInstance.merge({ coaches })

      state = state.merge({ [instance_id]: stateInstance.asMutable({ deep: true }) })

      return state
    }

    case types.DELETE_PLAYBOOK_INSTANCE: {
      const instance_id = action.payload

      state = state.without(instance_id)

      return state
    }

    // old =>
    case types.SET_PLAYBOOK_INSTANCE: {
      return state.merge({
        ...state,
        current: action.payload,
      })
    }
    case types.SET_PLAYBOOK_EMPLOYEES_INSTANCE: {
      return state.merge({
        ...state,
        current: { ...state.current, employees: action.payload },
      })
    }
    case types.SET_PLAYBOOK_COACHES_INSTANCE: {
      return state.merge({
        ...state,
        current: { ...state.current, coaches: action.payload },
      })
    }
    case types.UPDATE_PLAYBOOK_INSTANCE: {
      return state.merge({
        ...state,
        current: {
          ...action.payload,
          employees: state.current.employees,
          coaches: state.current.coaches,
        },
      })
    }
    case types.RESET_STORED_PLAYBOOK_INSTANCE: {
      return state.merge({
        ...state,
        current: {},
      })
    }
    case types.SET_INVITE_LINKS_FOR_INSTANCE: {
      return state.merge({
        ...state,
        current: {
          ...state.current,
          links: action.payload.links,
        },
      })
    }
    case types.LOADING: {
      return state.merge({
        isLoaded: false,
      })
    }
    case types.LOADED: {
      return state.merge({
        isLoaded: true,
      })
    }
    default:
      return state
  }
}
