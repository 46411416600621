import types from './types'

export default {
  setCategory: (categoryName: string) => ({ type: types.SET_CATEGORY, payload: { categoryName } }),
  setPlaybooks: (playbooks: { playbooks: Playbook[] }) => ({ type: types.SET_PLAYBOOKS, payload: playbooks }),
  setPlaybookId: playbook_id => ({ type: types.SET_PLAYBOOK_ID, payload: { playbook_id } }),
  setName: (name: string) => ({ type: types.SET_NAME, payload: { name } }),
  setTypes: (type_ids: string[]) => ({ type: types.SET_TYPES, payload: { type_ids } }),
  setNext: payload => ({ type: types.SET_NEXT, payload }),
}
