import apiGroup from '@reducers/groups/api'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actionsCompany from '../company/actions'
import actions from './actions'
import apiPlaybookInstances from './api'

class Dispatcher {
  getPlaybookInstances(): DispatcherResponse {
    return async (dispatch) => {
      const instances = await apiGroup.getList()
      dispatch(actionsCompany.setPlaybookInstances(instances)) // list
      dispatch(actions.set(instances)) // object
    }
  }

  createInstance(payload: { name: string; description: string; asset_id?: string }) {
    return async (dispatch) => {
      const pb = await apiPlaybookInstances.createPlaybookInstance(payload)
      dispatch(this.getPlaybookInstances())
      return pb
    }
  }

  kickEmployees(instance_id: string, employees: string[]) {
    return async (dispatch) => {
      await apiGroup.kickEmployees(instance_id, employees)
      dispatch(this.getInstanceEmployees(instance_id))
    }
  }

  editInstance(instance_id: string, payload: { name: string; description: string; asset_id?: string }) {
    return async (dispatch) => {
      await apiPlaybookInstances.editPlaybookInstance(instance_id, payload)
      await dispatch(this.get(instance_id, { isForce: true }))
    }
  }

  changeEmployeesInstance(instance_id: string, employees) {
    return async (dispatch) => {
      await apiGroup.addEmployees(instance_id, employees)
      dispatch(this.get(instance_id, { isForce: true }))
    }
  }

  changeCoachRole(payload: { instance_id: string; coach_id: string; role: string }) {
    return async (dispatch) => {
      await apiPlaybookInstances.changeCoachRole(payload)
      dispatch(this.getInstanceCoaches(payload.instance_id))
    }
  }

  get(group_id: string, options: DispatcherOptions): DispatcherResponse {
    const { isForce } = options || {}
    return createLazy({
      isForce,
      timeout: storeHelpers.TTL.DAY,
      onFindStoreUpdatedAt(state: ReduxState) {
        return state.playbookInstances[group_id]
      },
      onExecute: async (dispatch) => {
        const instance = await apiPlaybookInstances.getPlaybookInstance(group_id)
        if (_.isEmpty(instance)) return null
        await dispatch(actions.set(instance))
      },
    })
  }

  getInstanceEmployees(group_id: string) {
    return async (dispatch) => {
      const employees = await apiGroup.getEmployees(group_id)
      dispatch(actions.setPlaybookInstanceEmployees({ instance_id: group_id, employees }))
    }
  }

  getInstanceCoaches(instance_id: string) {
    return async (dispatch) => {
      const coaches = await apiPlaybookInstances.getInstanceCoaches(instance_id)
      dispatch(actions.setPlaybookInstanceCoaches({ instance_id, coaches }))
      return coaches
    }
  }

  createPlaybookInstanceInvite(instance_id: string) {
    return async (dispatch) => {
      const link = await apiPlaybookInstances.createPlaybookInstanceInvite(instance_id)
      dispatch(actions.setInviteLinks({ link, instance_id }))
    }
  }

  createPlaybookInstanceCoachInvite(instance_id: string) {
    return async (dispatch) => {
      const link = await apiPlaybookInstances.createPlaybookInstanceCoachInvite(instance_id)
      dispatch(actions.setInviteCoachLinks({ link, instance_id }))
    }
  }

  addPlaybookToInstance(instance_id: string, playbook_id: string) {
    return async (dispatch) => {
      await apiPlaybookInstances.addPlaybookToInstance(instance_id, playbook_id)
      dispatch(this.get(instance_id, { isForce: true }))
    }
  }

  makeCoachPrimary(instance_id: string, coach_id: string) {
    return async (dispatch) => {
      await apiPlaybookInstances.makeCoachPrimary(instance_id, coach_id)
      await dispatch(this.getInstanceCoaches(instance_id))
    }
  }

  updateMode({
    instance_id,
    mode,
    program_id,
    time,
  }: {
    instance_id: string
    mode: 'manual' | 'auto' | 'self'
    program_id?: string
    time?: string
  }) {
    return async (dispatch) => {
      if (mode === 'manual') await apiPlaybookInstances.makeManual(instance_id)
      else if (mode === 'self') await apiPlaybookInstances.makeSelfDriven(instance_id)
      else if (mode === 'auto') await apiPlaybookInstances.makeAuto(instance_id, { program_id, time })
      dispatch(this.get(instance_id, { isForce: true }))
    }
  }

  kickCoach(instance_id: string, coach_id: string) {
    return async (dispatch) => {
      await apiPlaybookInstances.kickCoach(instance_id, coach_id)
      dispatch(this.getInstanceCoaches(instance_id))
    }
  }

  addCoach(instance_id: string, coach_id: string) {
    return async (dispatch) => {
      await apiPlaybookInstances.addCoach(instance_id, coach_id)
      const coaches = await dispatch(this.getInstanceCoaches(instance_id))

      if (coaches.length === 1) {
        await dispatch(this.makeCoachPrimary(instance_id, coach_id))
      }
    }
  }

  deleteInstance(instance_id: string) {
    return async (dispatch) => {
      await apiPlaybookInstances.deleteInstance(instance_id)
      dispatch(actions.deleteInstance(instance_id))
    }
  }
}

const GroupDispatcher = new Dispatcher()
export default GroupDispatcher
