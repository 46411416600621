/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactionEvent } from '@reaction-club-types/core'
import React from 'react'
import { Props as SelectProps } from 'react-select'
import infoIcon from '../../../../assets/Info.png'
import useModalState from '../../../../hooks/useModalState'
import EventFieldTypeInfoModal from '../modals/EventFieldTypeInfoModal'
import EventFieldInfo from './elements/EventFieldInfo'
import EventFormLabel from './elements/EventFormLabel'
import EventFormSelect from './elements/EventFormSelect'

const options: SelectProps['options'] = [{ value: 'on_site', label: 'On Site' }]

interface Props {
  value: ReactionEvent['workout_type']
  onChange(type: ReactionEvent['workout_type']): any
}

export default function EventTypeSelect(props: Props) {
  const { value, onChange } = props
  const infoModal = useModalState()

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <EventFormLabel title={'Event type'}>
        <EventFieldInfo onClick={infoModal.onOpen} />
      </EventFormLabel>
      <EventFieldTypeInfoModal {...infoModal} />

      <EventFormSelect value={value} options={options} onChange={onChange} />
    </div>
  )
}
