import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import ActionButtonContainer from './ActionButtonContainer'

const useStyles = makeStyles(
  {
    actionsText: {
      marginLeft: 20,
      fontWeight: 600,
    },
  },
  {
    classNamePrefix: 'ActionButtonDefault',
  },
)

interface Props {
  icon: string
  text: string
  onClick(): any
}

function ActionButtonDefault(props: Props) {
  const { icon, text, onClick } = props

  const classes = useStyles()

  return (
    <ActionButtonContainer onClick={onClick}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <img src={icon} alt={text} />
        <Typography className={classes.actionsText}>{text}</Typography>
      </Grid>
    </ActionButtonContainer>
  )
}

export default ActionButtonDefault
