import { Grid, Box, Typography, Button } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import MyButton from '../components/MyButton'
import ProfileHeader from '../components/ProfileHeader'
import ReactionLoader from '../components/ReactionLoader'
import CoachProfileWrapper from './acceptedCoachProfile/CoachProfileWrapper'
import AgreementDetailsForm from './inviteCoaches/components/AgreementDetailsForm'

const aProps = {
  style: { color: '#000' },
  target: '_blank',
  rel: 'noopener noreferrer',
}

const AcceptWorkOffer = ({
  offers,
  getOffers,
  acceptCoach,
  history,
  match: {
    params: { offerId, id },
  },
}) => {
  const [budget, setBudget] = React.useState()
  const [period, setPeriod] = React.useState()
  const [currency, setCurrency] = React.useState()
  const [startDate, setStartDate] = React.useState()
  const [description, setDescription] = React.useState()

  React.useEffect(() => {
    if (!offers) {
      getOffers()
    }
  }, [])

  const offer = offers?.find(o => o.id === offerId)
  const coach = offer?.matchedCoaches?.find(c => c.user_id === id)

  const onSubmit = () => {
    if (budget && period && currency && startDate && description) {
      acceptCoach({
        offer_id: offerId,
        coach_id: id,
        period,
        description,
        agreed_price: budget,
        agreed_currency: currency,
        started_at: startDate,
      })
      history.push('/coaches/work-offers')
    }
  }

  if (!coach) {
    return <ReactionLoader />
  }

  return (
    <Grid container style={{ height: '100%' }}>
      <ProfileHeader />
      <Grid xs={12} item style={{ margin: '0 50px 50px 50px' }}>
        <CoachProfileWrapper data={coach}>
          <Box style={{ padding: '74px', width: '100%', maxWidth: 623, margin: 'auto' }}>
            <AgreementDetailsForm
              budget={budget}
              period={period}
              currency={currency}
              startDate={startDate}
              description={description}
              setBudget={setBudget}
              setPeriod={setPeriod}
              setCurrency={setCurrency}
              setStartDate={setStartDate}
              setDescription={setDescription}
              budgetLabel={'Agreed payment'}
              descriptionLabel={'Agreement summary'}
            />
            <Typography style={{ margin: '8px auto 16px' }}>
              By clicking confirm you are approving Reaction{' '}
              <a {...aProps} href="https://www.reaction-club.com/termsofuse">
                terms and condition
              </a>
              {' and '}
              <a {...aProps} href="https://www.reaction-club.com/privacypolicy">
                privacy policy
              </a>
              {' and '}
              <a {...aProps} href="#">
                cancellation policy
              </a>
              .
            </Typography>
            <MyButton onClick={onSubmit} disabled={!budget || !period || !currency || !startDate}>
              Confirm
            </MyButton>
          </Box>
        </CoachProfileWrapper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  users: state.chat.users,
  offers: state.coaches.offers,
})
const mapDispatchToProps = dispatch => ({
  getUser: param => dispatch(ChatDispatcher.getUser(param, { isForce: false })),
  getOffers: () => dispatch(coachesDispatcher.getOffers()),
  acceptCoach: param => dispatch(coachesDispatcher.acceptCoach(param)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({})

export default connectStore(withStyles(styles)(AcceptWorkOffer))
