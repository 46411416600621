/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { CardActionArea } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Asset } from '@reaction-club-types/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Progress } from 'react-sweet-progress'
import { compose } from 'recompose'
import fileExplorer from 'web-file-explorer'
import uploadIcon from '../../assets/upload/uploadIcon.png'
import assetsApi from '../../libs/api/apiAssets'
import { ExtractStyles } from '../../typings/stylesExtractor'
import AssetPicture from './AssetPicture'

const connectStyles = withStyles({
  container: {
    height: 150,
    backgroundColor: '#7f7f7f',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  uploadTitle: {},

  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  progressText: {
    color: '#fff',
  },
})

interface Props {
  asset: Asset
  onChange: (asset: Asset) => void
  className?: string
  imageClassName?: string
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

interface State {
  progress: number
  isLoadingVisible: boolean
  status: 'idle' | 'active' | 'success' | 'error'
}
class AssetEditable extends React.PureComponent<PropsInner, State> {
  static propTypes: any = {
    asset: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  }

  state: State = {
    progress: 0,
    status: 'idle',
    isLoadingVisible: false,
  }

  render() {
    const { classes, className } = this.props

    const containerClasses = [classes.container]
    if (className) containerClasses.push(className)

    return (
      <CardActionArea
        onClick={this.handlePress}
        onKeyPress={this.handlePress}
        tabIndex={-1}
        role={'button'}
        className={containerClasses.join(' ')}
      >
        {this.renderBody()}
        {this.renderLoading()}
      </CardActionArea>
    )
  }

  renderBody() {
    const { asset, imageClassName } = this.props

    if (_.isEmpty(asset)) {
      return (
        <React.Fragment>
          <img
            src={uploadIcon}
            alt={'upload'}
            css={css({
              width: 16,
              height: 16,
              objectFit: 'contain',
              marginBottom: 12,
            })}
          />
          <span
            css={css({
              color: '#fff',
              fontSize: 11,
              fontWeight: 'bold',
            })}
          >
            Upload Image
          </span>
        </React.Fragment>
      )
    }

    return <AssetPicture asset={asset} className={`image ${imageClassName}`} />
  }

  renderLoading() {
    const { classes } = this.props
    const { isLoadingVisible, progress, status } = this.state
    if (!isLoadingVisible) return null

    // const status = progress != 100 ? 'active' : 'success'

    return (
      <div className={classes.loadingContainer} onClick={this.handlePressLoading} onKeyDown={this.handlePressLoading}>
        <Progress
          type="circle"
          percent={progress}
          status={status}
          width={50}
          color={'#fff'}
          symbolClassName={classes.progressText}
          theme={{
            active: {
              symbol: `${progress.toFixed(0)}%`,
              trailColor: 'rgba(255, 255, 255, 0.3)',
              color: '#fff',
            },
          }}
        />
        {/* <CircularProgress value={90} size={60} color={'inherit'} className={classes.progress} variant={'determinate'} /> */}
      </div>
    )
  }

  handlePressLoading = (e) => {
    e.stopPropagation() // doesn't help
  }

  handlePress = async () => {
    if (this.state.isLoadingVisible) return null

    const file = await fileExplorer.getFile({
      acceptImage: true,
    })

    await this.upload(file)
  }

  upload = async (file: File) => {
    this.setState({ isLoadingVisible: true, status: 'active' })

    try {
      const asset = await assetsApi.upload(file, {
        onUploadProgress: (event) => {
          this.setState({ progress: (event.loaded / event.total) * 100 })
        },
      })

      this.setState({ status: 'success' })
      this.props.onChange(asset)
    } catch (e) {
      this.setState({ status: 'error' })
      console.error('upload error', e)
    }

    setTimeout(() => this.setState({ isLoadingVisible: false }), 300)
  }
}

export default compose<PropsInner, Props>(connectStyles)(AssetEditable)
