import ACTION_TYPE, { ModalAction, MODAL_TYPES } from './types'
import './reducer'

type ShowModalParam = {
  modalType: MODAL_TYPES
  modalProps?: any
}

interface ModalActions {
  /** @deprecated use just modal component. No need for store */
  showModal: (modalInfo: ShowModalParam) => ModalAction
  closeModal: (modalType: MODAL_TYPES) => ModalAction
}

const modalActions: ModalActions = {
  showModal: modalInfo => ({ type: ACTION_TYPE.SHOW_MODAL, payload: modalInfo }),
  closeModal: modalType => ({ type: ACTION_TYPE.CLOSE_MODAL, payload: modalType }),
}

export default modalActions
