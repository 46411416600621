/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Tab } from '@mui/material'
import { Moment } from 'moment'
import React from 'react'
import { View } from 'react-big-calendar'
import { Link } from 'react-router-dom'
import WhiteTabs from '../../../../elements/tabs/WhiteTabs'
import reportsImage from '../../assets/reports.png'
import EventCreateButton from '../EventCreateButton'
import EventsCalendarMonth from './components/EventsCalendarMonth'

interface Props {
  tab: string
  date: Moment
  isEventsLoading?: boolean
  onTabChange(tab: View): any
  onDateNavigate?(action: string, date: any): any
}

function CalendarToolbar(props: Props) {
  const { tab, date, onTabChange, onDateNavigate, isEventsLoading } = props

  const handleTabChange = (event: any, newValue: string) => {
    onTabChange(newValue as View)
  }

  const handleNavigate = (event: any, newValue: Moment) => {
    onDateNavigate(event, newValue)
  }

  return (
    <Grid
      container
      justifyContent={'space-between'}
      flexShrink={1}
      flexBasis={1}
      css={css`
        margin-bottom: 30px;
      `}
    >
      {tab !== 'discover' ? (
        <EventsCalendarMonth date={date} view={tab} onNavigate={handleNavigate} isLoading={isEventsLoading} />
      ) : null}

      <div>
        <Grid container alignItems={'center'}>
          <div
            css={css`
              margin-right: 15px;
            `}
          >
            <WhiteTabs onChange={handleTabChange}>
              <Tab disableRipple label="Discover" value="discover" />
              <Tab disableRipple label="Upcoming" value="agenda" />
              <Tab disableRipple label="Calendar" value="month" />
            </WhiteTabs>
          </div>

          <Link to={'/reports'}>
            <img
              src={reportsImage}
              alt={'reports'}
              css={css`
                width: 29px;
                height: 28px;
                object-fit: contain;
                margin: 10px 0;
              `}
            />
          </Link>
        </Grid>
      </div>

      <EventCreateButton />
    </Grid>
  )
}

export default CalendarToolbar
