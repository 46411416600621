import { Typography } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { View } from 'react-big-calendar'
import CalendarToolbar from '../calendar/CalendarToolbar'
import AgendaEvent from './AgendaEvent'

interface Props {
  // date: Moment
  events: ReactionEvent[]
  onTabChange(tab: View): any
  onDateNavigate(nextDate: any): any
}

function Agenda(props: Props) {
  const { events, onTabChange, onDateNavigate } = props

  const handleDateNavigate = (event: string, nextDate: any) => {
    onDateNavigate(nextDate)
  }

  const eventsFuture = useMemo(() => {
    const nowMoment = moment()
    return _.filter(events, (event) => {
      // It is important to show active events
      const eventEndMoment = moment(event.time).add(event.duration, 'minutes')
      return eventEndMoment.isAfter(nowMoment)
    })
  }, [events])

  const eventsOrdered = useMemo(() => {
    return _.orderBy(eventsFuture, (event) => event.time)
  }, [eventsFuture])

  return (
    <div>
      <CalendarToolbar tab={'agenda'} date={moment()} onTabChange={onTabChange} onDateNavigate={handleDateNavigate} />

      <div>
        {_.isEmpty(eventsOrdered) ? (
          <Typography style={{ marginTop: 50 }} textAlign={'center'}>
            No future events
          </Typography>
        ) : null}

        {_.map(eventsOrdered, (item) => (
          <AgendaEvent event={item} key={`calendar-agenda-event-${item.id}`} />
        ))}
      </div>
    </div>
  )
}

export default Agenda
