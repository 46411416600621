import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_TOKEN: 'SET_TOKEN',
    SET_IS_ADMIN: 'SET_IS_ADMIN',
    ACCEPT_TERMS: '',
  },
  { prefix: 'AUTH__' },
)
