import api from './api'
import { actions } from './reducer'

class Dispatcher {
  getChallengeApplications() {
    return async (dispatch) => {
      const list = await api.getChallengeApplications()
      dispatch(actions.setChallengeApplications(list))
    }
  }

  getChallenges() {
    return async (dispatch) => {
      const list = await api.getChallenges()
      dispatch(actions.setChallenges(list))
    }
  }
}

const ChallengeListsDispatcher = new Dispatcher()
export default ChallengeListsDispatcher
