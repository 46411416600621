import React, { useState, useMemo, useEffect } from 'react'
import { Select, MenuItem } from '@mui/material'
import styled from '@emotion/styled'
import _ from 'lodash'
import api from 'src/libs/api/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

const Header = styled.h2`
  font-weight: 800;
  font-size: 16px;
  font-family: Poppins;
  margin-bottom: 15px;
`

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`

const Radio = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  span {
    content: '*',
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #000000;
    border-radius: 50%;
    margin-right: 5px;
  }

  aside {
    display: ${props => props.active ? 'flex': 'none'};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    background: black;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  &:not(:last-child) {
    margin-right: 40px;
  }
`

const TextField = styled.input`
  display: inline-flex;
  border-radius: 2px;
  padding: 7px 12px;
  background: white;
  border: 1px solid black;
  text-align: center;
  width: ${props => props.sm ? '50px' : '70px'};
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Button = styled.button`
  cursor:  ${p => p.isChanges ? 'pointer' : 'not-allowed'};
  display: inline-flex;
  align-self: flex-start;
  color: black;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 700;
  word-wrap: break-word;
  padding: 9px 25px;
  border-radius: 20px;
  border: none;
  background: ${p => !p.isChanges ? '#979797' : '#F9B22D'};
`

const StyledSelect = styled(Select)`
  margin-left: 12px;
  height: 32px;

  fieldset {
    border-color: black;
    border-width: 1px;
  }
`

const checkNumber = (number, old) => {
  if (old && number === '') return ''
  if (Number(number)) return Number(number)

  return old
}

const rewardTypes = [
  { label: 'Gift Cards', value: 'gift_cards' },
  { label: 'Donations', value: 'donations' },
  { label: 'Both', value: 'both' }
]

const SettingsForm = ({ settings, fetchSettings }) => {
  const initialValues = {
    type: settings.type,
    exchange_rate: settings.exchange_rate,
    frequency_unit: settings.frequency_unit,
    frequency_value: settings.frequency_value,
    min_value: settings.min_value,
    max_value: settings.max_value
  }
  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const isChanges = useMemo(() => !(_.isEqual(initialValues, values)), [initialValues, values])

  const handleSubmit = async() => {
    try {
      setLoading(true)
      await api.updateRewardsSettings(values)
      await fetchSettings()
      alert('Changes saved successfully')
    } catch (err) {
      alert(err?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Section>
        <Header>Rewards type</Header>
        <RadioGroup>
          {rewardTypes.map(({ label, value }) => (
            <Radio
              active={values.type === value}
              onClick={() => setValues(values => ({ ...values, type: value }))}
            >
              <span />
              <aside />
              {label}
            </Radio>
          ))}
        </RadioGroup>
      </Section>
      <Section>
        <Header>How much points worth</Header>
        <Row>
          $1 USD &nbsp; &nbsp;  = &nbsp; &nbsp; <TextField sm value={values.exchange_rate} onChange={e => setValues(values => ({ ...values, exchange_rate: checkNumber(e.target.value, values.exchange_rate) }))} /> &nbsp; &nbsp; Points
        </Row>
      </Section>
      <Section>
        <Header>How frequently members can redeem rewards</Header>
        <Row>
          <TextField
            value={values.frequency_value}
            onChange={e => {
              setValues(values => ({
                ...values,
                frequency_value: checkNumber(e.target.value, values.frequency_value)
              }))
            }}
          />
          <StyledSelect
            id="frequency-unit"
            value={values.frequency_unit}
            onChange={e => {
              setValues(values => ({
                ...values,
                frequency_unit: e.target.value
              }))
            }}
          >
            <MenuItem value="month">Per Month</MenuItem>
            <MenuItem value="week">Per week</MenuItem>
          </StyledSelect>
        </Row>
      </Section>
      <Section>
        <Header>What kind of rewards would you like to offer?</Header>
        <Row>
          <span>Min value $ &nbsp;</span>
          <TextField
            value={values.min_value}
            onChange={e => {
              setValues(values => ({
                ...values,
                min_value: checkNumber(e.target.value, values.min_value)
              }))
            }}
          />
          &nbsp; &nbsp; &nbsp;
          <span>Max value $ &nbsp;</span>
          <TextField
            value={values.max_value}
            onChange={e => {
              setValues(values => ({
                ...values,
                max_value: checkNumber(e.target.value, values.max_value)
              }))
            }}
          />
        </Row>
      </Section>
      <Button
        disabled={!isChanges || loading}
        isChanges={isChanges || loading}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Container>
  )
}

const SettingsTab = () => {
  const [settings, setSettings] = useState<any>({})

  const fetchSettings = async () => {
    const settings = await api.getRewardsSettings()
    setSettings(settings)
  }

  useEffect(() => {
    fetchSettings()
  }, [])


  if (!settings.company_id) return null

  return (
    <SettingsForm settings={settings} fetchSettings={fetchSettings} />
  )
}

export default SettingsTab
