import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import api from './apiCategories'
import './reducer'

export default {
  getCategories(): DispatcherResponse {
    return async dispatch => {
      const categories = await api.getCategories()
      dispatch(actions.setCategories(categories))
    }
  },
  getCategoryTypes(category: string): DispatcherResponse {
    return async dispatch => {
      const category_id = category.toLowerCase()
      const types = await api.getTypes(category_id)
      dispatch(actions.setCategoryTypes({ category_id, types }))
    }
  },
}
