import { Edit } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactionLoader from '../../../../components/ReactionLoader'
import ButtonOrangeSquare from '../../../../modals/components/ButtonOrangeSquare'
import defaultAppIcon from '../../assets/default-icon.png'
import SectionContainer from '../../components/SectionContainer'
import Timezone from './Timezone'
import UploadCompanyLogo from './UploadCompanyLogo'

const useStyles = makeStyles({
  colorBox: {
    width: 42,
    height: 42,
    borderRadius: '50%',
  },
  colorBoxYellow: {
    backgroundColor: '#F9B22D',
  },
  colorBoxBlue: {
    backgroundColor: '#26283B',
  },
  link: {
    color: '#3C8AF0',
  },
  icon: {
    width: 42,
    height: 42,
  },
})

const CompanyDetails = () => {
  const classes = useStyles()
  const company = useSelector((state: ReduxState) => state.company)
  const [name, setName] = useState('')
  const [logoUrl, setLogoUrl] = useState(company.logo)
  const dispatch = useDispatch()

  useEffect(() => {
    setName(company.name)
    setLogoUrl(company.logo)
  }, [company])

  const handleNameChange = (e) => {
    const text = e.target.value || ''
    setName(text)
  }

  const handleEdit = async () => {
    await dispatch(CompanyDispatcher.editCompany(company.id, { name, logo: logoUrl }))
    alert('Company was edited successfully.')
  }

  const handleEditTheme = () => {
    dispatch(
      modalActions.showModal({
        modalType: MODAL_TYPES.INFO,
        modalProps: {
          title: 'App Branding',
          headerStyle: { textAlign: 'center' },
          text: (
            <span>
              Changing your app branding requires additional cost and <br /> can take 2 weeks to setup. If you are{' '}
              <br /> interested please email us{' '}
              <a href="mailto:hello@reaction-club.com" className={classes.link}>
                hello@reaction-club.com
              </a>
            </span>
          ),
        },
      }),
    )
  }

  return (
    <SectionContainer>
      <Grid container spacing={5}>
        {company.id ? (
          <>
            <Grid item>
              <UploadCompanyLogo logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
            </Grid>
            <Grid item xs={6}>
              <Grid container direction={'column'}>
                <Box mb={3}>
                  <Typography mb={2} fontSize={16} fontFamily="Manrope">
                    Company name
                  </Typography>
                  <TextField value={name} onChange={handleNameChange} fullWidth />
                </Box>
                <Timezone company={company} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography mb={2} fontSize={16} fontFamily="Manrope">
                App icon
              </Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <img src={defaultAppIcon} alt="Default icon" className={classes.icon} />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleEditTheme}>
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography mb={2} fontSize={16} fontFamily="Manrope" mt={4}>
                App colors
              </Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <Box className={classnames(classes.colorBox, classes.colorBoxYellow)} />
                </Grid>
                <Grid item>
                  <Box className={classnames(classes.colorBox, classes.colorBoxBlue)} />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleEditTheme}>
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={12}>
              <ButtonOrangeSquare onClick={handleEdit}>Update Profile</ButtonOrangeSquare>
            </Grid>
          </>
        ) : (
          <Box style={{ margin: '-30px auto', width: 'fit-content' }}>
            <ReactionLoader />
          </Box>
        )}
      </Grid>
    </SectionContainer>
  )
}

export default CompanyDetails
