/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import EventModalStyled from './elements/EventModalStyled'
import EventModalText from './elements/EventModalText'
import EventModalTitle from './elements/EventModalTitle'

interface Props {
  event: ReduxStateReactionEventItem
  isVisible: boolean
  onClose(): any
  onDelete(): any
}

function EventDeleteModal(props: Props) {
  const { event, onClose, onDelete } = props
  const { repeat } = event
  const dispatch = useDispatch<ReduxDispatch>()

  const deleteAction = useAsyncHandler(async (params: { spawnNext?: boolean }) => {
    await dispatch(EventDispatcher.deleteEvent(event.id))
    onClose()
    onDelete()
  })

  const handleDeleteSingleEvent = () => {
    deleteAction.execute({ spawnNext: true })
  }

  const handleDeleteAllEvents = () => {
    deleteAction.execute({})
  }

  return (
    <EventModalStyled {...props}>
      <div>
        <EventModalTitle>You are deleting an event.</EventModalTitle>

        <EventModalText>
          Do you want to delete this and all future occurrences of this event, or only the selected occurrence?
        </EventModalText>

        <Grid
          container
          css={css`
            margin-top: 30px;
          `}
        >
          <RendererStatusSplit
            statuses={deleteAction}
            renderError={(error) => <Typography color={'error'}>{error}</Typography>}
            renderLoading={() => (
              <Grid container justifyContent={'center'}>
                <Loader />
              </Grid>
            )}
            render={() =>
              repeat ? (
                <React.Fragment>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleDeleteSingleEvent}
                    css={css`
                      margin-right: 10px;
                      padding: 7px 20px;
                    `}
                  >
                    Delete only this event
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleDeleteAllEvents}
                    css={css`
                      padding: 7px 20px;
                    `}
                  >
                    Delete all future events
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={handleDeleteAllEvents}
                  css={css`
                    padding: 7px 20px;
                  `}
                >
                  Delete event
                </Button>
              )
            }
          />
        </Grid>
      </div>
    </EventModalStyled>
  )
}

export default EventDeleteModal
