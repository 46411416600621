import { Box, Grid, TextareaAutosize } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import Picker from 'emoji-picker-react'
import React from 'react'
import { compose } from 'redux'
import fileExplorer from 'web-file-explorer'
import clearIcon from '../../../../components/modals/assets/close.svg'
import { addFileIcon, sendIcon, smileIcon } from './assets/facade'

const SendMsgField = ({ classes, sendMessage, style, withAttachments = true }) => {
  const [isEmojiPickerOpen, setEmojiPickerOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [asset, setAsset] = React.useState<File>(null)
  const [fakeAsset, setFakeAsset] = React.useState(null)

  const toggleEmojiPicker = () => {
    setEmojiPickerOpen(!isEmojiPickerOpen)
  }

  const removeAsset = () => {
    setAsset(null)
    setFakeAsset(null)
  }

  const onEmojiClick = (e, { emoji }) => {
    setMessage(message + emoji)
    toggleEmojiPicker()
  }

  const keyPress = e => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault()
      sendMsg()
    }
  }

  const sendMsg = () => {
    if ((message && message.trim()) || asset) {
      sendMessage(message, asset)

      setMessage('')
      removeAsset()
    }
  }

  const handleAssetUpload = async () => {
    const file = await fileExplorer.getFile()

    if (!file.type.includes('image')) {
      alert('You can add only images for now.')
      return null
    }

    const reader = new FileReader()
    reader.onload = ({ target: { result } }) => {
      setFakeAsset(result)
      setAsset(file)
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      {fakeAsset && (
        <Grid className={classes.imageContainer} container alignItems={'center'}>
          <img src={clearIcon} className={classes.clear} alt="" onClick={removeAsset} />
          <img src={fakeAsset} className={classes.image} alt="" />
        </Grid>
      )}
      <Box className={classes.textareaWrapper} style={style}>
        <Box className={classes.prefixContainer}>
          {isEmojiPickerOpen && (
            <Box className={classes.emojiPicker}>
              <Picker onEmojiClick={onEmojiClick} />
            </Box>
          )}
          <img src={smileIcon} className={classes.button} alt="" onClick={toggleEmojiPicker} />
          {withAttachments && <img src={addFileIcon} className={classes.button} alt="" onClick={handleAssetUpload} />}
        </Box>
        <TextareaAutosize
          value={message}
          onKeyDown={keyPress}
          onChange={e => setMessage(e.target.value)}
          placeholder={`Your message`}
          className={classes.textarea}
        />
        <Box className={classes.postfixContainer}>
          <img src={sendIcon} className={classes.button} alt="" onClick={sendMsg} />
        </Box>
      </Box>
    </>
  )
}

const styles = createStyles({
  imageContainer: {
    padding: '30px 40px 0 40px',
    position: 'relative',
    width: 'fit-content',
  },
  image: {
    height: 100,
  },
  clear: {
    top: 30,
    right: 40,
    position: 'absolute',
    cursor: 'pointer',
  },
  textareaWrapper: {
    position: 'relative',
  },
  prefixContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: '24px',
    left: '40px',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: '40px',
  },
  postfixContainer: {
    position: 'absolute',
    bottom: '27.5px',
    right: '40px',
  },
  button: {
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: 24,
    },
  },
  textarea: {
    width: '100%',
    minHeight: '77px',
    resize: 'none',
    padding: '32px 86px 32px 144px',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#868686',
      letterSpacing: '0.01em',
      fontSize: 14,
      fontWeight: '800',
    },
  },
})

export default compose(withStyles(styles))(SendMsgField)
