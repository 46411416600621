const devConfig = {
  // analyst: {
  //   key: 'f2c589f9-b202-4984-a252-4df2e6b15aef',
  //   url: 'https://analyst-dev.reaction-club.app',
  // },

  onboard: {
    url: 'https://hr-onboard-dev.reaction-club.app/onboard',
  },
}

export default devConfig
