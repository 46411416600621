import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import ButtonOrange from './ButtonOrange'
import theme from '../app/theme'
import config from '../config'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import { ReduxState } from '@reducers/index'
import lockIcon from '../assets/lock.svg'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#D9D9D9',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
  },
  text: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: 'Poppins',
    textAlign: 'center',
    margin: '16px 0',
  },
  upgradeNow: {
    fontFamily: 'Poppins',
    padding: '12px 28px',
    fontSize: 16,
    border: '2px solid rgb(249, 178, 45)',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: '5px 15px',
    },
  },
  icon: {
    width: 30,
    height: 30,
  },
})

const Locked = ({ text }) => {
  const classes = useStyles()
  const company = useSelector((state: ReduxState) => state.company)
  const history = useHistory()

  const handleUpgrade = useCallback(() => {
    mixpanel.track('Upgrade Now Clicked', {
      distinct_id: company?.id,
    })
    history.push('/payment')
  }, [])

  return (
    <div className={classes.container}>
      <img src={lockIcon} className={classes.icon} />
      <div className={classes.text}>{text}</div>
      <ButtonOrange className={classes.upgradeNow} color="transparent" onClick={handleUpgrade}>
        Upgrade Now
      </ButtonOrange>
    </div>
  )
}

export default Locked
