import { Grid, Box, Button, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import CN from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import tickInCircleIcon from '../assets/tick-in-circle.svg'
import CoachWrapper from '../components/CoachWrapper'

const serviceTypes = [
  {
    id: 'online',
    name: 'Online',
    description:
      'I’m looking for an expert to provide online livestream sessions, online training programs and guidance.',
  },
  {
    id: 'onsite',
    name: 'OnSite',
    description: 'I’m looking for an expert to provide onsite sessions and deliver face to face guidance.',
  },
  {
    id: 'onsite+online',
    name: 'Combined',
    description:
      'I’m looking for an expert to provide both onsite and online sessions, in addition to programs and digital guidance.',
  },
]

const ChooseServiceType = ({ classes, serviceType, setOfferServiceType }) => {
  React.useEffect(() => {
    if(!serviceType) {
      setOfferServiceType(serviceTypes[0].id)
    }
  }, [])

  return (
    <CoachWrapper title="Choose Service Type" titleWidth={152}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content} spacing={3}>
            {serviceTypes.map(({ id, name, description }) => {
              const isServiceTypeActive = serviceType === id

              return (
                <Grid item xs={4}>
                  <Button
                    classes={{
                      root: CN(classes.serviceTypeBtn, { [classes.activeServiceTypeBtn]: isServiceTypeActive }),
                      label: classes.serviceTypeBtnLabel,
                    }}
                    onClick={() => setOfferServiceType(id)}
                  >
                    <Box style={{ textTransform: 'none' }}>
                      <Typography className={classes.title}>{name}</Typography>
                      <Typography>{description}</Typography>
                    </Box>
                    {isServiceTypeActive && <img src={tickInCircleIcon} className={classes.tickInCircle} alt="" />}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Link to="/coaches/invite/external/choose-location-language" style={{ textDecoration: 'none' }}>
            <Button className={classes.btn}>Next</Button>
          </Link>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  serviceType: state.coaches.offer.serviceType,
})
const mapDispatchToProps = dispatch => ({
  setOfferServiceType: (serviceType: string) => dispatch(coachesDispatcher.actions.setOfferServiceType(serviceType)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 16,
  },
  serviceTypeBtn: {
    width: '194px',
    // height: '206px',
    padding: '22px',
    textAlign: 'left',
    height: '100%',
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    fontWeight: 'bold',
    display: 'block',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#F9B22D',
    },
  },
  serviceTypeBtnLabel: {
    height: '100%',
  },
  activeServiceTypeBtn: {
    borderColor: '#F9B22D',
    position: 'relative',
  },
  tickInCircle: {
    position: 'absolute',
    top: 11,
    right: 14,
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(ChooseServiceType)
