import apiClient from './libs/apiClientOld'
import { prefix_agent } from './api'

export default {
  async getPlaybooks(offset, category) {
    const result = await apiClient.get(`${prefix_agent}playbooks?offset=${offset || 0}&category=${category.toLowerCase() || ''}`)
    return result.data
  },
  async getPlaybook(playbookId) {
    const result = await apiClient.get(`${prefix_agent}playbook/${playbookId}`)
    return result.data
  },
  async getPlaybookInstance(instanceId) {
    const result = await apiClient.get(`${prefix_agent}playbook-instance/${instanceId}`)
    return result.data
  },
  async getPlaybookPrograms(playbookId) {
    const result = await apiClient.get(`${prefix_agent}playbook/${playbookId}/programs`)
    return result.data
  },
  async createPlaybook(playbook) {
    const result = await apiClient.post(`${prefix_agent}playbook`, {
      name: playbook.name,
      description: playbook.description,
      assets_id: playbook.logo,
    })
    return result.data
  },
  async deletePlaybook(playbookId) {
    const result = await apiClient.delete(`${prefix_agent}playbook/${playbookId}`)
    return result.data
  },
  async deletePlaybookProgram(playbookId, programId) {
    const result = await apiClient.delete(`${prefix_agent}playbook/${playbookId}/program`, {
      data: { program_id: programId },
    })
    return result.data
  },
  async deletePlaybookInstance(instance_id) {
    const result = await apiClient.delete(`${prefix_agent}playbook-instance/${instance_id}`)
    return result.data
  },
  async updatePlaybook(playbookId, playbook) {
    const result = await apiClient.post(`${prefix_agent}playbook/${playbookId}`, playbook)
    return result.data
  },
  async updatePlaybookInstance(instanceId, { playbookName, description }) {
    const result = await apiClient.post(`${prefix_agent}playbook-instance/${instanceId}`, {
      name: playbookName,
      description,
    })
    return result.data
  },
  async updatePlaybookInstanceAsset(instanceId, asset_id) {
    const result = await apiClient.post(`${prefix_agent}playbook-instance/${instanceId}/asset`, { asset_id })
    return result.data
  },
  async getPlaybookComments(playbookId) {
    const result = await apiClient.get(`${prefix_agent}playbook/${playbookId}/comments`)
    return result.data
  },
  async createPlaybookInstance(playbook_id, coaches) {
    let coach_id
    if (Array.isArray(coaches)) coach_id = coaches[0]
    coach_id = coaches
    const result = await apiClient.post(`${prefix_agent}playbook-instance`, {
      playbook_id,
      coach_id,
    })
    return result.data
  },
  async addCoachToPlaybookInstance(coach_id, instanceId) {
    const result = await apiClient.post(`${prefix_agent}playbook-instance/${instanceId}/coach`, {
      coach_id,
    })
    return result.data
  },
  async deleteCoachFromPlaybookInstance(coach_id, instanceId) {
    const result = await apiClient.delete(`${prefix_agent}playbook-instance/${instanceId}/coach/${coach_id}`)
    return result.data
  },
  async createInviteLink(instanceId) {
    const result = await apiClient.post(`${prefix_agent}playbook-instance/${instanceId}/create-invite`)
    return result.data
  },
  async getInviteLinksById(instanceId) {
    const result = await apiClient.get(`${prefix_agent}playbook-instance/${instanceId}/links`)
    return result.data
  },
  async setStars(playbookId, stars) {
    const result = await apiClient.post(`${prefix_agent}playbook/${playbookId}/stars`, { stars })
    return result.data
  },
}
