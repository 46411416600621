/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Grid } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import React from 'react'

interface Props {
  event: ReactionEvent
  isActive: boolean
}
function EventTypeLabel(props: Props) {
  const { event, isActive } = props
  const { workout_type } = event

  if (workout_type === 'live_streaming') {
    return (
      <div
        css={[
          css`
            display: inline-block;
            border: 1px solid #fa394e;
            border-radius: 4px;
            padding: 0 4px;
            line-height: 20px;

            svg,
            .MuiTypography-root {
              color: #fa394e;
            }
          `,
          isActive &&
            css`
              border-color: #3ad520;

              svg,
              .MuiTypography-root {
                color: #3ad520;
              }
            `,
        ]}
      >
        <Grid container alignItems="center">
          <Typography
            fontWeight={'bold'}
            css={css`
              font-family: Poppins;
              font-size: 14px;
              line-height: 20px;
              margin-right: 7px;
            `}
          >
            {isActive ? 'Live now' : 'Live'}
          </Typography>

          <FontAwesomeIcon icon={faVideo} />
        </Grid>
      </div>
    )
  }

  if (workout_type === 'on_site') {
    return (
      <div
        css={css`
          display: inline-block;
          border: 1px solid #000;
          border-radius: 4px;
          padding: 0 4px;
          line-height: 20px;

          svg {
            color: #000;
          }
        `}
      >
        <Grid container alignItems="center">
          <Typography
            fontWeight={'bold'}
            css={css`
              font-family: Poppins;
              color: #000 !important;
              font-size: 14px;
              line-height: 20px;
              margin-right: 7px;
            `}
          >
            On-site
          </Typography>
        </Grid>
      </div>
    )
  }

  return null
}

export default EventTypeLabel
