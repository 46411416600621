/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Paper } from '@mui/material'
import React, { ReactElement, ReactNode } from 'react'

interface Props {
  isVisible: boolean
  onClose(): any
  children: ReactNode
  className?: string
  showCloseButton?: boolean
}
function ModalStyled(props: Props): ReactElement {
  const { isVisible, onClose, children, className, showCloseButton } = props

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Paper
        css={css`
          position: relative;
          min-width: 100px;
          max-width: 90vw;
          max-height: 96vh;
          display: flex;
          flex-direction: column;
        `}
        className={className}
      >
        {showCloseButton && (
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
            `}
          >
            <FontAwesomeIcon
              icon={faXmark}
              css={css`
                padding: 5px;
                font-size: 20px;
                margin-right: 5px;
                cursor: pointer;
              `}
              color={'#444444'}
              onClick={onClose}
            />
          </div>
        )}

        <div
          css={[
            css`
              flex-grow: 1;
              overflow-y: auto;
              padding: 0 20px 20px;
            `,
            showCloseButton &&
              css`
                margin-top: 30px;
              `,
          ]}
        >
          {children}
        </div>
      </Paper>
    </Modal>
  )
}

export default ModalStyled
