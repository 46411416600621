/* eslint-disable jsx-a11y/aria-role */
import { Grid, Input, InputAdornment, MenuItem, Typography, Box } from '@mui/material'
import Search from '@mui/icons-material/Search'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import CompanyDispatcher from '@reducers/company/dispatcher'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import CN from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { compose } from 'redux'
import PaperContainer from '../components/PaperContainer'
import ReactionLoader from '../components/ReactionLoader'
import capitalize from '../helpers/capitalize'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import PlusIcon from './chats/assets/plus.svg'
import ChatsList from './chats/components/ChatsList'
import CoachesListDispatcher from '@reducers/coachesList/dispatcher'

const types = ['coaches', 'groups', 'employees']

type PathParams = {
  type: string
}
type Props = ExtractConnectType<typeof connectStore> & WithStyles<typeof styles> & RouteComponentProps<PathParams>
const Chats: React.FC<Props> = ({
  isAdmin,
  classes,
  history,
  coaches,
  employees,
  getCoaches,
  getEmployees,
  showModal,
  getUser,
  match: {
    params: { type },
  },
  chat: { rooms, users },
}) => {
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    if (isAdmin) {
      window.alert('Chat is not available from admin panel.')
      history.goBack()
    }
  }, [])

  const handleCreate = () => {
    const roomsArr = Object.values(rooms)
    const allUsers = type === 'coaches' ? coaches : employees

    const filteredUsers = allUsers.filter(user => !roomsArr.find(({ userId }) => userId === user.chat_user_id))

    showModal({
      modalType: MODAL_TYPES.CREATE_CHAT,
      modalProps: { users: filteredUsers },
    })
  }

  React.useEffect(() => {
    !coaches && getCoaches()
    !employees && getEmployees()
  }, [])

  React.useEffect(() => {
    Object.values(rooms).forEach(({ isDirect, userId }) => {
      if (isDirect && !users[userId]) {
        getUser(userId)
      }
    })
  }, [rooms])

  const isLoading = !coaches || !employees || !users || !rooms
  return (
    <PaperContainer>
      <Grid>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h1'} className={classes.caption}>
            Chats
          </Typography>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={'Search'}
            className={classes.inputSearch}
            disableUnderline
            endAdornment={
              <InputAdornment position="end">
                <Search style={{ color: '#F9B22D' }} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid container className={classes.menuContainer}>
          {types.map(typeName => (
            <MenuItem
              button={false}
              onClick={() => history.push(`/chats/${typeName}`)}
              className={CN(classes.menuItem, { [classes.selected]: typeName === type })}
            >
              <Typography className={CN(classes.menuText, { [classes.textSelected]: typeName === type })}>
                {capitalize(typeName)}
              </Typography>
            </MenuItem>
          ))}
          {!isLoading && type === 'employees' && (
            <img alt="" src={PlusIcon} style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={handleCreate} />
          )}
        </Grid>
        <Grid>
          {isLoading ? (
            <Box style={{ margin: '10px auto -40px auto' }}>
              <ReactionLoader />
            </Box>
          ) : (
            <Switch>
              <Route path={'/chats/groups'} component={() => <ChatsList type="group" search={search} />} />
              <Route
                path={'/chats/coaches'}
                component={() => <ChatsList type="direct" role="coach" search={search} сhatsFor={coaches} />}
              />
              <Route
                path={'/chats/employees'}
                component={() => <ChatsList type="direct" role="employee" search={search} />}
              />
            </Switch>
          )}
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
  isAdmin: state.auth.isAdmin,
  coaches: state.coachesList,
  employees: state.company.employees,
})
const mapDispatchToProps = dispatch => ({
  showModal: param => dispatch(modalActions.showModal(param)),
  getCoaches: () => dispatch(CoachesListDispatcher.getCoaches()),
  getEmployees: () => dispatch(CompanyDispatcher.getEmployees()),
  showModal: param => dispatch(modalActions.showModal(param)),
  getUser: userId => dispatch(ChatDispatcher.getUser({ userId }, { isForce: false })),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  caption: {
    fontSize: 24,
    fontWeight: 800,
    color: '#332233',
  },
  menuContainer: {
    marginTop: 35,
    borderBottom: '1px solid #D4D4D4',
  },
  menuItem: {
    padding: 0,
    marginRight: 55,
    paddingRight: 50,
    paddingBottom: 15,
    outline: 'none',
    cursor: 'pointer',
  },
  selected: {
    borderBottom: '3px solid #F9B22D',
  },
  menuText: {
    fontSize: 18,
  },
  textSelected: {
    fontWeight: 800,
  },
  inputSearch: {
    width: 252,
    height: 55,
    borderRadius: 6,
    border: '1px solid #D4D4D4',
    padding: '0 20px',
    '&::before': {
      borderBottom: 0,
    },
  },
})

export default compose(withStyles(styles), withRouter, connectStore)(Chats)
