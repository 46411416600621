/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import UserAvatar from '../../../components/UserAvatar'
import { secondsToTime } from '../../../helpers/utils'
import useFormattedNumber from '../../dashboard/hooks/useFormattedNumber'

interface Props {
  name: string
  team: string
  valueAvg: number
  valueTotal: number
  rank: number
  avatar_url: string
  history?: boolean
  unitType: 'time' | 'integer'
}

function UserLeaderboardItemNew(props: Props) {
  const { name, team, valueAvg, valueTotal, avatar_url, rank, history, unitType } = props
  const isTimeUnit = useMemo(() => unitType === 'time', [unitType])

  const rankColor = useMemo(() => {
    if (rank === 1) return '#F5962C'
    if (rank === 2) return '#D9D9D9'
    if (rank === 3) return '#863C3E'

    return null
  }, [rank])

  const averageValue = useFormattedNumber(valueAvg)
  const totalValue = useFormattedNumber(valueTotal)

  return (
    <div
      css={css`
        background-color: ${history ? '#fff' : '#f4f4f4'};
        border-radius: 5px;
        padding: 12px 15px 12px 20px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
      `}
    >
      <Grid container alignItems={'center'}>
        {rankColor && (
          <div
            style={{ backgroundColor: rankColor }}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 10px;
            `}
          />
        )}
        <div
          css={css`
            margin-right: 10px;
            font-family: 'Mulish';
          `}
        >
          <UserAvatar user={{ avatar_url }} size={40} />
        </div>

        <Grid
          item
          flexGrow={1}
          css={css`
            width: 1px;
          `}
        >
          <Typography
            fontWeight="bold"
            fontSize={14}
            css={css`
              font-family: 'Mulish';
            `}
          >
            {name}
          </Typography>
          <Typography
            fontSize={11}
            css={css`
              font-family: 'Mulish';
            `}
          >
            {team}
          </Typography>
        </Grid>

        <Grid item textAlign="center">
          <Typography variant="caption" fontWeight="bold" fontSize={14}>
            {isTimeUnit ? secondsToTime(valueAvg) : averageValue}
          </Typography>
          <Typography
            fontSize={11}
            css={css`
              font-family: 'Mulish';
            `}
          >
            Average
          </Typography>
        </Grid>
        <Grid item ml={4} textAlign="center">
          <Typography variant="caption" fontWeight="bold" fontSize={14}>
            {isTimeUnit ? secondsToTime(valueTotal) : totalValue}
          </Typography>
          <Typography
            fontSize={11}
            css={css`
              font-family: 'Mulish';
            `}
          >
            Total
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default UserLeaderboardItemNew
