import apiClient from '../../libs/api/libs/apiClient'

export default {
  async getCategories(): Promise<PlaybookCategory[]> {
    const result = await apiClient.get('/playbook-categories')
    return result.data
  },
  async getTypes(category_id: string): Promise<PlaybookType[]> {
    const result = await apiClient.get(`/playbook-category/${category_id}/types`)
    return result.data
  },
}
