/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import CoachesListDispatcher from '@reducers/coachesList/dispatcher'
import { ReduxState } from '@reducers/index'
import ListsDispatcher from '@reducers/lists/dispatcher'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import _ from 'lodash'
import * as React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import InfoLine from '../champions/InfoLine'
import Row from './Row'

const sizes = [3, 7, 2]

const Champions = ({
  isChooseMode,
  addCoach,
  history,
  match: {
    params: { groupId },
  },
}) => {
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchCoaches = useAsyncFetch(async () => {
    await dispatch(CoachesListDispatcher.getCoaches())
    await dispatch(ListsDispatcher.getGroupsCoaches())
  })

  const { coaches } = useSelector((state: ReduxState) => ({
    coaches: state.coachesList,
  }))

  const chooseCoach = async (coachId: string) => {
    await addCoach(groupId, coachId)
    history.push(`/groups/${groupId}`)
  }

  return (
    <div>
      <Row
        sizes={sizes}
        css={css`
          background: #323232;
          width: 100%;
          margin-bottom: 10px;
        `}
        data={['Name', 'Groups', 'Number of members'].map((name) => (
          <Typography
            css={css`
              font-size: 14px;
              font-weight: 800;
              color: #fff;
              border: 1px solid #323232;
            `}
          >
            {name}
          </Typography>
        ))}
      />

      <RendererStatusSplit
        statuses={fetchCoaches}
        isEmpty={_.isEmpty(coaches)}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderPreview={() => null}
        renderEmpty={() => <Typography textAlign={'center'}>There is no coaches yet.</Typography>}
        renderError={(error) => (
          <Typography color={'error'} textAlign={'center'}>
            {error}
          </Typography>
        )}
        render={() => {
          return (
            <Grid container direction={'column'}>
              {coaches.map((c) => (
                <InfoLine
                  data={c}
                  sizes={sizes}
                  isChooseMode={isChooseMode}
                  chooseCoach={chooseCoach}
                  key={`coach-champ-${c.user_id}`}
                />
              ))}
            </Grid>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  addCoach: (groupId, coachId) => dispatch(PlaybookInstancesDispatcher.addCoach(groupId, coachId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default connectStore(Champions)
