import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  max-width: 155px;
  border: 0.50px #C4C4C4 solid;
`

const Img = styled.img`
  width: 155px;
  height: 88px;
  display: block;
`

const Span = styled.span`
  padding: 8px 10px;
  color: black;
  font-size: 12px;
  font-family: Manrope;
  font-weight: 600;
  word-wrap: break-word
`

const RewardCard = ({ reward }) => {

  return (
    <Container>
      <Img alt="thumbnail" src={reward.data.gift_card.assets.card_image_url} />
      <Span>${reward.start_price}&nbsp;{reward.data.name}</Span>
    </Container>

  )
}

export default RewardCard