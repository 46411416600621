import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import ReactionLoader from '../../components/ReactionLoader'
import ButtonOrangeSquare from './ButtonOrangeSquare'

interface Props {
  title: string
  onSubmit: () => Promise<any>
}

function SubmitButton(props: Props) {
  const { title, onSubmit } = props
  const actionSubmit = useAsyncHandler(async () => {
    await onSubmit()
  })

  return (
    <Box marginTop={'20px'}>
      <Grid container justifyContent={'center'}>
        <RendererStatusSplit
          statuses={actionSubmit}
          renderLoading={() => <ReactionLoader />}
          render={() => (
            <Grid container direction={'column'} alignItems={'flex-end'}>
              <Box marginBottom={'5px'}>
                <Typography color={'error'}>{actionSubmit.error}</Typography>
              </Box>
              <div>
                <ButtonOrangeSquare onClick={actionSubmit.execute}>{title}</ButtonOrangeSquare>
              </div>
            </Grid>
          )}
        />
      </Grid>
    </Box>
  )
}

export default SubmitButton
