/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TabList } from '@mui/lab'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  onChange: (event: React.SyntheticEvent, newValue: string) => any
}

function WhiteTabs(props: Props) {
  const { onChange, children } = props

  return (
    <div
      css={css`
        background-color: #edf1fb;
        border-radius: 18px;

        .MuiTabs-root,
        .MuiTabs-scroller {
          overflow: visible !important;
        }

        .MuiTypography-root,
        .MuiTab-root {
          font-family: Poppins;
          font-size: 14px;
          color: #717579;
          text-transform: none;
          position: relative;
          z-index: 5;
        }

        .Mui-selected {
          color: #303134;
        }

        .MuiTabs-indicator {
          z-index: 2;
          // height: 60px;
          height: auto;
          bottom: -1px;
          top: -1px;
          background-color: #fff;
          border-radius: 16px;
          box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
        }

        .MuiButtonBase-root.MuiTab-root {
          padding: 3px 7px !important;
          min-width: 50px;
        }
      `}
    >
      <TabList onChange={onChange} disableRipple>
        {children}
      </TabList>
    </div>
  )
}

export default WhiteTabs
