/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ReduxStateGroupsItem } from '@reducers/playbookInstances/reducer'
import React from 'react'

interface CalendarEventResources {
  description: string
  instance: ReduxStateGroupsItem | null | any
}

function CalendarDateCellWrapper(props) {
  const { children, value, range } = props
  const { className = '' } = children.props

  const isToday = className.includes('rbc-today')
  const isOffRange = className.includes('rbc-off-range-bg')

  return (
    <div
      css={[
        css`
          border-left: 1px solid #eeeeee;
          flex: 1 1;
        `,
        isOffRange &&
          css`
            background-color: #f6f6f6;
          `,
      ]}
    >
      {children}
    </div>
  )
}

export default CalendarDateCellWrapper
