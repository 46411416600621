/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { ICompanyTeam } from '@reducers/teams/types'

const cssLinkOpen = css`
  background-color: #d9d9d9;
  padding: 8px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`

interface Props {
  team: ICompanyTeam
}
function CompanyTeamItem(props: Props) {
  const { team } = props
  const { id, name, asset, team_size } = team

  return (
    <Link to={`/team/${id}`} css={cssLinkOpen}>
      <Typography fontSize={21}>{name}</Typography>
      <Typography fontSize={14} ml={1} fontWeight="bold">
        ({team_size})
      </Typography>
    </Link>
  )
}

export default CompanyTeamItem
