/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TabContext } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import { ReduxStateGroupsItem } from '@reducers/playbookInstances/reducer'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import React, { useMemo, useState } from 'react'
import { CalendarProps, View } from 'react-big-calendar'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import UpgradePlan from '../../components/UpgradePlan'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import backgroundImage from './assets/background.png'
import Agenda from './events/agenda/Agenda'
import EventsCalendar from './events/calendar/EventsCalendar'
import Discover from './events/discover/Discover'

const useStyles = makeStyles(
  {
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 0,
    },
  },
  {
    classNamePrefix: 'Events',
  },
)

export interface CalendarEvent {
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: CalendarEventResources
}

interface CalendarEventResources {
  description: string
  instance: ReduxStateGroupsItem | null | any
}

function Events() {
  const classes = useStyles()
  const [tab, setTab] = React.useState<View>('discover')
  const [calendarMoment, setCalendarMoment] = useState<Moment>(moment())
  // const [selectedEvent, setSelectedEvent] = useState<ReactionEvent | null>(null)

  const { groups, events, company } = useSelector((state: ReduxState) => ({
    events: state.events,
    company: state.company,
    groups: state.playbookInstances,
  }))

  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  const dispatch = useDispatch<ReduxDispatch>()

  const history = useHistory()
  const handleOpenEvent = (event: ReactionEvent) => {
    if (!isFreeTrial) {
      history.push(`/event/${event.id}`)
    }
  }

  const eventPublicFetch = useAsyncFetch(async () => {
    await dispatch(EventDispatcher.getPublicEvents())
  })

  const eventGroupFetch = useAsyncHandler(async (group_id: string | undefined) => {
    /** @note there is also dynamic fetch within calendar */
    await dispatch(
      EventDispatcher.getEvents({
        dateStart: moment().add(-5, 'hours').toISOString(),
      }),
    )
  })

  const fetchAction = useAsyncFetch(async () => {
    await eventGroupFetch.execute(undefined)
    dispatch(PlaybookInstancesDispatcher.getPlaybookInstances())
  })

  const handleCalendarNavigate: CalendarProps['onNavigate'] = (date, view, action) => {
    setCalendarMoment(moment(date))
  }

  const eventsFormatted = useMemo(() => {
    const eventArray = Object.values(events)
    return eventArray.map((event) => {
      const group = groups[event.group_id]

      return {
        ...event,
        group: group ? group.asMutable() : null,
      }
    })
  }, [events, groups])

  return (
    <PaperContainer
      css={css`
        padding: 20px 40px 40px;
        overflow: hidden;

        @media (max-width: 520px) {
          padding: 10px 10px 20px;
        }
      `}
    >
      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={_.isEmpty(eventsFormatted)}
        renderLoading={() => <ReactionLoader />}
        render={() => (
          <TabContext value={tab}>
            <React.Fragment>
              {tab === 'discover' ? (
                <Discover onTabChange={setTab} />
              ) : tab === 'agenda' ? (
                <Agenda events={eventsFormatted} onTabChange={setTab} onDateNavigate={handleCalendarNavigate as any} />
              ) : (
                <EventsCalendar
                  tab={tab}
                  events={eventsFormatted}
                  onSelectEvent={handleOpenEvent}
                  onTabChange={setTab}
                />
              )}
            </React.Fragment>
          </TabContext>
        )}
      />
    </PaperContainer>
  )
}

export default Events
