import { CompanyStock, CompanyStockFull, CompanyStockOrder } from '@reaction-club-types/core'
import types from './types'

export default {
  setStocksList: (stocks: CompanyStock[]): ReduxAction => ({
    type: types.SET_LIST,
    payload: stocks,
  }),
  setStockListItem: (stock: CompanyStockFull): ReduxAction => ({
    type: types.SET_LIST_ITEM,
    payload: stock,
  }),
  setStocksOrders: (orders: CompanyStockOrder[]): ReduxAction => ({
    type: types.SET_ORDERS,
    payload: orders,
  }),
  deleteStock: (stock_id: string): ReduxAction => ({
    type: types.DELETE_ITEM,
    payload: stock_id,
  }),
}
