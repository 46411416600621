import axios from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const COUNTRY_DEFAULT = 'IL'

function useCountryCode(phone_number?: string): [string, Dispatch<SetStateAction<string>>] {
  const [countryCode, setCountryCode] = useState(COUNTRY_DEFAULT)

  async function getCountryCode() {
    const userInfo = await axios.get(`https://ipwhois.app/json/`)

    const { country_code } = userInfo?.data || {}
    if (country_code) {
      setCountryCode(country_code.toUpperCase())
    }
  }

  useEffect(() => {
    if (!phone_number) {
      getCountryCode()
    }
  }, [])

  return [countryCode, setCountryCode]
}

export default useCountryCode
