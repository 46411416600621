import { Typography, Grid, Box } from '@mui/material'
import { ReduxState } from '@reducers/index'
import OfficeDispatcher from '@reducers/offices/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import PlusButton from '../../../../components/buttons/PlusButton'
import useModalState from '../../../../hooks/useModalState'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import SectionContainer from '../../components/SectionContainer'
import OfficeCreateModal from './OfficeCreateModal'
import CompanyOfficeItem from './components/CompanyOfficeItem'
import Help from '../../../../components/Help'
import { Manrope } from '../../../../helpers/commonStyles'

function CompanyOffices() {
  const dispatch = useDispatch<ReduxDispatch>()
  const modalCreate = useModalState()
  useAsyncFetch(
    async () => {
      await dispatch(OfficeDispatcher.getList({ isForce: false }))
    },
    { maxTries: 3 },
  )
  const { offices, company } = useSelector((state: ReduxState) => ({
    offices: state.lists.offices,
    company: state.company,
  }))
  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  return (
    <SectionContainer>
      <Typography variant={'h2'} fontSize={21} fontFamily={Manrope}>
        Offices{' '}
        <Help
          title="Company offices"
          subtitle="Create location-specific events"
          text="When you set up the offices, employees will see a pop-up on the app asking them to choose which office or offices they belong to. They can select one or multiple offices based on their work location or preferences. Then, you can create an event just for a specific office and only employees belonging to that office (or offices) will have access to it."
        />
      </Typography>

      {isFreeTrial ? (
        <Typography mb={4}>Only for premium accounts.</Typography>
      ) : (
        <>
          <Box marginBottom={'10px'}>
            <Grid container>
              {_.map(offices, (item) => (
                <CompanyOfficeItem office={item} key={`settings-office-${item.id}`} />
              ))}
            </Grid>
          </Box>

          <OfficeCreateModal {...modalCreate} />
        </>
      )}

      <div>
        <PlusButton onClick={modalCreate.onOpen} title="Add office" lock={isFreeTrial} />
      </div>
    </SectionContainer>
  )
}

export default CompanyOffices
