/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Paper } from '@mui/material'
import React, { ReactElement } from 'react'
import Header from './Header'

interface Props {
  children: ReactElement | ReactElement[]
  className?: string
}
function Container(props: Props) {
  const { className, children } = props

  return (
    <Grid container justifyContent="center">
      <Header />

      <Grid xs={12} item>
        <Paper
          css={css`
            padding: 20px 30px 30px;
            box-shadow: 0px 2px 4px rgba(156, 156, 156, 0.25);
            border-radius: 5px;

            @media (max-width: 520px) {
              padding: 20px 10px 20px;
            }
          `}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Container
