/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { CompanyStock } from '@reaction-club-types/core'
import { ApiPayloadStockCreate } from '@reducers/stocks/api'
import StockDispatcher from '@reducers/stocks/dispatcher'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Immutable } from 'seamless-immutable'
import ModalStyled from '../../../elements/ModalStyled'
import StockForm from '../stockCreate/StockForm'

interface Props {
  stock: CompanyStock | Immutable<CompanyStock>
  isVisible: boolean
  onClose(): any
}

function StockEditModal(props: Props) {
  const { stock, isVisible, onClose } = props
  const dispatch = useDispatch()
  if (!stock) return null

  const handleSaveClick = async (payload: ApiPayloadStockCreate) => {
    await dispatch<any>(StockDispatcher.updateStock(stock.id, payload))
    onClose()
  }

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      css={css({
        minWidth: 400,
        maxWidth: 600,
        paddingTop: 20,
      })}
    >
      {/* <Typography variant={'h1'}>Edit Reward</Typography> */}
      <Grid item lg={12} md={12} sm={12}>
        <StockForm stock={stock} onExecute={handleSaveClick} mode={'update'} />
      </Grid>
    </ModalStyled>
  )
}

export default StockEditModal
