import { Typography } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import moment from 'moment'
import React, { useMemo } from 'react'

interface Props {
  event: ReactionEvent
  className?: string
}

function EventTime(props: Props) {
  const { event, className } = props
  const { time, repeat } = event

  const timeTxt = useMemo(() => {
    let timeFormatted = moment(time).format('dddd, MMM DD, HH:mm')
    if (repeat === 'week') timeFormatted += ' (repeat weekly)'
    if (repeat === 'month') timeFormatted += ' (repeat monthly)'

    return timeFormatted
  }, [time, repeat])

  return <Typography className={className}>{timeTxt}</Typography>
}

export default EventTime
