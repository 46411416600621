import { Grid, Typography } from '@mui/material'
import categoryDispatcher from '@reducers/categories/dispatcher'
import React from 'react'
import { connect } from 'react-redux'
import capitalize from '../../../helpers/capitalize'
import { fitnessIcon, mindfulnessIcon, nutritionIcon } from '../../groups/assets/facade'

const categoriesNames = ['fitness', 'nutrition', 'mindfulness']

const categoryIcon = {
  fitness: fitnessIcon,
  mindfulness: mindfulnessIcon,
  nutrition: nutritionIcon,
}

const Categories = ({ categories, types, withIcon = true, categoriesObj, getTypes }) => {
  React.useEffect(() => {
    categoriesNames.forEach(n => !categoriesObj[n] && getTypes(n))
  }, [])

  return (
    <Grid item container>
      {categories?.map(c => {
        const key = c.trim().toLowerCase()

        return (
          <Grid item container style={{ marginBottom: 10 }}>
            {withIcon && <img src={categoryIcon[key]} alt="" style={{ width: 20, marginRight: 20 }} />}
            <Typography style={{ fontSize: 13 }}>
              <b>{capitalize(key)}</b>{' '}
              {categoriesObj[key]?.types
                .filter(({ id }) => types.find(t => t === id))
                .map(({ name }) => capitalize(name))
                .join(', ')}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

const mapStateToProps = state => ({
  categoriesObj: state.categories,
})
const mapDispatchToProps = dispatch => ({
  getTypes: (category: string) => dispatch(categoryDispatcher.getCategoryTypes(category)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default connectStore(Categories)
