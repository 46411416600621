import { ReduxState } from '@reducers/index'
import { useSelector } from 'react-redux'

function useAuthToken() {
  const { token } = useSelector((state: ReduxState) => state.auth)

  return token
}

export default useAuthToken
