/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { ReactionEvent } from '@reaction-club-types/core'
import { APIEventCreatePayload } from '@reducers/events/api'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import React from 'react'
import validate from 'validate.js'
import SubmitButton from '../../../modals/components/SubmitButton'
import EventCoachSelect from './components/EventCoachSelect'
import EventCreateFilter from './components/EventCreateFilter'
import EventDateSelect from './components/EventDateSelect'
import EventDurationSelect from './components/EventDurationSelect'
import EventRepeatSelect from './components/EventRepeatSelect'
import EventTimeSelect from './components/EventTimeSelect'
import EventTypeSelect from './components/EventTypeSelect'
import EventFormInput from './components/elements/EventFormInput'

interface Errors {
  name?: string
  description?: string
  coach_id?: string
  duration?: string
  location_formatted?: string
  time?: string
}

interface State {
  name: string
  description: string
  type: ReactionEvent['workout_type']
  coach_id: null | string
  duration: string
  location: string
  repeat?: ReactionEvent['repeat']
  participant_limit?: number
  time: Moment
  group_id: string | null
  offices?: string[]
  errors: Errors
  submitErrorMessage?: string
}

interface Props {
  onCreate(payload: APIEventCreatePayload): Promise<any>
}

interface PropsInner extends Props {}

class EventCreateForm extends React.PureComponent<PropsInner, State> {
  state: State = {
    name: '',
    description: '',
    type: 'on_site',
    coach_id: null,
    duration: '60',
    location: '',
    time: moment(),
    group_id: null,
    errors: {},
    submitErrorMessage: '',
  }

  render() {
    const {
      name,
      description,
      type,
      coach_id,
      duration,
      location,
      time,
      group_id,
      errors,
      repeat,
      participant_limit,
      offices,
    } = this.state

    return (
      <div
        css={css`
          padding-top: 20px;
          padding-right: 200px;

          @media (max-width: 699px) {
            padding-right: 20px;
          }

          @media (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
          }
        `}
      >
        <Grid
          container
          css={css`
            .MuiGrid-item {
              padding-right: 70px;

              @media (max-width: 500px) {
                padding-right: 0px;
              }
            }
          `}
        >
          {/* 1st row */}
          {/* <Grid item sm={12}> */}
          {/*  <Grid container> */}
          <Grid item sm={6} xs={12}>
            <EventTypeSelect value={type} onChange={this.handleTypeChange} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <EventCreateFilter
              group_id={group_id}
              offices={offices}
              onGroupSelect={this.handleGroupChange}
              onOfficeSelect={this.handleOfficesChange}
            />
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}

          {/* 2nd row => */}
          <Grid item sm={6} xs={12}>
            <EventCoachSelect coach_id={coach_id} onChange={this.handleCoachChange} isErrored={!!errors.coach_id} />
          </Grid>
          <Grid item sm={6} xs={12}>
            {type !== 'live_streaming' && (
              <EventFormInput
                value={location}
                label={'Location (optional)'}
                fullWidth
                onChange={this.handleLocationChange}
                error={!!errors.location_formatted}
                placeholder={'I.e. meeting room'}
              />
            )}
          </Grid>

          {/* 3rd row => */}
          <Grid item sm={6} xs={12}>
            <EventFormInput value={name} label={'Name'} onChange={this.handleNameChange} error={!!errors.name} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <EventDateSelect value={time} onChange={this.handleTimeChange} isErrored={!!errors.time} />
          </Grid>

          {/* 4=> */}
          <Grid item sm={6} xs={12}>
            <EventTimeSelect
              value={time}
              // onChange={(timeMoment) => this.setState({ timeHours: timeMoment })}
              onChange={this.handleTimeChange}
              error={errors.time}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <EventDurationSelect value={duration} onChange={this.handleDurationChange} />
          </Grid>

          {/* 5 => */}
          <Grid item sm={12} xs={12}>
            <EventFormInput
              multiline
              value={description}
              label={'Description'}
              onChange={this.handleDescriptionChange}
              error={!!errors.description}
              css={css`
                textarea {
                  min-height: 60px;
                }
              `}
            />
          </Grid>

          {/* 6 => */}
          <Grid item sm={6} xs={12}>
            <EventFormInput
              value={participant_limit}
              label={'Participation limit (optional)'}
              onChange={this.handleParticipantLimitChange}
              type={'number'}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <EventRepeatSelect value={repeat} onChange={this.handleRepeatChange} />
          </Grid>

          <Grid
            container
            justifyContent={'flex-end'}
            css={css`
              padding-right: 70px;

              @media (max-width: 500px) {
                padding-right: 0;
              }
            `}
          >
            <SubmitButton title={'Create event'} onSubmit={this.handleSubmit} />
          </Grid>
        </Grid>
      </div>
    )
  }

  handleNameChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.name
    this.setState({ name: e.target.value, errors })
  }

  handleDescriptionChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.description
    this.setState({ description: e.target.value, errors })
  }

  handleTypeChange = (type: State['type']) => {
    this.setState({ type })
  }

  handleRepeatChange = (repeat: string) => this.setState({ repeat: repeat || null })

  handleLocationChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ location: e.target.value, errors })
  }

  handleParticipantLimitChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ participant_limit: parseInt(e.target.value) || undefined, errors })
  }

  handleCoachChange = (coach_id: string) => {
    const { errors } = this.state
    delete errors.coach_id
    this.setState({ coach_id, errors })
  }

  handleGroupChange = (group_id: string | undefined) => {
    this.setState({ group_id })
  }

  handleOfficesChange = (offices: string[]) => {
    this.setState({ offices })
  }

  handleDurationChange = (duration: string) => {
    this.setState({ duration })
  }

  handleTimeChange = (time: Moment | null) => {
    if (!time) return null
    const { errors } = this.state
    delete errors.time
    this.setState({ time, errors })
  }

  handleSubmit = async () => {
    const {
      type,
      group_id,
      duration,
      coach_id,
      description,
      location,
      name,
      time,
      submitErrorMessage,
      repeat,
      participant_limit,
      offices,
    } = this.state

    if (submitErrorMessage) {
      this.setState({ submitErrorMessage: '' })
    }

    const form: APIEventCreatePayload = {
      workout_type: type,
      group_id,
      duration: Number(duration),
      coach_id,
      description,
      location_formatted: location,
      name,
      time: moment(time).toISOString(),
      repeat,
    }

    if (participant_limit) form.participant_limit = participant_limit
    if (!_.isEmpty(offices)) form.offices = offices

    if (!this.isValid(form)) {
      return null
    }

    await this.props.onCreate(form)
  }

  isValid = (form: APIEventCreatePayload): boolean => {
    const errors: Errors = validate(form, validationSchema) || {}

    const startThresholdMoment = moment().add(-3, 'minutes') // server has 5 minutes
    if (moment(form.time).isBefore(startThresholdMoment)) {
      errors.time = 'You cannot select time, which already passed'
    }

    this.setState({ errors })

    return _.isEmpty(errors)
  }
}

export default EventCreateForm

const validationSchema = {
  name: {
    presence: {
      message: 'Name is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Name at least %{count} letters',
    },
  },
  description: {
    presence: {
      message: 'Description is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Description at least %{count} letters',
    },
  },
}
