import apiClient from '../../libs/api/libs/apiClient'
import { ICompanyTeam } from '@reducers/teams/types'

const apiCompanyTeams = {
  async getList(): Promise<{ teams: ICompanyTeam[] }> {
    const { data } = await apiClient.get('/team/list')
    return data
  },
  async getTeam(id: string): Promise<ICompanyTeam> {
    const { data } = await apiClient.get(`/team/${id}`)
    return data
  },

  async createTeam(params: { name: string; description?: string }): Promise<{ team_id: string }> {
    const { data } = await apiClient.post('/team/create', params)
    return data
  },

  async updateTeam(id: string, params: { name: string }): Promise<any> {
    const { data } = await apiClient.post(`/team/rename/${id}`, params)
    return data
  },

  async deleteTeam(id: string): Promise<{ deleted: number }> {
    const { data } = await apiClient.delete(`/team/${id}`)
    return data
  },

  async moveTeamMembers(id: string, userIds: string[]): Promise<any> {
    const { data } = await apiClient.post(`/team/move/${id}`, { userIds })
    return data
  },

  async removeTeamMembers(userIds: string[]): Promise<any> {
    const { data } = await apiClient.post(`/team/remove`, { userIds })
    return data
  },

  async getTeamPotentialMembers(id: string, params: { name?: string; teamId: string }): Promise<any> {
    const { data } = await apiClient.get(`/team/potential-members/${id}`, { params })
    return data
  },
  async getTeamMembers(id: string): Promise<any> {
    const { data } = await apiClient.get(`/team/members/${id}`)
    return data
  },
}

export default apiCompanyTeams
