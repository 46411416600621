import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import { setItemOrArray } from '../../libs/reducerUtils'
import types, { ICompanyTeam } from './types'

interface State {
  [key: string]: ReduxStateTeamItem
}

const initialState: State = {
  // [id]: { ... }
}

interface ReduxStateTeamItem extends ICompanyTeam, StoreUpdatedObject {}

export default function companyReducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const item: ICompanyTeam = action.payload
      return setItemOrArray(state, item)
    }

    case types.DELETE: {
      const team_id = action.payload
      return state.without(team_id)
    }

    default:
      return state
  }
}
