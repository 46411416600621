/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography, Grid } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import EventPicture from '../../components/EventPicture'
import EventTime from '../elements/EventTime'

interface Props {
  event: ReactionEvent
}

function EventMainInfo(props: Props) {
  const { event } = props
  const { coach, name, location_formatted, duration, coach_id } = event
  const { name: coach_name, avatar_url, chat_user_id } = coach || {}

  const dispatch = useDispatch<ReduxDispatch>()
  const handlePress = (e) => {
    dispatch(ChatDispatcher.actions.createMockDirectRoom('mock-room-id', chat_user_id))
  }

  return (
    <Grid container alignItems={'center'}>
      <div
        css={css`
          padding-right: 20px;
        `}
      >
        <EventPicture event={event} css={css({ width: 80, height: 80 })} />
      </div>

      {/* {coach && ( */}
      {/*    <Link to={`/chats/direct/mock-room-id`} style={{ textDecoration: 'none' }} onClick={handlePress}> */}
      {/*      <ReactionAvatar asset={{ url: avatar_url }} style={{ width: 80, height: 80 }} /> */}
      {/*    </Link> */}
      {/* )} */}
      <div
        css={css`
          .MuiTypography-root {
            line-height: 26px;
          }
        `}
      >
        <Typography
          variant={'h2'}
          css={css`
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
          `}
        >
          {name}
          {location_formatted && (
            <span
              css={css`
                display: inline-block;
                margin-left: 10px;
                font-weight: normal;
              `}
            >
              ({location_formatted})
            </span>
          )}
        </Typography>
        {coach_id && (
          <Typography>
            with <b>{coach_name}</b>
          </Typography>
        )}
        <EventTime event={event} />
        <Typography>Duration: {duration} minutes</Typography>
      </div>
    </Grid>
  )
}

export default EventMainInfo
