import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Playbook } from '@reaction-club-types/core'
import React from 'react'
import PriceAndStars from '../../../../components/PriceAndStars'
import Asset from '../../../../components/assets/Asset'

const useStyles = makeStyles(
  {
    playbookContainer: {
      margin: '20px 0',
      padding: '0 28px',
      cursor: 'pointer',
      minWidth: 200,
      width: '33%',
      maxWidth: 324,
    },
    asset: {
      borderRadius: 5,
      height: 147,
    },
    playbookName: {
      fontSize: 18,
      fontWeight: 800,
      margin: '10px 0',
    },
    playbookDescription: {
      fontSize: 14,
      height: 40,
      overflow: 'hidden',
      minWidth: '100%',
    },
  },
  { classNamePrefix: 'PlaybookCard' },
)

const cropDescription = (description?: string): string => {
  if (!description) return ''
  if (description.length < 50) return description
  return `${description.substring(0, 50)}...`
}

interface Props {
  playbook: Playbook
  onClick(id: string): any
}

function PlaybookCard(props: Props) {
  const { playbook, onClick } = props
  const classes = useStyles()

  const { id, name, asset, thumb, description } = playbook

  const handleClick = () => {
    onClick(id)
  }

  const descriptionFormatted = cropDescription(description)

  return (
    <Grid item className={classes.playbookContainer} onClick={handleClick}>
      <Grid container>
        <Asset asset={asset || { url: thumb }} resizeMode={'cover'} className={classes.asset} />
        <Typography className={classes.playbookName}>{name}</Typography>
        <Typography className={classes.playbookDescription}>{descriptionFormatted}</Typography>

        <PriceAndStars playbook={playbook} />
      </Grid>
    </Grid>
  )
}

export default PlaybookCard
