import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import ReactionAvatar from './assets/ReactionAvatar'
import { arrowBackIcon } from './paperContainer/assets/facade'

const styles = {
  profileContainer: {
    margin: '40px 50px 15px 50px',
  },
  image: {
    width: 7,
    height: 13,
    marginRight: 15,
  },
  backText: {
    fontSize: 14,
    color: '#000',
    lineHeight: '100%',
  },
  backContainer: {
    cursor: 'pointer',
  },
}

function ProfileHeader(
  props: ExtractConnectType<typeof connectStore> & WithStyles<typeof styles> & RouteComponentProps,
) {
  const { classes, me, history, goBack } = props
  const { avatar_url } = me || {}

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={classes.profileContainer}
    >
      <Grid item>
        <Grid
          container
          direction={'row'}
          alignItems={'center'}
          className={classes.backContainer}
          onClick={goBack || history.goBack}
        >
          <img src={arrowBackIcon} alt="back icon" className={classes.image} />
          <Typography className={classes.backText}>Back</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <Typography style={{ color: '#252733', fontSize: 14, fontWeight: '600', marginRight: 10 }}>
            {me.name}
          </Typography>
          {avatar_url && <ReactionAvatar resizeMode={'contain'} asset={{ url: me.avatar_url }} />}
        </Grid>
      </Grid>
    </Grid>
  )
}

const connectStore = connect((state: ReduxState) => ({
  me: state.me,
}))

export default compose(withStyles(styles), connectStore, withRouter)(ProfileHeader) as any
