import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import AuthActionDispatcher from '@reducers/auth/dispatcher'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import MeDispatcher from '@reducers/me/dispatcher'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import config from '../config'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractConnectType } from '../typings/ReduxExtractor'

interface Props {
  children: ReactNode
}

interface State {
  isLoading: boolean
  isErrored: boolean
  firstGroupModalShown: boolean
  createCompanyModalShown: boolean
}

class SessionGuard extends React.PureComponent<
  Props & RouteComponentProps<{}> & ExtractConnectType<typeof connectStore>,
  State
> {
  state = {
    isLoading: false,
    isErrored: false,
    firstGroupModalShown: false,
    createCompanyModalShown: false,
  }

  render() {
    const { token, children } = this.props

    if (!token) {
      return null
    }

    return children
  }

  handleCreateSession = async () => {
    const { getMySelf, getCompany, getGroups } = this.props

    this.setState({ isLoading: true, isErrored: false })
    try {
      await Promise.all([getMySelf(), getCompany(), getGroups()])
    } catch (e) {
      this.setState({ isErrored: true })
    }

    this.setState({ isLoading: false })
  }

  componentDidMount(): void {
    const { token, chatToken, logout, chatUser, initChat, isAdmin } = this.props

    if (token) {
      this.handleCreateSession()
      if ((!chatToken || !chatUser) && !isAdmin) {
        console.error('tokens are not valid. Logout.')
        if (!config.chat.isLogOutDisabled) {
          logout()
        }
      } else {
        initChat({ chatToken, chatUser })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { token } = this.props
    const { token: tokenPrev } = prevProps

    // const groupIds = Object.keys(groups)
    // if (!firstGroupModalShown && !isLoading && !isErrored && me.company_id) {
    //   if (groupIds.length === 0) {
    //     showModal({ modalType: MODAL_TYPES.CREAT_FIRST_GROUP })
    //   }
    //   this.setState({ firstGroupModalShown: true })
    // }

    if (!token || token === tokenPrev) return null

    this.handleCreateSession()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    token: state.auth.token,
    isAdmin: state.auth.isAdmin,
    chatToken: state.auth.chatToken,
    chatUser: state.auth.chatUser,
  }),
  (dispatch: ReduxDispatch) => ({
    getMySelf: () => dispatch(MeDispatcher.getMySelf()),
    initChat: ({ chatUser, chatToken }) =>
      dispatch(
        ChatDispatcher.init({
          verbose: config.env !== 'production',
          userId: chatUser,
          token: chatToken,
          url: config.chat_api_url,
        }),
      ),
    getCompany: () => dispatch(CompanyDispatcher.getCompany()),
    getGroups: () => dispatch(PlaybookInstancesDispatcher.getPlaybookInstances()),
    logout: () => dispatch(AuthActionDispatcher.logout()),
  }),
)
export default withRouter(connectStore(SessionGuard))
