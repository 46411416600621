import React from 'react'
import SelectAssetOption from '../../../../../elements/select/SelectAssetOption'

interface Props {
  item: PlaybookInstance
  innerProps: any
}

export default function EventGroupSelectOption(props: Props) {
  const { item, innerProps } = props

  // const options: SelectProps['options'] = [{ value: '123', label: 'Coach1' }]
  return <SelectAssetOption item={item} innerProps={innerProps} />
}
