import apiClient from '../../libs/api/libs/apiClient'
import apiClientAssets from '../../libs/api/libs/apiClientAssets'
import apiClientOld from '../../libs/api/libs/apiClientOld'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import appActions from '../app/actions'
import CompanyDispatcher from '../company/dispatcher'
import MeDispatcher from '../me/dispatcher'
import actions from './actions'
import apiAuth from './apiAuth'

class Dispatcher {
  login(username: string, password: string): DispatcherResponse {
    return async (dispatch) => {
      const data = await apiAuth.login(username, password)
      dispatch(this.logout())
      await dispatch(this.setToken(data))
    }
  }

  setToken(authData): DispatcherResponse {
    return async (dispatch) => {
      await dispatch(actions.setToken(authData))
      await dispatch(this.useCredentials())
    }
  }

  useCredentials(): DispatcherResponse {
    return async (dispatch, getState) => {
      console.log('useCredentials called')
      let { token } = getState().auth

      apiClient.defaults.headers.common['Authorization'] = token
      apiClientAssets.defaults.headers.common['Authorization'] = token
      apiClientOld.defaults.headers.common['Authorization'] = token
    }
  }

  loginWithFirebaseToken(firebaseToken: string): DispatcherResponse {
    console.log('loginWithFirebaseToken', firebaseToken)
    return async (dispatch) => {
      const data = await apiAuth.loginWithFirebaseToken(firebaseToken)
      console.log('loginWithFirebaseToken res', data)

      await dispatch(this.setToken(data))

      await dispatch(MeDispatcher.getMySelf())
      await dispatch(CompanyDispatcher.getCompany())
      return data
    }
  }

  loginWithEmailAndPassword(email: string, password: string): DispatcherResponse {
    return async (dispatch) => {
      const data = await apiAuth.loginWithEmailAndPassword(email, password)
      console.log('loginWithEmailAndPassword res', data)

      await dispatch(this.setToken(data))

      await dispatch(MeDispatcher.getMySelf())
      await dispatch(CompanyDispatcher.getCompany())
      return data
    }
  }

  sendSms(phone: string): DispatcherResponse {
    return async () => {
      await apiAuth.sendSms(phone)
    }
  }

  loginWithPhoneAndCode(phone: string, code: string): DispatcherResponse {
    return async (dispatch) => {
      const data = await apiAuth.phoneAuthorize(phone, code)
      console.log('loginWithPhoneAndCode res', data)

      await dispatch(this.setToken(data))

      await dispatch(MeDispatcher.getMySelf())
      await dispatch(CompanyDispatcher.getCompany())
      return data
    }
  }

  signupWithEmailAndPassword({ email, password }: { email: string; password: string }): DispatcherResponse {
    return async (dispatch) => {
      const data = await apiAuth.signup({ email, password })
      console.log('signupWithEmailAndPassword res', data)

      await dispatch(this.setToken(data))

      await dispatch(MeDispatcher.getMySelf())
      await dispatch(CompanyDispatcher.getCompany())
      return data
    }
  }

  logout(): DispatcherResponse {
    console.log('AuthDispatcher.logout()')
    return async (dispatch) => {
      dispatch(appActions.resetStore())
      delete apiClient.defaults.headers.common['Authorization']
      delete apiClientOld.defaults.headers.common['Authorization']
      delete apiClientOld.defaults.headers.common['company_id']
      // window.location.reload()
    }
  }

  loginForAdmin(authData): DispatcherResponse {
    return async (dispatch) => {
      dispatch(actions.setIsAdmin())
      await dispatch(this.setToken(authData))
    }
  }

  acceptUserAgreement(): DispatcherResponse {
    return async (dispatch) => {
      await apiAuth.acceptUserAgreement()
      dispatch(actions.acceptTerms())
    }
  }
}

const AuthDispatcher = new Dispatcher()
export default AuthDispatcher
