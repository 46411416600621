import { Box, Divider, Grid } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import React from 'react'
import useEventTiming from '../../../libs/events/useEventTiming'
import ReportRecordings from '../../reports/report/ReportRecordings'
import EventFilterInfo from './EventFilterInfo'
import EventNextTime from './EventNextTime'
import EventPageHead from './EventPageHead'
import EventParticipants from './EventParticipants'
import EventLinks from './components/EventLinks'
import EventDescription from './elements/EventDescription'

interface Props {
  event: ReduxStateReactionEventItem
}

function EventInfo(props: Props) {
  const { event } = props

  const eventTimings = useEventTiming(event)

  return (
    <Grid>
      <EventPageHead event={event} />

      <EventLinks event={event} />

      <EventFilterInfo event={event} />

      <Box marginBottom={'15px'} marginTop={'15px'}>
        <Divider />
      </Box>

      <EventDescription event={event} />

      {/* {event.next_event_time && ( */}
      {/*  <> */}
      {/*    <Box marginBottom={'15px'} marginTop={'15px'}> */}
      {/*      <Divider /> */}
      {/*    </Box> */}
      {/*    <EventNextTime event={event} /> */}
      {/*  </> */}
      {/* )} */}

      <ReportRecordings event={event} />

      <EventParticipants event={event} isEventFinished={eventTimings.isFinished} />
    </Grid>
  )
}

export default EventInfo
