import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import appTypes from '../app/types'
import types from './types'

interface State {
  token?: string
  isAdmin?: boolean
  chatUser?: string
  chatToken?: string
  is_eula_accepted?: boolean
}

const initialState: State = {
  isAdmin: false,
  token: '',
  chatUser: '',
  chatToken: '',
}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_TOKEN: {
      const { token, chat_user_id: chatUser, chat_token: chatToken, is_eula_accepted } = action.payload
      return state.merge({ token, chatUser, chatToken, is_eula_accepted })
    }
    case types.SET_IS_ADMIN: {
      const isAdmin = action.payload

      return state.merge({ isAdmin })
    }

    case types.ACCEPT_TERMS: {
      return state.merge({ is_eula_accepted: true })
    }

    default:
      return state
  }
}
