import { ReduxState } from '@reducers/index'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'
import apiChallenge from './api'

class Dispatcher {
  get(id: string, { isForce }: DispatcherOptions) {
    return createLazy({
      isForce,
      onFindStoreUpdatedAt(state: ReduxState): StoreUpdatedAt | { storeUpdatedAt: StoreUpdatedAt } {
        return state.challenge.storeUpdatedAt
      },
      timeout: storeHelpers.TTL.DAY,
      async onExecute(dispatch) {
        const challenge = await apiChallenge.getChallenge(id)
        dispatch(actions.set(challenge))
      },
    })
  }

  updateChallenge(app_id: string, params: any): DispatcherResponse<void> {
    return async (dispatch) => {
      const challenge = await apiChallenge.updateChallenge(app_id, params)
      dispatch(actions.set(challenge))
    }
  }

  deleteChallenge(id: string): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiChallenge.deleteChallenge(id)
    }
  }

  terminateChallenge(id: string): DispatcherResponse<void> {
    return async (dispatch) => {
      await apiChallenge.terminateChallenge(id)
    }
  }

  getChallengesGlobalRank(type: string): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.getChallengesGlobalRank(type)
    }
  }

  getChallengeGoals(id: string): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.getChallengeGoals(id)
    }
  }

  setChallengeGoals(id: string, data): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.setChallengeGoals(id, data)
    }
  }

  getChallengeGoalSettings(id: string): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.getChallengeGoalSettings(id)
    }
  }

  setChallengeGoalSettings(id: string, data): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.setChallengeGoalSettings(id, data)
    }
  }

  clearHistory(id: string): DispatcherResponse<any> {
    return async (dispatch) => {
      return apiChallenge.clearHistory(id)
    }
  }
}

const ChallengeDispatcher = new Dispatcher()
export default ChallengeDispatcher
