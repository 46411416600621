/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import { RouteComponentProps } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import TeamInfo from './team/TeamInfo'

interface Props extends RouteComponentProps<{ id: string }> {}

function TeamPage(props: Props) {
  const { match } = props
  const { id } = match.params

  const { team } = useSelector((state: ReduxState) => ({
    team: state.teams[id],
  }))
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(TeamDispatcher.get(id, { isForce: false }))
  })

  return (
    <PaperContainer>
      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={!team}
        renderLoading={() => <ReactionLoader />}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderEmpty={() => <div>Not Found</div>}
        render={() => <TeamInfo team={team} />}
      />
    </PaperContainer>
  )
}

export default TeamPage
