/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Group } from '@reaction-club-types/core'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { components, Props as SelectProps } from 'react-select'
import EventGroupSelectOption from './components/EventGroupSelectOption'
import EventFormSelect from './elements/EventFormSelect'

interface Props {
  value: string | null
  onChange(type: string | undefined): any
  isErrored?: boolean
}

interface SelectOptionCustom {
  value: string | null
  label: string
  item?: Group
}

export default function EventGroupSelect(props: Props) {
  const { value, onChange, isErrored } = props

  const { instances } = useSelector((state: ReduxState) => ({
    instances: state.company.instances,
    playbookInstances: state.playbookInstances,
  }))

  let options: SelectOptionCustom[] = _.map(instances, (instance) => ({
    value: instance.id,
    label: instance.name,
    item: instance,
  }))

  options.unshift({ label: 'None (recommended)', value: null })

  return (
    <EventFormSelect
      placeholder={'Select Group'}
      value={value}
      onChange={onChange}
      options={options}
      isErrored={isErrored}
      components={{
        Option: (optionProps) => {
          const { data, innerProps } = optionProps
          const { item }: { item: Group } = data

          if (!item) {
            return (
              <components.Option
                {...optionProps}
                css={css`
                  min-height: 44px;
                `}
              />
            )
          }

          return <EventGroupSelectOption item={item} innerProps={innerProps} />
        },
      }}
    />
  )
}
