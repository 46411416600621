import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import jssPreset from '@mui/styles/jssPreset'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { create } from 'jss'
import { SnackbarProvider } from 'notistack'
import React, { ReactElement } from 'react'
import { JssProvider } from 'react-jss'
import { Provider } from 'react-redux'
import theme from './app/theme'
import ErrorMessageProvider from './context/ErrorMessageProvider'
import store from './store'

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())

function AppContext({ children }: { children: ReactElement }) {
  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ErrorMessageProvider>
                <Provider store={store}>{children}</Provider>
              </ErrorMessageProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </JssProvider>
  )
}

export default AppContext
