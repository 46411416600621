/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import AppDispatcher from '@reducers/app/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import Loader from '../../components/Loader'
import config from '../../config'
import { ReduxDispatch } from '../../typings/ReduxDispatch'

function OnboardContinue() {
  const dispatch = useDispatch<ReduxDispatch>()
  const history = useHistory()

  const onboardFetch = useAsyncFetch(async () => {
    await dispatch(AppDispatcher.getOnboardForm())
  })

  const { company, onboard } = useSelector((state: ReduxState) => ({
    company: state.company,
    onboard: state.app.onboard,
  }))

  const url = useMemo(() => {
    const onboardLink = config.onboard.url

    const { email, phone_number } = onboard || {}
    const urlParams = qs.stringify({
      email,
      phone_number,
      step: phone_number ? 'sms' : '',
    })

    return `${onboardLink}?${urlParams}`
  }, [onboardFetch.isDone])

  console.log('onboard url:', url, onboard)

  useEffect(() => {
    const listener = function (message) {
      if (!_.isObject(message.data)) return null
      const { type, data } = message.data

      if (type == 'redirect') {
        const { target, params = {} } = data

        if (target === 'hr') {
          const { email } = params
          const urlParams = qs.stringify({ email, skipCheckAuth: '1' })

          history.replace(`/login?${urlParams}`)
        }
      }
    }

    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  return (
    <div
      css={css`
        height: 100%;
        padding: 30px 10% 10px;
      `}
    >
      <RendererStatusSplit
        statuses={onboardFetch}
        renderPreview={() => null}
        renderError={(error) => (
          <Typography color={'error'} textAlign={'center'}>
            {error}
          </Typography>
        )}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => (
          <iframe
            src={url}
            css={css`
              width: 100%;
              height: 655px;
            `}
          />
        )}
      />
    </div>
  )
}

export default OnboardContinue
