/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { Playbook } from '@reaction-club-types/core'
import React from 'react'
import Stars from './Stars'

type Props = { playbook: Playbook }

function PriceAndStars(props: Props) {
  const { playbook } = props

  const convertPlaybookFee = (fee?: number): string => {
    if (!fee) return 'Free'
    return `$${fee}`
  }

  return (
    <Grid
      container
      justifyContent={'space-between'}
      css={css`
        margin-top: 5px;
      `}
    >
      <Stars number={playbook.stars} />
      <Typography
        css={css({
          color: '#17A700',
          marginLeft: 10,
          fontSize: 14,
          fontWeight: 600,
        })}
      >
        {convertPlaybookFee(playbook.fee)}
      </Typography>
    </Grid>
  )
}

export default React.memo(PriceAndStars)
