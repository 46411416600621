import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import LoginCredentialsForm from './LoginCredentialsForm'

const useStyles = makeStyles(
  {
    title: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 20,
      lineHeight: '24px',
    },

    description: {
      fontSize: 12,
      marginBottom: 30,
    },

    container: {
      marginTop: '20px',
    },
  },
  {
    classNamePrefix: 'AuthSection',
  },
)

interface Props {
  onFacebookLogin(): Promise<any>
  onGoogleLogin(): Promise<any>
  onEmailAndPasswordLogin(credentials: { login: string; password: string }): Promise<any>
  email?: string
}

function AuthSection(props: Props) {
  const { onFacebookLogin, onGoogleLogin, onEmailAndPasswordLogin } = props
  const classes = useStyles()

  const { email } = props

  return (
    <Grid container direction={'column'} justifyContent={'center'} className={classes.container}>
      <Box>
        <Typography variant={'h1'} className={classes.title}>
          Welcome to the
          <br />
          Reaction Admin
        </Typography>

        <Typography variant={'body1'} className={classes.description} align={'center'}>
          Login to manage your company app
        </Typography>
      </Box>

      <Grid container direction={'column'}>
        {/* TODO: Properly remove social logins once tested */}
        {/* {!(type === 'emailOnly') && ( */}
        {/*  <LoginButton title={'Sign in with Facebook'} icon={facebookIcon} onLogin={onFacebookLogin} /> */}
        {/* )} */}
        {/* {!(type === 'emailOnly') && ( */}
        {/*  <LoginButton title={'Sign in with Google'} icon={googleIcon} onLogin={onGoogleLogin} /> */}
        {/* )} */}
        <LoginCredentialsForm onLogin={onEmailAndPasswordLogin} email={email} />
      </Grid>
    </Grid>
  )
}

export default AuthSection
