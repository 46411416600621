// COMPANY REQUESTS
import { prefix_agent, prefix_web } from './api'
import { prefix_assets } from './api'

export default {
  async getCompanies() {
    const result = await this.apiClient.get(`${prefix_agent}companies`)
    return result.data
  },

  async getCompany() {
    const result = await this.apiClient.get(`${prefix_agent}company`)
    return result.data
  },

  /**
   * Associate coaches with company
   * @param coaches - array id coaches [0, 1, 2,...]
   * @returns {Promise<*>} return status {success: true } or error
   */
  async assignCoachesToCompany(coaches) {
    const result = await this.apiClient.post(`${prefix_agent}company` + `/coach`, { coach_id: coaches })
    return result.data
  },
  async unassigneCoachWithCompany(coachId) {
    const result = await this.apiClient.delete(`${prefix_agent}company/coach`, { data: { coach_id: coachId } })
    return result.data
  },
  async assignCoachesToCompanyPlaybook(playbookId, coachId) {
    const result = await this.apiClient.post(`${prefix_agent}company` + `/playbook/${playbookId}/coach/${coachId}`)
    return result.data
  },
  async deleteCompany(companyId) {
    const result = await this.apiClient.delete(`${prefix_agent}company/${companyId}`)
    return result.data
  },
  async getCompanyCoaches(companyId) {
    const result = await this.apiClient.get(`${prefix_agent}company` + `/coaches`)
    return result.data
  },
  async getCompanyEmployees(companyId) {
    const result = await this.apiClient.get(`${prefix_agent}company` + `/employees`)
    return result.data
  },
  async getPlaybookEmployeesInstances(instance_id) {
    const result = await this.apiClient.get(`${prefix_agent}playbook-instance/${instance_id}/employees`)
    return result.data
  },
  async getPlaybookCoachesInstances(instance_id) {
    const result = await this.apiClient.get(`${prefix_agent}playbook-instance/${instance_id}/coaches`)
    return result.data
  },
  async getCompanyPlaybooks() {
    const result = await this.apiClient.get(`${prefix_agent}company` + `/playbooks`)
    return result.data
  },
  async getCompanyPlaybooksInstances() {
    const result = await this.apiClient.get(`${prefix_agent}playbook-instances`)
    return result.data
  },
  async buyPlaybook(playbook_id) {
    const result = await this.apiClient.post(`${prefix_agent}company` + `/playbook`, { playbook_id })
    return result.data
  },
  async deleteCompanyPlaybook(playbooksIds, companyId) {
    await this.apiClient.delete(`${prefix_agent}company` + `/playbook`, { data: { playbook_id: playbooksIds } })
    const result = await this.apiClient.get(`${prefix_agent}company` + `/playbooks`)
    return result.data
  },
  async addCompanyPlaybook(playbooksIds, companyId) {
    await this.apiClient.post(`${prefix_agent}company` + `/playbook`, { playbook_id: playbooksIds })
    const result = await this.apiClient.get(`${prefix_agent}company` + `/playbooks`)
    return result.data
  },
  async updateCompany(company) {
    await this.apiClient.post(`${prefix_agent}company`, {
      name: company.name,
      code: company.code,
      workspace: company.workspace,
      address: company.address,
      asset_id: company.assets_id,
      stickers: company.stickers,
    })
    const result = await this.apiClient.get(`${prefix_agent}company`)
    return result.data
  },
  async updateCompanyStickers(company) {
    const result = await this.apiClient.post(`${prefix_agent}company` + `/sticker`, { asset_id: company.asset_id })
    return result.data
  },
  async updateSessionLink(companyId, link) {
    await this.apiClient.post(`${prefix_agent}company/${companyId}/session_link`, { session_link: link })
    const result = await this.apiClient.get(`${prefix_agent}company/${companyId}`)
    return result.data
  },
  async getStickers(companyId) {
    const result = await this.apiClient.get(`${prefix_agent}company` + `/stickers`)
    return result.data
  },
  async setCompanySticker(companyId, stickerAssetId) {
    const result = await this.apiClient.post(`${prefix_agent}company` + `/sticker`, { asset_id: stickerAssetId })
    return result.data
  },
  async deleteSticker(stickerId) {
    const result = await this.apiClient.delete(`${prefix_agent}company` + `/sticker`, {
      data: { sticker_id: stickerId },
    })
    return result.data
  },
  async getInviteLinks() {
    const result = await this.apiClient.get(`${prefix_agent}invites`)
    return result.data
  },
  async createLink() {
    const result = await this.apiClient.post(`${prefix_agent}create-invite`)
    return result.data
  },
  async deleteLink(linkId) {
    const result = await this.apiClient.delete(`${prefix_agent}invite/${linkId}`)
    return result.data
  },
}
