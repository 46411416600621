import React from 'react'
import SettingsStickers from './SettingsStickers'
import CompanyDetails from './components/CompanyDetails'
import CompanyOffices from './offices/CompanyOffices'

const SettingsCompany = () => {
  return (
    <div>
      <CompanyDetails />
      <CompanyOffices />
      <SettingsStickers />
    </div>
  )
}
export default SettingsCompany
