/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import GroupDispatcher from '@reducers/playbookInstances/dispatcher'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useTimeout } from 'usehooks-ts'
import EmailWrapper from '../../components/EmailWrapper'
import ReactionLoader from '../../components/ReactionLoader'
import copyLink from '../../components/modals/components/inviteMember/copyLink.svg'
import emailInviteMember from '../../components/modals/components/inviteMember/emailInviteMember.svg'
import ModalStyled from '../../elements/ModalStyled'
import { employeeShortLinks } from '../../libs/const/shortLinks'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import InviteQR from './components/InviteQR'

const useStyles = makeStyles(
  {
    caption: {
      fontWeight: 800,
      fontSize: 18,
      marginBottom: 20,
    },
    actionText: {
      marginLeft: 15,
    },
    actionButton: {
      width: '100%',
      cursor: 'pointer',
      borderRadius: 5,
      padding: '20px 15px',
      background: 'transparent',
      border: '1px solid #D4D4D4',
      '&:not(:last-child)': {
        marginBottom: 10,
      },
      outline: 'none',
    },
    linkCopied: {
      color: '#37AF1D',
    },
  },
  {
    classNamePrefix: 'InviteEmployeesModal',
  },
)

interface Props {
  isVisible: boolean
  group_id: string
  onClose(): any
}

function InviteEmployeesToGroupModal(props: Props) {
  const { isVisible, onClose, group_id } = props
  const classes = useStyles()
  const [isCopied, setCopied] = useState(false)
  const dispatch = useDispatch<ReduxDispatch>()

  useTimeout(() => setCopied(false), isCopied ? 5000 : null)

  const { group } = useSelector((state: ReduxState) => ({
    group: state.playbookInstances[group_id],
  }))
  const { name, link: linkObject } = group || {}

  const fetchLink = useAsyncFetch(async () => {
    if (!isVisible) return null
    await dispatch(GroupDispatcher.createPlaybookInstanceInvite(group_id))
  }, [isVisible, group_id])

  const emailSubject = 'Reaction Club Invitation'
  const emailBody = `
Hey 👋 

It would be awesome if you would join our ${name}!

First, download Reaction Club app.
AppStore: ${employeeShortLinks.apple}
Google Play: ${employeeShortLinks.google}


Once you have the app, tap on the link below to join: ${linkObject?.link}  


Let’s do this, 
`

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        width: 500px;
      `}
    >
      <Grid
        container
        css={css`
          padding-top: 20px;
        `}
      >
        <Typography className={classes.caption}>Invite members to {name}</Typography>
        <RendererStatusSplit
          statuses={fetchLink}
          isEmpty={!linkObject}
          renderEmpty={() => (
            <Typography textAlign={'center'}>No link. Try again or contact support for help.</Typography>
          )}
          renderError={(error) => (
            <Typography textAlign={'center'} color={'error'}>
              {error}
            </Typography>
          )}
          renderLoading={() => <ReactionLoader />}
          render={() => (
            <Grid container>
              <InviteQR link={linkObject?.link as string} />

              <Grid
                item
                flexGrow={1}
                css={css`
                  padding-left: 30px;
                `}
              >
                <EmailWrapper body={emailBody} subject={emailSubject} className={classes.actionButton}>
                  <Grid container direction={'row'} alignItems={'center'}>
                    <img src={emailInviteMember} alt="email invite" />
                    <Typography className={classes.actionText}>Email</Typography>
                  </Grid>
                </EmailWrapper>
                <CopyToClipboard text={linkObject?.link || ''} onCopy={() => setCopied(true)}>
                  <button type="button" className={classes.actionButton}>
                    <Grid container direction={'row'} alignItems={'center'}>
                      <img src={copyLink} alt="copy link" />
                      {!isCopied ? (
                        <Typography className={classes.actionText}>Copy Link</Typography>
                      ) : (
                        <Typography className={[classes.actionText, classes.linkCopied].join(' ')}>
                          Link Copied!
                        </Typography>
                      )}
                    </Grid>
                  </button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </ModalStyled>
  )
}

export default InviteEmployeesToGroupModal
