import { Grid, Typography, Box, Button } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import PlaybookInstanceDispatcher from '@reducers/playbookInstances/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import tickIcon from '../assets/tick.svg'
import CoachWrapper from '../components/CoachWrapper'

const SubmitChampionRequest = ({ classes, offerGroupId, getPlaybookInstances, showModal, playbookInstances }) => {
  const handleInvite = () => {
    showModal({
      modalType: MODAL_TYPES.INVITE_GROUP_COACH,
      modalProps: { instance: playbookInstances[offerGroupId] },
    })
  }

  React.useEffect(() => {
    if (offerGroupId) {
      getPlaybookInstances()
    }
  }, [])

  return (
    <CoachWrapper title="Submit a champion request" titleWidth={121}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content}>
            <Box style={{ marginBottom: 47 }}>
              <Typography className={classes.title}>What is Champion?</Typography>
              <Typography>
                A wellness passionated individual in your organization can volunteer to lead wellness program.
                <b> With a dedicated App </b>
                we will give your champions superpowers to easily become a wellness hero.
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.title}>Champion Role</Typography>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>Schedule weekly onsite live stream session.</Typography>
              </Box>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>Engage and motivate the group to follow the program.</Typography>
              </Box>
              <Box className={classes.infoLine}>
                <img src={tickIcon} alt="" className={classes.tick} />
                <Typography>Make sharing achievements a daily habit.</Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          {offerGroupId ? (
            <Button className={classes.btn} onClick={handleInvite}>
              Send Invite
            </Button>
          ) : (
            <Link to="/coaches/invite/internal/choose-group" style={{ textDecoration: 'none' }}>
              <Button className={classes.btn}>Next</Button>
            </Link>
          )}
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  offerGroupId: state.coaches.offer.groupId,
  playbookInstances: state.playbookInstances,
})
const mapDispatchToProps = dispatch => ({
  showModal: param => dispatch(modalActions.showModal(param)),
  getPlaybookInstances: () => dispatch(PlaybookInstanceDispatcher.getPlaybookInstances()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    maxWidth: 570,
    padding: '102px 16px 0 16px',
    margin: '0 auto',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 9,
    fontSize: '17px',
  },
  infoLine: {
    display: 'flex',
    marginBottom: 15,
  },
  tick: {
    marginRight: 12,
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#f8b84e',
    },
  },
})

export default compose(connectStore, withStyles(styles))(SubmitChampionRequest)
