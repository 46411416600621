import apiClient from '../../libs/api/libs/apiClient'

export type postOfferParam = {
  proposed_price: number
  proposed_currency: string
  type: string // onsite, online, onsite+online
  type_ids: [string] // array
  description: string
  location: any
  coordinates: any
  language: string
  period: string
  start_at: string
}

const api = {
  async getCoach(id): Promise<Coach> {
    const result = await apiClient.get(`/coach/${id}`)
    return result.data
  },
  async postOffer(param: postOfferParam): Promise<void> {
    const result = await apiClient.post(`/offer`, param)
    return result.data
  },
  async cancelOffer(id): Promise<void> {
    const result = await apiClient.post(`/offer/${id}/cancel`)
    return result.data
  },
  async getOffers(): Promise<any> {
    const result = await apiClient.get(`/offer/all`)
    return result.data
  },
  async getOfferMatchedCoaches(offer_id): Promise<any> {
    const result = await apiClient.get(`/offer/${offer_id}/applicants`)
    return result.data
  },
  async acceptCoach({
    offer_id,
    coach_id,
    period,
    description,
    agreed_price,
    agreed_currency,
    started_at,
  }): Promise<any> {
    const result = await apiClient.post(`/offer/${offer_id}/accept-coach`, {
      offer_id,
      coach_id,
      period,
      description,
      agreed_price,
      agreed_currency,
      started_at,
    })
    return result.data
  },
  async declineCoach({ offer_id, coach_id }): Promise<any> {
    const result = await apiClient.post(`/offer/${offer_id}/decline-coach`, { coach_id })
    return result.data
  },
}

export default api
