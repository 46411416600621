import { Box, Grid, Paper, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import * as React from 'react'
import ProfileHeader from '../../../components/ProfileHeader'

const CoachWrapper = ({ title, titleWidth = 150, children, classes }) => (
  <Grid container style={{ height: '100%' }}>
    <ProfileHeader />
    <Grid xs={12} item style={{ margin: '0 50px 50px 50px' }}>
      <Paper className={classes.contentWrapper}>
        <Grid container className={classes.content}>
          <Grid
            item
            xs={3}
            container
            justifyContent={'center'}
            className={classes.sidebar}
            style={{ minWidth: titleWidth + 32 }}
          >
            <Box className={classes.sidebarDecor} />
            <Typography className={classes.title} style={{ width: titleWidth }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={9} container>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
)

const styles = createStyles({
  contentWrapper: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  content: {
    minHeight: '84vh',
    flexWrap: 'nowrap',
  },
  sidebar: {
    backgroundColor: '#F9B22D',
    paddingTop: 115,
    width: '100%',
    position: 'relative',
  },
  sidebarDecor: {
    position: 'absolute',
    borderRadius: 5,
    width: 35,
    height: 35,
    transform: 'rotate(45deg) translateX(47%)',
    right: 0,
    top: 115,
    backgroundColor: '#F9B22D',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#000',
  },
})

export default withStyles(styles)(CoachWrapper)
