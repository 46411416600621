import { Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import * as React from 'react'
import { connect } from 'react-redux'
import config from '../config'
import PaperContainer from '../components/PaperContainer'
import { ExtractConnectType } from '../typings/ReduxExtractor'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: 32,
  },
  openPlaybooks: {
    cursor: 'pointer',
    color: '#ecb61d',
  },
})

const { playbooks_url = '' } = config

const Coaches = (props: ExtractConnectType<typeof connectStore>) => {
  const { token } = props
  const classes = useStyles()

  return (
    <PaperContainer>
      <Typography className={classes.title}>Playbooks</Typography>

      <a
        href={`${playbooks_url}?session=${token}`}
        className={classes.openPlaybooks}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <Typography>{playbooks_url.replace('https://', '')}</Typography>
      </a>
    </PaperContainer>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    token: state.auth.token,
  }),
  dispatch => ({}),
)

export default connectStore(Coaches)
