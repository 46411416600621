import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_LINK: '',
    SET_HR_LINK: '',
    // SET_AGENT: '',
    SET_AGENTS: '',
    REMOVE_AGENT: '',
    SET_STICKERS: '',
    SET_PLAYBOOK_INSTANCES: '',
    SET_COACHES: '',
    SET_COACH_GROUPS: '',
    SET_EMPLOYEES: '',
    SET_EMAIL_LIST: '',

    // old
    SET_COMPANY_COACHES: '[SET_COMPANY_COACHES]: Set coaches assigned to company  ',
    SET_COMPANY_EMPLOYEES: '[SET_COMPANY_EMPLOYEES]: Set employees assigned to company',
    FILTER_COACHES: '[FILTER_COACHES]: Set value for filtering coach',
    SET_COMPANY_PLAYBOOK: '[SET_COMPANY_PLAYBOOK]: Set company playbook',
    ADD_PLAYBOOK: '[ADD_PLAYBOOK]: Add one playbook to company ',
    REMOVE_PLAYBOOK: '[REMOVE_PLAYBOOK]: Remove one playbook with company ',
    SET_COMPANY_STICKERS: '[SET_COMPANY_STICKERS]: Set company stickers',
    SET_COMPANY_PLAYBOOK_INSTANCES: '[SET_COMPANY_PLAYBOOK_INSTANCES]: Set company playbook instances',
    SET_COMPANY_INVITE_LINKS: '[SET_COMPANY_INVITE_LINKS] Set invite links for company',
  },
  { prefix: 'Company__' },
)
