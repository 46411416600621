/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import React, { useMemo } from 'react'
import Moment from 'react-moment'
import useModalState from '../../../../../hooks/useModalState'
import clockIcon from '../../../assets/clock.svg'
import EventFieldInfo from '../../../eventCreate/components/elements/EventFieldInfo'
import EventTypeInfoModal from '../../../eventCreate/modals/EventTypeInfoModal'

interface Props {
  event: ReactionEvent
}

function AgendaEventTimeInfo(props: Props) {
  const { event } = props
  const { filter_type, time } = event
  const typeInfoModalState = useModalState()

  let filterTxt = useMemo(() => {
    if (filter_type === 'public') return 'Public Event'
    if (filter_type === 'company') return 'Club Event'
    if (filter_type === 'office') return 'Office Event'
    if (filter_type === 'group') return 'Group Event'

    return ''
  }, [filter_type])

  return (
    <div
      css={css`
        border-right: 1px solid #eeeeee;
        padding: 15px 10px 10px;
        width: 170px;
      `}
    >
      <Grid container justifyContent={'center'}>
        <Typography>
          <Moment format={'ddd Do'}>{time}</Moment>
        </Typography>
      </Grid>
      <Grid container alignItems={'center'}>
        <img
          src={clockIcon}
          alt={'clock'}
          css={css`
            width: 30px;
            height: 30px;
            object-fit: contain;
          `}
        />

        <Typography
          css={css`
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 30px;
            line-height: 45px;
            margin: 0 20px 0 10px;
          `}
        >
          <Moment format={'HH:mm'}>{time}</Moment>
        </Typography>
      </Grid>

      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        css={css`
          margin-top: 10px;
        `}
      >
        <Typography>{filterTxt}</Typography>
        <EventFieldInfo onClick={typeInfoModalState.onOpen} />
      </Grid>

      <EventTypeInfoModal {...typeInfoModalState} />
    </div>
  )
}

export default AgendaEventTimeInfo
