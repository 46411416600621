import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import AuthDispatcher from '@reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import ButtonOrange from '../../components/ButtonOrange'
import useCountryCode from '../../hooks/useCountryCode'
import logoImage from './assets/logo.png'

const localCountryCodes = [
  {
    code: 1,
    localCode: '1',
  },
  {
    code: 972,
    localCode: '0',
  },
  {
    code: 36,
    localCode: '06',
  },
  {
    code: 7,
    localCode: '8',
  },
]

const LoginPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState('')
  const [enterOTP, setEnterOTP] = useState(false)
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const token = useSelector((state: ReduxState) => state.auth.token)
  const { seconds, restart } = useTimer({ expiryTimestamp: new Date() })
  const [countryCode] = useCountryCode(phone)
  const {
    location: { search },
  } = history

  async function checkAuthAndRedirect() {
    const urlParams = new URLSearchParams(search)
    const adminToken = urlParams.get('token')

    if (adminToken) {
      await dispatch(
        AuthDispatcher.loginForAdmin({
          token: adminToken,
          // chatToken: adminChatToken,
          // chatUserId: adminChatUserId,
        }),
      )
      if(location.search.includes('/payment')){
        location.search = ''
        return history.push('/payment')
      }

      return history.push('/')
    }

    if (token) {
      return history.push('/')
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const skipCheckAuth = urlParams.get('skipCheckAuth')

    // const adminChatToken = new URLSearchParams(search).get('chatToken')
    // const adminChatUserId = new URLSearchParams(search).get('chatUserId')
    if (!skipCheckAuth) {
      checkAuthAndRedirect()
    }
  }, [])

  const handleChangePhone = (value) => {
    let cleanNumber = value.replace(/[^\d]/g, '')
    let newValue = value
    if (value.length > 7) {
      localCountryCodes.forEach((country) => {
        // sometimes user start number with local style. (code is exists in number already)
        const countryAndLocalRegex = new RegExp(`^${country.code}${country.localCode}`)
        if (countryAndLocalRegex.test(cleanNumber)) {
          newValue = `+${cleanNumber.replace(countryAndLocalRegex, country.code)}`
        }
      })
    }
    setPhone(newValue)
  }

  const handleChangeOTP = (newValue) => {
    setOtp(newValue)
  }

  const handleSendSms = async (e?) => {
    e?.preventDefault()
    if (matchIsValidTel(phone)) {
      try {
        setLoading(true)
        await dispatch(AuthDispatcher.sendSms(phone))
        const time = new Date()
        time.setSeconds(time.getSeconds() + 60) // 1 minute
        restart(time)
        setTimeout(() => {
          setEnterOTP(true)
        }, 1000)
      } catch (e) {
        setError(e?.response?.data?.error || e.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleLogin = async () => {
    const urlParams = new URLSearchParams(search)
    const redirect = urlParams.get('redirect')
    if (matchIsValidTel(phone)) {
      try {
        setLoading(true)
        await dispatch(AuthDispatcher.loginWithPhoneAndCode(phone, otp))
        history.replace(redirect || '/dashboard')
      } catch (e) {
        alert(e.response.data.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleVerify = async () => {
    setError('')
    if (enterOTP) {
      handleLogin()
    } else {
      handleSendSms()
    }
  }

  return (
    <Container>
      <BlockContainer>
        <img src={logoImage} alt="logo" height="100" />
        <Typography fontSize={44} fontWeight="bold" mt={3} lineHeight={1.2}>
          {enterOTP ? 'Enter the 4-digit code' : 'Welcome'}
        </Typography>
        <Typography fontSize={28} mt={5} lineHeight={1.2}>
          {enterOTP ? `Sent to ${phone}` : 'Log in to your admin dashboard'}
        </Typography>
        <Box mt={8} width="100%">
          {enterOTP ? (
            <MuiOtpInputStyled value={otp} onChange={handleChangeOTP} />
          ) : (
            <MuiTelInputStyled defaultCountry={countryCode} value={phone} onChange={handleChangePhone} />
          )}
        </Box>
        <Typography fontSize={21} mt={6}>
          {enterOTP ? (
            <span>
              Didn&apos;t get the code?{' '}
              {seconds > 0 ? (
                <span>Resend in {seconds}s</span>
              ) : (
                <CreateAccountLink href="#" onClick={handleSendSms}>
                  Resend
                </CreateAccountLink>
              )}
            </span>
          ) : (
            'We will send you a code to verify your number'
          )}
        </Typography>
        <VerifyButton onClick={handleVerify} isLoading={loading}>
          Verify
        </VerifyButton>
        {error ? (
          <Typography fontSize={18} mt={4} align="center" color="error">
            {error}
          </Typography>
        ) : null}
        <LoginWithEmail to={`/email-login${location.search}`}>
          <Typography color="#332233" fontSize={21}>
            Or login using email
          </Typography>
        </LoginWithEmail>
      </BlockContainer>
      <Typography fontSize={21} my={6} textAlign="center">
        Dont have an account?{' '}
        <CreateAccountLink href="http://www.reaction-club.com/getstarted">Create one now</CreateAccountLink>
      </Typography>
    </Container>
  )
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
`

const BlockContainer = styled(Box)`
  padding-bottom: 60px;
  width: 100%;
  max-width: 1000px;
  border-bottom: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const MuiTelInputStyled = styled(MuiTelInput)`
  width: 480px;
  max-width: 100%;
  & input {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`

const MuiOtpInputStyled = styled(MuiOtpInput)`
  width: 380px;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`

const VerifyButton = styled(ButtonOrange)`
  width: 280px;
  font-size: 18px;
  margin-top: 40px;
  padding: 15px;
`

const LoginWithEmail = styled(Link)`
  text-decoration: underline;
  margin-top: 25px;
`

const CreateAccountLink = styled('a')`
  color: #3151f9;
`

export default LoginPage
