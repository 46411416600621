/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import { Box, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IChallengeApp } from '@reducers/challenge/types'
import classnames from 'classnames'
import _, { capitalize } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import Team from './Team'
import UserLeaderboardItemNew from './UserLeaderboardItemNew'

interface Props {
  challenge: IChallengeApp
  summary: {
    winners: { userName: string }[]
    team: {
      name: string
    }
  }
}

const useStyles = makeStyles({
  close: {
    transform: 'rotate(180deg)',
  },
})

function HistoryItem(props: Props) {
  const [collapsed, setCollapsed] = useState(true)
  const classes = useStyles()
  const [winner] = props.winners || []
  const winnerTeam = props.team
  const { challenge } = props
  const teamMaxValue = _.maxBy(props.team_leaderboard, 'daily_average')

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div
      css={css`
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 12px 15px 12px 20px;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
        position: relative;
      `}
    >
      <Box justifyContent="space-between" mb={3} display="flex">
        <Typography fontWeight="bold">{challenge.name || `${capitalize(challenge?.type_data.label)}`}</Typography>
        <Typography fontWeight="bold">
          {moment(props.start_at).format('MMM DD')} - {moment(props.end_at).format('MMM DD')}
        </Typography>
      </Box>
      <Typography mb={1}>
        <b>Winner:</b> {winner?.name}
      </Typography>
      <Typography>
        <b>Winning Team:</b> {winnerTeam?.name}
      </Typography>
      <Collapse isOpened={!collapsed}>
        {_.map(props.team_leaderboard, (t) => (
          <Team
            team={t}
            key={t.id}
            unitType={props.challenge.result_type}
            type={props.challenge.type_data.label}
            teamMaxValue={teamMaxValue}
          />
        ))}

        <Box pl={4} pr={4} mt={5} maxHeight={340} overflow="auto">
          {_.map(props.leaderboard, (u, i) => (
            <UserLeaderboardItemNew
              name={u.name}
              avatar_url={u.avatar_url}
              valueAvg={u.daily_average}
              unitType={props.challenge.result_type}
              valueTotal={u.total_value}
              rank={i + 1}
              history
              key={`leaderboard-steps-summary-${props.challenge_item_id}-${u.user_id}`}
            />
          ))}
        </Box>
      </Collapse>
      <Box textAlign="right">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleCollapse}
          size="large"
        >
          <ExpandCircleDownIcon className={classnames(!collapsed && classes.close)} />
        </IconButton>
      </Box>
    </div>
  )
}

export default HistoryItem
