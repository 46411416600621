import { GroupCoach } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

const api = {
  async getGroupsCoaches(): Promise<GroupCoach[]> {
    const result = await apiClient.get(`/group/groups-coaches`)
    return result.data
  },
}

export default api
