import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { GROUP_COACHES_URL } from '../GroupCoaches'
import addCoachIcon from '../assets/actions/addCoachIcon.svg'
import ActionButton from './components/ActionButton'
import ActionButtonDefault from './components/ActionButtonDefault'

interface Props {
  instance: PlaybookInstance
}

function PlaybookInstanceCoach(props: Props) {
  const { instance } = props
  const history = useHistory()

  const { coaches = [] } = instance
  const coach = _.find(coaches, { is_primary: true }) as Coach

  const handleAddCoach = () => {
    history.push(`/coaches/invite/${instance.id}`)
  }

  const handleClick = () => {
    history.push(GROUP_COACHES_URL(instance.id))
  }

  if (!coach) {
    return <ActionButtonDefault icon={addCoachIcon} text={'Add Coach'} onClick={handleAddCoach} />
  }

  let subText = `${coach?.name}`
  if (coaches.length > 1) subText = `${subText} and ${coaches.length - 1} more`

  const { avatar_url } = coach

  return <ActionButton asset={{ url: avatar_url }} title={'Coach'} description={subText} onClick={handleClick} />
}

export default PlaybookInstanceCoach
