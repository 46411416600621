/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../components/Loader'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import SectionContainer from '../elements/SectionContainer'
import SectionTitle from '../elements/SectionTitle'
import CompanyLeaderBoardActions from './components/CompanyLeaderBoardActions'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import LeaderBoardList from './components/LeaderBoardList'
import { isEmpty } from 'lodash'

interface Props {
  type: string
  unitType: 'type' | 'integer'
}

function CompaniesLeaderBoard(props: Props) {
  const dispatch = useDispatch<ReduxDispatch>()
  const [globalRank, setGlobalRank] = useState()

  const fetchAction = useAsyncFetch(async () => {
    const res = await dispatch(ChallengeDispatcher.getChallengesGlobalRank(props.type))
    setGlobalRank(res)
  })

  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))

  return (
    <SectionContainer>
      <Grid
        container
        flexDirection={'column'}
        css={css`
          min-height: 350px;
        `}
      >
        <Grid container justifyContent={'space-between'}>
          <SectionTitle>Reaction's Global Ranking</SectionTitle>

          <CompanyLeaderBoardActions />
        </Grid>
        <Typography
          css={css`
            font-size: 14px;
            margin-bottom: 10px;
          `}
        >
          See how your company is doing compared with other companies on Reaction.
          {/* By avg. daily step count per member */}
        </Typography>

        <Grid
          item
          flexGrow={1}
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <RendererStatusSplit
            statuses={fetchAction}
            isEmpty={!globalRank}
            renderLoading={() => (
              <Grid
                container
                justifyContent={'center'}
                css={css`
                  margin-top: 50px;
                `}
              >
                <Loader />
              </Grid>
            )}
            render={() => {
              if (isEmpty(globalRank)) {
                return null
              }

              return <LeaderBoardList list={globalRank} unitType={props.unitType} company={company} />
            }}
          />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default CompaniesLeaderBoard
