/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Box, Divider, Typography } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import { ReduxState } from '@reducers/index'
import OfficeDispatcher from '@reducers/offices/dispatcher'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import EventOffices from './components/EventOffices'
import GroupReference from './components/GroupReference'

interface Props {
  event: ReduxStateReactionEventItem
}
function EventFilterInfo(props: Props) {
  const { event } = props
  const { filter_type, group_id, offices } = event
  const dispatch = useDispatch<ReduxDispatch>()
  const officesFetch = useAsyncFetch(async () => {
    await dispatch(OfficeDispatcher.getList({ isForce: false }))
  })
  const state = useSelector((state: ReduxState) => ({ offices: state.offices }))

  const officeList = useMemo(() => {
    let list = _.map(offices, (office_id) => state.offices[office_id])
    return _.compact(list)
  }, [offices, state.offices])

  if (filter_type === 'company') return null

  let filterStr = ''
  if (filter_type === 'company') filterStr = 'All Company'
  if (filter_type === 'group') filterStr = 'By Group'
  if (filter_type === 'office') {
    const officeListStr = _.map(officeList, (item) => item.name).join(', ')
    if (officeList) {
      filterStr = `Offices: ${officeListStr}`
    } else {
      filterStr = 'By Office'
    }
  }
  if (filter_type === 'public') filterStr = 'Public'

  return (
    <div>
      <Box marginBottom={'15px'} marginTop={'15px'}>
        <Divider />
      </Box>

      <Typography
        variant={'h2'}
        css={css`
          margin-bottom: 5px !important;
        `}
      >
        Filter
      </Typography>
      <Typography>{filterStr}</Typography>

      <div>
        {filter_type === 'group' && <GroupReference group_id={group_id as string} />}
        {/* {filter_type === 'office' && <EventOffices offices={offices} />} */}
      </div>
    </div>
  )
}

export default EventFilterInfo
