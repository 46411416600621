/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { CalendarEvent } from '../../../Events'
import { eventColors } from '../../../values/properties'

interface Props {
  event: CalendarEvent
}

function CalendarEventVertical(props: Props) {
  const { event: item } = props
  const { title, resource: event } = item
  const { participant_count, is_public } = event

  let indicatorColor = is_public ? eventColors.publicEventColor : eventColors.companyEventColor

  return (
    <Grid
      direction={'row'}
      container
      alignItems={'center'}
      css={css`
        padding: 0 1px 0 10px;

        .MuiTypography-root {
          font-family: Poppins;
          font-size: 11px;
        }
      `}
    >
      <div
        css={css`
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #0099ff;
          margin-right: 5px;
        `}
        style={{ backgroundColor: indicatorColor }}
      />
      <Typography
        css={css`
          width: 1px;
          flex-grow: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `}
      >
        {title}
        {participant_count ? <React.Fragment>{participant_count} going</React.Fragment> : null}
      </Typography>
    </Grid>
  )
}

export default CalendarEventVertical
