import { CompanyStock, CompanyStockFull, CompanyStockOrder } from '@reaction-club-types/core'
import apiClient from '../../libs/api/libs/apiClient'

export interface ApiPayloadStockCreate {
  name: string
  description: string
  price: number
  quantity: number
  asset_id?: string | null
}

const apiStocks = {
  async getStocks(): Promise<CompanyStock[]> {
    const { data } = await apiClient.get('/stock/list')
    return data
  },

  async getStock(stock_id: string): Promise<CompanyStockFull> {
    const { data } = await apiClient.get(`/stock/${stock_id}`)
    return data
  },

  async getOrders(): Promise<CompanyStockOrder[]> {
    const { data } = await apiClient.get('/stock/orders')
    return data
  },

  async create(payload: ApiPayloadStockCreate) {
    const { data } = await apiClient.post('/stock/create', payload)
    return data
  },

  async updateStock(stock_id: string, payload: ApiPayloadStockCreate) {
    const { data } = await apiClient.post(`/stock/${stock_id}`, payload)
    return data
  },

  async cancelOrder(order_id: string) {
    const { data } = await apiClient.post(`/stock/order/${order_id}/cancel`)
    return data
  },

  async completeOrder(order_id: string) {
    const { data } = await apiClient.post(`/stock/order/${order_id}/complete`)
    return data
  },

  async deleteStock(stock_id: string) {
    const { data } = await apiClient.delete(`/stock/${stock_id}`)
    return data
  },

  async setVisibility(stock_id: string, payload: { is_visible: boolean }): Promise<{}> {
    const { data } = await apiClient.post(`/stock/${stock_id}/set-visibility`, payload)
    return data
  },
}

export default apiStocks
