/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Grid, Typography, Box } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import Locked from '../../../components/Locked'
import HistoryItem from '../components/HistoryItem'
import UserLeaderboardItemNew from '../components/UserLeaderboardItemNew'

interface Props {
  challenge: any
  isFreeTrial: boolean
}

function LeaderboardListSteps(props: Props) {
  const { isFreeTrial } = props
  const {
    name,
    participantCount,
    start_at,
    result_type,
    end_at,
    challenge_item_start_at,
    challenge_item_end_at,
    leaderboard,
    summaries,
  } = props.challenge
  const formattedLeaderboard = isFreeTrial ? leaderboard.slice(0, 3) : leaderboard

  const startDate = challenge_item_start_at || start_at
  const endDate = challenge_item_end_at || end_at

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Box justifyContent="space-between" mb={3} display="flex">
          <Typography fontWeight="bold">{name || 'Step Challenge'}</Typography>
          <Typography fontWeight="bold">
            {moment(startDate).format('MMM DD')} - {moment(endDate).format('MMM DD')}
          </Typography>
        </Box>

        {isFreeTrial ? (
          <Box display="flex" height={280}>
            <Locked text="Unlock leaderboard with the premium version" />
          </Box>
        ) : (
          _.map(formattedLeaderboard, (u, i) => (
            <UserLeaderboardItemNew {...u} rank={i + 1} unitType={result_type} key={`leaderboard-steps-${u.userId}`} />
          ))
        )}
      </Grid>
      <Grid item xs={6} display="flex" flexDirection="column">
        <Box justifyContent="center" mb={3} display="flex">
          <Typography fontWeight="bold" fontSize={24}>
            History
          </Typography>
        </Box>
        {participantCount < 2 ? (
          <Box padding={4}>
            <Typography fontSize={18} fontWeight="bold">
              No history yet...
            </Typography>
          </Box>
        ) : isFreeTrial ? (
          <Locked text="Unlock history with the premium version" />
        ) : (
          _.map(summaries, (s, i) => (
            <HistoryItem challenge={props.challenge} {...s} key={`summary-${s.challenge_item_id}`} />
          ))
        )}
      </Grid>
    </Grid>
  )
}

export default LeaderboardListSteps
