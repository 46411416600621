import { Grid, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { SelectInputProps } from '@mui/material/Select/SelectInput'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import PlaybookDispatcher from '@reducers/playbooks/dispatcher'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import ModeItem, { Props as ModeItemProps } from './ModeItem'

const useStyles = makeStyles(
  {
    selectRoot: {
      minWidth: 254,
    },
    selectRootLabel: {
      fontSize: 12,
    },
  },
  {
    classNamePrefix: 'ModeItemAuto',
  },
)

interface Props extends Pick<ModeItemProps, 'onCheck'> {
  playbook_id: string
  isChecked: boolean

  time: string
  onTimeChange(time: string): any

  program_id?: string | null
  onProgramChange(program_id: string): any
}

function ModeItemAuto(props: Props & ExtractConnectType<typeof connectStore>) {
  const { programs, isChecked, onCheck, time, onTimeChange, program_id, onProgramChange } = props
  const [isLoading, setLoading] = useState(false)

  const classes = useStyles()

  const handleChange: SelectInputProps['onChange'] = e => {
    const program_id_next = e.target.value
    if (!program_id_next) return null
    // const selectedProgram = _.find(programs, { id: program_id }) as Program

    onProgramChange(program_id_next)
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = event => {
    const selectedTime = event.target.value
    onTimeChange(selectedTime)
  }

  async function fetchPrograms() {
    setLoading(true)
    try {
      await props.getPrograms()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPrograms()
  }, [])

  return (
    <ModeItem
      mode={'auto'}
      title={'Auto'}
      description={'The one-plan-fits-all option sends all users the same plan according to your choice.'}
      isChecked={isChecked}
      onCheck={onCheck}
    >
      <Grid item style={{ marginTop: 16 }}>
        <InputLabel id="placeholder" classes={{ root: classes.selectRootLabel }}>
          Choose Plan
        </InputLabel>
        <Select
          labelId="placeholder"
          value={program_id || ''}
          inputProps={{ name: 'age', classes: { root: classes.selectRoot } }}
          onChange={handleChange}
        >
          {_.map(programs, program => (
            <MenuItem value={program.id} key={program.id}>
              {program.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="datetime-local"
          label="When to start"
          type="datetime-local"
          defaultValue={time}
          onChange={handleTimeChange}
          style={{ marginTop: 16, minWidth: '100%' }}
        />
      </Grid>
    </ModeItem>
  )
}

const connectStore = connect(
  (state: ReduxState, { playbook_id }: Props) => ({
    programs: state.playbooks[playbook_id]?.programs,
  }),
  (dispatch: ReduxDispatch, { playbook_id }) => ({
    getPrograms: () => dispatch(PlaybookDispatcher.getPlaybookPrograms(playbook_id)),
  }),
)

export default connectStore(ModeItemAuto)
