import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface ReduxStatePlaybook extends Playbook {
  programs?: Program[]
}

interface State {
  category?: string
  playbook_id?: string
  playbooks: Playbook[]
  name: string
  type_ids?: string[]
  total_count: number
}

const initialState: State = {
  playbooks: [],
  name: '',
  total_count: 0,
}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_CATEGORY: {
      const { categoryName: category } = action.payload
      state = state.merge({ category })
      return state
    }
    case types.SET_PLAYBOOKS: {
      const { playbooks, total_count } = action.payload
      state = state.merge({ playbooks, total_count })
      return state
    }
    case types.SET_NEXT: {
      const { playbooks } = action.payload

      return state.updateIn(['playbooks'], list => list.concat(playbooks))
    }
    case types.SET_PLAYBOOK_ID: {
      const { playbook_id } = action.payload
      state = state.merge({ playbook_id })
      return state
    }
    case types.SET_NAME: {
      const { name } = action.payload
      state = state.merge({ name })
      return state
    }
    case types.SET_TYPES: {
      const { type_ids } = action.payload
      state = state.merge({ type_ids })
      return state
    }
    default:
      return state
  }
}
