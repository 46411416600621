import api, { GetPlaybooksParam } from '@reducers/playbooks/api'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import actions from './actions'

export default {
  getPlaybooks(param: GetPlaybooksParam): DispatcherResponse {
    return async dispatch => {
      const playbooks = await api.getPlaybooks(param)
      dispatch(actions.setPlaybooks(playbooks))
    }
  },
  getOwnPlaybooks(payload?: { category?: string }): DispatcherResponse<Playbook[]> {
    return async dispatch => {
      const playbooks = await api.getOwnPlaybooks(payload)
      dispatch(actions.setPlaybooks({ playbooks }))
      return playbooks
    }
  },
  getPlaybookById(playbook_id: string): DispatcherResponse {
    return async dispatch => {
      const playbook = await api.getPlaybookById(playbook_id)
      if (!playbook) return null
      dispatch(actions.setPlaybooks({ playbooks: [playbook] }))
    }
  },
  getPlaybookPrograms(playbook_id): DispatcherResponse {
    return async dispatch => {
      const programs = await api.getProgramsByPlaybookId(playbook_id)
      dispatch(actions.setPlaybookPrograms({ playbook_id, programs }))
    }
  },
}
