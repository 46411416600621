import { Avatar, Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Agent } from '@reaction-club-types/core'
import React from 'react'
import { deleteIcon } from '../../assets/facade'

const useStyles = makeStyles({}, { classNamePrefix: 'SettingsAdminItem' })

interface Props {
  agent: Agent
  isMe: boolean
  onDelete(agent: Agent): any
}

function SettingsAdminItem(props: Props) {
  const { agent, isMe, onDelete } = props
  const { name, user_id, avatar_url } = agent
  // const classes = useStyles()

  const handleClickDelete = () => {
    onDelete(agent)
  }

  return (
    <Box mb={'10px'}>
      <Grid item key={`settings-admin-${user_id}`}>
        <Grid container direction={'column'}>
          <Grid item container alignItems="center">
            <Box mr={4}>
              <Avatar src={avatar_url} style={{ height: 48, width: 48 }} />
            </Box>
            <Box mr={4}>
              <Typography style={{ fontSize: 20 }}>{name}</Typography>
            </Box>
            {isMe ? null : <img src={deleteIcon} style={{ cursor: 'pointer' }} onClick={handleClickDelete} alt="" />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SettingsAdminItem
