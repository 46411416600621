import { Grid, Typography } from '@mui/material'
import AppDispatcher from '@reducers/app/dispatcher'
import AuthDispatcher from '@reducers/auth/dispatcher'
import { ReactElementLike } from 'prop-types'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import LoadingModal from '../modals/LoadingModal'
import { ReduxDispatch } from '../typings/ReduxDispatch'

const ua = window.navigator.userAgent
const isIE = /MSIE|Trident/.test(ua)

interface Props {
  children: ReactElementLike
}
function AppLauncher(props: Props) {
  const { children } = props

  const dispatch = useDispatch<ReduxDispatch>()
  const installAction = useAsyncFetch(async () => {
    await dispatch(AuthDispatcher.useCredentials())
    await dispatch(AppDispatcher.install())
  })

  // async function installSafe() {
  //   try {
  //     await this.props.install()
  //   } catch (e) {
  //     console.error('installSafe error', e.message)
  //   }
  // }

  if (isIE) {
    return <div style={{ textAlign: 'center', padding: 20 }}>We don't support IE, try any other browser.</div>
  }

  return (
    <RendererStatusSplit
      statuses={installAction}
      renderPreview={() => null}
      renderLoading={() => <LoadingModal isVisible onClose={() => {}} message={`Loading data`} />}
      renderError={(error) => (
        <Grid container justifyContent={'center'}>
          <Typography color={'error'} style={{ marginTop: 40 }}>
            {error}
          </Typography>
        </Grid>
      )}
      render={() => <>{children}</>}
    />
  )
}

export default AppLauncher
