import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import ReactionLoader from '../../../components/ReactionLoader'
import Row from './Row'
import InfoLine from '../workOffers/InfoLine'

const sizes = [3, 3, 2, 4]

const WorkOffers = ({ classes, offers, getOffers, chat }) => {
  React.useEffect(() => {
    if (!offers) {
      getOffers()
    }
  }, [])

  if (!offers) {
    return <ReactionLoader />
  }

  const sortOffers = offersArr => {
    const obj = {
      pending: [],
      accepted: [],
      canceled: [],
    }

    offersArr.forEach(offer => {
      obj[offer.status].push(offer)
    })

    const finalArr = []
    Object.values(obj).forEach(arr => {
      finalArr.push.apply(
        finalArr,
        arr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      )
    })

    return finalArr.sort(a =>
      a.pending_count > 0 ||
      a.matchedCoaches?.find(c => Object.values(chat?.rooms || {}).find(r => r.userId === c.chat_user_id)?.unread > 0)
        ? -1
        : 1,
    )
  }

  return offers.length ? (
    <Grid container>
      <Row
        sizes={sizes}
        className={classes.heading}
        data={['Your offers', 'Created at', 'Status ', 'Applied coaches'].map(name => (
          <Typography className={classes.headingCaption}>{name}</Typography>
        ))}
      />
      <Grid container direction={'column'}>
        {sortOffers(offers).map(d => (
          <InfoLine data={d} sizes={sizes} />
        ))}
      </Grid>
    </Grid>
  ) : (
    <Typography style={{ textAlign: 'center' }}>There is no work offers yet.</Typography>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
  offers: state.coaches.offers,
})
const mapDispatchToProps = dispatch => ({
  getOffers: () => dispatch(coachesDispatcher.getOffers()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  heading: {
    background: '#323232',
    width: '100%',
    marginBottom: 10,
  },
  headingCaption: {
    fontSize: 14,
    fontWeight: 800,
    color: '#fff',
  },
})

export default connectStore(withStyles(styles)(WorkOffers))
