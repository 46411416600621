/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React, { ReactNode } from 'react'
import arrowDown from '../../assets/arrowDown.svg'

interface Props {
  children: ReactNode
  onClick(): any
}

function ActionButtonContainer(props: Props) {
  const { children, onClick } = props

  return (
    <button
      type="button"
      onClick={onClick}
      css={[
        css({
          width: '100%',
          cursor: 'pointer',
          borderRadius: 5,
          background: 'transparent',
          border: '1px solid #D4D4D4',
          marginBottom: 10,
          outline: 'none',
          padding: '0 30px',
        }),
        css`
          min-height: 103px;

          @media (max-width: 500px) {
            padding: 0 10px;
            min-height: 80px;
          }
        `,
      ]}
    >
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item flexGrow={1} style={{ width: 1 }}>
          {children}
        </Grid>
        <Grid item>
          <img src={arrowDown} alt="arrow down" />
        </Grid>
      </Grid>
    </button>
  )
}

export default ActionButtonContainer
