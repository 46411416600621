import { AssetFull } from '@reaction-club-types/core'
import _ from 'lodash'
import React from 'react'
import AssetImage, { ImageAssetProps } from './types/AssetImage'

interface Props extends ImageAssetProps {
  asset: AssetFull
}

function Asset(props: Props) {
  const { asset } = props
  if (_.isEmpty(asset)) return null

  const isBasic = _.isEmpty(asset.sources) || _.isEmpty(asset.sources[0])

  if (isBasic) return <AssetImage {...props} asset={{ url: asset.url }} />

  if (asset.mime_type.includes('image')) return <AssetImage {...props} asset={asset} />

  return <h3 color={'red'}>Such asset is not supported</h3>
}

export default Asset
