/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CompanyStockFull } from '@reaction-club-types/core'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import AssetPicture from '../../../components/asset/AssetPicture'
import StockItemMenu from './StockItemMenu'

const useStyles = makeStyles(
  {
    description: {
      fontSize: 12,
      lineHeight: '18px',
      // height: 38,
      // overflow: 'hidden',
    },
    label: {
      color: '#556262',
      marginRight: 5,
      display: 'inline-block',
    },
    value: {
      fontWeight: 'bold',
    },
    actionsContainer: {
      opacity: 0,
      position: 'absolute',
      top: 5,
      right: 5,
      padding: 4,
      backgroundColor: 'rgba(250,250,250,0.5)',
      borderRadius: '50%',
    },
    hiddenContainer: {
      fontSize: 12,
      paddingTop: 3,
      paddingBottom: 5,
      backgroundColor: '#ff7580',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    hiddenIcon: {
      fontSize: 18,
      marginRight: 6,
    },

    outOfStockText: {
      color: '#db2333',
      fontWeight: 'bold',
      fontSize: 12,
    },
  },
  {
    classNamePrefix: 'StockItem',
  },
)

interface Props {
  stock: CompanyStockFull | Immutable<CompanyStockFull>
}

function StockItem(props: Props) {
  const { stock } = props
  const { asset, name, description, quantity, price, is_visible } = stock
  const classes = useStyles()

  return (
    <div
      css={css`
        margin-right: 10px;
        margin-bottom: 20px;
        width: 200px;
        position: relative;

        &:hover .actionsContainer {
          opacity: 1;
        }

        @media (max-width: 420px) {
          width: 100%;
          margin-bottom: 30px;
        }
      `}
    >
      {is_visible ? null : (
        <Grid container alignItems={'center'} justifyContent={'center'} className={classes.hiddenContainer}>
          <VisibilityOffIcon className={classes.hiddenIcon} />
          Item is hidden
        </Grid>
      )}
      <div className={`actionsContainer ${classes.actionsContainer}`}>
        <StockItemMenu stock={stock} />
      </div>
      <AssetPicture asset={asset} />

      <Typography fontWeight={'bold'}>{name}</Typography>
      <Typography
        css={css`
          font-size: 12px;
          line-height: 18px;
        `}
      >
        {description}
      </Typography>

      <div>
        <span className={classes.label}>Price:</span>
        <span className={classes.value}>{price}</span>
      </div>
      <div>
        <span className={classes.label}>Quantity:</span>
        <span className={classes.value}>
          {quantity || <span className={classes.outOfStockText}>out of stock</span>}
        </span>
      </div>
    </div>
  )
}

export default StockItem
