import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import moment, { Moment } from 'moment-timezone'
import React, { useMemo } from 'react'
import trophyIcon from '../assets/trophy.png'
import { Typography } from '@mui/material'

const weekDays = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
]

const getDates = (startDate, endDate) => {
  const datesArray: Moment[] = []

  let currentDate = startDate.clone()
  while (currentDate.isSameOrBefore(endDate)) {
    datesArray.push(moment(currentDate.toDate()))
    currentDate.add(1, 'day')
  }

  return datesArray
}

const ProgressBar = ({ startAt, endAt, width, duration, currentChallengeDay, disabled }) => {
  const classes = useStyles()
  const calculateProgressWidth = (total, curr) => {
    if (curr <= 0) {
      return {
        width: 0,
      }
    }

    if (curr > total) {
      return {
        width: '100%',
      }
    }

    return {
      width: `${(100 * curr) / total}%`,
    }
  }

  const startDate = moment(startAt)
  const days = duration
  const slicedWeekdays = useMemo(() => {
    if (days > 7) {
      const datesArray = getDates(startDate, endAt)
      const numDatesToPick = 4

      const selectedDates: Moment[] = []
      selectedDates.push(datesArray[0])
      const stepSize = Math.ceil((datesArray.length - 1) / numDatesToPick)
      for (let i = stepSize; i < datesArray.length - 1; i += stepSize) {
        selectedDates.push(datesArray[i])
      }
      selectedDates.push(datesArray[datesArray.length - 1])

      return selectedDates.map((d) => d.format('D MMM'))
    }

    const firstDay = startDate.day()
    const slicedWeekDays = weekDays.slice(firstDay, firstDay + days)

    return slicedWeekDays
  }, [startDate, days])

  return (
    <div className={classes.container}>
      <div className={classnames([classes.progressBar])} style={{ width }}>
        <div
          className={classes.progress}
          style={calculateProgressWidth(days > 7 ? days : days - 1, disabled ? 0 : currentChallengeDay - 1)}
        />
      </div>
      <div className={classes.bulletContainer} style={{ width }}>
        {slicedWeekdays.map((label, index) => (
          <div key={index} className={classes.bulletWrapper}>
            <div className={classnames(classes.bullet, disabled && classes.disabledBullet)} />
            <Typography className={classes.label}>{label}</Typography>
          </div>
        ))}
        <img src={trophyIcon} className={classes.trophy} />
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  bullet: {
    backgroundColor: '#21990D',
    borderRadius: 7,
    height: 14,
    marginBottom: 5,
    position: 'absolute',
    top: -9,
    width: 14,
  },
  bulletContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  bulletWrapper: {
    alignItems: 'center',
  },
  container: {
    marginTop: 15,
    marginBottom: 15,
    alignItems: 'flex-start',
    flex: 1,
    padding: '4px',
  },
  label: {
    color: '#25283D',
    fontSize: 10,
    fontWeight: '300',
    position: 'absolute',
    top: 5,
    width: 40,
    marginLeft: -16,
    textAlign: 'center',
  },
  progress: {
    backgroundColor: '#44EA29',
    borderRadius: 5,
    height: '100%',
  },
  progressBar: {
    backgroundColor: '#eee',
    borderRadius: 5,
    height: 4,
  },
  trophy: {
    height: 35,
    position: 'absolute',
    objectFit: 'contain',
    right: -50,
    top: -15,
    width: 35,
  },
  disabledBullet: {
    backgroundColor: '#979797',
  },
})

export default ProgressBar
