import { Box, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { ExtractConnectType } from 'src/typings/ReduxExtractor'
import ReactionLoader from '../components/ReactionLoader'
import copyLink from '../components/modals/components/inviteMember/copyLink.svg'
import ModalStyled from '../elements/ModalStyled'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractStyles } from '../typings/stylesExtractor'

interface Props {
  isVisible: boolean
  onClose(): any
}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

interface State {
  isCopied: boolean
  isLoading: boolean
}

class InviteMember extends React.PureComponent<PropsInner, State> {
  state = {
    isCopied: false,
    isLoading: false,
  }

  render() {
    const { classes, linkObject, company, isVisible, onClose } = this.props
    const { isCopied, isLoading } = this.state

    return (
      <ModalStyled isVisible={isVisible} onClose={onClose} showCloseButton>
        <Box paddingTop={'10px'}>
          <Grid container>
            <Typography className={classes.caption}>Invite admin to {company.name}</Typography>
            {!isLoading ? (
              <CopyToClipboard text={linkObject?.inner_link || ''} onCopy={this.handleCopy}>
                <button type="button" className={classes.actionButton}>
                  <Grid container direction={'row'} alignItems={'center'}>
                    <img src={copyLink} alt="copy link" />
                    {!isCopied ? (
                      <Typography className={classes.actionText}>Copy Link</Typography>
                    ) : (
                      <Typography className={[classes.actionText, classes.linkCopied].join(' ')}>
                        Link Copied!
                      </Typography>
                    )}
                  </Grid>
                </button>
              </CopyToClipboard>
            ) : (
              <ReactionLoader />
            )}
          </Grid>
        </Box>
      </ModalStyled>
    )
  }

  handleCopy = () => this.setState({ isCopied: true })

  fetchData = async () => {
    const { linkObject, createInvite } = this.props
    if (linkObject?.link) return

    this.setState({ isLoading: true })
    try {
      await createInvite()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    company: state.company,
    linkObject: state.company.hrLink,
  }),
  (dispatch: ReduxDispatch) => ({
    createInvite: () => dispatch(CompanyDispatcher.createInviteHrLink()),
  }),
)

const connectStyles = withStyles({
  paper: {
    width: 507,
  },
  caption: {
    fontWeight: 800,
    fontSize: 18,
    marginBottom: 20,
  },
  actionText: {
    marginLeft: 15,
  },
  actionButton: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    padding: '20px 15px',
    background: 'transparent',
    border: '1px solid #D4D4D4',
    outline: 'none',
  },
  linkCopied: {
    color: '#37AF1D',
  },
})

export default compose<PropsInner, Props>(connectStyles, connectStore)(InviteMember)
