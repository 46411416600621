import { Grid } from '@mui/material'
import React from 'react'

interface Props {
  subject: string
  body: string
  className?: string
}

class EmailWrapper extends React.PureComponent<Props> {
  render() {
    const { children, className } = this.props
    return (
      <Grid className={className} onClick={this.handleClick}>
        {children}
      </Grid>
    )
  }

  handleClick = event => {
    event.preventDefault()
    window.open(
      this.createMailtoLink('', {
        subject: this.props.subject,
        body: this.props.body,
      }),
      '_blank',
    )
  }

  toSearchString = (searchParams = {}) => {
    return Object.keys(searchParams)
      .map(key => `${key}=${encodeURIComponent(searchParams[key])}`)
      .join('&')
  }

  createMailtoLink = (email, headers: { subject?: string; body?: string; cc?: string }) => {
    let link = `mailto:${email}`
    if (headers) {
      link += `?${this.toSearchString(headers)}`
    }
    return link
  }
}

export default EmailWrapper
