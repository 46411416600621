import { Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import StockDispatcher from '@reducers/stocks/dispatcher'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PaperContainer from '../../components/PaperContainer'
import OrdersList from './orders/OrdersList'

const useStyles = makeStyles(
  {
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
      marginRight: 10,
    },
    headContainer: {
      marginBottom: 10,
    },
  },
  {
    classNamePrefix: 'StockOrders',
  },
)

const StockOrders = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  console.log('props', props)

  async function fetchData() {
    dispatch(StockDispatcher.getOrders())
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <PaperContainer>
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.headContainer}>
        <Grid item>
          <Typography className={classes.title}>List of Orders</Typography>
        </Grid>
      </Grid>

      <OrdersList />
    </PaperContainer>
  )
}

export default StockOrders
