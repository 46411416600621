// COACHES REQUESTS
import { prefix_agent } from './api'

export default {
  /**
   * Get list Coaches
   * @returns Object - contain array coach inside
   */
  async getStoreCoaches() {
    const result = await this.apiClient.get(`${prefix_agent}coaches`)
    return result.data
  },
  /**
   * Get detail about one Coach
   * @param coachId - coach number id
   * @returns Object - detail about coach inside
   */
  async getCoachById(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}`)
    return result.data
  },
  /**
   * Assigned roads to coach
   * @param coachId - coach number id
   * @param roads - array roads id [0,1]
   * @returns Object - status inside
   */
  async setCoachRoads(coachId, roads) {
    const result = await this.apiClient.post(`${prefix_agent}coach/${coachId}/roads`, roads)
    return result.data
  },
  async getCoachCompanies(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}/companies`)
    return result.data
  },
  async getCoachRoads(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}/roads`)
    return result.data
  },
  async getCoachEmployees(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}/employees`)
    return result.data
  },
  async updateCoach(coachId, coachModel) {
    const result = await this.apiClient.post(`${prefix_agent}coach/${coachId}`, coachModel)
    return result.data
  },
  async getCoachActiveDevices(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}/devices`)
    return result.data
  },
  async getCoachPlaybook(coachId) {
    const result = await this.apiClient.get(`${prefix_agent}coach/${coachId}/paybooks`)
    return result.data
  },
}
