/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Coach } from '@reaction-club-types/core'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { components, Props as SelectProps } from 'react-select'
import SelectAssetOption from '../../../../elements/select/SelectAssetOption'
import EventFormLabel from './elements/EventFormLabel'
import EventFormSelect from './elements/EventFormSelect'

interface Props {
  coach_id: string
  isErrored?: boolean
  onChange(type: string | undefined): any
}

export default function EventCoachSelect(props: Props) {
  const { coach_id, onChange, isErrored } = props
  const { coaches } = useSelector((state: ReduxState) => ({
    coaches: state.coachesList,
  }))

  const options = _.map(coaches, (coach) => ({
    value: coach.user_id,
    label: coach.name,
    item: coach,
  }))

  options.unshift({ label: 'none', value: null })

  let controlStyle: { borderColor?: string } = {}
  if (isErrored) {
    controlStyle.borderColor = 'red'
  }

  return (
    <div
      css={css`
        position: relative;
        margin-bottom: 20px;
      `}
    >
      <EventFormLabel title={'Coach'} />
      <EventFormSelect
        placeholder={'Select Coach'}
        value={coach_id}
        onChange={onChange}
        options={options}
        isErrored={isErrored}
        components={{
          Option: (optionProps) => {
            const { data, innerProps } = optionProps
            const { item } = data as { item: Coach }
            if (!item) {
              return (
                <components.Option
                  {...optionProps}
                  css={css`
                    min-height: 44px;
                  `}
                />
              )
            }

            const coachItem = {
              name: item.name,
              asset: { url: item.avatar_url },
            }

            return <SelectAssetOption item={coachItem} innerProps={innerProps} />
          },
        }}
      />
    </div>
  )
}
