import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  eventsConfig?: any
}

const initialState: State = {
  // userStepsDailyList,
}

const slice = createSlice({
  name: 'configs',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setEventConfigs(state, action: PayloadAction<any>) {
      state.eventsConfig = action.payload
    },
  },
})

export const { actions, reducer } = slice
export default reducer
