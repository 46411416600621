import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_CATEGORY: '',
    SET_PLAYBOOKS: '',
    SET_PLAYBOOK_ID: '',
    SET_PLAYBOOK: '',
    SET_NAME: '',
    SET_TYPES: '',
    SET_NEXT: '',
  },
  { prefix: 'GROUP_EDIT__' },
)
