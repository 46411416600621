import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import 'reset-css/reset.css'
import '@fontsource/open-sans'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/300.css'
import '@fontsource/mulish'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/poppins'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/300.css'
import '@fontsource/manrope'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/400.css'
import './app/styles.css'
import './App.less'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
