interface Props {
  days?: number
  hours: number
  minutes?: number
  seconds?: number
  isZeroMinutesVisible?: boolean
  isZeroHoursVisible?: boolean
  isZeroDaysVisible?: boolean
  isSecondsVisible?: boolean
}
function getFormattedTime(params: Props) {
  let { isZeroMinutesVisible, isZeroHoursVisible, isZeroDaysVisible, isSecondsVisible = true } = params
  if (isZeroDaysVisible) isZeroHoursVisible = true
  if (isZeroHoursVisible) isZeroMinutesVisible = true

  let hours: number | string = params.hours || 0
  let days: number | string = params.days || 0
  let minutes: number | string = params.minutes || 0
  let seconds: number | string = params.seconds || 0

  let formatted = ''
  // if (days > 1) {
  //   formatted = `${days} days `
  //   hours -= days * 24
  // }

  // if (days && hours < 10) minutes = `0${days}`
  if (days > 1 && hours < 10) hours = `0${hours}`
  if ((hours || isZeroMinutesVisible) && minutes < 10) minutes = `0${minutes}`
  if ((minutes || isZeroMinutesVisible) && seconds < 10) seconds = `0${seconds}`

  // formatted += _.compact([hours, minutes, seconds]).join(':') || 'refresh
  let timeFormattedArr: (string | number)[] = []
  if (isSecondsVisible) {
    timeFormattedArr.push(seconds)
  }

  if (minutes || isZeroMinutesVisible) {
    timeFormattedArr.unshift(minutes)
  }

  if (hours || isZeroHoursVisible) {
    timeFormattedArr.unshift(hours)
  }

  formatted = timeFormattedArr.join(':')
  if (days || isZeroDaysVisible) formatted = `${days} days ${formatted}`

  return formatted
}

export default getFormattedTime
