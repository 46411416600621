import axios from 'axios'
import config from '../../../config'

const instance = axios.create({
  baseURL: config.assets_api_url,
})

patchMethod('get')
patchMethod('post')
patchMethod('delete')

export default instance

function patchMethod(methodName) {
  const originName = `${methodName}Original`
  instance[originName] = instance[methodName]
  instance[methodName] = async (...args) => {
    try {
      return await instance[originName](...args)
    } catch (e) {
      const logArgs = ['[ERROR] API']
      if (e.response) {
        const { status, data } = e.response
        logArgs.push(status)
        logArgs.push(data)
      } else logArgs.push(e.message)
      console.log(...logArgs)
      throw e
    }
  }
}
