import actions from './actions'
import api from './api'
import './reducer'

class Dispatcher {
  actions = actions

  getOffers() {
    return async dispatch => {
      const offers = await api.getOffers()
      offers.forEach(({ id }) => dispatch(this.getOfferMatchedCoaches(id)))
      dispatch(actions.setOffers(offers))
    }
  }

  getCoach(id) {
    return async dispatch => {
      const coach = await api.getCoach(id)
      dispatch(actions.setFullCoach(coach))
    }
  }

  getOfferMatchedCoaches(id) {
    return async dispatch => {
      const matchedCoaches = await api.getOfferMatchedCoaches(id)

      dispatch(
        actions.setOfferMatchedCoaches(
          id,
          matchedCoaches.coaches.map(({ coach, coach_applied_at, created_at }) => ({
            ...coach,
            applied_at: coach_applied_at || created_at,
          })),
        ),
      )
    }
  }

  cancelOffer(id) {
    return async dispatch => {
      const cancelOfferRes = await api.cancelOffer(id)
      dispatch(this.getOffers())

      return cancelOfferRes
    }
  }

  acceptCoach(param) {
    return async dispatch => {
      const acceptCoachRes = await api.acceptCoach(param)
      dispatch(this.getOffers())

      return acceptCoachRes
    }
  }

  declineCoach({ offer_id, coach_id }) {
    return async dispatch => {
      const declineCoachRes = await api.declineCoach({ offer_id, coach_id })
      dispatch(this.getOffers())

      return declineCoachRes
    }
  }

  postOffer() {
    return async (dispatch, getState) => {
      const {
        coaches: {
          offer: {
            type_ids,
            serviceType,
            location,
            language,
            coordinates,
            description,
            currency,
            period,
            startDate,
            budget,
            groupId,
          },
        },
      } = getState()

      await api.postOffer({
        type_ids,
        type: serviceType,
        location: {
          ...(coordinates && {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
          }),
          formattedAddress: location,
          formatted_address: location,
        },
        language,
        ...(coordinates && {
          coordinates: {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
          },
        }),
        description,
        proposed_currency: currency,
        period,
        proposed_price: budget,
        ...(groupId && { playbook_instance_id: groupId }),
        start_at: startDate,
      })
      dispatch(this.getOffers())
      dispatch(actions.resetOffer())
    }
  }
}

const CoachDispatcher = new Dispatcher()
export default CoachDispatcher
