import { Avatar } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import AssetImage, { ImageAssetProps } from './types/AssetImage'

interface Props {
  style: React.CSSProperties
  className?: string
}

function ReactionAvatar(props: Props & ImageAssetProps) {
  const { style, className, asset } = props

  if (_.isEmpty(asset)) return <Avatar src={''} style={style} className={className} />

  return (
    <Avatar style={style} className={className}>
      <AssetImage resizeMode={'cover'} {...props} />
    </Avatar>
  )
}

export default ReactionAvatar
