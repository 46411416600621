import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import * as React from 'react'
import DatePicker from 'react-datepicker'
import CalendarIcon from '../assets/calendar.svg'
import CardIcon from '../assets/card.svg'

const currencies = ['usd', 'eur', 'ils']
const periods = ['month', 'day', 'hour', 'session']

const AgreementDetailsForm = ({
  classes,
  budget,
  errors = {},
  period = '',
  currency = '',
  startDate,
  description,
  setBudget,
  setPeriod,
  setCurrency,
  setStartDate,
  setDescription,
  withPriceNote = false,
  budgetLabel = 'Budget',
  descriptionLabel = 'Description',
}) => {
  React.useEffect(() => {
    if (!currency) {
      setCurrency(currencies[0])
    }
    if (!period) {
      setPeriod(periods[0])
    }
  }, [])

  return (
    <Grid item xs>
      <Box className={classes.budgetWrapper} style={{ ...(!withPriceNote && { marginBottom: 36 }) }}>
        <TextField
          variant={'outlined'}
          value={budget}
          onChange={e => setBudget(e.target.value)}
          style={{ width: '100%' }}
          InputProps={{
            className: classes.budget,
            placeholder: budgetLabel,
            type: 'number',
          }}
        />
        <img src={CardIcon} className={classes.budgetIcon} alt="" />
        <Box className={classes.selectWrapper}>
          <Select
            value={currency}
            inputProps={{ name: 'currency' }}
            onChange={e => setCurrency(e.target.value)}
            className={classes.selectRoot}
            style={{ width: 60, marginRight: 16 }}
          >
            {currencies.map(c => (
              <MenuItem value={c} key={c}>
                {c.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={period}
            inputProps={{ name: 'period' }}
            onChange={e => setPeriod(e.target.value)}
            className={classes.selectRoot}
            style={{ width: 100 }}
          >
            {periods.map(p => (
              <MenuItem value={p} key={p}>
                Per {p}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      {withPriceNote && (
        <Typography className={classes.note}>* Leave blank if you'd like coaches to offer the price </Typography>
      )}
      <Box className={classes.dateWrapper}>
        <DatePicker
          selected={startDate}
          className={errors.startDate ? classes.error : ''}
          onChange={date => setStartDate(date)}
          placeholderText="Start date"
        />
        <img src={CalendarIcon} className={classes.dateIcon} alt="" />
      </Box>
      <TextField
        variant={'outlined'}
        label={descriptionLabel}
        multiline
        value={description}
        onChange={e => setDescription(e.target.value)}
        className={classes.description}
        rows={8}
        InputLabelProps={{ style: { color: '#979797' } }}
      />
    </Grid>
  )
}

const styles = createStyles({
  description: {
    color: '#231F20',
    width: '100%',
  },
  dateWrapper: {
    zIndex: 2,
    position: 'relative',
    marginBottom: '36px',
  },
  selectWrapper: {
    position: 'absolute',
    right: 30,
    top: 16,
  },
  selectRoot: {
    fontSize: '14px',
    '&:before': {
      border: 'none',
    },
  },
  note: {
    fontSize: '13px',
    color: '#979797',
    marginBottom: 36,
  },
  budget: {
    paddingLeft: '64px !important',
    paddingRight: '200px !important',
    borderRadius: '8px',
    minHeight: 60,
    fontSize: '14px',
    width: '100%',
  },
  budgetWrapper: {
    position: 'relative',
    marginBottom: 8,
  },
  budgetIcon: {
    top: 18,
    left: 22,
    position: 'absolute',
  },
  dateIcon: {
    top: 18,
    left: 22,
    position: 'absolute',
  },
  error: {
    borderColor: 'red !important',
  },
})

export default withStyles(styles)(AgreementDetailsForm)
