import SearchIcon from '@mui/icons-material/Search'
import { Grid, IconButton, Input, Typography } from '@mui/material'
import { withStyles, WithStyles } from '@mui/styles'
import categoryDispatcher from '@reducers/categories/dispatcher'
import groupEditDispatcher from '@reducers/changeProgram/dispatcher'
import { ReduxState } from '@reducers/index'
import PlaybooksDispatcher from '@reducers/playbooks/dispatcher'
import _ from 'lodash'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import ReactionLoader from '../../../components/ReactionLoader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { CHOOSE_CATEGORY_PAGE } from './ChooseCategory'
import PlaybookCard from './components/PlaybookCard'

const styles = {
  caption: {
    fontSize: 24,
    fontWeight: 800,
    marginBottom: 30,
  },

  searchInput: {
    border: '1px solid #D4D4D4',
    borderRadius: 6,
    width: 257,
    height: 41,
    padding: '20px 0 20px 20px',
    marginTop: '-10px',
  },

  inputInner: {
    padding: 0,
  },

  playbookContainerMock: {
    margin: '20px 0',
    minWidth: 200,
    width: '33%',
    maxWidth: 324,
  },

  type: {
    marginRight: 10,
    backgroundColor: '#EEEEEE',
    padding: '3px 14px',
    fontSize: 12,
    borderRadius: 8,
    cursor: 'pointer',
  },

  selectedType: {
    backgroundColor: '#F9B22D',
    color: '#fff',
  },

  titleSeparator: {
    marginTop: 30,
  },
}

interface State {
  isLoading: boolean
  selectedTypes: string[]
  isLoadingNext: boolean
  own_playbooks: Playbook[]
}

class PlaybookSelect extends React.PureComponent<
  ExtractConnectType<typeof connectStore> & WithStyles<typeof styles> & RouteComponentProps<{ instance_id }, State>
> {
  scrollRef

  state: State = {
    isLoading: false,
    selectedTypes: [],
    isLoadingNext: false,
  }

  render() {
    const { classes, category, instance_id } = this.props
    const { isLoading } = this.state

    if (!category) {
      return <Redirect to={CHOOSE_CATEGORY_PAGE(instance_id)} />
    }

    return (
      <Grid style={{ margin: '0 22px' }}>
        <Grid direction={'column'} container>
          <Grid item style={{ margin: '0 28px' }}>
            <Grid container justifyContent={'space-between'}>
              <Typography className={classes.caption}>Add Playbook</Typography>
              <Input
                disableUnderline
                placeholder={'Search'}
                className={classes.searchInput}
                margin={'none'}
                classes={{ input: classes.inputInner }}
                onChange={this.handleInputChange}
                endAdornment={
                  <IconButton size="large">
                    <SearchIcon htmlColor={'#F9B22D'} />
                  </IconButton>
                }
              />
            </Grid>
            {this.renderCategories()}
          </Grid>
        </Grid>
        {!isLoading && this.renderScroll()}
      </Grid>
    )
  }

  renderScroll = () => {
    const { playbooks = [], total_count = 0 } = this.props

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.handleLoadMore}
        hasMore={total_count > playbooks?.length || 0}
        loader={<ReactionLoader />}
        useWindow={false}
        getScrollParent={() => this.scrollRef}
      >
        {this.renderOwnPlaybooks()}
        {this.renderPlaybooks()}
      </InfiniteScroll>
    )
  }

  renderPlaybooks = () => {
    const { playbooks, classes } = this.props
    if (!playbooks) return null

    return (
      <Grid container justifyContent={'space-between'}>
        {playbooks.map((playbook) => (
          <PlaybookCard playbook={playbook} onClick={this.handleSelectPlaybook} key={`playbook-item-${playbook.id}`} />
        ))}
        <Grid item className={classes.playbookContainerMock} />
        <Grid item className={classes.playbookContainerMock} />
      </Grid>
    )
  }

  renderCategories = () => {
    const { types, classes } = this.props
    if (!types) return null
    const { selectedTypes } = this.state
    return (
      <Grid container direction={'row'}>
        {types.map((type) => {
          const isSelected = selectedTypes.includes(type.id)
          return (
            <Typography
              onClick={this.handleTypeClick(type.id)}
              className={[classes.type, isSelected ? classes.selectedType : ''].join(' ')}
              key={type.id}
            >
              {type_data.name}
            </Typography>
          )
        })}
      </Grid>
    )
  }

  renderOwnPlaybooks() {
    const { classes, name } = this.props
    const { own_playbooks } = this.state

    let playbooksFiltered = own_playbooks
    if (name) {
      const nameLow = name.toLowerCase()
      playbooksFiltered = playbooksFiltered.filter((playbook) => playbook.name.toLowerCase().includes(nameLow))
    }
    if (_.isEmpty(playbooksFiltered)) return null

    return (
      <div>
        <Typography variant={'h4'} align={'center'} className={classes.titleSeparator}>
          Company Playbooks:
        </Typography>
        <Grid container justifyContent={'space-between'}>
          {playbooksFiltered.map((playbook) => (
            <PlaybookCard
              playbook={playbook}
              onClick={this.handleSelectPlaybook}
              key={`playbook-own-item-${playbook.id}`}
            />
          ))}
          <Grid item className={classes.playbookContainerMock} />
          <Grid item className={classes.playbookContainerMock} />
        </Grid>

        <Typography variant={'h4'} align={'center'} className={classes.titleSeparator}>
          Store Playbooks:
        </Typography>
      </div>
    )
  }

  handleTypeClick = (type_id: string) => () => {
    const { selectedTypes } = this.state
    let updatedTypes: string[] = []
    if (selectedTypes.includes(type_id)) {
      updatedTypes = selectedTypes.filter((type) => type !== type_id)
    } else {
      updatedTypes = selectedTypes.concat([type_id])
    }
    this.setState({ selectedTypes: updatedTypes })
    this.props.setTypes(updatedTypes)
    this.getPlaybooksDebounced()
  }

  handleSelectPlaybook = (playbook_id: string) => {
    const { instance_id } = this.props
    this.props.setPlaybookId(playbook_id)
    this.props.history.push(`/groups/${instance_id}/training-program/playbook/${playbook_id}`)
  }

  handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.props.setName(e.target.value || '')
    this.getPlaybooksDebounced()
  }

  handleLoadMore = _.throttle(
    async () => {
      if (this.state.isLoading) return null
      if (this.state.isLoadingNext) return null

      this.state.isLoadingNext = true

      try {
        await this.props.getPlaybooksNext()
      } finally {
        this.setState({
          isLoadingNext: false,
        })
      }
    },
    1000,
    { maxWait: 300 },
  )

  getPlaybooksDebounced = _.debounce(
    function fetch() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.props.getPlaybooks()
    },
    1000,
    { maxWait: 300 },
  )

  fetchOurPlaybooks = async () => {
    const playbooks = await this.props.getOwnPlaybooks({})
    this.setState({ own_playbooks: playbooks || [] })
  }

  fetchData = async () => {
    this.setState({ isLoading: true })
    await this.fetchOurPlaybooks()
    await this.props.getPlaybooks()
    this.setState({ isLoading: false })
    const { category } = this.props
    if (category) this.props.getTypes(category)
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

export const SELECT_PLAYBOOK_PAGE = (instance_id: string) => `/groups/${instance_id}/training-program/playbook`

const connectStore = connect(
  (state: ReduxState, { match }: RouteComponentProps<{ instance_id }>) => ({
    instance_id: match.params.instance_id,
    category: state.changeProgram.category,
    playbooks: state.changeProgram.playbooks,
    total_count: state.changeProgram.total_count,
    name: state.changeProgram.name,
    types: state.categories[state.changeProgram.category?.toLowerCase() as string]?.types,
  }),
  (dispatch: ReduxDispatch) => ({
    getPlaybooks: () => dispatch(groupEditDispatcher.getPlaybooks()),
    getOwnPlaybooks: (params: Parameters<typeof PlaybooksDispatcher.getOwnPlaybooks>[0]) =>
      dispatch(PlaybooksDispatcher.getOwnPlaybooks(params)),
    getPlaybooksNext: () => dispatch(groupEditDispatcher.getPlaybooksNext()),
    setPlaybookId: (playbook_id: string) => dispatch(groupEditDispatcher.setPlaybookId(playbook_id)),
    setName: (name: string) => dispatch(groupEditDispatcher.setName(name)),
    setTypes: (types: string[]) => dispatch(groupEditDispatcher.setTypes(types)),
    getTypes: (category: string) => dispatch(categoryDispatcher.getCategoryTypes(category)),
  }),
)

export default compose(withRouter, connectStore, withStyles(styles))(PlaybookSelect)
