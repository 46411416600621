import { Grid, Typography, Box, Button, Link } from '@mui/material'
import React from 'react'

// const useStyles = makeStyles({
//   optionContainer: {
//     // padding
//   },
// })
// 

interface Props {
  onCreateCompany(): any
}

function CompanyQuestion(props: Props) {
  const handlePressCreateCompany = () => {
    props.onCreateCompany()
  }

  return (
    <div>
      <Typography variant={'h1'}>Company manager</Typography>
      <Typography variant={'subtitle1'}>You need to create or join company to continue</Typography>

      <div style={{ maxWidth: 300 }}>
        <Box sx={{ padding: '10px', mt: '20px' }}>
          <Grid container alignItems={'center'} direction={'column'}>
            <Grid>
              <Button variant={'contained'} color={'primary'} onClick={handlePressCreateCompany}>
                Create a new company
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default CompanyQuestion
