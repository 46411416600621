/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import theme from '../../app/theme'
import ButtonOrange from '../../components/ButtonOrange'
import ModalStyled from '../../elements/ModalStyled'

const useStyles = makeStyles({
  removeButton: {
    fontFamily: 'Poppins',
    padding: '12px 28px',
    fontSize: 16,
    border: '2px solid #FB3353',
    margin: '20px auto 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: '5px 15px',
    },
  },
})

export const RemoveChallenge = ({ challengeId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const handleToggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog)
  }

  const handleDeleteChallenge = async () => {
    await dispatch(ChallengeDispatcher.deleteChallenge(challengeId))
    handleToggleDeleteDialog()
    window.location = '/'
  }

  return (
    <Fragment>
      <IconButton aria-label="delete" onClick={handleToggleDeleteDialog}>
        <DeleteIcon color="error" />
      </IconButton>
      <ModalStyled
        isVisible={showDeleteDialog}
        onClose={handleToggleDeleteDialog}
        showCloseButton
        css={css`
          width: 520px;
        `}
      >
        <DialogTitle textAlign="center" fontSize={21} fontWeight="bold">
          Are you sure?
        </DialogTitle>
        <DialogContent align="center">
          <DialogContentText color="black" textAlign="center">
            Once you remove the activity all data and history will be lost and members will not see the activity on
            their app
          </DialogContentText>
          <ButtonOrange className={classes.removeButton} color="transparent" onClick={handleDeleteChallenge}>
            Remove
          </ButtonOrange>
        </DialogContent>
      </ModalStyled>
    </Fragment>
  )
}
