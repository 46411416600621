import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_OFFER_SUBCATEGORY: '',
    SET_OFFER_IS_NEW: '',
    SET_OFFER_SERVICE_TYPE: '',
    SET_OFFER_LOCATION: '',
    SET_OFFER_LANGUAGE: '',
    SET_OFFER_COORDINATES: '',
    SET_OFFER_DESCRIPTION: '',
    SET_OFFER_CURRENCY: '',
    SET_OFFER_PERIOD: '',
    SET_OFFER_START_DATE: '',
    SET_OFFER_BUDGET: '',
    SET_OFFER_CATEGORY: '',
    RESET_OFFER: '',
    SET_OFFERS: '',
    SET_OFFER_MATCHED_COACHES: '',
    SET_OFFER_GROUP: '',
    SET_FULL_COACH: '',
  },
  { prefix: 'Coaches__' },
)
