/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, TextField, Typography } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { CompanyStock } from '@reaction-club-types/core'
import { ApiPayloadStockCreate } from '@reducers/stocks/api'
import _ from 'lodash'
import React from 'react'
import { Immutable } from 'seamless-immutable'
import validate from 'validate.js'
import ButtonOrange from '../../../components/ButtonOrange'
import AssetEditable from '../../../components/asset/AssetEditable'
import { ExtractStyles } from '../../../typings/stylesExtractor'

interface Errors {
  name?: string
  description?: string
  price?: string
  quantity?: string
}

interface State {
  name: string
  description: string
  asset: Asset | null
  price: number
  quantity: number
  isLoading: boolean
  errors: Errors
  submitErrorMessage?: string
}

interface Props {
  stock: CompanyStock | Immutable<CompanyStock>
  mode?: 'create' | 'update'
  onExecute(payload: ApiPayloadStockCreate): Promise<any>
}


class StockForm extends React.PureComponent<Props, State> {
  state: State = {
    name: '',
    description: '',
    quantity: 1,
    price: 50,
    // playbook_instance_id: null,
    errors: {},
    submitErrorMessage: '',
    isLoading: false,
    asset: null,
  }

  constructor(props: PropsInner) {
    super(props)

    const { stock } = props

    if (stock) {
      this.state.asset = stock.asset
      this.state.name = stock.name
      this.state.description = stock.description
      this.state.quantity = stock.quantity
      this.state.price = stock.price
    }
    // const { event } = props
    //
    // this.state.name = event.name
    // this.state.coach_id = event.coach_id
    // this.state.location = event.location_formatted
    // this.state.description = event.description
    // this.state.time = moment(event.time).toDate()
  }

  render() {
    const { name, description, quantity, price, errors, isLoading, asset, submitErrorMessage } = this.state
    const { mode } = this.props

    let buttonText = 'Create'
    if (mode === 'update') {
      buttonText = 'Update'
    }

    return (
      <div>
        <AssetEditable
          asset={asset}
          onChange={this.handleChangeAsset}
          css={css`
            width: 240px;
            height: 140px;
            margin-bottom: 20px;

            .image {
              height: 140px;
            }
          `}
        />

        <TextField
          value={name}
          label={'Name'}
          onChange={this.handleNameChange}
          fullWidth
          error={!!errors.name}
          css={css`
            margin-bottom: 20px;
          `}
        />

        <TextField
          multiline
          value={description}
          label={'Description'}
          onChange={this.handleDescriptionChange}
          fullWidth
          error={!_.isEmpty(errors.description)}
          css={css`
            margin-bottom: 20px;

            textarea {
              min-height: 100px;
            }
          `}
        />

        <TextField
          value={price}
          label={'Price'}
          onChange={this.handlePriceChange}
          fullWidth
          error={!!errors.price}
          css={css`
            margin-bottom: 20px;
          `}
        />

        <TextField
          value={quantity || ''}
          label={'Quantity'}
          onChange={this.handleQuantityChange}
          fullWidth
          error={!!errors.quantity}
          css={css`
            margin-bottom: 20px;
          `}
        />

        <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
          <Typography color={'error'}>{submitErrorMessage}</Typography>
          <ButtonOrange onClick={this.handleSubmit} isLoading={isLoading}>
            {buttonText}
          </ButtonOrange>
        </Grid>
      </div>
    )
  }

  handleNameChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.name
    this.setState({ name: e.target.value, errors })
  }

  handleQuantityChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.quantity
    const quantity = parseInt(e.target.value) || 0
    this.setState({ quantity, errors })
  }

  handlePriceChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.price
    let price = Number(e.target.value) || 0
    if (price < 0) price *= -1
    this.setState({ price, errors })
  }

  handleDescriptionChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.description
    this.setState({ description: e.target.value, errors })
  }

  handleChangeAsset = (asset: Asset) => {
    this.setState({ asset })
  }

  handleSubmit = async () => {
    const { description, name, price, quantity, asset } = this.state

    const form: ApiPayloadStockCreate = {
      description,
      name,
      price,
      asset_id: asset?.id,
      quantity,
    }

    if (!this.isValid(form)) {
      return null
    }

    try {
      console.log('create', form)
      await this.props.onExecute(form)
    } catch (e) {
      const { data } = e?.response || {}
      const eMessage = data?.error || e.message
      this.setState({
        submitErrorMessage: eMessage,
      })
    }
  }

  isValid = (form): boolean => {
    const errors: Errors = validate(form, validationSchema) || {}

    console.log('isValid errors', errors)

    this.setState({ errors })

    return _.isEmpty(errors)
  }
}

export default StockForm

const validationSchema = {
  name: {
    presence: {
      message: 'Name is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Name at least %{count} letters',
    },
  },
  description: {
    presence: {
      message: 'Description is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Description at least %{count} letters',
    },
  },
  price: {
    numericality: {
      greaterThan: 0,
    },
  },
  quantity: {
    numericality: {
      greaterThan: 0,
    },
  },
}
