import { css, jsx } from '@emotion/react'
import { Close, Edit, MoreVert, SportsScore, Add, Delete, People } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography as MuiTypography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import _, { isEmpty } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import Loader from '../../../components/Loader'
import useDistanceMeasurement from '../../../hooks/useDistanceMeasurement'
import ButtonOrangeSquare from '../../../modals/components/ButtonOrangeSquare'
import mapBackgroundImage from '../assets/map-background.png'
import mapImage from '../assets/map.png'
import DistanceMeasurementSwitch from '../path/components/DistanceMeasurementSwitch'
import MapGoalProgressBar from './MapGoalProgressBar'
/** @jsx jsx */
import RendererStatusSplit from 'react-renderer-status-split'
import useModalState from '../../../hooks/useModalState'
import SmartLandmarksModal from '../../../modals/SmartLandmarksModal'
import { nFormatter } from '../../../helpers/utils'
import Switch from '@mui/material/Switch'

const DEBUG_MODE = true

interface ILandmark {
  name: string
  distance: number
}

const landmarks = [
  {
    name: 'Marathon',
    distance: 42.2,
  },
  {
    name: 'Ultramarathon',
    distance: 161,
  },
  {
    name: 'Cowboy trail',
    distance: 314,
  },
  {
    name: 'Dallas to San Antonio',
    distance: 454,
  },
  {
    name: 'Edinburg to London',
    distance: 542,
  },
  {
    name: 'Paris to Berlin',
    distance: 990,
  },
  {
    name: 'Arizona trail',
    distance: 1371,
  },
  {
    name: 'Sahara desert',
    distance: 1609,
  },
  {
    name: 'Tour de France',
    distance: 3357,
  },
  {
    name: 'Appalachian trail',
    distance: 3531,
  },
  {
    name: 'New York to LA',
    distance: 4492,
  },
  {
    name: 'Florida to Washington',
    distance: 4509,
  },
  {
    name: 'Across Europe',
    distance: 4699,
  },
  {
    name: 'Buenos Aires to Bogota',
    distance: 7191,
  },
  {
    name: 'American discovery trail',
    distance: 10944,
  },
  {
    name: 'Great wall of China',
    distance: 21197,
  },
  {
    name: 'Around the world',
    distance: 40074,
  },
  {
    name: 'To the moon',
    distance: 383023,
  },
  {
    name: 'Earth to sun',
    distance: 146449940,
  },
] as ILandmark[]

interface ISegment {
  top: number
  left: number
  position: 'left' | 'right' | 'top'
  length?: number
  distance?: number
}

const defaultSegments = [
  { left: 215, top: 3, length: 0, position: 'left' },
  { left: 215, top: 21, position: 'left' },
  { left: 215, top: 40, position: 'left' },
  { left: 215, top: 60, position: 'left' },
  { left: 215, top: 79, position: 'left' },
  { left: 215, top: 99, position: 'left' },
  { left: 214, top: 119, position: 'left' },
  { left: 209, top: 138, position: 'left' },
  { left: 199, top: 155, position: 'left' },
  { left: 184, top: 168, position: 'right' },
  { left: 167, top: 177, position: 'top' },
  { left: 147, top: 180, position: 'top' },
  { left: 127, top: 183, position: 'left' },
  { left: 111, top: 193, position: 'left' },
  { left: 98, top: 208, position: 'left' },
  { left: 93, top: 227, position: 'right' },
  { left: 95, top: 246, position: 'right' },
  { left: 103, top: 264, position: 'left' },
  { left: 117, top: 277, position: 'left' },
  { left: 136, top: 284, position: 'left' },
  { left: 155, top: 284, position: 'top' },
  { left: 175, top: 284, position: 'top' },
  { left: 195, top: 287, position: 'top' },
  { left: 213, top: 296, position: 'top' },
  { left: 227, top: 309, position: 'left' },
  { left: 237, top: 325, position: 'left' },
  { left: 242, top: 344, position: 'left' },
  { left: 241, top: 364, position: 'left' },
  { left: 234, top: 383, position: 'left' },
  { left: 223, top: 399, position: 'top' },
  { left: 207, top: 410, position: 'top' },
  { left: 189, top: 416, position: 'top' },
  { left: 169, top: 417, position: 'top' },
  { left: 149, top: 417, position: 'top' },
  { left: 129, top: 417, position: 'top' },
  { left: 110, top: 417, position: 'top' },
  { left: 90, top: 416, position: 'top' },
  { left: 70, top: 416, position: 'top' },
  { left: 51, top: 419, position: 'top' },
  { left: 34, top: 430, position: 'top' },
  { left: 23, top: 445, position: 'right' },
  { left: 18, top: 464, position: 'right' },
  { left: 21, top: 483, position: 'right' },
  { left: 32, top: 500, position: 'right' },
  { left: 48, top: 511, position: 'right' },
  { left: 67, top: 515, position: 'right' },
  { left: 87, top: 515, position: 'right' },
  { left: 106, top: 516, position: 'right' },
  { left: 124, top: 524, position: 'right' },
  { left: 139, top: 538, position: 'right' },
  { left: 147, top: 555, position: 'left' },
  { left: 148, top: 575, position: 'left' },
  { left: 143, top: 593, position: 'right' },
  { left: 131, top: 609, position: 'right' },
  { left: 113, top: 619, position: 'top' },
  { left: 94, top: 622, position: 'top' },
  { left: 74, top: 624, position: 'top' },
  { left: 57, top: 633, position: 'top' },
  { left: 42, top: 646, position: 'right' },
  { left: 32, top: 663, position: 'right' },
  { left: 26, top: 682, position: 'right' },
  { left: 26, top: 701, position: 'right' },
  { left: 26, top: 721, position: 'right' },
  { left: 26, top: 740, position: 'right' },
  { left: 26, top: 760, position: 'right' },
] as ISegment[]
const numberOfSegments = defaultSegments.length

const stepsToDistance = (steps) => steps * 0.7

const firstDistance = 0

const Tooltip = ({ segment, children, width = 120 }) => {
  const isLeft = segment.position === 'left'
  const isRight = segment.position === 'right'
  const isTop = segment.position === 'top'
  const horizontalOffset = width / 2

  return (
    <TooltipContainer
      style={{
        width,
        ...(isLeft && { marginRight: 36, right: 260 - segment.left, top: segment.top - 20 }),
        ...(isRight && { marginLeft: 36, left: segment.left, top: segment.top - 20 }),
        ...(isTop && { left: segment.left - horizontalOffset, top: segment.top - 70 }),
      }}
    >
      <>
        {children}
        {isLeft ? <ArrowRight /> : null}
        {isRight ? <ArrowLeft /> : null}
        {isTop ? <ArrowBottom /> : null}
      </>
    </TooltipContainer>
  )
}

const GoalsSwitcher = (props) => {
  return (
    <Box gap={2} display="flex" justifyContent="center" alignItems="center" my={4}>
      <GoalType onClick={() => props.setActiveTab('company')} active={props.activeTab === 'company'}>
        Company
      </GoalType>
      |
      <GoalType onClick={() => props.setActiveTab('user')} active={props.activeTab === 'user'}>
        User
      </GoalType>
    </Box>
  )
}

const EditingMode = (props) => {
  const [unit] = useDistanceMeasurement()
  const { challengeGoals, duration, loading } = props
  const smartLandmarksModal = useModalState()
  const membersCount = challengeGoals.members_count || 10
  const dailyAverage = challengeGoals.daily_average || 6000

  const handleSmartLandmarks = () => {
    smartLandmarksModal.onOpen()
  }

  const calculateDistance = useCallback(
    (distance: number): number => {
      if (unit === 'miles') {
        return distance / 1.609
      }

      return distance
    },
    [unit],
  )

  const { control, register, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      membersCount,
      dailyAverage,
      landmarks: challengeGoals.landmarks?.map((l) => ({
        ...l,
        distance: nFormatter(calculateDistance(l.distance)),
      })),
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'landmarks',
  })
  const [totalSteps, setTotalSteps] = useState(membersCount * dailyAverage * duration)

  const handleSelectSmartLandmarks = (landmarks) => {
    reset({
      landmarks: landmarks?.map((l) => ({
        ...l,
        distance: nFormatter(calculateDistance(l.distance)),
      })),
    })
  }

  return (
    <>
      <SmartLandmarksModal
        {...smartLandmarksModal}
        callback={handleSelectSmartLandmarks}
        distance={stepsToDistance(totalSteps / 1000)}
      />
      <EditContainer>
        <Typography fontSize={21} textAlign="center" lineHeight={1.6}>
          With{' '}
          <Input
            type="number"
            min={0}
            step={1}
            {...register('membersCount', {
              onChange: (e) => {
                setTotalSteps(e.target.value * getValues('dailyAverage') * duration)
              },
            })}
          />{' '}
          participants and an average daily step count of{' '}
          <Input
            type="number"
            min={0}
            step={1}
            {...register('dailyAverage', {
              onChange: (e) => {
                setTotalSteps(e.target.value * getValues('membersCount') * duration)
              },
            })}
          />{' '}
          we recommend:
        </Typography>
        <Typography fontSize={24} mt={4} mb={6} textAlign="center">
          <B>GOAL</B> {totalSteps} Steps / {nFormatter(calculateDistance(stepsToDistance(totalSteps / 1000)))}
          {unit || 'km'} In {duration} days
        </Typography>
        <Divider />
        <Box mt={6} mb={4}>
          <Stack gap={6}>
            {fields.map((field, index) => (
              <Stack direction="row" gap={2} key={`landmarks-${field.id}`}>
                <StyledTextField
                  {...register(`landmarks.${index}.name`)}
                  label="Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <StyledTextField
                  {...register(`landmarks.${index}.distance`)}
                  label={`Distance in ${unit || 'km'}`}
                  type="number"
                  min={0}
                  step={1}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <IconButton aria-label="delete" onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Stack>
            ))}
            <Button
              startIcon={<Add />}
              onClick={() => {
                append({ name: '', distance: 0 })
              }}
            >
              Add more landmarks
            </Button>
            <ButtonOrangeSquare isLoading={loading} onClick={handleSubmit(props.handleEdit)}>
              Save Changes
            </ButtonOrangeSquare>
            <Divider />
            <ButtonOrangeSquare onClick={handleSmartLandmarks}>Smart Landmarks (AI)</ButtonOrangeSquare>
          </Stack>
        </Box>
        <Typography fontSize={18} mt={4} textAlign="center">
          The goal will adjust if significant number of new members join the challenge
        </Typography>
      </EditContainer>
    </>
  )
}

const EditUserGoals = (props) => {
  const { loading, challengeGoalSettings, unit } = props
  const [type, setType] = useState(challengeGoalSettings?.increase_by ? 'dynamic' : 'fixed')
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      goal: challengeGoalSettings?.goal || 0,
      increaseGoalBy: challengeGoalSettings?.increase_by || 0,
    },
  })

  const onSubmit = handleSubmit((data) => {
    props.handlEditSettings({
      ...data,
      type,
    })
  })

  return (
    <EditContainer>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={2} mb={4}>
        <Typography>Fixed</Typography>
        <Switch
          checked={type === 'dynamic'}
          onChange={(e) => (e.target.checked ? setType('dynamic') : setType('fixed'))}
        />
        <Typography>Dynamic</Typography>
      </Stack>
      {type === 'dynamic' ? (
        <Typography fontSize={21} textAlign="center" lineHeight={1.6}>
          Minimum member goal is <Input type="number" min={0} step={1} {...register('goal')} /> {unit} increasing by{' '}
          <Input type="number" min={0} step={1} {...register('increaseGoalBy')} /> from previous day
        </Typography>
      ) : (
        <Typography fontSize={21} textAlign="center" lineHeight={1.6}>
          Member goal per day <Input type="number" min={0} step={1} {...register('goal')} /> {unit}
        </Typography>
      )}
      <Box mt={8} mx="auto">
        <ButtonOrangeSquare isLoading={loading} onClick={onSubmit}>
          Save Changes
        </ButtonOrangeSquare>
      </Box>
    </EditContainer>
  )
}

const EditGoals = (props) => {
  const [activeTab, setActiveTab] = useState<'company' | 'user'>(props.isSteps ? 'company' : 'user')
  const { duration, handleEdit, handleEditGoalSettings, challengeGoals, challengeGoalSettings, loading } = props

  return (
    <Stack sx={{ position: 'relative', flex: 1, width: '100%' }}>
      <IconButton onClick={props.handleClose} sx={{ position: 'absolute', top: 6, right: 6 }}>
        <Close />
      </IconButton>
      {props.isSteps ? <GoalsSwitcher activeTab={activeTab} setActiveTab={setActiveTab} /> : null}
      {activeTab === 'company' ? (
        <EditingMode challengeGoals={challengeGoals} handleEdit={handleEdit} duration={duration} loading={loading} />
      ) : (
        <EditUserGoals
          challengeGoalSettings={challengeGoalSettings}
          handlEditSettings={handleEditGoalSettings}
          unit={props.unit}
        />
      )}
    </Stack>
  )
}

const Map = (props) => {
  const dispatch = useDispatch()
  const [challengeGoals, setChallengeGoals] = useState<any>()
  const [challengeGoalSettings, setChallengeGoalSettings] = useState<any>()
  const [segments, setSegments] = useState<ISegment[]>([])
  const [unit] = useDistanceMeasurement()
  const [isEditing, setIsEditing] = useState(!props.isSteps)
  const [loading, setLoading] = useState(false)

  const membersCount = challengeGoals?.members_count || 10
  const dailyAverage = challengeGoals?.daily_average || 6000
  const goalTotalSteps = membersCount * dailyAverage * props.challenge.duration
  const totalSteps = props.challenge.valueTotal
  const goal = stepsToDistance(goalTotalSteps / 1000).toFixed(2)
  const mapScale = stepsToDistance(Math.max(totalSteps, goalTotalSteps))

  const fetchAction = useAsyncFetch(async () => {
    const [challengeGoals, challengeGoalSettings] = await Promise.all([
      dispatch(ChallengeDispatcher.getChallengeGoals(props.challenge.challenge_id)),
      dispatch(ChallengeDispatcher.getChallengeGoalSettings(props.challenge.challenge_id)),
    ])
    setChallengeGoals(challengeGoals)
    setChallengeGoalSettings(challengeGoalSettings)
  })

  const handleEdit = async (data) => {
    const parsedData = {
      landmarks: data.landmarks.map((l) => {
        let distance = l.distance
        if (unit === 'miles') {
          distance = l.distance * 1.609
        }

        return {
          ...l,
          distance,
        }
      }),
      daily_average: parseInt(data.dailyAverage),
      members_count: parseInt(data.membersCount),
    }
    setLoading(true)
    try {
      await dispatch(ChallengeDispatcher.setChallengeGoals(props.challenge.challenge_id, parsedData))
      setChallengeGoals(parsedData)
    } finally {
      setLoading(false)
      if (props.isSteps) {
        setIsEditing(false)
      } else {
        alert('Saved successfully!')
      }
    }
  }

  const handleEditGoalSettings = async (data) => {
    const parsedData = {
      increase_by: data.type === 'fixed' ? null : +data.increaseGoalBy,
      goal: +data.goal,
    }
    setLoading(true)
    try {
      await dispatch(ChallengeDispatcher.setChallengeGoalSettings(props.challenge.challenge_id, parsedData))
      setChallengeGoalSettings(parsedData)
    } finally {
      setLoading(false)
      if (props.isSteps) {
        setIsEditing(false)
      } else {
        alert('Saved successfully!')
      }
    }
  }

  useMemo(() => {
    for (let i = 0; i < numberOfSegments - 1; i++) {
      const x1 = defaultSegments[i].left
      const y1 = defaultSegments[i].top
      const x2 = defaultSegments[i + 1].left
      const y2 = defaultSegments[i + 1].top

      defaultSegments[i + 1].length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
    }

    let totalRoutLength = 0
    for (let i = 0; i < numberOfSegments; i++) {
      totalRoutLength += defaultSegments[i].length
    }

    const pixelSize = mapScale / totalRoutLength

    defaultSegments[0].distance = firstDistance ? firstDistance - mapScale / 10 : 0

    for (let i = 1; i < numberOfSegments; i++) {
      defaultSegments[i].distance = defaultSegments[i].length * pixelSize
    }

    let total = 0
    for (let i = 0; i < numberOfSegments; i++) {
      total += defaultSegments[i].distance
      defaultSegments[i].totalDistance = total
    }

    setSegments(defaultSegments)
  }, [mapScale])

  const calculateDistance = useCallback(
    (distance: number): number => {
      if (unit === 'miles') {
        return distance / 1.609
      }

      return distance
    },
    [unit],
  )

  const findSegment = useCallback(
    (distance) => {
      if (isEmpty(segments)) return null

      for (let i = 0; i < numberOfSegments - 1; i++) {
        if (distance > segments[i].totalDistance && distance <= segments[i + 1].totalDistance) {
          return segments[i + 1]
        }
      }
      return null
    },
    [segments, mapScale],
  )

  const lastSegment = findSegment(stepsToDistance(goalTotalSteps)) || _.last(segments)
  const teamDistance = stepsToDistance(props.challenge.valueTotal)
  const teamSegment = findSegment(teamDistance) || _.first(segments)

  const hasCustomLandmarks = !isEmpty(challengeGoals?.landmarks)

  return (
    <RendererStatusSplit
      statuses={fetchAction}
      isEmpty={!challengeGoals && !challengeGoalSettings}
      renderLoading={() => (
        <Grid
          container
          justifyContent={'center'}
          css={css`
            margin-top: 50px;
          `}
        >
          <Loader />
        </Grid>
      )}
      render={() => {
        return (
          <Container>
            {isEditing ? (
              <EditGoals
                challengeGoals={challengeGoals}
                challengeGoalSettings={challengeGoalSettings}
                unit={props.challenge.type_data.name}
                handleEdit={handleEdit}
                handleEditGoalSettings={handleEditGoalSettings}
                handleClose={() => {
                  if (props.isSteps) {
                    setIsEditing(false)
                  }
                }}
                duration={props.challenge.duration}
                loading={loading}
                isSteps={props.isSteps}
              />
            ) : (
              <>
                <BackgroundImage src={mapBackgroundImage} alt="Map Background" />
                <MapHeader>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={24} fontWeight="bold">
                      Challenge map
                    </Typography>
                    <Stack direction="row" gap={4} alignItems="center">
                      <DistanceMeasurementSwitch />
                      <IconButton onClick={() => setIsEditing(true)}>
                        <MoreVert />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="center" gap={2} my={6}>
                    <Typography fontSize={21}>
                      <B>GOAL</B> {goalTotalSteps} Steps / {nFormatter(calculateDistance(goal))}
                      {unit || 'km'}
                    </Typography>
                    <IconButton onClick={() => setIsEditing(true)}>
                      <Edit />
                    </IconButton>
                  </Stack>
                  <MapGoalProgressBar
                    start={props.challenge.valueTotal}
                    end={goalTotalSteps}
                    duration={props.challenge.duration}
                    startDate={props.challenge.start_date}
                  />
                  <Typography fontSize={11} mt={4} textAlign="center">
                    Goal is automatically generated based on your user count and duration
                  </Typography>
                </MapHeader>
                <MapContainer>
                  <MapImage src={mapImage} alt="Map Image" />
                  {DEBUG_MODE &&
                    segments.map((seg) => (
                      <DebugSegment
                        style={{
                          top: seg.top,
                          left: seg.left,
                          ...(seg.position === 'left' && { backgroundColor: 'blue' }),
                          ...(seg.position === 'top' && { backgroundColor: 'black' }),
                        }}
                      />
                    ))}
                  {!isEmpty(segments) &&
                    (!hasCustomLandmarks ? landmarks : challengeGoals?.landmarks)
                      .reduce((prev, curr) => {
                        if (hasCustomLandmarks) {
                          prev.push(curr)
                          return prev
                        }

                        const last = _.last(prev)
                        const segment = findSegment(parseFloat(curr.distance) * 1000)

                        if (!last && segment) {
                          prev.push(curr)
                        } else if (last) {
                          const lastSegment = findSegment(parseFloat(last.distance) * 1000)
                          if (segment && lastSegment) {
                            const diff = segment.totalDistance - lastSegment.totalDistance
                            if (diff >= mapScale / 20) {
                              prev.push(curr)
                            }
                          }
                        }

                        return prev
                      }, [] as ILandmark[])
                      .map(({ name, distance }) => {
                        const segment = findSegment(distance * 1000)
                        if (!segment) {
                          return null
                        }

                        return (
                          <>
                            {DEBUG_MODE && (
                              <DebugSegment
                                style={{ top: segment.top, left: segment.left, backgroundColor: 'yellow' }}
                              />
                            )}
                            <Tooltip segment={segment}>
                              <Typography fontSize={11} fontWeight="bold">
                                {name}
                              </Typography>
                              <Typography fontSize={11}>
                                {' '}
                                {nFormatter(calculateDistance(distance))}
                                {unit || 'km'}
                              </Typography>
                            </Tooltip>
                          </>
                        )
                      })}
                  <MapPoint style={{ top: teamSegment?.top, left: teamSegment?.left }}>
                    <People style={{ color: 'white' }} />
                  </MapPoint>
                  <Tooltip segment={teamSegment}>
                    <Typography fontWeight="bold" fontSize={11} color="#FF00C7">
                      Team Progress
                    </Typography>
                    <Typography fontSize={11}>
                      {nFormatter(calculateDistance(teamDistance / 1000))}
                      {unit || 'km'}
                    </Typography>
                  </Tooltip>
                  {lastSegment ? (
                    <>
                      <FinishPoint style={{ top: lastSegment.top, left: lastSegment.left }}>
                        <SportsScore />
                      </FinishPoint>
                      <Tooltip segment={lastSegment}>
                        <Typography fontWeight="bold" fontSize={11} color="#FF00C7">
                          Goal
                        </Typography>
                        <Typography fontSize={11}>
                          {nFormatter(calculateDistance(goal))}
                          {unit || 'km'}
                        </Typography>
                      </Tooltip>
                    </>
                  ) : null}
                </MapContainer>
              </>
            )}
          </Container>
        )
      }}
    />
  )
}

const Container = styled(Box)`
  width: 600px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
`

const MapHeader = styled(Box)`
  width: 100%;
  z-index: 1;
  background: white;
  padding: 20px 32px;
`

const MapContainer = styled(Box)`
  position: relative;
`

const BackgroundImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const DebugSegment = styled('div')`
  background-color: red;
  border-radius: 3px;
  height: 6px;
  margin-left: -3px;
  margin-top: -3px;
  position: absolute;
  width: 6px;
`

const MapImage = styled('img')``

const TooltipContainer = styled('div')`
  align-items: center;
  background-color: white;
  border-color: #dedede;
  border-radius: 5px;
  border-width: 1px;
  height: 40px;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 10;
  box-shadow: 0px 2px 6px rgba(156, 156, 156, 1);
`

const Typography = styled(MuiTypography)`
  font-family: Poppins;
`

const B = styled('span')`
  font-weight: bold;
`

const ArrowLeft = styled('div')`
  border-bottom-color: transparent;
  border-bottom-width: 6px;
  border-left-color: transparent;
  border-left-width: 0;
  border-right-color: white;
  border-right-width: 8px;
  border-top-color: transparent;
  border-top-width: 6px;
  left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
`

const ArrowRight = styled('div')`
  border-bottom-color: transparent;
  border-bottom-width: 6px;
  border-left-color: white;
  border-left-width: 8px;
  border-right-color: transparent;
  border-right-width: 0;
  border-top-color: transparent;
  border-top-width: 6px;
  margin-top: -6px;
  position: absolute;
  right: -6px;
  top: 50%;
`

const ArrowBottom = styled('div')`
  border-bottom-color: transparent;
  border-bottom-width: 0;
  border-left-color: transparent;
  border-left-width: 6px;
  border-right-color: transparent;
  border-right-width: 6px;
  border-top-color: white;
  border-top-width: 8px;
  bottom: -8px;
  left: 50%;
  margin-left: -6px;
  position: absolute;
`

const MapPoint = styled('div')`
  align-items: center;
  background-color: #ff00c7;
  border-radius: 22px;
  height: 44px;
  justify-content: center;
  margin-left: -22px;
  margin-top: -22px;
  position: absolute;
  width: 44px;
  display: flex;
  z-index: 10;
`

const FinishPoint = styled(MapPoint)`
  background-color: #ff4141;
`

const Input = styled('input')`
  border: none;
  border-bottom: 1px solid #000;
  padding: 0;
  text-align: center;
  outline: none;
  font-size: 16px;
  width: 100px;
`

const EditContainer = styled(MapHeader)`
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField)`
  flex: 1;
`

const GoalType = styled(Link)<{ active?: boolean }>`
  font-family: Poppins;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#0a58ba' : 'black')};
  text-decoration: none;
  cursor: pointer;
`

export default Map
