/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Paper, Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactElement } from 'react'
import loginPicture from '../assets/login-illustration.png'

const useStyles = makeStyles(
  {
    paper: {
      // height: 450,
      // maxHeight: '80%',
    },
    container: {
      height: '100%',
    },
    phoneContainer: {
      position: 'relative',
    },

    phonePicture: {
      height: '100%',
      objectFit: 'contain',
    },

    phonePictureContainer: {
      position: 'absolute',
      top: '15%',
      bottom: '15%',
      left: '50%',
      transform: 'translateX(-50%)',
    },

    termsContainer: {
      marginTop: 5,
      fontSize: 12,
      textAlign: 'center',
    },
    link: {
      marginLeft: 5,
      marginRight: 5,
      color: '#566aaa',
    },
  },
  {
    classNamePrefix: 'LoginContainer',
  },
)

interface Props {
  children: ReactElement
}

function LoginContainer(props: Props) {
  const { children } = props
  const classes = useStyles()

  return (
    <div
      css={[
        css({
          left: '50%',
          top: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80%',
          width: 720,
          maxWidth: '80%',
          minWidth: 290,
          height: 450,
        }),

        css`
          @media (max-width: 500px) {
            width: 100%;
            max-width: 100%;
            position: static;
            transform: none;
          }
        `,
      ]}
    >
      <Paper className={classes.paper}>
        <Grid container direction={'row'} className={classes.container}>
          <Grid item md={6} xs={12}>
            {children}
          </Grid>

          <Hidden mdDown>
            <Grid item sm={6} className={classes.phoneContainer}>
              <div className={classes.phonePictureContainer}>
                <img src={loginPicture} className={classes.phonePicture} alt={'Login'} />
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </div>
  )
}

export default LoginContainer
