/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { MouseEventHandler } from 'react'
import EventModalStyled from '../../modals/elements/EventModalStyled'

interface Props {
  isVisible: boolean
  onClose(): any
}

function EventTypeInfoModal(props: Props) {
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    // prevent redirect to single event page
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div onClick={handleClick}>
      <EventModalStyled {...props}>
        <div
          css={css`
            padding-top: 40px;
          `}
        >
          <Typography
            css={css`
              font-family: Poppins;
            `}
          >
            <span
              css={css`
                font-size: 16px;
                font-weight: bold;
              `}
            >
              Public events
            </span>{' '}
            are created by Reaction and broadcast to all the clubs on Reaction. When your members join they won’t be
            able to see members from other clubs.
          </Typography>

          <br />
          <Typography
            css={css`
              font-family: Poppins;
            `}
          >
            <span
              css={css`
                font-size: 16px;
                font-weight: bold;
              `}
            >
              Club events
            </span>{' '}
            are events that you create and available only to your members. You can lead an event by yourself or connect
            a coach (external vendor) to lead them for you.
          </Typography>

          <br />
          <Typography
            css={css`
              font-family: Poppins;
            `}
          >
            <span
              css={css`
                font-size: 16px;
                font-weight: bold;
              `}
            >
              Office events
            </span>{' '}
            are shown only to participants in a specific office. You can set up offices in the settings.
          </Typography>
        </div>
      </EventModalStyled>
    </div>
  )
}

export default EventTypeInfoModal
