import { Grid, Box, TextField } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import languageIcon from '../assets/language.svg'
import CoachWrapper from '../components/CoachWrapper'
import PlacesAutocomplete from '../components/PlacesAutocomplete'

const languages = ['English', 'Hebrew', 'Russian', 'Spanish']

const ChooseServiceType = ({
  classes,
  location,
  language = '',
  serviceType,
  coordinates,
  setOfferLocation,
  setOfferLanguage,
  setOfferCoordinates,
}) => {
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => {
    setOfferLanguage(languages[0])
  }, [])

  const onNextClick = e => {
    const newErrors = {}

    if (serviceType !== 'online' && !location) {
      newErrors.location = 'required'
    }

    if (!language) {
      newErrors.language = 'required'
    }

    if (Object.keys(newErrors).length) {
      e.preventDefault()
      setErrors(newErrors)
    }
  }

  return (
    <CoachWrapper title="Choose Location and Language" titleWidth={170}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content}>
            <Grid item xs>
              <PlacesAutocomplete
                isOptional={serviceType === 'online'}
                error={errors.location}
                location={location}
                coordinates={coordinates}
                setLocation={setOfferLocation}
                setCoordinates={setOfferCoordinates}
              />
              <Autocomplete
                options={languages}
                inputValue={language}
                disableClearable
                onChange={(e, value) => setOfferLanguage(value)}
                renderInput={params => (
                  <div className={classes.languageWrapper}>
                    <TextField
                      {...params}
                      error={!!errors.language}
                      helperText={errors.language}
                      variant={'outlined'}
                      InputProps={{
                        ...params.InputProps,
                        className: `${classes.language} ${params.InputProps.className}`,
                        placeholder: 'Language',
                      }}
                    />
                    <img src={languageIcon} className={classes.languageIcon} alt="" />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Link
            to="/coaches/invite/external/choose-budget-date"
            style={{ textDecoration: 'none', color: '#323232' }}
            onClick={onNextClick}
            className={classes.btn}
          >
            Next
          </Link>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  location: state.coaches.offer.location,
  language: state.coaches.offer.language,
  serviceType: state.coaches.offer.serviceType,
  coordinates: state.coaches.offer.coordinates,
})
const mapDispatchToProps = dispatch => ({
  setOfferLocation: (location: string) => dispatch(coachesDispatcher.actions.setOfferLocation(location)),
  setOfferLanguage: (language: string) => dispatch(coachesDispatcher.actions.setOfferLanguage(language)),
  setOfferCoordinates: (coordinates: string) => dispatch(coachesDispatcher.actions.setOfferCoordinates(coordinates)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  language: {
    paddingLeft: '80px !important',
    borderRadius: '8px',
    minHeight: 60,
    fontSize: '14px',
    marginBottom: 36,
  },
  languageWrapper: {
    position: 'relative',
  },
  languageIcon: {
    top: 18,
    left: 22,
    position: 'absolute',
  },
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
    maxWidth: 475,
    margin: 'auto',
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(ChooseServiceType)
