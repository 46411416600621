import { Grid, Box } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import '../assets/datepicker.css'
import { compose } from 'redux'
import AgreementDetailsForm from '../components/AgreementDetailsForm'
import CoachWrapper from '../components/CoachWrapper'

const ChooseServiceType = ({
  classes,
  currency,
  period,
  startDate,
  setOfferCurrency,
  setOfferPeriod,
  budget,
  setOfferBudget,
  setOfferStartDate,
  description,
  setOfferDescription,
}) => {
  const [errors, setErrors] = React.useState({})

  const onNextClick = e => {
    const newErrors = {}

    if (!startDate) {
      newErrors.startDate = 'required'
    }

    if (Object.keys(newErrors).length) {
      e.preventDefault()
      setErrors(newErrors)
    }
  }

  return (
    <CoachWrapper title="Choose budget and start date" titleWidth={138}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content}>
            <AgreementDetailsForm
              withPriceNote
              errors={errors}
              budget={budget}
              period={period}
              currency={currency}
              startDate={startDate}
              description={description}
              setBudget={setOfferBudget}
              setPeriod={setOfferPeriod}
              setCurrency={setOfferCurrency}
              setStartDate={setOfferStartDate}
              setDescription={setOfferDescription}
            />
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Link
            className={classes.btn}
            to="/coaches/invite/external/submit-request"
            style={{ textDecoration: 'none', color: '#323232' }}
            onClick={onNextClick}
          >
            Next
          </Link>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  budget: state.coaches.offer.budget,
  period: state.coaches.offer.period,
  currency: state.coaches.offer.currency,
  startDate: state.coaches.offer.startDate,
  description: state.coaches.offer.description,
})
const mapDispatchToProps = dispatch => ({
  setOfferBudget: (budget: number) => dispatch(coachesDispatcher.actions.setOfferBudget(budget)),
  setOfferPeriod: (period: string) => dispatch(coachesDispatcher.actions.setOfferPeriod(period)),
  setOfferCurrency: (currency: string) => dispatch(coachesDispatcher.actions.setOfferCurrency(currency)),
  setOfferStartDate: (startDate: string) => dispatch(coachesDispatcher.actions.setOfferStartDate(startDate)),
  setOfferDescription: (description: string) => dispatch(coachesDispatcher.actions.setOfferDescription(description)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
    maxWidth: 475,
    margin: 'auto',
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(ChooseServiceType)
