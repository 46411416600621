import { WithStyles, withStyles } from '@mui/styles'
import { PlaybookInstanceExtended } from '@reducers/playbookInstances/reducer'
import React from 'react'
import RoundSelectFilter from './RoundSelectFilter'

const styles = {
  filterButton: {
    cursor: 'pointer',
    marginRight: 5,
    fontWeight: 600,
  },
  menuItem: {
    color: '#323232',
    fontSize: 14,
    fontWeight: 600,
    // margin: '5px 0',
    paddingLeft: 8,
    '&:hover': {
      color: '#F89A00',
      fontWeight: 600,
    },
  },
  menuItemWrapper: {
    marginBottom: 11,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}

interface Props {
  instances: Record<string, PlaybookInstanceExtended>
  onInstanceChange: (instance_id: string) => any
}

class GroupFilter extends React.PureComponent<WithStyles<typeof styles> & Props> {
  state = {
    filters: [{ value: 'all', name: 'All' }],
  }

  render() {
    const { filters } = this.state

    return <RoundSelectFilter filters={filters} defaultFilter={'all'} onChange={this.handleChange} />
  }

  handleChange = value => {
    this.props.onInstanceChange(value !== 'all' ? value : '')
  }

  setup = () => {
    const { instances } = this.props
    const { filters: stateFilters } = this.state
    const filters = Object.values(instances).map(instance => ({
      value: instance.id,
      name: instance.name,
    }))
    this.setState({ filters: stateFilters.concat(filters) })
  }

  componentDidMount(): void {
    this.setup()
  }
}

export default withStyles(styles)(GroupFilter)
