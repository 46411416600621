import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_PLAYBOOKS: '',
    SET_PLAYBOOK_PROGRAMS: '',


    SET_PLAYBOOK_DETAIL: '[SET_PLAYBOOK_DETAIL] Set playbook details',
    LOADED: '[LOADED] Playbooks loaded',
    LOADING: '[LOADING] Playbooks are loading',
  },
  { prefix: 'PLAYBOOKS__' },
)
