import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles(
  {
    itemContainer: {
      // padding: 10,
    },
    itemLink: {
      padding: 24,
      display: 'block',
      textDecoration: 'none',
      color: '#717B8C',
      borderBottom: '2px solid #E0E4EC',
      fontSize: 20,

      '&:hover': {
        color: 'rgba(37, 40, 61, 0.8)',
        borderBottomColor: 'rgba(37, 40, 61, 0.8)',
      },
    },
    itemLinkActive: {
      fontWeight: 'bold',
      color: 'rgba(37, 40, 61, 0.8)',
      borderBottomColor: 'rgba(37, 40, 61, 0.8)',
    },
  },
  {
    classNamePrefix: 'SettingsNavItem',
  },
)

interface Props {
  title: string
  href: string
  location: ReturnType<typeof useLocation>
}

function SettingsNavItem(props: Props) {
  const { title, href, location } = props
  const classes = useStyles()

  const { pathname } = location

  const isActive = pathname === href

  return (
    <div className={classes.itemContainer}>
      <Link
        to={href}
        className={classNames(classes.itemLink, {
          [classes.itemLinkActive]: isActive,
        })}
      >
        {title}
      </Link>
    </div>
  )
}

export default SettingsNavItem
