import apiClient from '../../libs/api/libs/apiClient'

const apiConfigs = {
  async getEventConfigs(): Promise<void> {
    const result = await apiClient.get(`/company/events-config`)
    return result.data
  },

  async setPenaltyEnabled(isEnabled: boolean): Promise<void> {
    const result = await apiClient.post(`/company/events-config/set-penalty-enabled`, { status: isEnabled })
    return result.data
  },

  async setPenaltyDays(params: { penalty_duration: number; penalty_start_offset: number }): Promise<void> {
    const result = await apiClient.post(`/company/events-config/set-penalty-days`, params)
    return result.data
  },
}

export default apiConfigs
