/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useDurationTxt from '../../../../hooks/dt/useDurationTxt'
import eventUtils from '../../../../libs/events/eventUtils'
import EventPicture from '../../components/EventPicture'
import EventTypeLabel from '../../components/EventTypeLabel'
import AgendaEventParticipants from './components/AgendaEventParticipants'
import AgendaEventTimeInfo from './components/AgendaEventTimeInfo'

interface Props {
  event: ReduxStateReactionEventItem
}

function AgendaEvent(props: Props) {
  const { event } = props
  const { id, name, duration, filter_type, coach, offices: officesIdsSelected = [] } = event

  const durationTxt = useDurationTxt({
    duration: moment.duration(duration, 'minutes'),
  })

  const { offices } = useSelector((state: ReduxState) => ({
    offices: state.offices,
  }))

  // const { group } = useSelector((state: ReduxState) => ({
  //   group: group_id ? state.playbookInstances[group_id] : null,
  // }))

  const filterTxt = useMemo(() => {
    if (filter_type === 'company') return ''

    if (filter_type === 'office') {
      const officeList = _.filter(offices, (item) => officesIdsSelected.includes(item.id))
      const officesListTxt = _.map(officeList, (item) => item.name).join(', ')
      return officesListTxt || 'Office'
    }

    return filter_type
  }, [filter_type, offices])

  console.log('filterTxt', filterTxt)

  let eventHeadingExtra = ` | ${durationTxt}`
  if (filterTxt) eventHeadingExtra += ` | ${filterTxt}`

  const eventTimings = eventUtils.getEventTimings(event)

  return (
    <Link to={`/event/${id}`}>
      <Grid
        container
        css={css`
          border: 1px solid #eeeeee;
          padding: 10px;
          margin-top: 15px;
          border-radius: 8px;

          .MuiTypography-root {
            font-family: Poppins;
            color: #717579;
          }
        `}
      >
        <AgendaEventTimeInfo event={event} />

        <Grid
          item
          flexGrow={1}
          css={css`
            padding: 15px 30px;
          `}
        >
          <Grid
            container
            alignItems={'center'}
            css={css`
              height: 100%;
            `}
          >
            <EventPicture
              event={event}
              css={css`
                height: 70px;
                width: 70px;
                //border-radius: 50%;
                margin-right: 20px;
              `}
            />

            <Grid
              item
              flexGrow={1}
              css={css`
                width: 1px;
                padding-right: 20px;
              `}
            >
              <EventTypeLabel event={event} isActive={eventTimings.isActive} />

              <Typography
                display={'block'}
                css={css`
                  font-size: 16px;
                  font-weight: bold;
                  margin-top: 5px;
                `}
              >
                <span
                  css={css`
                    color: #323232;
                  `}
                >
                  {name}
                </span>
                {eventHeadingExtra}
              </Typography>
              {coach && <Typography>With {coach.name}</Typography>}

              <AgendaEventParticipants event={event} />
            </Grid>

            <div
              css={css`
                svg {
                  color: #717579;
                }
              `}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  )
}

export default AgendaEvent
