/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import { Employee } from '@reaction-club-types/core'
import React from 'react'
import { compose } from 'redux'
import { smallPlusIcon } from '../assets/facade'
import EmployeeActions from './EmployeeActions'
import EmployeeList from './EmployeeList'

type ComponentProps = WithStyles<typeof styles> & Props

interface Props {
  employees: Employee[]
  handleClickOnInviteMember: () => any
  instance_id: string
}

class Employees extends React.PureComponent<ComponentProps> {
  state = {
    selectedEmployee: [] as string[],
  }

  render() {
    const { classes, employees, handleClickOnInviteMember, instance_id } = this.props
    const { selectedEmployee } = this.state
    const isActionsActive = selectedEmployee.length > 0
    return (
      <Grid
        container
        direction={'column'}
        alignItems={'flex-start'}
        css={css`
          height: 100%;
          border: 1px solid #d4d4d4;
          border-radius: 5px;
          padding: 30px;

          @media (max-width: 500px) {
            padding: 20px 10px;
          }
        `}
      >
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Typography className={classes.membersCount}>{`${employees.length} Member${
            employees.length === 1 ? '' : 's'
          }`}</Typography>
          <Grid item>
            <Grid container direction={'row'}>
              <Grid item>
                <Grid container direction={'row'} className={classes.smallInvite} onClick={handleClickOnInviteMember}>
                  <img src={smallPlusIcon} alt="Plus icon" />
                  <Typography className={classes.captionInvite}>Invite</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {isActionsActive && (
                  <EmployeeActions
                    employees={selectedEmployee}
                    handleEmployeeDeleted={this.handleEmployeeDeleted}
                    instance_id={instance_id}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EmployeeList employees={employees} onSelect={this.handleSelect} />
      </Grid>
    )
  }

  handleSelect = (employee_id: string) => {
    const { selectedEmployee } = this.state
    if (selectedEmployee.length === 0) return this.setState({ selectedEmployee: [employee_id] })
    if (selectedEmployee.includes(employee_id)) {
      const updatedSelectedEmployee = selectedEmployee.filter((id) => employee_id !== id)
      this.setState({ selectedEmployee: updatedSelectedEmployee })
      return
    }
    const updatedSelectedEmployee = selectedEmployee.concat([employee_id])
    this.setState({ selectedEmployee: updatedSelectedEmployee })
  }

  handleEmployeeDeleted = (employees: string[]) => {
    const { selectedEmployee } = this.state
    this.setState({ selectedEmployee: selectedEmployee.filter((employee) => !employees.includes(employee)) })
  }
}

const styles = {
  inviteMembersText: {
    marginTop: 15,
    fontSize: 18,
    fontWeight: 800,
  },
  membersCount: {
    fontSize: 17,
    fontWeight: 800,
    marginBottom: 8,
  },
  captionInvite: {
    fontSize: 15,
    fontWeight: 800,
    marginLeft: 5,
  },
  smallInvite: {
    cursor: 'pointer',
  },
}

export default compose(withStyles(styles))(Employees)
