/* eslint-disable no-underscore-dangle */
import { Grid, Paper, Box } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import ProfileHeader from '../../../components/ProfileHeader'
import Reactionloader from '../../../components/ReactionLoader'
import CoachProfile from './chat/CoachProfile'
import MembersList from './chat/MembersList'
import MessageDivider from './chat/MessageDivider'
import MessageItem from './chat/MessageItem'
import Profile from './chat/Profile'
import SendMsgField from './chat/SendMsgField'

type OwnProps = {
  room: any
  users: any
  user?: any
  coach?: any
  offerId?: any
  members?: any
  sendMessage: any
}
type Props = OwnProps & WithStyles<typeof styles>

const Chat: React.FC<Props> = ({
  room,
  user,
  members,
  coach,
  classes,
  sendMessage,
  offerId,
  isAdmin,
  history,
  readMessages,
}) => {
  const msgContainer = React.useRef()

  React.useEffect(() => {
    if (isAdmin) {
      window.alert('Chat is not available from admin panel.')
      history.goBack()
    }
  }, [])

  React.useEffect(() => {
    if (room) {
      readMessages(room._id)
    }
  }, [])

  React.useEffect(() => {
    scrollToBottom()
  }, [room])

  const scrollToBottom = () => {
    const mc = msgContainer.current

    if (mc) {
      mc.scrollTop = mc.scrollHeight
    }
  }

  const isLoading = !user && !members && !coach
  return (
    <Grid container justifyContent="center">
      <ProfileHeader />
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <Paper className={classes.container}>
          {isLoading ? (
            <Reactionloader />
          ) : (
            <>
              <Box style={{ display: 'flex' }}>
                <Box className={classes.messageContainer} ref={msgContainer}>
                  {room?.messages
                    .filter(({ t }) => t !== 'au' && t !== 'ru')
                    .map(({ msg, ts_formatted, ts_date_formatted, u: { _id }, attachments }, i, arr) => (
                      <>
                        {ts_date_formatted !== arr[i - 1]?.ts_date_formatted && (
                          <MessageDivider date={ts_date_formatted} />
                        )}
                        <MessageItem
                          userId={_id}
                          date={ts_formatted}
                          text={msg}
                          img={attachments ? attachments[0]?.image_url : null}
                        />
                      </>
                    ))}
                </Box>
                {user && <Profile user={user} />}
                {coach && <CoachProfile coach={coach} offerId={offerId} />}
                {members && <MembersList members={members} />}
              </Box>
            </>
          )}
          <SendMsgField sendMessage={sendMessage} />
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
})
const mapDispatchToProps = dispatch => ({
  readMessages: roomId => dispatch(ChatDispatcher.readMessages(roomId)),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  container: {
    fontFamily: 'Lato',
    margin: '0 50px',
  },
  messageContainer: {
    flexGrow: 1,
    borderBottom: '1px solid #D4D4D4',
    padding: '50px',
    maxHeight: '77vh',
    overflow: 'auto',
  },
})

export default compose(withStyles(styles), withRouter, connectStore)(Chat)
