import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import EventsDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import PaperContainer from '../../components/PaperContainer'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import { ExtractStyles } from '../../typings/stylesExtractor'
import { REPORT_PAGE_URL } from './ReportPage'
import ReportsExportButton from './ReportsExportButton'
import ReportsMenu, { filters } from './ReportsMenu'
import EventItem from './component/EventItem'

const connectStyles = withStyles({
  heading: {
    background: '#323232',
    borderRadius: 8,
    padding: '10px 0',
    width: '100%',
    marginTop: 60,
    textAlign: 'center',
  },
  headingCaption: {
    fontSize: 14,
    fontWeight: 800,
    color: '#fff',
  },
})

interface Props extends RouteComponentProps {}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

interface State {
  selectedFilter: string
}

class Reports extends React.PureComponent<PropsInner, State> {
  state = {
    selectedFilter: 'last-week',
  }

  render() {
    const { classes, events } = this.props
    const { selectedFilter } = this.state

    const eventArray = Object.values(events)
    const sortedEventArray = _.orderBy(eventArray, 'time', 'desc')

    return (
      <PaperContainer>
        <Grid container justifyContent={'space-between'}>
          <Typography variant={'h1'}>Events Report</Typography>

          <div>
            <ReportsMenu value={selectedFilter} onChange={this.handleChange} />

            <ReportsExportButton events={sortedEventArray} period={selectedFilter} />
          </div>
        </Grid>
        <Grid container>
          <Grid item className={classes.heading}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
              {['Date', 'Type', 'Coach', 'Arrived'].map(name => (
                <Grid item xs={3} key={name}>
                  <Typography className={classes.headingCaption} key={name}>
                    {name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container direction={'column'}>
            {sortedEventArray.map(event => (
              <EventItem key={event.id} event={event} onClick={this.handleOnEventClick(event.id)} />
            ))}
          </Grid>
        </Grid>
      </PaperContainer>
    )
  }

  handleChange = (value: string) => {
    this.setState({ selectedFilter: value })
    this.fetchEvents(value)
  }

  handleOnEventClick = (event_id: string) => () => {
    this.props.history.push(REPORT_PAGE_URL(event_id))
  }

  fetchEvents = (filter?: string) => {
    const { selectedFilter } = this.state
    const selectedFilterObject = _.find(filters, { value: filter || selectedFilter })
    this.props.getEvents(selectedFilterObject?.date)
  }

  fetchData = () => {
    this.fetchEvents()
  }

  componentDidMount(): void {
    this.fetchData()
    const reportsFilter = localStorage.getItem('reportsFilter')
    if (reportsFilter) {
      this.setState({ selectedFilter: reportsFilter })
    }
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    events: state.events,
  }),
  (dispatch: ReduxDispatch) => ({
    getEvents: (date?: string) => dispatch(EventsDispatcher.getReports({ date })),
  }),
)

export default compose<PropsInner, Props>(connectStore, connectStyles)(Reports)
