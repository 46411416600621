import api from './api'
import { actions } from './toolkit'

class Dispatcher {
  getUsersDailyLeaderboard() {
    return async (dispatch) => {
      const list = await api.getUserDailyLeaderBoard({ offset: 0 })
      dispatch(actions.setUsersDailyList(list))
    }
  }

  getStepsPath() {
    return async (dispatch) => {
      const data = await api.getStepsPath()
      dispatch(actions.setStepsPath(data))
    }
  }

  resetStepsPath() {
    return async (dispatch) => {
      await api.resetStepsPath()
      await dispatch(this.getStepsPath())
    }
  }

  getMyCompanyStepsRanking() {
    return async (dispatch) => {
      const ranking = await api.getMyCompanyStepsRanking()
      dispatch(actions.setMyCompanyStepsRanking(ranking))
    }
  }

  getCompaniesRanking() {
    return async (dispatch) => {
      const list = await api.getCompaniesRanking({ offset: 0 })
      dispatch(actions.setCompaniesBoard(list))
    }
  }

  joinCompaniesRanking() {
    return async (dispatch) => {
      await api.joinCompanyRanking({ is_public: true })
      await dispatch(this.getMyCompanyStepsRanking())
    }
  }

  leaveCompaniesRanking() {
    return async (dispatch) => {
      await api.joinCompanyRanking({ is_public: false })
      await dispatch(this.getMyCompanyStepsRanking())
    }
  }
}

const DashboardNextDispatcher = new Dispatcher()
export default DashboardNextDispatcher
