import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography as MuiTypography,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Swiper, SwiperSlide } from 'swiper/react'
import ButtonOrange from '../../components/ButtonOrange'
import customImage from './assets/custom.png'
import warrantyImage from './assets/money-back-warranty.jpg'

import 'swiper/css'
import CheckoutForm from './CheckoutForm'
import paymentApi from '../../libs/api/paymentApi'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import { ReduxState } from '@reducers/index'
import { arrowBackIcon } from '../../components/paperContainer/assets/facade'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import config from '../../config'

const stripePromise = loadStripe(config.stripe.key as string) // starts with pk_

const CURRENCIES = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'EUR',
    value: 'eur',
  },
  {
    label: 'GBP',
    value: 'gbp',
  },
]

const CURRENCIES_SYMBOL = {
  usd: '$',
  eur: '€',
  gbp: '£',
}

const PLANS = [
  {
    key: 'base',
    title: 'Basic',
    description: 'Everything you need for a successful step challenge',
    includes: 'Includes free, plus:',
  },
  {
    key: 'pro',
    title: 'Pro',
    mostPopular: true,
    description: 'Ultimate solution for team building, employee engagement and wellbeing',
    includes: 'Includes basic, plus:',
  },
  {
    title: 'Enterprise',
    key: 'enterprise',
    custom: true,
    features: [
      'Account manager',
      'Multi-level permissions',
      'Enterprise analytics',
      'Dedicated support',
      'Custom branding',
      'Planning & Management',
      'Enterprise-grade security & governance',
    ],
    description: 'Exclusive features tailored for large organizations with multiple teams',
  },
]

const useStyles = makeStyles({
  image: {
    width: 7,
    height: 13,
    marginRight: 15,
  },
  backText: {
    fontSize: 14,
    color: '#000',
    lineHeight: '100%',
  },
  backContainer: {
    cursor: 'pointer',
    marginBottom: 26,
  },
})

const SIZE = [
  {
    label: '5 Members',
    value: 5,
  },
  {
    label: '10 Members',
    value: 10,
  },
  {
    label: '15 Members',
    value: 15,
  },
  {
    label: '20 Members',
    value: 20,
  },
  {
    label: '30 Members',
    value: 30,
  },
  {
    label: '40 Members',
    value: 40,
  },
  {
    label: '50 Members',
    value: 50,
  },
  {
    label: '100 Members',
    value: 100,
  },
  {
    label: '200+ Members',
    value: 200,
  },
]

// Get closest number to value in SIZE array but not less than value
const getClosestValue = (value: number) => {
  const sorted = SIZE.map((s) => s.value).sort((a, b) => a - b)
  for (let i = 0; i < sorted.length; i++) {
    if (sorted[i] >= value) {
      return sorted[i]
    }
  }
  return sorted[sorted.length - 1]
}

const PaymentPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const company = useSelector((state: ReduxState) => state.company)
  const [plans, setPlans] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [teamSize, setTeamSize] = useState(company?.employee_count ? getClosestValue(company?.employee_count) : 5)
  const [currency, setCurrency] = useState('usd')
  const [interval, setInterval] = useState('yearly')
  const isMobile = useMediaQuery('(max-width: 600px)')

  const hasSubscription = company?.subscriptionPlan?.subscriptionId

  useEffect(() => {
    const getPlans = async () => {
      const response = await paymentApi.getSubscriptionConfig()
      setPlans(response)
    }

    getPlans()
  }, [])

  const handleSelectTeamSize = (e) => {
    setTeamSize(e.target.value)
  }

  const handleSelectCurrency = (e) => {
    setCurrency(e.target.value)
  }
  const handleBack = () => {
    if(history.length === 1 || history.length === 3 && history.location.search.includes('token')  ){
      history.replace('/');
    }else {
      history.goBack();
    }
  }

  const calculateGraduateTotal = (members, tiers) => {
    let total = 0
    let remainingMembers = members

    for (const tier of tiers) {
      if (tier.upTo) {
        const membersInTier = Math.min(tier.upTo, remainingMembers)
        total += membersInTier * tier.amount
        remainingMembers -= membersInTier
      } else {
        total += remainingMembers * tier.amount
      }
    }

    return total
  }

  const renderPlan = (plan) => {
    const pricingData = plans[`${plan.key}_${interval}_${currency}_id`]
    const featuresData = plans[`${plan.key}_features`] || []

    if (!pricingData && !plan.custom) {
      return null
    }

    let total
    const tier = pricingData?.tiers?.find((p) => (p.upTo ? teamSize <= p.upTo : true))
    if (!plan.custom) {
      total = teamSize * tier?.amount
      if (pricingData?.isGraduated) {
        total = calculateGraduateTotal(teamSize, pricingData.tiers)
      }
    }

    const isMostPopular = plan.custom ? teamSize === 200 : plan.mostPopular && teamSize !== 200

    return (
      <Plan key={plan.title} mostPopular={isMostPopular} isMobile={isMobile}>
        <Typography fontSize={21} fontWeight="bold" mb={4}>
          {plan.title}
        </Typography>
        {!plan.custom ? (
          <>
            {teamSize === 200 ? (
              <>
                <CustomImage src={customImage} />
                <ContactSalesButton
                  onClick={() => window.open('https://calendly.com/d/4mb-wnk-yf9/reaction-s-platform-demo')}
                  color="white"
                >
                  Contact Sales
                </ContactSalesButton>
              </>
            ) : (
              <>
                <PriceBlock>
                  {isMostPopular ? (
                    <MostPopular>
                      <Typography color="white" fontWeight="bold">
                        Most Popular
                      </Typography>
                    </MostPopular>
                  ) : null}
                  <Stack direction="row" alignItems="center" gap={4} mb={3}>
                    <Typography fontSize={46} fontWeight="bold">
                      {CURRENCIES_SYMBOL[currency]}
                      {interval === 'yearly' ? Math.ceil(tier.amount / 12) : tier.amount}
                    </Typography>
                    <Typography fontSize={12} lineHeight={1.4}>
                      member <br />/ month
                    </Typography>
                  </Stack>
                  <Typography fontSize={14} fontWeight="bold">
                    Total {CURRENCIES_SYMBOL[currency]}
                    {interval === 'yearly' ? Math.ceil(total / 12) : total} / month
                  </Typography>
                  <Typography fontSize={14}>Billed {interval}</Typography>
                </PriceBlock>
                <UpgradeButton
                  isMobile={isMobile}
                  onClick={() => setSelectedPlan({ ...plan, total, teamSize, currency, priceId: pricingData.priceId })}
                >
                  Upgrade
                </UpgradeButton>
              </>
            )}
            <Typography fontWeight="500" fontSize={13} color="#676878" mb={6} height={isMobile ? '40px': '80px'}>
              {plan.description}
            </Typography>
            <Features>
              <Typography fontWeight="bold" fontSize={15} mb={2}>
                {plan.includes}
              </Typography>
              {featuresData?.map((f) => (
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
                  <Typography fontSize={13} color="#5A5A5A">
                    {f.feature}
                  </Typography>
                  <Tooltip title={f.description}>
                    <InfoIcon fontSize="small" htmlColor="#DADADA" />
                  </Tooltip>
                </Stack>
              ))}
            </Features>
          </>
        ) : (
          <>
            {isMostPopular ? (
              <MostPopular>
                <Typography color="white" fontWeight="bold">
                  Most Popular
                </Typography>
              </MostPopular>
            ) : null}
            <CustomImage src={customImage} />
            <ContactSalesButton
              isMobile={isMobile}
              onClick={() => window.open('https://calendly.com/d/4mb-wnk-yf9/reaction-s-platform-demo')}
              color="white"
            >
              Contact Sales
            </ContactSalesButton>
            <Typography fontWeight="500" fontSize={13} color="#676878" mb={6} height={isMobile ? '40px':'80px'}>
              {plan.description}
            </Typography>
            <Features>
              <Typography fontWeight="bold" fontSize={15} mb={2}>
                Includes pro, plus:
              </Typography>
              {featuresData?.map((f) => (
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
                  <Typography fontSize={13} color="#5A5A5A">
                    {f.feature}
                  </Typography>
                  <Tooltip title={f.description}>
                    <InfoIcon fontSize="small" htmlColor="#DADADA" />
                  </Tooltip>
                </Stack>
              ))}
            </Features>
          </>
        )}
      </Plan>
    )
  }

  return (
    <Container isMobile={isMobile}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.backContainer}
        onClick={selectedPlan ? () => setSelectedPlan(undefined) : handleBack}
      >
        <img src={arrowBackIcon} alt="back icon" className={classes.image} />
        <Typography className={classes.backText}>Back</Typography>
      </Box>
      {selectedPlan ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm selectedPlan={selectedPlan} quantity={teamSize} currentSubscriptionId={hasSubscription} />
        </Elements>
      ) : (
        <>
          {!plans ? (
            <Stack justifyContent="center" alignItems="center" flex={1}>
              <Loader scale={1} />
            </Stack>
          ) : (
            <>
              <Box mb={isMobile? 3: 10}>
                <Typography fontSize={isMobile ? 24: 38} fontWeight="bold" textAlign="center" lineHeight={1.2}>
                  Supercharge your team&apos;s happiness,
                  <br />
                  engagement, and wellbeing
                </Typography>
              </Box>
              <Stack direction="row" justifyContent={isMobile ? 'center': 'space-between'} flexWrap="wrap">
                <Stack direction={isMobile ? 'column' : 'row'} gap={4}>
                  <Box gap={2} display="flex" justifyContent={isMobile ? 'space-between':''} alignItems="center">
                    <Typography>Choose team size:</Typography>
                    <Select value={teamSize} onChange={handleSelectTeamSize}>
                      {SIZE?.map((s) => (
                        <MenuItem value={s.value} key={s.value}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box gap={2} display="flex" justifyContent={isMobile ? 'space-between':''} alignItems="center">
                    <Typography>Currency:</Typography>
                    <Select value={currency} onChange={handleSelectCurrency}>
                      {CURRENCIES.map((s) => (
                        <MenuItem value={s.value} key={s.value}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack>
                <Box gap={2} display="flex" alignItems="center" my={4}>
                  <Interval active={interval === 'yearly'} onClick={() => setInterval('yearly')}>
                    Yearly SAVE 18%
                  </Interval>
                  |
                  <Interval active={interval === 'monthly'} onClick={() => setInterval('monthly')}>
                    Monthly
                  </Interval>
                </Box>
              </Stack>
              {isMobile ? (
                <Swiper slidesPerView={1.4} centeredSlides spaceBetween={25}>
                  {PLANS.map((plan) => (
                    <SwiperSlide style={{ paddingTop: 60 }}>{renderPlan(plan)}</SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Stack direction="row" mt={20} gap={5} justifyContent="center">
                  {PLANS.map(renderPlan)}
                </Stack>
              )}
              <Box mt={10}>
                <Typography fontSize={26} fontWeight="bold" textAlign="center" lineHeight={1.2}>
                  100% Money Back Guarantee
                </Typography>
                <Typography mt={4} textAlign="center">
                  Although we don’t think you’ll ever want one, we’ll gladly provide a refund if
                  <br />
                  you request it within 7 days of your purchase
                </Typography>
                <WarrantyImg src={warrantyImage} alt="Money Back Warranty" />
              </Box>
              <Box mt={5}>
                <iframe src="https://www.reaction-club.com/faq-payments/" height="600" width="100%" />
              </Box>
            </>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled(Box)<{ isMobile?: boolean}>`
  max-width: 1200px;
  margin: auto;
  padding: ${({ isMobile }) => (isMobile ? '10px' : '50px')};;
`

const Interval = styled(Link)<{ active?: boolean }>`
  font-family: Poppins;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#0a58ba' : 'black')};
  text-decoration: none;
  cursor: pointer;
`

const Plan = styled('div')<{ mostPopular?: boolean, isMobile?: boolean }>`
  flex: 0 0 290px;
  padding: 15px 20px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '220px')};
  color: #2d2d2d;
  box-sizing: border-box;
  position: relative;
  ${({ mostPopular }) =>
    mostPopular
      ? `
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    `
      : ''}
`

const UpgradeButton = styled(ButtonOrange)<{isMobile?: boolean}>`
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 10px 15px;
  margin-bottom: ${({ theme, isMobile }) => theme.spacing(isMobile ? 5: 10,)};
  border: 1px solid #f9b22d;
`

const ContactSalesButton = styled(UpgradeButton)`
  color: #1a1a1a;
`

const Features = styled('div')`
  border-top: 1px solid #cacaca;
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`

const CustomImage = styled('img')`
  height: 130px;
  margin: auto;
  display: block;
`

const PriceBlock = styled('div')`
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const MostPopular = styled('div')`
  background: #191b32;
  position: absolute;
  padding: 6px 10px;
  text-align: center;
  left: -1px;
  width: 101%;
  bottom: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`

const Typography = styled(MuiTypography)`
  font-family: Poppins;
`

const WarrantyImg = styled('img')`
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
`

export default PaymentPage
