import coachesDispatcher from '@reducers/coaches/dispatcher'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import ChooseBudgetAndDate from './externalCoaches/ChooseBudgetAndDate'
import ChooseCategory from './externalCoaches/ChooseCategory'
import ChooseGroup from './externalCoaches/ChooseGroup'
import ChooseLocationLanguage from './externalCoaches/ChooseLocationLanguage'
import ChooseServiceType from './externalCoaches/ChooseServiceType'
import SubmitRequest from './externalCoaches/SubmitRequest'

const ExternalCoaches = ({ history, isNew, setIsNew }) => {
  React.useEffect(() => {
    if (isNew) {
      setIsNew()
      history.replace('/coaches/invite/external/choose-group')
    }
  })

  return (
    <Switch>
      <Route path={'/coaches/invite/external/choose-group'} component={ChooseGroup} />
      <Route path={'/coaches/invite/external/choose-category'} component={ChooseCategory} />
      <Route path={'/coaches/invite/external/choose-service-type'} component={ChooseServiceType} />
      <Route path={'/coaches/invite/external/choose-location-language'} component={ChooseLocationLanguage} />
      <Route path={'/coaches/invite/external/choose-budget-date'} component={ChooseBudgetAndDate} />
      <Route path={'/coaches/invite/external/submit-request'} component={SubmitRequest} />
      <Redirect to={'/coaches/invite/external/choose-group'} />
    </Switch>
  )
}

const mapStateToProps = state => ({
  isNew: state.coaches.offer.isNew,
})
const mapDispatchToProps = dispatch => ({
  setIsNew: () => dispatch(coachesDispatcher.actions.setIsNew()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

export default connectStore(ExternalCoaches)
