import { Avatar } from '@mui/material'
import React from 'react'

interface Props {
  user: { avatar_url: string }
  size?: number
  className?: string
}
function UserAvatar(props: Props) {
  const { user, className, size } = props
  const { avatar_url } = user

  const style: React.CSSProperties = {}
  if (size) {
    style.width = size
    style.height = size
  }

  return <Avatar src={avatar_url} className={className} style={style} />
}

export default UserAvatar
