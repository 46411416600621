/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import mixpanel from 'mixpanel-browser'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import classnames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import theme from '../app/theme'
import ButtonOrange from './ButtonOrange'
import InviteEmployeesButton from './invite/InviteEmployeesButton'

const useStyles = makeStyles(
  {
    container: {
      paddingLeft: 50,
      paddingRight: 50,
      height: 70,
      backgroundColor: '#e9e9e9',

      [theme.breakpoints.down('md')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        height: 'auto',
      },
    },
    headerButton: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 15,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    customize: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
    },
    upgradeNow: {
      fontFamily: 'Poppins',
      padding: '12px 28px',
      fontSize: 16,
      border: '2px solid rgb(249, 178, 45)',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        padding: '5px 15px',
        width: '100%',
        marginTop: 12,
      },
    },
  },
  {
    classNamePrefix: 'Dashboard',
  },
)

const Header = () => {
  const classes = useStyles()
  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))
  const history = useHistory()

  const handleUpgrade = () => {
    mixpanel.track('Upgrade Now Clicked', {
      distinct_id: company?.id,
    })
    history.push('/payment')
  }

  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

  return (
    <Grid container alignItems={'center'} className={classes.container}>
      <Grid item xs={12} md={4}>
        <InviteEmployeesButton title={'Invite members to the mobile app'} className={classes.headerButton} />
      </Grid>
      <Grid item xs={4} display={{ xs: 'none', md: 'block' }}>
        <Typography fontWeight="bold" textAlign="center">
          Admin Console
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} textAlign="right">
        {isFreeTrial ? (
          <ButtonOrange
            className={classnames(classes.upgradeNow, classes.headerButton)}
            color="transparent"
            onClick={handleUpgrade}
          >
            Upgrade Now
          </ButtonOrange>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default Header
