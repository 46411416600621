/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import TeamHeadActions from './components/TeamHeadActions'
import { ICompanyTeam } from '@reducers/teams/types'
import EmployeeItem from '../../groups/employees/EmployeeItem'
import { useDispatch, useSelector } from 'react-redux'
import PlusButton from '../../../components/buttons/PlusButton'
import ReactionLoader from '../../../components/ReactionLoader'
import TeamDispatcher from '@reducers/teams/dispatcher'
import { useDebounce } from 'use-debounce'
import ButtonOrangeSquare from '../../../modals/components/ButtonOrangeSquare'

interface Props {
  team: ICompanyTeam
}
function TeamInfo(props: Props) {
  const dispatch = useDispatch()
  const { team } = props
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const [loading, setLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState([])
  const [addingMembers, setAddingMembers] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [potentialMembers, setPotentialMembers] = useState([])
  const teams = useSelector((state) => state.lists.teams)
  const [selectedTeam, setSelectedTeam] = useState('non')
  const { name } = team

  const getTeamsMembers = useCallback(() => {
    setLoading(true)
    dispatch(TeamDispatcher.getTeamMembers(team.id))
      .then((data) => {
        setTeamMembers(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(TeamDispatcher.getList({ isForce: false }))
      getTeamsMembers()
    }
    fetchData()
  }, [])

  const handleLoadPotentialMembers = useCallback(() => {
    setLoading(true)
    dispatch(
      TeamDispatcher.getTeamPotentialMembers(team.id, {
        name: search,
        teamId: selectedTeam === 'all' ? '' : selectedTeam,
      }),
    )
      .then(setPotentialMembers)
      .finally(() => {
        setLoading(false)
      })
  }, [team, search, selectedTeam])

  useEffect(() => {
    if (addingMembers && team) {
      handleLoadPotentialMembers()
    }
  }, [addingMembers, team, selectedTeam, debouncedSearch])

  const handleAddEmployee = useCallback(() => {
    setAddingMembers(true)
  }, [])

  const handleRemoveEmployee = useCallback((id) => {
    const isConfirmed = confirm('Are you sure want to remove this user from team?')
    if (!isConfirmed) return null

    dispatch(TeamDispatcher.removeTeamMembers([id])).then(getTeamsMembers)
  }, [])

  const handleSelectEmployee = useCallback(
    (id) => {
      const isExist = selectedUsers.includes(id)
      if (isExist) {
        setSelectedUsers(selectedUsers.filter((user) => user !== id))
      } else {
        setSelectedUsers([...selectedUsers, id])
      }
    },
    [selectedUsers],
  )

  const handleSelectTeam = useCallback((e) => {
    setSelectedTeam(e.target.value)
  }, [])

  const handleMoveUsers = useCallback(() => {
    setLoading(true)
    dispatch(TeamDispatcher.moveTeamMembers(team.id, selectedUsers)).finally(() => {
      setAddingMembers(false)
      setSelectedTeam('all')
      setSearch('')
      setSelectedUsers([])
      setPotentialMembers([])
      getTeamsMembers()
    })
  }, [selectedUsers, selectedTeam])

  return (
    <div>
      <Grid container justifyContent={'space-between'}>
        <Typography variant={'h1'} fontSize={21}>
          Team {name} ({teamMembers?.length} members)
        </Typography>

        <TeamHeadActions team={team} />
      </Grid>

      <Box mb={2}>
        {addingMembers ? (
          <Box gap={2} display="flex" alignItems="center">
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder="Search"
              variant={'outlined'}
              InputProps={{
                style: {
                  width: 300,
                  color: '#323232',
                },
              }}
            />
            <Select value={selectedTeam} onChange={handleSelectTeam}>
              <MenuItem value="non">No Team</MenuItem>
              <MenuItem value="all">All</MenuItem>
              {teams
                ?.filter((t) => t.id !== team.id)
                ?.map((t) => (
                  <MenuItem value={t.id} key={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        ) : (
          <PlusButton onClick={handleAddEmployee} title="Add members to the team" />
        )}
      </Box>

      {loading ? (
        <ReactionLoader />
      ) : (
        <Grid container spacing={4}>
          {addingMembers ? (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Box
                  css={css`
                    max-height: 60vh;
                    overflow: auto;
                    margin-bottom: 15px;
                  `}
                >
                  {potentialMembers.map((employee) => {
                    return (
                      <EmployeeItem
                        key={`employees-list-item-${employee.user_id}`}
                        employee={employee}
                        subText={teams.find((t) => t.id === employee.team_id)?.name}
                        selected={selectedUsers.includes(employee.user_id)}
                        onSelect={handleSelectEmployee}
                      />
                    )
                  })}
                </Box>
                {selectedUsers.length ? (
                  <ButtonOrangeSquare onClick={handleMoveUsers}>Move to {name}</ButtonOrangeSquare>
                ) : null}
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12} md={6}>
              {teamMembers.map((employee) => {
                return (
                  <EmployeeItem
                    key={`employees-list-item-${employee.user_id}`}
                    employee={employee}
                    onRemove={handleRemoveEmployee}
                  />
                )
              })}
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )
}

export default TeamInfo
