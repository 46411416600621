import api from './api'
import { actions } from './toolkit'

class Dispatcher {
  getEventConfigs() {
    return async (dispatch) => {
      const eventConfig = await api.getEventConfigs()
      await dispatch(actions.setEventConfigs(eventConfig))
    }
  }

  setPenaltyEnabled(isEnabled: boolean) {
    return async (dispatch) => {
      await api.setPenaltyEnabled(isEnabled)
      await dispatch(this.getEventConfigs())
    }
  }

  setPenaltyDays(params: { penalty_duration: number; penalty_start_offset: number }) {
    return async (dispatch) => {
      await api.setPenaltyDays(params)
      await dispatch(this.getEventConfigs())
    }
  }
}

const ConfigDispatcher = new Dispatcher()
export default ConfigDispatcher
