import { Employee } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  challengesLeaderBoard?: any
  challengesSummary?: any[]
  userStepsList?: (Employee & { steps: number })[]
  userPointsList?: (Employee & { points: number })[]
}

const initialState: State = {}

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setChallengesLeaderBoard(state, action: PayloadAction<State['challengesLeaderBoard']>) {
      state.challengesLeaderBoard = action.payload
    },
    setChallengesSummary(state, action: PayloadAction<State['challengesSummary']>) {
      state.challengesSummary = action.payload
    },
    setUserStepsList(state, action: PayloadAction<State['userStepsList']>) {
      state.userStepsList = action.payload
    },
    setUserPointsList(state, action: PayloadAction<State['userPointsList']>) {
      state.userPointsList = action.payload
    },
  },
})

export const { actions, reducer } = slice
export default reducer
