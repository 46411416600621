import ACTION_TYPE, { ModalAction, MODAL_TYPES } from './types'

type ModalProps = {
  closable?: boolean
  [prop: string]: any
}

type ModalState = {
  [modalType in MODAL_TYPES]?: ModalProps
}

interface ModalReducer {
  (state: ModalState, action: ModalAction): ModalState
}

const initState: ModalState = {}

const reducer: ModalReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SHOW_MODAL: {
      const { modalType, modalProps = {} } = action.payload

      return {
        ...state,
        [modalType]: modalProps,
      }
    }
    case ACTION_TYPE.CLOSE_MODAL: {
      const newState = { ...state }
      delete newState[action.payload]

      return newState
    }
    default:
      return state
  }
}

export default reducer
