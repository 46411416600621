import { Agent, Coach, Company, CompanySticker, Employee, Group, Link } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State extends Company, StoreUpdatedObject {
  instances?: Group[]
  link?: Link
  hrLink?: Link
  stickers?: CompanySticker
  agents?: Agent[]
  employees?: Employee[]
  emails: string[]
  subscriptionPlan: {
    expired: boolean
    status: string
    type: string
    quantity: number
    intervalUnit: string
    subscriptionId: string
    paymentMethod: any
  }
}

const initialState: State = {
  emails: [],
} as unknown as State

export default function companyReducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const company: Company = action.payload
      return state.merge({
        ...company,
        storeUpdatedAt: Date.now(),
      })
    }
    // case types.SET_AGENT: {
    //   const agent = action.payload
    //   return state.merge(agent)
    // }
    case types.SET_AGENTS: {
      const agents = action.payload
      return state.merge({ agents })
    }
    case types.REMOVE_AGENT: {
      const id = action.payload
      return state.merge({ agents: state.agents.filter(({ user_id }) => user_id !== id) })
    }
    case types.SET_LINK: {
      const link: Link = action.payload
      return state.merge({ link })
    }
    case types.SET_HR_LINK: {
      const hrLink: Link = action.payload
      return state.merge({ hrLink })
    }
    case types.SET_INVITE_LINK: {
      const inviteLink = action.payload
      return state.merge({ inviteLink })
    }
    case types.SET_STICKERS: {
      const stickers: CompanySticker[] = action.payload
      return state.merge({ stickers })
    }
    case types.SET_PLAYBOOK_INSTANCES: {
      const instances: Group[] = action.payload
      return state.merge({ instances })
    }

    case types.SET_EMPLOYEES: {
      return state.merge({
        employees: action.payload,
      })
    }

    case types.SET_EMAIL_LIST: {
      const emails = action.payload || []
      return state.merge({
        emails,
      })
    }

    // Old
    case types.SET_COMPANY_EMPLOYEES: {
      return state.merge({
        employees: action.payload,
      })
    }
    case types.SET_COMPANY_COACHES: {
      return state.merge({
        coaches: action.payload,
      })
    }
    case types.FILTER_COACHES: {
      return state.merge({
        searchCoach: action.payload,
      })
    }
    case types.SET_COMPANY_PLAYBOOK: {
      return state.merge({
        ...state,
        playbooks: action.payload,
        _playbooksOriginal: action.payload,
      })
    }

    case types.REMOVE_PLAYBOOK: {
      let originPlaybooks = state.playbooks
      const removeByItem = (arr, value) =>
        arr.filter((item) => {
          return item.playbook_id !== value.id
        })
      const newPaybooks = removeByItem(originPlaybooks, action.payload)
      return state.merge({
        playbooks: newPaybooks,
      })
    }
    case types.ADD_PLAYBOOK: {
      let { playbooks } = state
      const addItem = (arrays, value) => arrays.concat(value)
      return state.merge({
        playbooks: addItem(playbooks, action.payload),
      })
    }
    case types.SET_COMPANY_STICKERS: {
      return state.merge({
        ...state,
        stickers: action.payload,
      })
    }
    case types.SET_COMPANY_INVITE_LINKS: {
      return state.merge({
        ...state,
        links: action.payload,
      })
    }

    default:
      return state
  }
}
