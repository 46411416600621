import { Box, Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import React from 'react'
import { compose } from 'redux'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'

type OwnProps = {
  members: any
}
type Props = OwnProps & WithStyles<typeof styles>
const MembersList: React.FC<Props> = ({ classes, members }) => {
  return (
    <Box className={classes.memberContainer}>
      {members.map(({ name, customFields: { avatar_url, role = '' } }) => (
        <Box className={classes.memberWrapper}>
          <Box style={{ marginRight: 16 }}>
            <ReactionAvatar asset={{ url: avatar_url }} style={{ height: 40 }} />
          </Box>
          <Box>
            <Typography className={classes.memberName}>{name}</Typography>
            <Typography className={classes.memberRole}>{role}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const styles = createStyles({
  memberContainer: {
    padding: '50px 30px',
    background: '#f3f3f3',
    maxWidth: 332,
    flexGrow: 1,
    borderBottom: '1px solid #d4d4d4',
  },
  memberWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 24,
    },
  },
  memberName: {
    fontSize: 16,
    fontWeight: 800,
    lineHeight: 1,
    fontFamily: 'Lato',
    letterSpacing: '1',
    marginBottom: 8,
  },
  memberRole: {
    color: '#5F6164',
    fontFamily: 'Lato',
    fontSize: 12,
    lineHeight: 1,
  },
})

export default compose(withStyles(styles))(MembersList)
