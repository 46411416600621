import { Grid, Menu, MenuItem, Radio, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { ExtractStyles } from '../../typings/stylesExtractor'
import { downIcon } from './assets/facade'

const STORE_MENU_KEY = 'reportsFilter'

const connectStyles = withStyles({
  container: {
    marginBottom: 20,
  },
  filterButton: {
    cursor: 'pointer',
    marginRight: 5,
    fontWeight: 600,
  },
  menuItem: {
    color: '#323232',
    fontSize: 14,
    fontWeight: 600,
    // margin: '5px 0',
    paddingLeft: 8,
    '&:hover': {
      color: '#F89A00',
      fontWeight: 600,
    },
  },
  menuItemWrapper: {
    marginBottom: 11,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

export const filters = [
  {
    value: 'last-week',
    name: 'Last Week',
    date: moment()
      .add(-1, 'week')
      .toISOString(),
    getDate() {
      return moment()
        .add(-1, 'week')
        .toISOString()
    },
  },
  {
    value: 'last-30',
    name: 'Last 30 days',
    period: 30,
    date: moment()
      .add(-30, 'day')
      .toISOString(),
    getDate() {
      return moment()
        .add(-30, 'day')
        .toISOString()
    },
  },
  {
    value: 'last-3',
    name: 'Last 3 Month',
    date: moment()
      .add(-3, 'month')
      .toISOString(),
    getDate() {
      return moment()
        .add(-3, 'month')
        .toISOString()
    },
  },
  {
    value: 'last-6',
    name: 'Last 6 Month',
    date: moment()
      .add(-6, 'month')
      .toISOString(),
    getDate() {
      return moment()
        .add(-6, 'month')
        .toISOString()
    },
  },
  {
    value: 'last-year',
    name: 'Last Year',
    date: moment()
      .add(-1, 'year')
      .toISOString(),
    getDate() {
      return moment()
        .add(-1, 'year')
        .toISOString()
    },
  },
]

interface Props {
  value: string
  onChange(val: string): any
}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

interface State {
  isMenuOpen: boolean
}

class ReportsMenu extends React.PureComponent<PropsInner, State> {
  actionRef

  state: State = {
    isMenuOpen: false,
  }

  render() {
    const { classes, value } = this.props
    const { isMenuOpen } = this.state

    const { name: selectedFilterName } = _.find(filters, { value }) || {}

    return (
      <Grid
        item
        className={classes.container}
        ref={ref => {
          this.actionRef = ref
        }}
      >
        <Grid container alignItems={'center'}>
          <Typography onClick={this.handleMenuOpen} className={classes.filterButton}>
            {selectedFilterName}
          </Typography>
          <img src={downIcon} alt="downIcon" />
          <Menu
            id="long-menu"
            anchorEl={this.actionRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ transform: 'translate(5px, 20px)' }}
            keepMounted
            open={isMenuOpen}
            onClose={this.handleMenuClose}
            PaperProps={{
              style: {
                width: 222,
                height: 222,
                backgroundColor: '#fff',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
                // transform: 'translate(13px, 20px)',
              },
            }}
            MenuListProps={{
              style: {
                padding: '20px 0 20px 0',
              },
            }}
          >
            {filters.map(item => (
              <MenuItem className={classes.menuItemWrapper} key={item.value} onClick={this.handleChange(item.value)}>
                <Radio checked={value === item.value} style={{ padding: 0 }} />
                <Typography variant="inherit" className={classes.menuItem}>
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
    )
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleChange = (value: string) => () => {
    console.log('handleChange', value)
    this.handleMenuClose()
    this.props.onChange(value)
    localStorage.setItem(STORE_MENU_KEY, value)
  }

  componentDidMount(): void {
    const reportsFilter = localStorage.getItem(STORE_MENU_KEY)
    if (reportsFilter) {
      this.props.onChange(reportsFilter)
    }
  }
}

export default connectStyles(ReportsMenu)
