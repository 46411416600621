/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  Grid,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import apiChallenge from '@reducers/challenge/api'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import { ReduxState } from '@reducers/index'
import TeamDispatcher from '@reducers/teams/dispatcher'
import { capitalize, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../components/ButtonOrange'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { TerminateChallenge } from '../groups/TerminateChallenge'
import CompanyTeams from './CompanyTeams'
import durationIcon from './assets/duration.png'
import rewardIcon from './assets/reward.png'
import startDateIcon from './assets/start-date.png'
import teamsIcon from './assets/teams.png'
import winnerImage from './assets/winner.png'

const steps = ['Challenge name', 'Start date & duration', 'Teams\n(optional)', 'Prize\n(optional)']

const spacing = 5

const cssIcon = css`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

const cssMetricItem = css`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`
const cssMetricTitle = css`
  font-weight: bold;
  margin-left: 10px;
  margin-right: 15px;
  font-size: 18px;
`

const cssMetricValue = css`
  font-size: 14px;
`

const cssWinnerImage = css`
  width: 350px;
`

const EditChallenge = (props) => {
  const { match } = props
  const { id } = match.params || {}
  const [challenge, setChallenge] = useState<any[]>([])
  const history = useHistory()
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [prizeType, setPrizeType] = useState<'points' | 'custom'>('points')
  const [settings, setSettings] = useState<any>({
    customPrize: null,
  })
  const [activeStep, setActiveStep] = useState(0)
  const { teams } = useSelector((state: ReduxState) => ({
    teams: state.lists.teams,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    const res = await apiChallenge.getChallenge(id)
    setChallenge(res)
  })

  useAsyncFetch(
    async () => {
      await dispatch(TeamDispatcher.getList({ isForce: false }))
    },
    { maxTries: 3 },
  )

  useEffect(() => {
    const challengeStartDate = moment(challenge?.start_date, 'YYYY-MM-DD').toDate()
    const challengeEndDate = moment(challenge?.start_date, 'YYYY-MM-DD')
      .add(challenge?.duration - 1, 'days')
      .toDate()

    setName(challenge?.name || '')
    setSettings({
      customPrize: challenge?.custom_prize,
      startDate: challengeStartDate,
      endDate: challengeEndDate,
      isDisabledDate: challenge?.general_start_date && challenge.general_end_date,
    })
    setPrizeType(challenge?.custom_prize ? 'custom' : 'points')
  }, [challenge])

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value || '')
  }

  const handleSettingsChange = (field) => (e) => {
    setSettings({
      ...settings,
      [field]: e.target.value || '',
    })
  }

  const handleDateChange = ([startDate, endDate]) => {
    setSettings({
      ...settings,
      startDate,
      endDate,
    })
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleNext = (validate?) => {
    setActiveStep(activeStep + 1)
  }

  const handlePublish = async () => {
    const duration = moment(settings.endDate).diff(settings.startDate, 'days') + 1

    await dispatch(
      ChallengeDispatcher.updateChallenge(challenge.application_id, {
        name,
        repeat: true,
        duration,
        customPrize: prizeType === 'custom' ? settings.customPrize : null,
        startDate: moment(settings.startDate).format('YYYY-MM-DD'),
      }),
    )
    history.push(`/challenge/${challenge.challenge_id}`)
  }

  const renderFirstStep = () => {
    return (
      <Box mt={15}>
        <div>
          <TextField
            value={name}
            onChange={handleNameChange}
            label="Challenge name"
            variant="standard"
            InputProps={{
              style: {
                width: 300,
                color: '#323232',
              },
            }}
          />
        </div>
        <ButtonOrange
          styles={{ width: 180, paddingTop: 13, paddingBottom: 13, marginTop: 100 }}
          onClick={() => handleNext()}
          size="large"
        >
          <Typography
            css={css`
              font-family: Poppins;
              font-weight: 700;
              font-size: 14px;
            `}
          >
            Next
          </Typography>
        </ButtonOrange>
      </Box>
    )
  }

  const renderSecondStep = () => {
    return (
      <Box mt={15} alignItems="center" display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <DateRangePicker
            disabled={settings.isDisabledDate}
            onChange={handleDateChange}
            minDate={moment().add(1, 'day').toDate()}
            value={[settings.startDate, settings.endDate]}
            renderInput={(startProps, endProps) => (
              <Box alignItems="center" display="flex">
                <Box position="relative">
                  <TextField {...startProps} style={{ width: 300 }} />
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    position="absolute"
                    top="0"
                    right="0"
                    height="100%"
                    pr={2}
                    pl={2}
                    borderLeft="1px solid #D4D4D4"
                  >
                    <AccessTimeIcon fill="#D4D4D4" />
                    00:01
                  </Box>
                </Box>
                <Box sx={{ mx: 2 }}> to </Box>
                <Box position="relative">
                  <TextField {...endProps} style={{ width: 300 }} />
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    position="absolute"
                    top="0"
                    right="0"
                    height="100%"
                    pr={2}
                    pl={2}
                    borderLeft="1px solid #D4D4D4"
                  >
                    <AccessTimeIcon fill="#D4D4D4" />
                    23:59
                  </Box>
                </Box>
              </Box>
            )}
          />
        </Box>
        <Box mt={20}>
          {error ? (
            <Box mb={15}>
              <Typography variant="body1" color="red">
                {error}
              </Typography>
            </Box>
          ) : null}
          {challenge.challenge_item_id && moment(settings.startDate).isSameOrBefore(challenge.challenge_item_end_at) ? (
            <Box mb={15}>
              <Typography variant="body1" color="red">
                There is an active challenge in this dates , please{' '}
                <TerminateChallenge challengeId={challenge.challenge_item_id} /> the active challenge before you
                continue.
              </Typography>
            </Box>
          ) : null}
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13 }}
            color="transparent"
            onClick={handleBack}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Back
            </Typography>
          </ButtonOrange>
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13, marginLeft: 15 }}
            onClick={() => handleNext(true)}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Next
            </Typography>
          </ButtonOrange>
        </Box>
      </Box>
    )
  }

  const renderThirdStep = () => {
    return (
      <Box mt={15}>
        <CompanyTeams optional />
        <Box mt={20}>
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13 }}
            color="transparent"
            onClick={handleBack}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Back
            </Typography>
          </ButtonOrange>
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13, marginLeft: 15 }}
            onClick={() => handleNext()}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Next
            </Typography>
          </ButtonOrange>
        </Box>
      </Box>
    )
  }

  const renderFourthStep = () => {
    return (
      <Box mt={15}>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={prizeType || ''}
              onChange={(e, value) => setPrizeType(value)}
            >
              <FormControlLabel
                value="points"
                control={<Radio />}
                label={
                  <Typography textAlign="left">
                    Points (default)
                    <Typography variant="body2">Top 3 members - 10 points</Typography>
                    <Typography variant="body2">Top 3 improvers week over week - 20 points</Typography>
                  </Typography>
                }
              />
              <FormControlLabel value="custom" control={<Radio />} label="Points + custom" />
            </RadioGroup>
          </FormControl>
          <div>
            <TextField
              value={settings.customPrize}
              onChange={handleSettingsChange('customPrize')}
              placeholder="The winning individual and team will get..."
              multiline
              rows={8}
              InputProps={{
                style: {
                  width: 300,
                  color: '#323232',
                },
              }}
            />
          </div>
        </div>
        <Box mt={20}>
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13 }}
            color="transparent"
            onClick={handleBack}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Back
            </Typography>
          </ButtonOrange>
          <ButtonOrange
            styles={{ width: 180, paddingTop: 13, paddingBottom: 13, marginLeft: 15 }}
            onClick={() => handleNext()}
            size="large"
          >
            <Typography
              css={css`
                font-family: Poppins;
                font-weight: 700;
                font-size: 14px;
              `}
            >
              Next
            </Typography>
          </ButtonOrange>
        </Box>
      </Box>
    )
  }

  const renderPublish = () => {
    const startDate = moment(settings.startDate)
    const endDate = moment(settings.endDate)
    const durationDays = endDate.diff(startDate, 'day') + 1
    const durationLength = durationDays % 7 === 0 ? durationDays / 7 : durationDays
    const durationType = durationDays % 7 === 0 ? 'weeks' : 'days'

    return (
      <Grid container spacing={spacing} alignItems={'stretch'}>
        <Grid item xs={6}>
          <Typography variant="h1" fontSize={24} mb={7}>
            {name || `${capitalize(challenge?.type_data.label)} Challenge`} Summary
          </Typography>

          <Grid container css={cssMetricItem}>
            <img css={cssIcon} src={startDateIcon} alt="Start date" />
            <Typography css={cssMetricTitle}>Start date</Typography>
            <Typography css={cssMetricValue}>{startDate.format('YYYY-MM-DD')}</Typography>
          </Grid>

          <Grid container css={cssMetricItem}>
            <img css={cssIcon} src={durationIcon} alt="Duration" />
            <Typography css={cssMetricTitle}>Duration</Typography>
            <Typography css={cssMetricValue}>
              {durationLength} {durationType} (repeated)
            </Typography>
          </Grid>

          <Grid container css={cssMetricItem}>
            <img css={cssIcon} src={teamsIcon} alt="Teams" />
            <Typography css={cssMetricTitle}>Teams</Typography>
            <Typography css={cssMetricValue}>
              {isEmpty(teams) ? 'Not set' : teams.map((t) => t.name).join(', ')}
            </Typography>
          </Grid>

          <Grid container css={cssMetricItem}>
            <img css={cssIcon} src={rewardIcon} alt="Prize" />
            <Typography css={cssMetricTitle}>Prize</Typography>
            <Typography css={cssMetricValue}>
              Points{prizeType === 'custom' ? `, ${settings?.customPrize}` : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <img css={cssWinnerImage} src={winnerImage} alt="Winner" />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mt={15}>
          <ButtonOrange
            styles={{
              width: 230,
              paddingTop: 17,
              paddingBottom: 17,
            }}
            onClick={handlePublish}
            size="large"
          >
            <Typography
              css={css`
                font-weight: 700;
                font-size: 16px;
                margin-right: 4px;
              `}
            >
              Publish
            </Typography>
          </ButtonOrange>
        </Grid>
      </Grid>
    )
  }

  const renderContent = () => {
    return activeStep === 4 ? (
      renderPublish()
    ) : (
      <Grid container>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1" fontSize={24} mb={7}>
            Edit Challenge
          </Typography>
          <Box mb={6}>
            {activeStep === 0 && <Typography>First up, what&apos;s your challenge title? (optional)</Typography>}
            {activeStep === 1 && <Typography>Great. Now select your challenge start date and duration</Typography>}
            {activeStep === 2 && <Typography>Would you like to add teams? (optional)</Typography>}
            {activeStep === 3 && <Typography>Would the winners get something?</Typography>}
          </Box>
          <Box width={500} style={{ margin: '0 auto' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel style={{ whiteSpace: 'pre-line' }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 && renderFirstStep()}
          {activeStep === 1 && renderSecondStep()}
          {activeStep === 2 && renderThirdStep()}
          {activeStep === 3 && renderFourthStep()}
        </Grid>
      </Grid>
    )
  }

  return (
    <PaperContainer>
      <RendererStatusSplit
        statuses={fetchAction}
        renderLoading={() => <ReactionLoader />}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        render={renderContent}
      />
    </PaperContainer>
  )
}

export default EditChallenge
