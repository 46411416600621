/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ReactionEvent } from '@reaction-club-types/core'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useSelector } from 'react-redux'
import CompanyLogo from '../../../components/CompanyLogo'
import UserAvatar from '../../../components/UserAvatar'

const cssDefault = css`
  height: 70px;
  width: 70px;
`

interface Props {
  event: ReactionEvent
  className?: string
}

function EventPicture(props: Props) {
  const { event, className } = props
  const { coach } = event

  const { company_logo } = useSelector((state: ReduxState) => ({
    company_logo: state.company.logo,
  }))

  if (coach) return <UserAvatar user={coach} className={className} css={cssDefault} />

  return <CompanyLogo url={company_logo} className={className} css={cssDefault} />
}

export default EventPicture
