import { Grid, Link } from '@mui/material'
import { withStyles } from '@mui/styles'
import apiCompany, { ConfigIdentifiersCheckResponse } from '@reducers/company/api'
import React from 'react'
import { compose } from 'recompose'
import fileExplorer from 'web-file-explorer'
import ButtonOrange from '../../../../components/ButtonOrange'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import IdentifiersInfoModal from './IdentifiersInfoModal'

const connectStyles = withStyles({
  identifierContainer: {
    padding: 20,
  },

  identityButtonsContainer: {
    marginTop: 30,
  },

  identityUploadButton: {
    marginLeft: 20,
  },
})

interface Props {
  isVisible: boolean
}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}
interface State {
  isFileUploading: boolean
  isFileUploaded: boolean
  isApplied: boolean
  file: File | null
  info: ConfigIdentifiersCheckResponse | null
}

class IdentifiersUpload extends React.PureComponent<PropsInner, State> {
  state: State = {
    isFileUploading: false,
    isFileUploaded: false,
    isApplied: false,
    info: null,
    file: null,
  }

  render() {
    const { classes, isVisible } = this.props
    const { isFileUploading, isApplied, info } = this.state

    if (!isVisible) {
      return null
    }

    if (isApplied)
      return (
        <div className={classes.identifierContainer}>
          <span>Changes are applied.</span>
        </div>
      )

    return (
      <div className={classes.identifierContainer}>
        <div>
          You can upload excel document with employee number list.
          <br />
          Note: If some number is deleted, employee will be kicked from company
        </div>

        <Grid className={classes.identityButtonsContainer}>
          <Link href={'/static/Reaction_em_numbers.xlsx'}>Download template</Link>
          <ButtonOrange
            onClick={this.handlePressUploadExcel}
            className={classes.identityUploadButton}
            isLoading={isFileUploading}
          >
            Upload Excel
          </ButtonOrange>
        </Grid>
        <IdentifiersInfoModal info={info} onClose={this.handleModalClose} onApply={this.handleApply} />
      </div>
    )
  }

  handlePressUploadExcel = async () => {
    const file = await fileExplorer.getFile()

    if (!file) return file

    this.setState({ isFileUploading: true, file })
    try {
      const info = await apiCompany.checkXlsxIdentifiers(file)
      this.setState({ isFileUploaded: true, info })
    } catch (e) {}

    this.setState({ isFileUploading: false })
  }

  handleApply = async () => {
    const { file } = this.state
    if (!file) {
      console.error('No File found')
      return null
    }

    console.log(3)
    await apiCompany.applyXlsxIdentifiers(file)
    console.log(5)

    this.setState({ isApplied: true })
  }

  handleModalClose = () => this.setState({ info: null })
}

export default compose<PropsInner, Props>(connectStyles)(IdentifiersUpload)
