import { Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import * as React from 'react'
import PaperContainer from '../../components/PaperContainer'
import Champions from './components/Champions'

const Coaches = ({ classes, ...rest }) => {
  return (
    <PaperContainer>
      <Typography className={classes.title}>Choose a coach</Typography>
      <Champions {...rest} isChooseMode />
    </PaperContainer>
  )
}

const styles = createStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: 32,
  },
})

export default withStyles(styles)(Coaches)
