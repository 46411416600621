import { ReduxState } from '@reducers/index'
import StockDispatcher from '@reducers/stocks/dispatcher'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AntSwitch from '../../../elements/AntSwitch'

function StocksPreference() {
  const dispatch = useDispatch()
  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))

  const { is_stocks_enabled } = company.preferences || {}

  const handleChange = async () => {
    dispatch(StockDispatcher.setStocksPreference(!is_stocks_enabled))
  }

  return <AntSwitch checked={is_stocks_enabled} onChange={handleChange} name="is_stocks_enabled" />
}

export default StocksPreference
