import { Company, CompanySticker, Link, PlaybookInstance } from '@reaction-club-types/core'
import types from './types'

export default {
  set: (company: Company) => ({ type: types.SET, payload: company }),
  // setAgent: agent => ({ type: types.SET_AGENT, payload: agent }),
  setAgents: (agents) => ({ type: types.SET_AGENTS, payload: agents }),
  setEmployees: (employees) => ({ type: types.SET_EMPLOYEES, payload: employees }),
  removeAgent: (id) => ({ type: types.REMOVE_AGENT, payload: id }),
  setLink: (link: Link) => ({ type: types.SET_LINK, payload: link }),
  setHrLink: (link: Link) => ({ type: types.SET_HR_LINK, payload: link }),
  setStickers: (stickers: CompanySticker[]) => ({ type: types.SET_STICKERS, payload: stickers }),
  setPlaybookInstances: (instances: PlaybookInstance[]) => ({ type: types.SET_PLAYBOOK_INSTANCES, payload: instances }),
  setEmailList: (emails) => ({ type: types.SET_EMAIL_LIST, payload: emails }),

  // old
  setCompanyCoaches: (coaches) => ({ type: types.SET_COMPANY_COACHES, payload: coaches }),
  setCompanyEmployees: (employees) => ({ type: types.SET_COMPANY_EMPLOYEES, payload: employees }),
  setCoachFilter: (search) => ({ type: types.FILTER_COACHES, payload: search }),
  setCompanyPlaybook: (playbooks) => ({ type: types.SET_COMPANY_PLAYBOOK, payload: playbooks }),
  addOnePlaybook: (playbook) => ({ type: types.ADD_PLAYBOOK, payload: playbook }),
  removePlaybook: (playbook) => ({ type: types.REMOVE_PLAYBOOK, payload: playbook }),
  setCompanyStickers: (stickers) => ({ type: types.SET_COMPANY_STICKERS, payload: stickers }),
  setCompanyPlaybookInstances: (playbookInstances) => ({
    type: types.SET_COMPANY_PLAYBOOK_INSTANCES,
    payload: playbookInstances,
  }),
  setInviteLinks: (links) => ({ type: types.SET_COMPANY_INVITE_LINKS, payload: links }),
}
