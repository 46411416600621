import { Avatar, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ConfigIdentifiersCheckResponse } from '@reducers/company/api'
import _ from 'lodash'
import React from 'react'
import ButtonOrange from '../../../../components/ButtonOrange'
import ModalStyled from '../../../../elements/ModalStyled'
import useActionHandler from '../../../../hooks/useAsyncHandler'

const useStyles = makeStyles(
  {
    modal: {
      width: 350,
    },
    title: {
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 30,
    },
    label: {
      color: '#6a6a6a',
      fontWeight: 'bold',
      fontSize: 12,
      marginRight: 5,
    },

    listContainer: {
      height: 200,
      overflowY: 'auto',
      padding: '5px 0',
      marginRight: -15,
      marginBottom: 5,
    },

    listItem: {
      padding: '3px 5px 3px 0',
    },

    listItemName: {
      fontSize: 12,
      flexGrow: 1,
    },

    listItemIdentifier: {
      marginLeft: 10,
      color: '#1b8003',
      fontWeight: 'bold',
      fontSize: 12,
    },

    listItemAvatar: {
      width: 24,
      height: 24,
      marginRight: 10,
    },
  },
  { classNamePrefix: 'IdentifiersInfoModal' },
)

interface Props {
  info: ConfigIdentifiersCheckResponse | null
  onClose(): any
  onApply(): any
}

function IdentifiersInfoModal(props: Props) {
  let { info, onClose, onApply } = props
  // info = {
  //   totalCreated: 5223,
  //   totalRemoved: 0,
  //   usersRemoved: [
  //     { name: 'User1', identifier: 'qqq', user_id: '1' },
  //     { name: 'User2', identifier: 'qq2q', user_id: '2', avatar_url: '12' },
  //   ],
  // }

  const classes = useStyles()

  const { onAction, isLoading } = useActionHandler(async () => {
    await onApply()
  })

  if (!info) return null

  const handleApply = async () => onAction()

  const handleButtonPress = () => {
    if (!_.isEmpty(usersRemoved)) {
      const answer = prompt(`Are you sure want to remove ${usersRemoved.length} users?. Input "confirm"`)
      if (answer !== 'confirm') return null
    }
    return handleApply()
  }

  const { totalCreated, totalRemoved, usersRemoved } = info

  return (
    <ModalStyled isVisible={!!info} onClose={onClose} className={classes.modal} showCloseButton>
      <div>
        <Typography align={'center'} className={classes.title}>
          Modification Preview
        </Typography>
      </div>
      <div>
        <div>
          <span className={classes.label}>Total Created:</span>
          {totalCreated}
        </div>
        <div>
          <span className={classes.label}>Total Removed:</span>
          {totalRemoved}
        </div>
      </div>
      <div>
        <div>
          <span className={classes.label}>Removed users:</span>
          {usersRemoved.length}
        </div>
        <div className={classes.listContainer}>
          {_.map(usersRemoved, (user) => (
            <div className={classes.listItem} key={`identifier-user-${user.user_id}`}>
              <Grid container alignItems={'center'}>
                <Avatar src={user.avatar_url} sizes={'small'} className={classes.listItemAvatar} />
                <span className={classes.listItemName}>{user.name}</span>
                <span className={classes.listItemIdentifier}>{user.identifier}</span>
              </Grid>
            </div>
          ))}
        </div>
      </div>
      <div>
        <ButtonOrange isLoading={isLoading} onClick={handleButtonPress}>
          Apply
        </ButtonOrange>
      </div>
    </ModalStyled>
  )
}

export default IdentifiersInfoModal
