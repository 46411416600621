import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment-timezone'
import React from 'react'
import openDesktopImg from '../assets/open_desktop.png'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 10000,
    flexDirection: 'column',
    padding: '80px 20px',
  },
  continue: {
    marginTop: 'auto',
    color: '#A3A0A0',
    textDecoration: 'underline',
  },
})

const MobileWarning = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    location: { search },
  } = history

  const handleContinue = (e) => {
    e.preventDefault()
    const urlParams = new URLSearchParams(search)
    const redirect = urlParams.get('redirect')
    localStorage.setItem('mobileWarning', moment().toString())
    history.replace(redirect || '/dashboard')
  }

  return (
    <div className={classes.container}>
      <img src={openDesktopImg} alt="Open Desktop" width={200} />
      <Typography fontSize={21} fontWeight="bold" mt={2} mb={2}>
        Admin console
      </Typography>
      <Typography fontSize={13} mb={1} textAlign="center">
        For the best experience, we recommend opening this URL on your desktop
      </Typography>
      <a href="https://hr.reaction-club.app/login">
        <Typography fontSize={13} color="#0803F3">
          hr.reaction-club.app/login
        </Typography>
      </a>

      <a href="#" onClick={handleContinue} className={classes.continue}>
        <Typography fontSize={13} color="#A3A0A0">
          Continue anyway
        </Typography>
      </a>
    </div>
  )
}

export default MobileWarning
