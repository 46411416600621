/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { FadeLoader } from 'react-spinners'

interface Props {
  scale?: number
  className?: string
  color?: string
  type?: 'absolute'
}

function Loader(props: Props) {
  const { scale = 0.5, className, color = '#333', type } = props

  return (
    <div
      css={css`
        position: relative;
      `}
      className={className}
      style={{ transform: `scale(${scale})` }}
    >
      <div
        css={[
          css`
            min-width: 10px;
            width: 30px;
            height: 50px;
            margin: auto;
          `,
          type === 'absolute' &&
            css`
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            `,
        ]}
      >
        <FadeLoader color={color} />
      </div>
    </div>
  )
}

export default Loader
