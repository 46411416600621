import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import api from 'src/libs/api/api'
import PaperContainer from 'src/components/PaperContainer'
import Tabs from './Tabs'
import OverviewTab from './overview/OverviewTab'
import SettingsTab from './settings/SettingsTab'
import BalanceTab from './balance/BalanceTab'

const RewardsPage = () => {
  const match = useRouteMatch()
  const [rewardsData, setRewardsData] = useState({ list: [] })
  const [countries, setCountries] = useState([])
  const [page, setPage] = useState(1)
  const [country, setCountry] = useState('')

  const getRewards = async () => {
    const data = await api.getRewardsList(page, country)
    setRewardsData(data)
  }

  const getCountries = async () => {
    const data  = await api.getRewardsCountries()
    setCountries(data)
  }

  useEffect(() => {
    getRewards()
  }, [page, country])

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <PaperContainer children={
      <>
        <Tabs/>
        <Switch>
          <Route path={match.path} exact component={() => (
            <OverviewTab
              data={rewardsData}
              setPage={setPage}
              countries={countries}
              country={country}
              setCountry={setCountry}
            />
          )}
          />
          <Route path={`${match.path}/balance`} component={BalanceTab} />
          <Route path={`${match.path}/settings`} component={SettingsTab} />
        </Switch>
      </>
    }/>
  )
}

export default RewardsPage