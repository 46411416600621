/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { AssetFull } from '@reaction-club-types/core'
import PropTypes from 'prop-types'
import React, { CSSProperties } from 'react'

export interface ImageAssetProps {
  asset: AssetFull
  className?: string
  resizeMode?: 'contain' | 'cover'
  style?: CSSProperties
}

function AssetImage(props: ImageAssetProps) {
  const { asset, className, resizeMode, style = {} } = props

  const { url, name } = asset

  const completeStyle: CSSProperties = { ...style }
  if (resizeMode) completeStyle.objectFit = resizeMode

  return (
    <img
      src={url}
      css={css`
        height: 200px;
        width: 100%;
        object-fit: contain;
        object-position: center;
        background-color: #fff;
      `}
      style={completeStyle}
      alt={name}
      className={className}
    />
  )
}

AssetImage.propTypes = {
  asset: PropTypes.object.isRequired,
} as any

export default AssetImage
