/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import PaperContainer from '../../components/PaperContainer'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import Group from './Group'
import GroupCoaches from './GroupCoaches'
import GroupCreate from './GroupCreate'
import ChooseCategory from './pages/ChooseCategory'
import Playbook from './pages/Playbook'
import SelectPlaybook from './pages/PlaybookSelect'

type Props = RouteComponentProps<{ instance_id; playbook_id }> & ExtractConnectType<typeof connectStore>

function GroupRouter(props: Props) {
  const { location, match } = props
  const { playbook_id, instance_id } = match.params || {}

  const isCategoryPage = location.pathname.includes('training-program/categories')
  return (
    <PaperContainer
      css={[
        isCategoryPage &&
          css`
            min-height: 93%;
            position: absolute;
          `,
      ]}
    >
      <Route path="/groups/create" component={GroupCreate} />
      <Route path="/groups/:instance_id/edit" component={GroupCreate} />
      <Route
        path={'/groups/:instance_id'}
        sensitive
        exact
        render={(props) => {
          if (instance_id === 'create') return null
          return <Group {...props} />
        }}
      />
      <Route exact path={'/groups/:instance_id/training-program/categories'} component={ChooseCategory} />
      <Route exact path={'/groups/:instance_id/training-program/playbook'} component={SelectPlaybook} />
      <Route exact path={'/groups/:instance_id/training-program/playbook/:playbook_id'} component={Playbook} />
      <Route exact path={'/groups/:instance_id/coaches'} component={GroupCoaches} />
    </PaperContainer>
  )
}

const connectStore = connect((state) => ({
  changeProgram: state.changeProgram,
}))

export default compose(connectStore, withRouter)(GroupRouter)
