import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { withStyles, WithStyles } from '@mui/styles'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import ModalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import React from 'react'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'

interface Props {
  employees: string[]
  instance_id: string
  handleEmployeeDeleted: (employees: string[]) => any
}

type ComponentProps = WithStyles<typeof styles> &
  Props &
  ExtractConnectType<typeof connectStore> &
  RouteComponentProps<{ instance_id: string }>

class EmployeeActions extends React.PureComponent<ComponentProps> {
  buttonRef

  state = {
    isMenuVisible: false,
  }

  render() {
    const { classes } = this.props
    const { isMenuVisible } = this.state

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
          disableFocusRipple
          style={{ padding: 0 }}
          ref={(ref) => (this.buttonRef = ref)}
          className={classes.container}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.buttonRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={isMenuVisible}
          onClose={this.handleCloseMenu}
          PaperProps={{
            style: {
              width: 200,
              backgroundColor: '#2D2D2D',
            },
          }}
        >
          <MenuItem onClick={this.handleChangeGroup}>
            <Typography variant="inherit" className={classes.menuItem}>
              Change group
            </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleKick}>
            <Typography variant="inherit" className={classes.menuItem}>
              Remove from company
            </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleResetPoints}>
            <Typography variant="inherit" className={classes.menuItem}>
              Reset points
            </Typography>
          </MenuItem>
        </Menu>
      </>
    )
  }

  handleChangeGroup = () => {
    const { showModal, employees } = this.props

    showModal({ modalType: MODAL_TYPES.CHANGE_EMPLOYEE_GROUP, modalProps: { employees } })

    this.handleCloseMenu()
  }

  handleKick = async () => {
    const { employees } = this.props
    this.handleCloseMenu()
    if (!window.confirm(`Are you sure you want to kick ${employees.length} employees?`)) return
    await this.props.kickEmployees(employees)
  }

  handleResetPoints = async () => {
    const { employees } = this.props
    this.handleCloseMenu()
    if (
      !window.confirm(
        `Are you sure you want to reset ALL THE POINTS for ${employees.length} users?\nThis operations is not reversible!`,
      )
    )
      return
    await this.props.resetPoints(employees)
  }

  handleOpenMenu = () => this.setState({ isMenuVisible: true })

  handleCloseMenu = () => this.setState({ isMenuVisible: false })
}

const connectStore = connect(null, (dispatch: ReduxDispatch) => ({
  showModal: (param) => dispatch(ModalActions.showModal(param)),
  kickEmployees: (employees: string[]) => dispatch(EmployeeDispatcher.kickEmployees(employees)),
  resetPoints: (employees: string[]) => dispatch(EmployeeDispatcher.resetPoints(employees)),
  getEmployees: () => dispatch(EmployeeDispatcher.getEmployees()),
}))

const styles = {
  container: {
    marginLeft: 15,
  },
  menuItem: {
    color: '#fff',
    fontSize: 14,
    margin: '5px 0',
    '&:hover': {
      color: '#F89A00',
    },
  },
  red: {
    color: 'red',
    '&:hover': {
      color: 'red',
    },
  },
}

export default compose(withStyles(styles), connectStore, withRouter)(EmployeeActions)
