/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxState } from '../../reducers'
import CompanyLogo from '../CompanyLogo'

interface Props {
  open: boolean
}

const SideBarCompany: React.FC<Props> = ({ open }) => {
  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))
  const { name, logo } = company

  return (
    <Grid
      container
      direction={'column'}
      css={css`
        padding: 0 15px 0 35px;
      `}
    >
      <Link to={'/settings'}>
        <CompanyLogo
          url={logo}
          css={[
            css`
              transition: 200ms;
              width: 68px;
              height: 68px;
              background-color: #fff;
              margin-bottom: 16px;
            `,
            !open &&
              css`
                width: 0px !important;
                height: 0px !important;
              `,
          ]}
        />
      </Link>
      {open && (
        <Typography
          css={css`
            color: #ffffff;
            font-weight: 600;
            line-height: 1;
          `}
        >
          {name}
        </Typography>
      )}
    </Grid>
  )
}

export default SideBarCompany
