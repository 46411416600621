/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Moment } from 'moment'
import React from 'react'
import EventFormInput from './elements/EventFormInput'

interface Props {
  value: Moment
  onChange(timeMoment: null | Moment): any
  error?: string
}

export default function EventTimeSelect(props: Props) {
  const { value, onChange, error } = props

  const handleChange = (date: null | Moment) => {
    onChange(date)
  }

  return (
    <div
      css={css`
        .container {
          margin-bottom: 0;
        }
      `}
    >
      <TimePicker
        label="Start Time"
        value={value}
        onChange={handleChange}
        renderInput={(params) => <EventFormInput {...params} error={!!error} />}
      />
      <Typography
        color={'error'}
        css={css`
          margin-bottom: 10px;
          font-size: 12px;
        `}
      >
        {error}
      </Typography>
    </div>
  )
}
