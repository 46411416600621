/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Divider, Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../components/Loader'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import SectionContainer from '../../components/SectionContainer'
import UserPenaltyItem from './components/UserPenaltyItem'

interface Props {}
function SettingsEventsUserPenalties(props: Props) {
  const dispatch = useDispatch<ReduxDispatch>()

  let { list } = useSelector((state: ReduxState) => ({
    list: state.lists.eventPenalties,
  }))

  const actionFetch = useAsyncFetch(async () => {
    await dispatch(EventDispatcher.getEventPenalties())
  })

  // list = list.concat(list)
  // list = list.concat(list)

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <SectionContainer>
        <Typography
          variant={'h2'}
          fontWeight={'bold'}
          css={css`
            font-size: 18px;
            margin-bottom: 20px;
          `}
        >
          Penalty list
        </Typography>

        <div>
          <RendererStatusSplit
            statuses={actionFetch}
            isEmpty={_.isEmpty(list)}
            renderError={(error) => <Typography color={'error'}>{error}</Typography>}
            renderLoading={() => <Loader />}
            renderEmpty={() => (
              <Typography textAlign={'center'} fontStyle={'italic'}>
                No items
              </Typography>
            )}
            render={() => (
              <div>
                {_.map(list, (item, index) => (
                  <React.Fragment>
                    {index ? (
                      <Divider
                        css={css`
                          margin: 10px 0;
                        `}
                      />
                    ) : null}
                    <UserPenaltyItem item={item} />
                  </React.Fragment>
                ))}
              </div>
            )}
          />
        </div>
      </SectionContainer>
    </div>
  )
}

export default SettingsEventsUserPenalties
