import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'
import { Employee } from '@reaction-club-types/core'

interface State {
  list: Employee[]
}

const initialState: State = {
  list: [],
}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_EMPLOYEES: {
      const { employees } = action.payload
      console.log('SET_EMPLOYEES', employees, action)
      return state.merge({ list: employees })
    }

    default:
      return state
  }
}
