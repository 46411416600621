/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ReactionEvent } from '@reaction-club-types/core'
import { APIEventUpdatePayload } from '@reducers/events/api'
import EventDispatcher from '@reducers/events/dispatcher'
import React from 'react'
import { useDispatch } from 'react-redux'
import ModalStyled from '../elements/ModalStyled'
import EventUpdateForm from '../pages/events/eventUpdate/EventUpdateForm'

interface Props {
  event: ReactionEvent
  isVisible: boolean
  onClose(): any
}

function EventUpdateModal(props: Props) {
  const { event, isVisible, onClose } = props
  const dispatch = useDispatch()
  if (!event) return null

  const handleSaveClick = async (payload: APIEventUpdatePayload) => {
    await dispatch<any>(EventDispatcher.updateEvent(event.id, payload))
    onClose()
  }

  return (
    <ModalStyled isVisible={isVisible} onClose={onClose} showCloseButton>
      {/* <Typography variant={'h1'}>Edit Event</Typography> */}
      <EventUpdateForm event={event} onUpdate={handleSaveClick} />
    </ModalStyled>
  )
}

export default EventUpdateModal
