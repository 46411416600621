/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, useMediaQuery, Theme } from '@mui/material'
import React, { ReactNode } from 'react'
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom'
import ChatMessageBroker from './ChatBroker'
import Notifications from './Notifications'
import SideBar from './SideBar'

// const connectStyles = withStyles(
//   (theme: Theme) => ({
//     version: {
//       color: '#000',
//       textAlign: 'right',
//       position: 'absolute',
//       right: 5,
//       bottom: 5,
//       lineHeight: 'inherit',
//       fontSize: 10,
//     },
//   }),
//   { withTheme: true },
// )

interface Props {
  children: ReactNode
}
interface PropsInner extends Props, RouteComponentProps {}

const AppContainer: React.FC<PropsInner> = ({ children }) => {
  const location = useLocation()
  const isDesktop: boolean = useMediaQuery(
    (t: Theme) => {
      const { breakpoints } = t
      if (!breakpoints) return '(max-width: 500)'
      return t.breakpoints.up('md')
    },
    { noSsr: true },
  )
  const [open, setOpen] = React.useState(isDesktop)

  const toggleOpen = () => {
    setOpen(!open)
  }

  if (location.pathname.includes('payment')) {
    return children
  }

  return (
    <div
      css={css`
        position: relative;
        flex-grow: 1;
        z-index: 1;
        background-color: #ededed;
        //overflow-y: scroll;
      `}
    >
      <Notifications />
      <ChatMessageBroker />
      <Grid>
        <SideBar open={open} toggleOpen={toggleOpen} />
        <div
          css={[
            css`
              margin-left: 59px;
              min-height: 100vh;
            `,
            open &&
              css`
                @media (min-width: 700px) {
                  margin-left: 230px;
                }
              `,
          ]}
        >
          {children}
        </div>
      </Grid>
      {/* <Typography className={classes.version}>v.{packageJson.version}</Typography> */}
    </div>
  )
}

export default withRouter(AppContainer)
