import invariant from 'invariant'
import _ from 'lodash'
import { Immutable } from 'seamless-immutable'

interface Item {}

interface StateItem extends Item {
  storeUpdatedAt: StoreUpdatedAt
}

function setItemOrArray<TState extends Immutable<{ [key: string]: Immutable<StateItem> | StateItem }>>(
  state: TState,
  itemOrArr: Item | Item[],
  params?: {
    idField?: string
  },
): TState {
  // const itemOrArr = action.payload
  const arr = _.isArray(itemOrArr) ? itemOrArr : [itemOrArr]
  const { idField = 'id' } = params || {}

  _.forEach(arr, item => {
    const item_id = item[idField]
    invariant(item_id, 'setItemOrArray: item id is invalid')

    let stateItem = state[item_id]
    if (stateItem) {
      stateItem = stateItem.merge({
        ...item,
        storeUpdatedAt: Date.now(),
      })

      state = state.merge({ [item_id]: stateItem }) as TState
      return null
    }

    state = state.merge({
      [item_id]: {
        ...item,
        storeUpdatedAt: Date.now(),
      },
    }) as TState
  })

  return state
}

export { setItemOrArray }

export default { setItemOrArray }
