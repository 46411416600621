import { Avatar, Grid, Typography, Box } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import arrowDownIcon from '../assets/arrow_right.svg'
import bin from '../assets/bin.svg'
import Row from '../components/Row'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const statusColors = {
  pending: '#2A8BF2',
  accepted: '#17A700',
  canceled: '#E91717',
}

const InfoLine = ({ classes, data, sizes, playbookInstances, cancelOffer, chat }) => {
  const { id, playbook_instance_id, created_at, status, matchedCoaches, accepted_coach_id, pending_count } = data
  const statusColor = statusColors[status.toLowerCase().trim()]

  let to = ''
  if (matchedCoaches?.length) {
    if (status === 'pending' || status === 'canceled') {
      to = `/coaches/work-offer/${data.id}`
    }
    if (status === 'accepted') {
      to = `/coaches/accepted-coach/${matchedCoaches[0].user_id}/${id}`
    }
  }

  const style = {
    fontSize: 14,
    fontWeight: pending_count > 0 ? 'bold' : 'none',
    color:
      pending_count > 0 ||
      matchedCoaches?.find(c => Object.values(chat?.rooms || {}).find(r => r.userId === c.chat_user_id)?.unread > 0)
        ? '#323232'
        : '#979797',
  }

  const handleCancelOffer = (e, id) => {
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()
    cancelOffer(id)
  }

  return (
    <Row
      to={to}
      sizes={sizes}
      className={classes.infoLine}
      data={[
        <Typography style={style}>{playbookInstances[playbook_instance_id]?.name || 'any'} group</Typography>,
        <Typography style={style}>{moment(created_at).fromNow()}</Typography>,
        <Typography style={{ ...style, color: statusColor }}>{capitalize(status)}</Typography>,
        <Grid container>
          {status === 'accepted' && accepted_coach_id ? (
            <Avatar
              src={matchedCoaches?.find(({ user_id }) => user_id === accepted_coach_id).avatar_url}
              height={30}
              style={{ marginRight: -12 }}
            />
          ) : (
            matchedCoaches
              ?.slice(0, 6)
              .map(({ avatar_url }) => <Avatar src={avatar_url} height={30} style={{ marginRight: -12 }} />)
          )}
          <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <img src={bin} alt="" className={classes.bin} onClick={e => handleCancelOffer(e, id)} />
            <img src={arrowDownIcon} alt="" />
          </Box>
        </Grid>,
      ]}
    />
  )
}

const mapStatToProps = state => ({
  chat: state.chat,
  playbookInstances: state.playbookInstances,
})
const mapDispatchToProps = dispatch => ({
  cancelOffer: id => dispatch(coachesDispatcher.cancelOffer(id)),
})
const connectStore = connect(mapStatToProps, mapDispatchToProps)

const styles = {
  infoLine: {
    cursor: 'pointer',
    minHeight: 66,
    border: '1px solid #D4D4D4',
    '&:hover': {
      borderColor: '#000000',
    },
    '&:hover $bin': {
      display: 'inherit',
    },
  },
  bin: {
    display: 'none',
    marginRight: 16,
    padding: 8,
  },
}

export default connectStore(withStyles(styles)(InfoLine))
