import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import apiCompany from '@reducers/company/api'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import CompanyLogo from '../../../components/CompanyLogo'
import ReactionLoader from '../../../components/ReactionLoader'
import ModalStyled from '../../../elements/ModalStyled'
import LinkModalButtons from './LinkModalButtons'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ReduxState } from '@reducers/index'
import AuthActionDispatcher from '@reducers/auth/dispatcher'

const useStyles = makeStyles(
  {
    container: {
      marginTop: 10,
      minWidth: 200,
    },
    warning: {
      fontSize: 28,
      textAlign: 'center',
      marginBottom: 32,
    },
  },
  {
    classNamePrefix: 'LinkModal',
  },
)

interface Props {
  invitation_id: string
}

const LinkModal: React.FC<Props> = (props: Props) => {
  const { invitation_id } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch<ReduxDispatch>()
  const { me, company } = useSelector((state: ReduxState) => ({ me: state.me, company: state.company }))
  const invitationGetAction = useAsyncFetch(() => apiCompany.getInvitation(invitation_id))

  const handleClose = async () => {
    if (me.company_id) {
      history.push('/')
    } else {
      await apiCompany.denyInvitation()
      dispatch(AuthActionDispatcher.logout())
    }
  }

  return (
    <ModalStyled isVisible onClose={handleClose}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        <RendererStatusSplit
          statuses={invitationGetAction}
          renderPreview={() => null}
          renderLoading={() => (
            <Grid item>
              <ReactionLoader />
            </Grid>
          )}
          renderError={(error: string) => (
            <Grid container direction={'column'} alignItems={'center'}>
              <Typography color={'error'}>{error}</Typography>
              <Box sx={{ marginTop: '10px' }}>
                <Button onClick={handleClose} variant={'contained'} color={'primary'} size={'small'}>
                  Close
                </Button>
              </Box>
            </Grid>
          )}
          renderEmpty={() => (
            <Grid container direction={'column'} alignItems={'center'}>
              <Grid item className={classes.warning}>
                <Typography>Invalid link</Typography>
              </Grid>
              <Box sx={{ marginTop: '10px' }}>
                <Button onClick={handleClose} variant={'contained'} color={'primary'} size={'small'}>
                  Close
                </Button>
              </Box>
            </Grid>
          )}
          render={(invite) => {
            const { name: company_name, logo: company_logo } = invite?.company || {}

            return (
              <>
                <Grid item className={classes.warning}>
                  <CompanyLogo url={company_logo} style={{ height: 80, width: 80, margin: '0 auto 16px' }} />
                  <Typography>Do you want to accept invitation to {company_name}?</Typography>
                </Grid>
                <Grid item>
                  <LinkModalButtons invite={invite} onClose={handleClose} />
                </Grid>
              </>
            )
          }}
        />
      </Grid>
    </ModalStyled>
  )
}

export default LinkModal
