import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles({
  container: {
    padding: '15px 0 30px',
    marginBottom: '15px',
    borderBottom: '2px solid #E0E4EC',
    position: 'relative',
  },
})

interface Props {
  children: ReactNode
}

function SectionContainer(props: Props) {
  const { children } = props
  const classes = useStyles()

  return <div className={classes.container}>{children}</div>
}

export default SectionContainer
