import Immutable from 'seamless-immutable'
import types from './types'

export default function reducer(state = Immutable({}), action: ReduxAction) {
  switch (action.type) {
    case types.ADD_SUCCESS: {
      action.payload['type'] = 'success'
      return state.merge(action.payload)
    }
    case types.ADD_WARNING: {
      action.payload['type'] = 'warning'
      return state.merge(action.payload)
    }
    case types.ADD_ERROR: {
      action.payload['type'] = 'error'
      return state.merge(action.payload)
    }
    case types.MAKE_EMPTY: {
      action.payload['type'] = ''
      return state.merge(action.payload)
    }
    default:
      return state
  }
}
