import { FormControl, FormHelperText, Grid, Paper, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import apiAuth from '@reducers/auth/apiAuth'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { validate } from 'validate.js'
import Loader from '../../../components/Loader'
import { OpenSans } from '../../../helpers/commonStyles'
import { ExtractStyles } from '../../../typings/stylesExtractor'

const connectStyles = withStyles({
  container: {
    padding: '70px 50px',
    margin: '30px',
    fontFamily: OpenSans,
  },
  absoluteContainer: {
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80%',
    width: 720,
    maxWidth: '80%',
    minWidth: 290,
    height: 450,
  },
  buttonText: {
    color: '#1A1A1A',
    fontSize: 12,
  },
  submitButton: {
    width: 100,
    marginTop: 24,
  },
  emailTextField: {
    maxWidth: 400,
  },
  loaderContainer: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 20,
  },
})

interface Props extends RouteComponentProps {}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class ResetPasswordRequest extends React.PureComponent<PropsInner> {
  state: {
    isLoading: boolean
    email: string
    formError: boolean
    emailFieldTouched: boolean
    completed: boolean
  } = {
    email: '',
    isLoading: false,
    formError: false,
    emailFieldTouched: false,
    completed: false,
  }

  render() {
    const { classes } = this.props
    const { isLoading, formError, email, emailFieldTouched, completed } = this.state

    return (
      <div className={classes.absoluteContainer}>
        <Paper>
          <Grid container direction={'row'} className={classes.container}>
            <Grid container direction={'column'} justifyContent={'center'}>
              <h1>Enter your email address</h1>
              <p>We will send the instructions how to reset your password.</p>
              <TextField
                id="login"
                label="Email"
                type="email"
                placeholder="example@your-company.com"
                autoFocus
                value={email}
                error={!!formError && emailFieldTouched}
                onBlur={this.setEmailFieldTouched}
                disabled={isLoading}
                onChange={this.onEmailChange}
                className={classes.emailTextField}
              />

              <FormControl>
                {isLoading && (
                  <Grid container className={classes.loaderContainer}>
                    <Loader />
                  </Grid>
                )}
                {!completed && !isLoading && (
                  <button
                    type={'button'}
                    onClick={this.handleSubmit}
                    disabled={isLoading || formError || !email}
                    className={classes.submitButton}
                  >
                    <Grid container direction={'row'} alignItems={'center'}>
                      <span className={classes.buttonText}>Submit</span>
                    </Grid>
                  </button>
                )}
                {!!formError && emailFieldTouched && <FormHelperText>Fix the errors please</FormHelperText>}
              </FormControl>

              {completed && (
                <p>
                  The instructions ware sent to the email address you provided.
                  <br />
                  <Link to={'/login'}>Return to login.</Link>
                </p>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }

  handleSubmit = () => {
    if (!this.state.email) {
      return
    }
    this.setState({
      isLoading: true,
    })
    apiAuth.resetPasswordRequest(this.state.email).finally(() => {
      this.setState({
        isLoading: false,
        completed: true,
      })
    })
  }

  formConstraints = {
    email: {
      email: true,
    },
  }

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const email = event.target.value
    const formError = !!validate({ email }, this.formConstraints)
    this.setState({
      formError,
      email,
    })
  }

  setEmailFieldTouched = () => {
    this.setState({
      emailFieldTouched: true,
    })
  }
}

export default compose<PropsInner, Props>(connectStyles)(ResetPasswordRequest)
