import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import { ExtractStyles } from '../../typings/stylesExtractor'
import ReportParticipantList from './report/ReportParticipantList'
import ReportRecordings from './report/ReportRecordings'

const connectStyles = withStyles({
  caption: {
    marginBottom: 10,
  },
  subText: {
    fontSize: 14,
  },
  bold: {
    fontWeight: 800,
  },
  container: {
    marginTop: 50,
  },
})

interface Props extends RouteComponentProps<{ event_id: string }> {}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class ReportPage extends React.PureComponent<PropsInner> {
  state = {
    isLoading: false,
  }

  render() {
    const { event, classes } = this.props
    const { isLoading } = this.state

    if (isLoading || _.isEmpty(event))
      return (
        <PaperContainer>
          <ReactionLoader />
        </PaperContainer>
      )

    const { participant_count = 0, participant_attended_count = 0, participant_unknown_count = 0, time } = event
    const date = moment(time.valueOf()).format('DD/MM/YY')

    return (
      <PaperContainer>
        <Typography variant={'h1'} className={classes.caption}>
          Event Report
        </Typography>
        <Grid container justifyContent={'space-between'}>
          <Typography className={classes.subText}>{`${date} - ${event.name}`}</Typography>
          <Typography className={classes.subText}>
            <span className={classes.bold}>{participant_attended_count}</span> Attended
            {participant_unknown_count ? (
              <span>
                <b> + {participant_unknown_count}</b> unknown arrived
              </span>
            ) : (
              ''
            )}
            <span> / </span>
            <span className={classes.bold}>{participant_count}</span> Registered
          </Typography>
        </Grid>

        <ReportRecordings event={event} />

        <ReportParticipantList event={event} />
      </PaperContainer>
    )
  }

  fetchData = async () => {
    this.setState({ isLoading: true })
    try {
      await this.props.getEvent()
      await this.props.getParticipants()
      await this.props.getRecordings()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

export const REPORT_PAGE_URL = (event_id: string) => `/report/${event_id}`

const connectStore = connect(
  (state: ReduxState, { match }: Props) => ({
    event: state.events[match.params.event_id],
  }),
  (dispatch: ReduxDispatch, { match }: Props) => ({
    getEvent: () => dispatch(EventDispatcher.getById(match.params.event_id)),
    getParticipants: () => dispatch(EventDispatcher.getParticipants(match.params.event_id)),
    getRecordings: () => dispatch(EventDispatcher.getRecordings(match.params.event_id)),
  }),
)

export default compose<PropsInner, Props>(connectStore, connectStyles)(ReportPage)
