import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import React from 'react'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import ButtonOrange from '../../../components/ButtonOrange'
import Asset from '../../../components/assets/Asset'
import { OpenSans } from '../../../helpers/commonStyles'

const styles = {
  wrapper: {
    marginBottom: 35,
  },
  container: {
    width: 260,
    height: 157,
    borderRadius: 5,
    cursor: 'pointer',
    backgroundColor: '#F3F3F3',
  },

  image: {
    width: 100,
    height: 100,
    borderRadius: 5,
  },

  text: {
    marginTop: 15,
    fontSize: 18,
    fontWeight: 600,
    fontFamily: OpenSans,
  },
}

interface Props {
  title: string
  id: string
  description: string
  icon: string
  comingSoon?: boolean
}

function ChallengeItem(props: Props & WithStyles<typeof styles> & RouteComponentProps) {
  const history = useHistory()
  const { classes, id, title, description, icon, comingSoon } = props

  const handlePress = () => {
    history.push(`/challenge/${id}/enable`)
  }

  return (
    <Grid className={classes.wrapper} item xs={12}>
      <Grid container>
        <Grid item flexBasis={135}>
          <Asset resizeMode={'cover'} className={classes.image} asset={{ url: icon }} />
        </Grid>
        <Grid item xs={8} paddingTop={2}>
          <Typography variant="h1" mb={2}>
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid item marginLeft="auto" alignItems="center" display="flex">
          <ButtonOrange color={comingSoon ? '#DADADA' : undefined} onClick={handlePress}>
            <Typography textTransform="uppercase" fontWeight="600">
              {comingSoon ? 'Coming Soon' : 'More Info'}
            </Typography>
          </ButtonOrange>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default compose(withRouter, withStyles(styles))(ChallengeItem as any) as any
