import { ReduxState } from '@reducers/index'
import { createBrowserHistory } from 'history'
import mixpanel from 'mixpanel-browser'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import ReNavigator from './ReNavigator'
import AppContainer from './components/AppContainer'
import AuthGuard from './components/AuthGuard'
import SessionGuard from './components/SessionGuard'
import AcceptWorkOffer from './pages/AcceptWorkOffer'
import AcceptedCoachProfile from './pages/AcceptedCoachProfile'
import Chats from './pages/Chats'
import InviteCoaches from './pages/InviteCoaches'
import MobileWarning from './pages/MobileWarning'
import Playbooks from './pages/Playbooks'
import WorkOffer from './pages/WorkOffer'
import Auth from './pages/auth/Auth'
import LoginPage from './pages/auth/LoginPage'
import Signup from './pages/auth/SignupPage'
import DirectChat from './pages/chats/DirectChat'
import DirectCoachChat from './pages/chats/DirectCoachChat'
import GroupChat from './pages/chats/GroupChat'
import ChooseChampions from './pages/coaches/ChooseChampions'
import Coaches from './pages/coaches/Coaches'
import CompanyCreatePage from './pages/companies/CompanyCreatePage'
import CustomizeChallenge from './pages/customize-challenge/CustomizeChallenge'
import EditChallenge from './pages/customize-challenge/EditChallenge'
import Dashboard from './pages/dashboard/Dashboard'
import DashboardEnable from './pages/dashboard/DashboardEnable'
import Employees from './pages/employees/Employees'
import EventCreate from './pages/events/EventCreate'
import EventPage from './pages/events/EventPage'
import EventsPage from './pages/events/Events'
import GroupRouter from './pages/groups/GroupRouter'
import Groups from './pages/groups/Groups'
import LinkPage from './pages/invitations/Link'
import LeaderboardPage from './pages/leaderboard/LeaderboardPage'
import OfficePage from './pages/offices/OfficePage'
import OnboardContinue from './pages/onboard/OnboardContinue'
import PaymentPage from './pages/payment/PaymentPage'
import ReportPage from './pages/reports/ReportPage'
import Reports from './pages/reports/Reports'
import ResetPassword from './pages/reset-password/ResetPassword'
import Settings from './pages/settings/Settings'
import StockCreate from './pages/stocks/StockCreate'
import StockOrders from './pages/stocks/StockOrders'
import Stocks from './pages/stocks/Stocks'
import TeamPage from './pages/teams/TeamPage'
import MembersAndTeams from './pages/employees/MembersAndTeam'
import RewardsPage from './pages/rewards/RewardsPage'

export const history = createBrowserHistory()

const isMobileCheck = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

function AppRouter() {
  const { company, me } = useSelector((state: ReduxState) => ({
    company: state.company,
    me: state.me,
  }))
  const location = useLocation()
  const isAdmin = me?.role === 'admin'
  const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'
  const isMobile = isMobileCheck()
  const lastShown = localStorage.getItem('mobileWarning')

  useEffect(() => {
    if (company && company.id) {
      console.log('company', company.id)
      console.log('mixpanel distinct id before', mixpanel.get_distinct_id())
      const fn = async () => {
        mixpanel.track('test-hr-before-company-identify')
        mixpanel.identify(company.id)
        setTimeout(() => {
          console.log('mixpanel distinct id after', mixpanel.get_distinct_id())
          mixpanel.track('test-hr-after-company-identify')
        }, 2000)
      }
      fn()
    }
  }, [company])

  useEffect(() => {
    const isExpired = !lastShown || moment().diff(moment(lastShown), 'day') >= 1

    if (isMobile && isExpired && location.pathname !== '/payment') {
      history.replace('/mobile-warning' + location.search)
    }
  }, [isMobile, lastShown])

  return (
    <Switch>
      <Route path="/login" component={LoginPage} exact />
      <Route path="/email-login" component={Auth} />
      <Route path="/signup" component={Signup} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/onboard" component={OnboardContinue} />
      <Route path="/mobile-warning" component={MobileWarning} />

      <AuthGuard>
        <SessionGuard>
          <AppContainer>
            <ReNavigator />
            <Switch>
              <Route path="/groups/:instance_id" component={GroupRouter} />
              <Route path={['/', '/dashboard', '/groups']} exact component={Groups} />

              <Route path="/settings" component={Settings} />

              <Route path="/members" component={MembersAndTeams} />

              <Route path="/reports" component={Reports} />
              <Route path="/report/:event_id" component={ReportPage} />

              <Route path={'/challenge/:id/enable'} render={(props) => <DashboardEnable {...props} />} />
              <Route path={'/challenge/:id'} component={Dashboard} />

              <Route path="/link/:id" component={LinkPage} />
              <Route path="/company/create" component={CompanyCreatePage} />
              <Route path={'/office/:id'} component={OfficePage} />
              <Route path={'/team/:id'} component={TeamPage} />

              <Route path="/events" component={EventsPage} />
              <Route path="/playbooks" component={Playbooks} />
              <Route path="/stocks" component={Stocks} />

              <Route path="/customize-challenge/:id" exact component={CustomizeChallenge} />
              <Route path="/customize-challenge/edit/:id" component={EditChallenge} />

              <Route path="/payment" component={PaymentPage} />

              <Route path="/rewards-secret" component={RewardsPage} />

              {!isFreeTrial && (
                <>
                  <Route path="/event/:id" component={EventPage} />
                  <Route path="/event-create" component={EventCreate} />
                  <Route path="/stock/orders" component={StockOrders} />
                  <Route path="/stock-create" component={StockCreate} />
                  <Route path={'/leaderboard'} component={LeaderboardPage} />
                </>
              )}

              {isAdmin && (
                <>
                  <Route path="/chats/direct/:id" component={DirectChat} />
                  <Route path="/chats/group/:id" component={GroupChat} />
                  <Route path="/chats/:type" component={Chats} />
                  <Redirect from="/chats" to="/chats/coaches" />

                  <Route path="/coaches/invite/:groupId?" component={InviteCoaches} />
                  <Route path="/coaches/accepted-coach/:id/:offerId?" component={AcceptedCoachProfile} />
                  <Route path="/coaches/work-offer/:offerId/chat/:id" component={DirectCoachChat} />
                  <Route path="/coaches/work-offer/:offerId/accept/:id" component={AcceptWorkOffer} />
                  <Route path="/coaches/work-offer/:offerId" component={WorkOffer} />
                  <Route path="/coaches/champions/choose/:groupId" component={ChooseChampions} />
                  <Route path="/coaches" component={Coaches} />
                </>
              )}

              <Redirect to="/dashboard" />
            </Switch>
          </AppContainer>
        </SessionGuard>
      </AuthGuard>

      <Redirect to="/" />
    </Switch>
  )
}

export default AppRouter
