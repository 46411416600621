import { CompanyOffice } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import { setItemOrArray } from '../../libs/reducerUtils'
import types from './types'

interface State {
  [key: string]: ReduxStateOfficeItem
}

const initialState: State = {
  // [id]: { ... }
}

interface ReduxStateOfficeItem extends CompanyOffice, StoreUpdatedObject {}

export default function companyReducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const item: CompanyOffice = action.payload
      return setItemOrArray(state, item)
    }

    case types.DELETE: {
      const office_id = action.payload
      return state.without(office_id)
    }

    default:
      return state
  }
}
