import ChatQueueListener from '@reaction-club/rocketchat-react-message-broker'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import apiAssets from '../libs/api/apiAssets'

const ChatMessageBroker = ({ chatToken, chatUser }) => {
  if (!chatToken || !chatUser) {
    return null
  }

  const handleUpload = async (asset: File) => {
    const { sources }: AssetFull = await apiAssets.upload(asset)

    if (!asset) return null

    const fileSmall = sources.find(({ size }) => size === 'small')
    const fileMedium = sources.find(({ size }) => size === 'medium')
    const fileLarge = sources.find(({ size }) => size === 'large')

    if (!fileMedium || !fileLarge) return null

    const { url, width, height, asset_id } = fileLarge || fileMedium
    const chatAttachment = {
      thumb_url: fileSmall ? fileSmall.url : '',
      image_url: url,
      fields: [
        { title: 'width', value: `${width}`, short: true },
        { title: 'height', value: `${height}`, short: true },
        { title: 'asset_id', value: asset_id, short: true },
      ],
    }

    return chatAttachment
  }

  return <ChatQueueListener onUpload={handleUpload} />
}

const connectStore = connect((state: ReduxState) => ({
  chatToken: state.auth.chatToken,
  chatUser: state.auth.chatUser,
}))
export default connectStore(ChatMessageBroker)
