import { Typography } from '@mui/material'
import { withStyles, WithStyles } from '@mui/styles'
import { GoogleApiWrapper } from 'google-maps-react'
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { compose } from 'redux'
import config from '../../../config'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import locationMarkIcon from '../assets/location-mark.svg'

const connectStyles = withStyles({
  wrapper: {
    marginBottom: 36,
  },
  container: {
    position: 'relative',
  },
  inputContainer: {
    position: 'relative',
  },
  locationMark: {
    position: 'absolute',
    top: 18,
    left: 22,
  },
  searchInput: {
    color: '#323232',
    border: '1px solid #ccc',
    outline: 'none',
    padding: '22px 22px 22px 80px',
    borderRadius: '8px',
    width: '100%',
    fontSize: '14px',
    '&:hover': {
      borderColor: '#000',
    },
    '&::placeholder': {
      color: '#979797',
    },
  },
  autocompleteContainer: {
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'absolute',
    background: 'white',
    zIndex: 10,
    padding: '16px',
    left: 0,
    right: 0,
    borderRadius: '8px',
  },
  suggestionItem: {
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  suggestionItemActive: {
    borderRadius: '8px',
    backgroundColor: '#e2e2e2',
  },
  optional: {
    fontSize: '13px',
    color: '#979797',
    marginTop: 13,
  },
  error: {
    // color: 'red',
    borderColor: 'red',
  },
})

interface State {
  latitude: number | null
  longitude: number | null
  errorMessage: string
  isGeocoding: boolean
}

interface Props {
  location
  coordinates
  setLocation
  setCoordinates
  isOptional
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class ReactionPlacesAutocomplete extends React.PureComponent<PropsInner, State> {
  state = {
    inputAddress: '',
    errorMessage: '',
    latitude: null,
    longitude: null,
    isGeocoding: false,
  }

  render() {
    const { inputAddress, errorMessage } = this.state
    const { classes, isOptional = false, error } = this.props

    const err = errorMessage || error

    return (
      <div className={classes.wrapper}>
        {/* {err && <Typography className={classes.error}>{err}</Typography>} */}
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={inputAddress}
          onSelect={this.handleSelect}
          onError={this.handleError}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className={classes.container}>
                <div className={classes.inputContainer}>
                  <input
                    {...getInputProps({
                      placeholder: 'Location',
                      className: `${classes.searchInput} ${err ? classes.error : ''}`,
                    })}
                  />
                  <img src={locationMarkIcon} className={classes.locationMark} alt="" />
                </div>
                {suggestions.length > 0 && (
                  <div className={classes.autocompleteContainer}>
                    {suggestions.map(suggestion => {
                      const className = [
                        classes.suggestionItem,
                        suggestion.active ? classes.suggestionItemActive : '',
                      ].join(' ')

                      return (
                        <div {...getSuggestionItemProps(suggestion, { className })}>
                          <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                          <small>{suggestion.formattedSuggestion.secondaryText}</small>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          }}
        </PlacesAutocomplete>
        {isOptional && <Typography className={classes.optional}> * Optional </Typography>}
      </div>
    )
  }

  handleChange = address => {
    this.setState({
      inputAddress: address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    })
  }

  handleSelect = selected => {
    const { setLocation, setCoordinates } = this.props
    this.setState({ isGeocoding: true })
    geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        setLocation(selected)
        setCoordinates({ lat, lng })
        this.setState({
          inputAddress: selected,
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        })
      })
      .catch(error => {
        console.log('error', error)
        this.setState({ isGeocoding: false })
      })
  }

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status)
    this.setState({ errorMessage: status }, () => {
      clearSuggestions()
    })
  }
}

export default compose(
  GoogleApiWrapper({
    apiKey: config.google_api_key,
  }),
  connectStyles,
)(ReactionPlacesAutocomplete)
