import types from './types'

export default {
  setGroup: (groupId: string) => ({ type: types.SET_OFFER_GROUP, payload: groupId }),
  setIsNew: (isNew = false) => ({ type: types.SET_OFFER_IS_NEW, payload: isNew }),
  setOfferCategory: (category: string) => ({ type: types.SET_OFFER_CATEGORY, payload: category }),
  setOfferSubcategory: (subcategory: string[]) => ({ type: types.SET_OFFER_SUBCATEGORY, payload: subcategory }),
  setOfferServiceType: (serviceType: string) => ({ type: types.SET_OFFER_SERVICE_TYPE, payload: serviceType }),
  setOfferLocation: (location: string) => ({ type: types.SET_OFFER_LOCATION, payload: location }),
  setOfferLanguage: (language: string) => ({ type: types.SET_OFFER_LANGUAGE, payload: language }),
  setOfferCoordinates: (coordinates: string) => ({ type: types.SET_OFFER_COORDINATES, payload: coordinates }),
  setOfferDescription: (description: string) => ({ type: types.SET_OFFER_DESCRIPTION, payload: description }),
  setOfferCurrency: (currency: string) => ({ type: types.SET_OFFER_CURRENCY, payload: currency }),
  setOfferPeriod: (period: string) => ({ type: types.SET_OFFER_PERIOD, payload: period }),
  setOfferStartDate: (startDate: string) => ({ type: types.SET_OFFER_START_DATE, payload: startDate }),
  setOfferBudget: (budget: number) => ({ type: types.SET_OFFER_BUDGET, payload: budget }),
  resetOffer: () => ({ type: types.RESET_OFFER }),
  setOffers: (offers: number) => ({ type: types.SET_OFFERS, payload: offers }),
  setOfferMatchedCoaches: (id, matchedCoaches) => ({
    type: types.SET_OFFER_MATCHED_COACHES,
    payload: { id, matchedCoaches },
  }),
  setFullCoach: coach => ({ type: types.SET_FULL_COACH, payload: coach }),
}
