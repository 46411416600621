import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import fileExplorer from 'web-file-explorer'
import apiAssets from '../../../libs/api/apiAssets'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import PlusButton from '../../../components/buttons/PlusButton'
import SectionContainer from '../components/SectionContainer'
import Help from '../../../components/Help'

const connectStyles = withStyles({
  stickerContainer: {
    minHeight: 250,
  },
  stickerCaption: {
    fontSize: 18,
    fontWeight: 800,
  },
  subText: {
    fontSize: 10,
    fontWeight: 200,
  },
  stickersListContainer: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  stickerListContainer: {
    width: '100%',
  },
  sticker: {
    maxWidth: 100,
    maxHeight: 100,
  },
})

interface Props {}
interface PropsInner extends ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class SettingsStickers extends React.PureComponent<PropsInner> {
  render() {
    const { classes, company } = this.props
    const isFreeTrial = company?.subscriptionPlan?.type === 'FREE_TRIAL'

    return (
      <SectionContainer>
        <Grid container direction={'column'} justifyContent={'space-between'} className={classes.stickerContainer}>
          <Grid item>
            <Typography className={classes.stickerCaption}>
              Camera Stickers{' '}
              <Help
                title="Add camera stickers"
                subtitle="Boosting brand awareness with stickers"
                text="The Reaction Club app promotes sharing progress and activities among members to inspire and motivate others. You can enhance this feature by adding stickers, such as your company logo, that members can include in their pictures. By doing so, you can increase your members' connection to your brand and reinforce brand awareness."
              />
            </Typography>
            {isFreeTrial ? (
              <Typography mb={4}>Only for premium accounts.</Typography>
            ) : (
              <>
                <Typography className={classes.subText}>PNG files with transparent background</Typography>
                <Grid className={classes.stickersListContainer}>{this.renderStickers()}</Grid>
              </>
            )}
          </Grid>
          <Grid item>
            <PlusButton onClick={this.handleAddSticker} title="Add sticker" lock={isFreeTrial} />
          </Grid>
        </Grid>
      </SectionContainer>
    )
  }

  renderStickers = () => {
    const { classes, company } = this.props
    const { stickers } = company || {}

    if (_.isEmpty(stickers)) {
      return
    }

    return stickers.map((sticker: CompanySticker) => (
      <Grid className={classes.stickerListContainer} style={{ textAlign: 'center', margin: '10px 0' }}>
        <img src={sticker.url} className={classes.sticker} alt="" />
      </Grid>
    ))
  }

  handleAddSticker = async () => {
    let file = await fileExplorer.getFile()

    try {
      const asset: AssetFull = await apiAssets.upload(file)
      this.props.updateSticker(asset.id)
    } catch (e) {
      alert('upload sticker failed')
    }
  }

  fetchData = async () => {
    this.props.getCompanyStickers()
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    me: state.me,
    company: state.company,
  }),
  (dispatch: ReduxDispatch) => ({
    updateSticker: (sticker_id: string) => dispatch(CompanyDispatcher.updateSticker(sticker_id)),
    getCompanyStickers: () => dispatch(CompanyDispatcher.getCompanyStickers()),
  }),
)

export default compose<PropsInner, Props>(connectStyles, connectStore)(SettingsStickers)
