import React from 'react'
import styled from '@emotion/styled'
import { FormControl, InputLabel, MenuItem, Pagination, PaginationItem, Select } from '@mui/material'
import RewardCard from './RewardCard'
import CountryTags from './CountryTags'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const RewardContainer = styled.div`
  display: grid;
  grid-gap: 28px;
  grid-template-columns: auto auto auto auto auto;
  max-width: 900px;
`

const PaginationContainer = styled.div`
  margin-top: 20px;
`

const SelectBox = styled.div`
  display: flex;
  max-width: 350px;
  width: 100%;
  margin: 20px 0;
`

const OverviewTab = ({ data, setPage, countries, country, setCountry }) => {

  return (
    <Container>
      <span>The following rewards are available to your members on their app. Learn how members earn points here.</span>
      <SelectBox>
        <FormControl fullWidth>
          <InputLabel id="reward-country">Country</InputLabel>
          <Select
            labelId="reward-country"
            id="reward-country"
            label="Country"
            value={country}
            displayEmpty
            onChange={event => setCountry(event.target.value)}
          >
            {countries.map(item => (
              <MenuItem value={item.code2} key={item.code2}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectBox>
      {/* <CountryTags /> */}
      <RewardContainer>
        {data.list.map((reward) => <RewardCard key={reward.code} reward={reward} />)}
      </RewardContainer>
      <PaginationContainer>
        <Pagination
          count={data.pageCount}
          page={data.page}
          onChange={(event, value) => setPage(value)}
        />
      </PaginationContainer>
    </Container>
  )
}

export default OverviewTab