import apiClient from '../../libs/api/libs/apiClient'

const apiGroup = {
  async getList() {
    const { data } = await apiClient.get('/group/list')
    return data
  },
  // async createPlaybookInstance(payload: {
  //   name: string
  //   description: string
  //   asset_id?: string
  // }): Promise<{ playbook_instance_id: string }> {
  //   const result = await apiClient.post(`/playbook-instance/create`, payload)
  //   return result.data
  // },
  // async editPlaybookInstance(
  //   instance_id: string,
  //   payload: {
  //     name: string
  //     description: string
  //     asset_id?: string
  //   },
  // ): Promise<{ playbook_instance_id: string }> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}`, payload)
  //   return result.data
  // },
  // async changeCoachRole({
  //   instance_id,
  //   coach_id,
  //   role,
  // }: {
  //   instance_id: string
  //   coach_id: string
  //   role: string
  // }): Promise<PlaybookInstance> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/coach/${coach_id}/role`, { role })
  //   return result.data
  // },
  // async getPlaybookInstance(instance_id: string): Promise<PlaybookInstance | null> {
  //   const result = await apiClient.get(`/playbook-instance/${instance_id}`)
  //   return result.data
  // },

  // async getInstanceCoaches(instance_id: string): Promise<Coach[]> {
  //   const result = await apiClient.get(`/playbook-instance/${instance_id}/coaches`)
  //   return result.data
  // },
  // async addPlaybookToInstance(instance_id: string, playbook_id: string): Promise<PlaybookInstance> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/playbook`, { playbook_id })
  //   return result.data
  // },
  // async createPlaybookInstanceInvite(instance_id: string): Promise<Link> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/create-invite`)
  //   return result.data
  // },
  // async createPlaybookInstanceCoachInvite(instance_id: string): Promise<Link> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/create-coach-invite`)
  //   return result.data
  // },
  // async makeCoachPrimary(instance_id: string, coach_id: string): Promise<void> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/make-coach-primary`, { coach_id })
  //   return result.data
  // },
  // async makeManual(instance_id: string): Promise<void> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/mode/default`)
  //   return result.data
  // },
  // async makeAuto(instance_id: string, { program_id, time }: { program_id: string; time: string }): Promise<void> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/mode/auto`, { program_id, start_at: time })
  //   return result.data
  // },
  // async makeSelfDriven(instance_id: string): Promise<void> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/mode/self-driven`)
  //   return result.data
  // },
  // async kickCoach(instance_id: string, coach_id: string): Promise<void> {
  //   const result = await apiClient.delete(`/playbook-instance/${instance_id}/coach/${coach_id}`)
  //   return result.data
  // },
  // async addCoach(instance_id: string, coach_id: string): Promise<void> {
  //   const result = await apiClient.post(`/playbook-instance/${instance_id}/coach`, { coach_id })
  //   return result.data
  // },

  async kickEmployees(group_id: string, employees: string[]): Promise<void> {
    const result = await apiClient.delete(`/group/${group_id}/employees`, { params: { employees } })
    return result.data
  },
  async addEmployees(group_id: string, employees: string[]): Promise<void> {
    const result = await apiClient.post(`/group/${group_id}/employees`, { employees })
    return result.data
  },
  async getEmployees(group_id: string): Promise<Employee[]> {
    const result = await apiClient.get(`/group/${group_id}/employees`)
    return result.data
  },
}

export default apiGroup
