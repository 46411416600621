/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import ConfigDispatcher from '@reducers/configs/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../components/Loader'
import AntSwitch from '../../../../components/switch/AntSwitch'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import SettingsEventsPenaltyDays from './components/SettingsEventsPenaltyDays'

interface Props {
  eventsConfig: any
}
function SettingsEventsPenalty(props: Props) {
  const { eventsConfig } = props
  const dispatch = useDispatch<ReduxDispatch>()

  const actionSetPenaltyEnabled = useAsyncHandler(async (isEnabled: boolean) => {
    await dispatch(ConfigDispatcher.setPenaltyEnabled(isEnabled))
  })

  const { is_penalty_enabled } = eventsConfig || {}
  const handleEnabledChange = (e, isSelected) => {
    actionSetPenaltyEnabled.execute(isSelected)
  }

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <Grid container>
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography
              fontWeight={'bold'}
              css={css`
                margin-right: 10px;
              `}
            >
              Cancellation penalty
            </Typography>

            <div>
              <RendererStatusSplit
                statuses={actionSetPenaltyEnabled}
                renderLoading={() => <Loader scale={0.3} />}
                render={() => <AntSwitch checked={is_penalty_enabled} onChange={handleEnabledChange} />}
              />
            </div>
          </Box>

          {is_penalty_enabled && <SettingsEventsPenaltyDays eventsConfig={eventsConfig} />}
        </Grid>
      </Grid>
    </div>
  )
}

export default SettingsEventsPenalty
