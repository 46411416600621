import { Avatar, AvatarProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import companyDefaultLogo from '../assets/bird.png'

const useStyles = makeStyles({
  logo: {
    transition: '200ms',
    width: 70,
    height: 70,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  logoPreview: {
    width: '70%',
    height: '70%',
    marginRight: -5,
  },
})

interface Props {
  url: string
  style?: AvatarProps['style']
  className?: string
}

const CompanyLogo: React.FC<Props> = ({ url, style, className }) => {
  const classes = useStyles()

  if (!url)
    return (
      <Avatar
        src={companyDefaultLogo}
        className={classNames(classes.logo, className)}
        style={style}
        classes={{
          img: classes.logoPreview,
        }}
      />
    )

  return <Avatar src={url} className={classNames(classes.logo, className)} style={style} />
}

export default CompanyLogo
