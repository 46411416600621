import { Tab } from '@mui/material'
import React from 'react'
import WhiteTabs from '../../elements/tabs/WhiteTabs'

interface Props {
  onChange: (event: React.SyntheticEvent, newValue: string) => any
}

function LeaderboardTabs(props: Props) {
  const { onChange } = props

  return (
    <WhiteTabs onChange={onChange}>
      <Tab disableRipple label="Points" value="points" />
      <Tab disableRipple label="Steps" value="steps" />
    </WhiteTabs>
  )
}

export default LeaderboardTabs
