import { Grid, Paper, Typography, Box, Avatar } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import CN from 'classnames'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import ProfileHeader from '../components/ProfileHeader'
import currencyToSign from '../helpers/currencyToSign'
import CoachProfile from './workOffer/CoachProfile'
import Rating from './workOffer/Rating'
// import filterIcon from './workOffer/assets/filter.svg'
import messageIcon from './workOffer/assets/message.svg'

const WorkOffer = ({
  classes,
  offers,
  getOffers,
  match: {
    params: { offerId },
  },
  chat,
}) => {
  const [activeItem, setActiveItem] = React.useState()

  React.useEffect(() => {
    if (!offers) {
      getOffers()
    }
  }, [offers])

  const offer = offers?.find(o => o.id === offerId)

  React.useEffect(() => {
    if (offer && offer.matchedCoaches && !activeItem) {
      setActiveItem(offer.matchedCoaches[0])
    }
  }, [offers])

  const { proposed_price, proposed_currency, period } = offer || {}
  const price = proposed_price ? `${currencyToSign(proposed_currency)}${proposed_price} per ${period}` : ''

  return (
    <Grid container style={{ height: '100%' }}>
      <ProfileHeader />
      <Grid xs={12} item style={{ margin: '0 50px 50px 50px' }}>
        <Paper>
          <Grid container>
            <Grid item xs={5} className={classes.sidebar}>
              <Grid container className={classes.sidebarTop}>
                <Typography>
                  <b>Your Match:</b>
                </Typography>
                {/* <Typography className={classes.filter}>Filter</Typography> */}
                {/* <img src={filterIcon} alt="" /> */}
              </Grid>
              <Grid container className={classes.sidebarBody}>
                {offer?.matchedCoaches?.map(item => {
                  const { name, avatar_url, applied_at, rating, chat_user_id } = item
                  const isNewMessage = Object.values(chat?.rooms || {}).find(r => r.userId === chat_user_id)?.unread > 0

                  return (
                    <Grid
                      item
                      container
                      alignItems="center"
                      className={CN(classes.matchItem, { [classes.activeMatchItem]: activeItem === item })}
                      onClick={() => setActiveItem(item)}
                    >
                      <Avatar src={avatar_url} className={classes.avatar} />
                      <Box>
                        <Typography className={classes.name}>
                          <b>{name}</b>
                        </Typography>
                        {applied_at && (
                          <Typography className={classes.applied}>Applied {moment(applied_at).fromNow()}</Typography>
                        )}
                        <Typography className={classes.price}>
                          <b>{price}</b>
                        </Typography>
                        {isNewMessage && (
                          <Box className={classes.msgWrapper}>
                            <img src={messageIcon} alt="" />
                          </Box>
                        )}
                      </Box>
                      <Rating value={rating} className={classes.rating} />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            {activeItem && (
              <Grid item xs={7}>
                <CoachProfile offer={offer} userId={activeItem.user_id} price={price} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  chat: state.chat,
  offers: state.coaches.offers,
})
const mapDispatchToProps = dispatch => ({
  getOffers: () => dispatch(coachesDispatcher.getOffers()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  sidebar: {
    backgroundColor: '#E8E8E8',
    padding: '45px 27px',
    maxWidth: 424,
  },
  sidebarBody: {
    maxHeight: '71vh',
    overflow: 'auto',
  },
  sidebarTop: {
    marginBottom: 26.5,
  },
  filter: {
    margin: '0 10px 0 auto',
    cursor: 'pointer',
  },
  matchItem: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '32px 24px',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: 5,
    '&:hover': {
      border: '1px solid #F9B22D',
    },
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  activeMatchItem: {
    border: '1px solid #F9B22D',
  },
  name: {
    fontSize: 15,
  },
  price: {
    marginTop: 12,
    fontSize: 13,
  },
  msgWrapper: {
    position: 'absolute',
    right: 20,
    bottom: 30,
    backgroundColor: '#E91717',
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  avatar: {
    width: 48,
    height: 48,
    marginRight: 24,
  },
  rating: {
    position: 'absolute',
    top: 18,
    right: 24,
  },
  applied: {
    marginBottom: 12,
    fontSize: 15,
  },
})

export default connectStore(withStyles(styles)(WorkOffer))
