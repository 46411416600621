import { Duration } from 'moment'
import { useMemo } from 'react'

function useDurationTxt(props: { duration: Duration }) {
  const { duration } = props

  const durationFormatted = useMemo(() => {
    let hours: number | string = Math.floor(duration.asHours() % 24)
    let minutes: number | string = Math.floor(duration.asMinutes() % 60)

    let formatted = ''
    if (hours) formatted += `${hours} hours`
    if (minutes) formatted += `${minutes} minutes`

    return formatted
  }, [duration])

  return durationFormatted
}

export default useDurationTxt
