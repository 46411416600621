import addChatIcon from './actions/addChatIcon.svg'
import fitnessIcon from './categories/fitness.svg'
import mindfulnessIcon from './categories/mindfulness.svg'
import nutritionIcon from './categories/nutrition.svg'
import clockIcon from './groups-clock.svg'
import coronaIcon from './coronaIcon.svg'
import groupIcon from './groupIcon.svg'
import listIcon from './list.svg'
import searchIcon from './searchIcon.svg'
import smallPlusIcon from './smallPlusIcon.svg'

// eslint-disable-next-line import/prefer-default-export
export {
  nutritionIcon,
  fitnessIcon,
  mindfulnessIcon,
  clockIcon,
  listIcon,
  searchIcon,
  smallPlusIcon,
  groupIcon,
  coronaIcon,
  addChatIcon,
}
