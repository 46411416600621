import { CircularProgress, Avatar, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AssetFull } from '@reaction-club-types/core'
import React from 'react'
import fileExplorer from 'web-file-explorer'
import companyIcon from '../../../../assets/company_icon_gray_background.png'
import plusIcon from '../../../../assets/plus_icon.png'
import { UseAsyncHandler } from '../../../../hooks/useActionHandler'

const useStyles = makeStyles(
  {
    company: {
      marginBottom: 32,
      position: 'relative',
      width: 190,
      margin: 'auto',
    },
    icon: {
      width: 100,
      height: 100,
      cursor: 'pointer',
    },
    progressContainer: {
      height: 100,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    plus: {
      position: 'absolute',
      cursor: 'pointer',
      width: 26,
      height: 26,
      zIndex: 2,
      left: '50%',
      marginLeft: 20,
      bottom: 4,
    },
  },
  {
    classNamePrefix: 'CompanyModalAsset',
  },
)

interface Props {
  assetUploader: UseAsyncHandler<AssetFull, File>
}

function CompanyModalAsset(props: Props) {
  const classes = useStyles()
  const { assetUploader } = props

  const handlePressLogo = async () => {
    const file = await fileExplorer.getFile({ acceptImage: true })
    if (!file) return null

    await assetUploader.onAction(file)
  }

  const { data: asset, isLoading } = assetUploader
  let logo = asset ? asset.url : companyIcon

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.company}>
      {isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div onClick={handlePressLogo}>
          <Avatar src={logo} className={classes.icon} alt="company logo" />
          <Avatar src={plusIcon} className={classes.plus} alt="plus icon" />
        </div>
      )}
    </Grid>
  )
}

export default CompanyModalAsset
