import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { Immutable } from 'seamless-immutable'

interface Props {
  event: Immutable<ReduxStateReactionEventItem>
}

const csvHeaLine = ['Event', 'Is Checked In']
function ReportsExportButton(props: Props) {
  const { event } = props
  const { name, participants, time, participant_unknown_count } = event
  if (_.isEmpty(participants)) return null

  const fileName: string = useMemo(() => {
    const timeMoment = moment(time.valueOf())
    const timeStr = timeMoment.format('YYYY-MM-DD')
    return `participants-${timeStr}-${name}.csv`
  }, [name, time])

  const csvEventsData = useMemo(() => {
    const registeredList = _.map(participants, participant => [participant.name, participant.check_in])
    const unknownList: [string, string][]  = []
    if (participant_unknown_count) {
      unknownList.length = participant_unknown_count
      unknownList.fill(['Unknown', 'true'], 0)
    }

    return registeredList.concat(unknownList)
  }, [participants])

  const csvData = [csvHeaLine, ...csvEventsData]

  return (
    <CSVLink data={csvData} filename={fileName}>
      Download csv
    </CSVLink>
  )
}

export default ReportsExportButton
