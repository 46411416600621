import { Grid, TextField, TextFieldProps, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import ButtonOrange from '../components/ButtonOrange'
import ModalStyled from '../elements/ModalStyled'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import { ExtractStyles } from '../typings/stylesExtractor'

const connectStyles = withStyles({
  container: {
    minWidth: 400,
    paddingTop: 20,
  },
  buttonContainer: {
    marginTop: 20,
  },
  title: {
    marginBottom: 10,
  },
})

interface State {
  email: string
  error: string
  isLoading: boolean
}

interface Props {
  isVisible: boolean
  onClose(): any
}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class CompanyEmailCreateModal extends React.PureComponent<PropsInner, State> {
  state: State = {
    email: '',
    error: '',
    isLoading: false,
  }

  render() {
    const { isVisible, onClose, classes } = this.props

    return (
      <ModalStyled isVisible={isVisible} onClose={onClose} className={classes.container}>
        <Typography variant={'h1'} className={classes.title}>
          Add new email
        </Typography>
        <p>
          Example: <b>company.com</b>
        </p>

        <Grid item lg={12} md={12} sm={12}>
          {this.renderForm()}
        </Grid>
      </ModalStyled>
    )
  }

  renderForm() {
    const { error, email, isLoading } = this.state
    const { classes } = this.props

    const isButtonDisabled = !email

    return (
      <div>
        <TextField value={email} label={'Email'} onChange={this.handleEmailChange} fullWidth error={!!error} />
        <Grid
          container
          className={classes.buttonContainer}
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
        >
          <Typography color={'error'}>{error}</Typography>
          <ButtonOrange onClick={this.handlePressSave} isLoading={isLoading} disabled={isButtonDisabled}>
            Add
          </ButtonOrange>
        </Grid>
      </div>
    )
  }

  handleEmailChange: TextFieldProps['onChange'] = e => this.setState({ email: e.target.value || '' })

  handlePressSave = async () => {
    const { onClose } = this.props
    const { email } = this.state
    if (!email) return null

    this.setState({ isLoading: true })

    try {
      await this.props.addEmail(email)
    } catch (e) {
      console.error(e)
      this.setState({
        error: e.message,
      })
    }
    this.setState({ isLoading: false })

    onClose()
  }
}

const connectStore = connect(
  state => ({}),
  (dispatch: ReduxDispatch) => ({
    addEmail: (email: string) => dispatch(CompanyDispatcher.addCorporateEmail(email)),
  }),
)
export default compose<PropsInner, Props>(connectStore, connectStyles)(CompanyEmailCreateModal)
