/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ReactionEvent } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import { ReduxStateGroupsItem } from '@reducers/playbookInstances/reducer'
import moment, { Moment } from 'moment'
import React, { useMemo, useState } from 'react'
import { Calendar, CalendarProps, momentLocalizer, View } from 'react-big-calendar'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import HorizontalCustomEvent from '../HorizontalCustomEvent'
import CalendarStyles from './CalendarStyles'
import CalendarToolbar from './CalendarToolbar'
import CalendarDateCellWrapperComponent from './components/CalendarDateCellWrapperComponent'
import CalendarEventVertical from './components/CalendarEventVertical'
import CalendarMonthDateHeaderComponent from './components/CalendarMonthDateHeaderComponent'
import CalendarMonthHeaderComponent from './components/CalendarMonthHeaderComponent'
import CalendarWeekHeaderComponent from './components/CalendarWeekHeaderComponent'
import CalendarWeeklyEvent from './components/CalendarWeeklyEvent'

const localizer = momentLocalizer(moment)

export interface CalendarEvent {
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: CalendarEventResources
}

interface CalendarEventResources {
  description: string
  instance: ReduxStateGroupsItem | null | any
}

interface Props {
  tab: View
  onTabChange(tab: View): any
  events: ReduxStateReactionEventItem[]
  onSelectEvent(event: ReactionEvent): any
}

function EventsCalendar(props: Props) {
  const { tab, events, onTabChange, onSelectEvent } = props
  const [calendarMoment, setCalendarMoment] = useState<Moment>(moment())

  const handleSelectEvent = (ev: CalendarEvent) => {
    if (!ev.resource) return null
    const event: ReactionEvent = ev.resource as any
    onSelectEvent(event)
  }

  const dispatch = useDispatch<ReduxDispatch>()
  const dateM = useMemo(() => {
    return calendarMoment.format('YYYY-MM')
  }, [calendarMoment])

  const fetchEvents = useAsyncFetch(
    async () => {
      const startMoment = calendarMoment.clone().startOf('month')
      const endMoment = startMoment.clone().add(1, 'month')

      await dispatch(
        EventDispatcher.getEvents({
          dateStart: startMoment.add(-6, 'days').toISOString(),
          dateEnd: endMoment.add(6, 'days').toISOString(),
        }),
      )
    },
    { maxTries: 3, timeoutBeforeRetry: 5000 },
    [dateM],
  )

  const handleCalendarNavigate: CalendarProps['onNavigate'] = (date, view, action) => {
    setCalendarMoment(moment(date))
  }

  const calendarEvents = useMemo(() => {
    return events.map((event) => {
      return {
        title: event.name,
        start: moment(event.time).toDate(),
        end: moment(event.time).add(event.duration, 'minutes').toDate(),
        allDay: false,
        resource: {
          ...event,
          instance: event.group,
        },
      }
    })
  }, [events])

  return (
    <CalendarStyles>
      <Calendar
        view={tab}
        // eventPropGetter={() => (!isAgendaActive ? { className: classes.eventItem } : {})}
        onView={onTabChange}
        onNavigate={handleCalendarNavigate}
        components={{
          toolbar(props) {
            return (
              <CalendarToolbar
                tab={tab}
                date={calendarMoment}
                onTabChange={onTabChange}
                onDateNavigate={props.onNavigate}
                isEventsLoading={fetchEvents.isLoading}
              />
            )
          },
          dateCellWrapper: CalendarDateCellWrapperComponent,
          // timeSlotWrapper(props) {
          //   console.log('timeSlotWrapper', props)
          //   return props.children
          // },
          month: {
            event: CalendarEventVertical,
            dateHeader: CalendarMonthDateHeaderComponent,
            header: CalendarMonthHeaderComponent,
          },
          agenda: {
            event: CalendarEventVertical,
          },
        }}
        startAccessor="start"
        events={calendarEvents}
        endAccessor="end"
        localizer={localizer}
        style={{ height: 700 }}
        hideTimeIndicator
        onSelectEvent={handleSelectEvent}
      />
    </CalendarStyles>
  )
}

export default EventsCalendar
