/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { Typography, Grid, Box, Divider } from '@mui/material'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useClipboard } from 'use-clipboard-copy'
import useModalState from '../../../../hooks/useModalState'
import EventHostLinkCreateModal from '../../modals/EventHostLinkCreateModal'

interface Props {
  event: ReduxStateReactionEventItem
}

function EventLinks(props: Props) {
  const { event } = props
  let { url, workout_type, time, is_public } = event
  const hostLinkCreateModal = useModalState()
  const clipboard = useClipboard({
    copiedTimeout: 3000, // timeout duration in milliseconds
  })

  const isTooLate = useMemo(() => {
    const timeMoment = moment(time)
    return moment().isAfter(timeMoment.add(3, 'hours'))
  }, [])

  // url = 'http://google.com' // NOCOMMIT
  if (workout_type !== 'live_streaming') return null
  if (isTooLate) return null

  const handleClickCopyUrl = () => {
    clipboard.copy(url)
  }

  return (
    <div>
      <Box marginBottom={'15px'} marginTop={'15px'}>
        <Divider />
      </Box>
      <EventHostLinkCreateModal {...hostLinkCreateModal} event={event} />
      <div>
        <Typography variant={'h2'}>Zoom links</Typography>
      </div>
      <div>
        {is_public ? null : (
          <Grid container alignItems={'center'}>
            <Typography>Host link:</Typography>
            <div
              css={css`
                color: #3c8af0;
                margin-left: 7px;
                cursor: pointer;
              `}
              onClick={hostLinkCreateModal.onOpen}
            >
              create
            </div>
          </Grid>
        )}

        <Grid container alignItems={'center'} style={{ marginBottom: 20 }}>
          <Typography>Participant link:</Typography>
          <a
            href={url}
            target={'_blank'}
            css={css`
              color: #3c8af0;
              margin-left: 7px;
              &:hover {
                text-decoration: underline;
              }
            `}
            rel="noreferrer"
          >
            {url}
          </a>
          <div
            css={css`
              margin-left: 10px;
            `}
          >
            {clipboard.copied ? (
              <Typography
                css={css`
                  font-size: 12px;
                  color: #656565;
                `}
              >
                copied
              </Typography>
            ) : (
              <ContentCopyIcon
                onClick={handleClickCopyUrl}
                css={css`
                  cursor: pointer;
                  font-size: 16px;
                `}
              />
            )}
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default EventLinks
