import { FormControl, FormHelperText, Grid, Paper, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import apiAuth from '@reducers/auth/apiAuth'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { validate } from 'validate.js'
import Loader from '../../../components/Loader'
import { OpenSans } from '../../../helpers/commonStyles'
import { ExtractStyles } from '../../../typings/stylesExtractor'

const connectStyles = withStyles({
  container: {
    padding: '70px 50px',
    margin: '30px',
    fontFamily: OpenSans,
  },
  absoluteContainer: {
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80%',
    width: 720,
    maxWidth: '80%',
    minWidth: 290,
    height: 450,
  },
  buttonText: {
    color: '#1A1A1A',
    fontSize: 12,
  },
  submitButton: {
    width: 100,
    marginTop: 24,
  },
  passwordTextField: {
    maxWidth: 400,
  },
  loaderContainer: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 20,
  },
})

type Props = RouteComponentProps<{
  id: string
}>
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class ResetPasswordRequest extends React.PureComponent<PropsInner> {
  state: {
    isLoading: boolean
    password: string
    formError: boolean
    passwordFieldTouched: boolean
    completed: boolean
    isInvalidRequest: boolean
  } = {
    password: '',
    isLoading: false,
    formError: false,
    passwordFieldTouched: false,
    completed: false,
    isInvalidRequest: false,
  }

  render() {
    const { classes } = this.props
    const { isLoading, formError, password, passwordFieldTouched, completed, isInvalidRequest } = this.state

    return (
      <div className={classes.absoluteContainer}>
        <Paper>
          <Grid container direction={'row'} className={classes.container}>
            <Grid container direction={'column'} justifyContent={'center'}>
              <h1>Enter your new password</h1>
              <p>You will be able to login using your new password.</p>
              <TextField
                id="password"
                label="Password"
                type="password"
                autoFocus
                value={password}
                error={formError && passwordFieldTouched}
                onBlur={this.setPasswordFieldTouched}
                disabled={isLoading}
                onChange={this.onPasswordChange}
                className={classes.passwordTextField}
              />

              <FormControl>
                {isLoading && (
                  <Grid container className={classes.loaderContainer}>
                    <Loader />
                  </Grid>
                )}
                {!completed && !isLoading && (
                  <button
                    type={'button'}
                    onClick={this.handleSubmit}
                    disabled={isLoading || formError || !password}
                    className={classes.submitButton}
                  >
                    <Grid container direction={'row'} alignItems={'center'}>
                      <span className={classes.buttonText}>Submit</span>
                    </Grid>
                  </button>
                )}
                {formError && passwordFieldTouched && (
                  <FormHelperText>
                    Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character, and be between 8 and 30 characters long.
                  </FormHelperText>
                )}
              </FormControl>

              {completed && !isInvalidRequest && (
                <p>
                  Your password was reset.
                  <br />
                  <Link to={'/login'}>Return to login.</Link>
                </p>
              )}

              {completed && isInvalidRequest && (
                <p>
                  This request is no longer valid, please try to reset password once more.
                  <br />
                  <Link to={'/login'}>Return to login.</Link>
                </p>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }

  handleSubmit = () => {
    const { id } = this.props.match.params
    if (!this.state.password) {
      return
    }
    this.setState({
      isLoading: true,
    })
    apiAuth
      .resetPassword(id, this.state.password)
      .catch(() => {
        this.setState({
          isInvalidRequest: true,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          completed: true,
        })
      })
  }

  formConstraints = {
    password: {
      format: {
        pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\#?!@$%^&*\\-_])[a-zA-Z\\d\\#?!@$%^&*\\-_]{8,30}$',
        message: "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character, and be between 8 and 30 characters long."
      }
    },
  }

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.target.value
    const formError = !!validate({ password }, this.formConstraints)
    this.setState({
      formError,
      password,
    })
  }

  setPasswordFieldTouched = () => {
    this.setState({
      passwordFieldTouched: true,
    })
  }
}

export default compose<PropsInner, Props>(connectStyles)(ResetPasswordRequest)
