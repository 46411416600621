import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { Box, Divider, Grid, Link, Stack, TextField, Typography as MuiTypography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import ButtonOrange from '../../components/ButtonOrange'
import paymentApi from '../../libs/api/paymentApi'
import creditCardFrom from './assets/credit-card.png'
import { useSelector } from 'react-redux'
import { ReduxState } from '@reducers/index'
import { useHistory } from 'react-router-dom'
import LoadingModal from 'src/modals/LoadingModal'
import secureStripePaymentImg from './assets/secure-payment-stripe.png'
import trustedByImg from './assets/trusted-by.png'

const CURRENCIES_SYMBOL = {
  usd: '$',
  eur: '€',
  gbp: '£',
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      display: 'block',
      backgroundColor: '#F6F6F6',
      color: '#000000',
      borderRadius: 15,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#C4C4C4',
      },
    },
    invalid: {},
  },
}

const percentageOff = (price: number, percentageValue: number): number => {
  if (!percentageValue) {
    return price
  }

  return price * (1 - percentageValue / 100)
}

function CheckoutForm({ selectedPlan, quantity, currentSubscriptionId }) {
  const [loading, setLoading] = useState(false)
  const company = useSelector((state: ReduxState) => state.company)
  const { register, handleSubmit } = useForm<{ name?: string }>({ defaultValues: { name: company.name } })
  const [promoCode, setPromoCode] = useState('')
  const [promo, setPromo] = useState<any>(null)
  const [havePromo, setHavePromo] = useState(false)
  const history = useHistory()

  // stripe items
  const stripe = useStripe()
  const elements = useElements()

  // main function
  const createSubscription = async (values) => {
    try {
      setLoading(true)
      if (currentSubscriptionId) {
        await paymentApi.changeSubscriptionPlan({
          priceId: selectedPlan.priceId,
          promoCode: promo?.code,
          quantity,
        })
      } else {
        const cardNumber = elements.getElement(CardNumberElement)
        const paymentToken = await stripe?.createToken(cardNumber)

        const paymentMethod = await paymentApi.addPaymentMethod({ token: paymentToken.token.id })

        const subscription = await paymentApi.createSubscription({
          priceId: selectedPlan.priceId,
          paymentMethodId: paymentMethod.paymentMethodId,
          quantity,
          organizationName: values.name,
          promoCode: promo?.code,
        })

        const confirmPayment = await stripe?.confirmCardPayment(subscription.clientSecret)
      }

      alert('Thank you! Your account has now been upgraded. You will receive your invoice via email shortly')
      history.push('/settings/billing')
    } catch (error) {
      alert(error.response.data)
    } finally {
      setLoading(false)
    }
  }

  const handleApplyPromoCode = async () => {
    try {
      const promo = await paymentApi.getPromoCode(promoCode)
      setPromo(promo)
    } catch (error) {
      setPromo(null)
      alert(error.response.data)
    }
  }

  return (
    <>
      {loading && <LoadingModal isVisible onClose={() => {}} message={`Processing...`} />}
      <Box py={15}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={currentSubscriptionId ? 12 : 5}>
            <Grid container>
              <Grid item xs={12} mb={4}>
                <Typography variant="h1" fontWeight="600" fontSize={25}>
                  {currentSubscriptionId ? 'Plan update summary' : 'Your Order Summary'}
                </Typography>
                <Stack direction="row" justifyContent="space-between" gap={2} mt={8} mb={4}>
                  <Typography fontWeight="600" fontSize={21}>
                    {selectedPlan.title} subscription x {selectedPlan.teamSize} members
                  </Typography>
                  <Typography fontWeight="bold" fontSize={21}>
                    {CURRENCIES_SYMBOL[selectedPlan.currency]}
                    {selectedPlan.total}
                  </Typography>
                </Stack>
                <Divider />
                {!havePromo && (
                  <ApplyPromo
                    onClick={(e) => {
                      e.preventDefault()
                      setHavePromo(true)
                    }}
                  >
                    Add promotion code
                  </ApplyPromo>
                )}
                {promo ? (
                  <>
                    <Stack direction="row" justifyContent="space-between" gap={2} my={4}>
                      <Typography fontWeight="bold">Discount</Typography>
                      <Typography fontWeight="bold">
                        {CURRENCIES_SYMBOL[selectedPlan.currency]}
                        {selectedPlan.total - percentageOff(selectedPlan.total, promo.percentOff)}
                      </Typography>
                    </Stack>
                    <ApplyPromo
                      onClick={(e) => {
                        e.preventDefault()
                        setHavePromo(false)
                        setPromo(null)
                      }}
                    >
                      Remove Promo Code
                    </ApplyPromo>
                    <Divider />
                  </>
                ) : null}
                {havePromo && !promo ? (
                  <Stack direction="row" gap={2} my={4}>
                    <TextField
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      label="Promo Code"
                      style={{ flex: 1 }}
                    />
                    <ButtonOrange onClick={handleApplyPromoCode}>Apply a promocode</ButtonOrange>
                  </Stack>
                ) : null}
                <Divider />
                <Stack direction="row" justifyContent="space-between" gap={2} my={4}>
                  <Typography fontWeight="bold" fontSize={26}>
                    Total
                  </Typography>
                  <Typography fontWeight="bold" fontSize={26}>
                    {CURRENCIES_SYMBOL[selectedPlan.currency]}
                    {percentageOff(selectedPlan.total, promo?.percentOff)}
                  </Typography>
                </Stack>
                {currentSubscriptionId ? (
                  <Stack alignItems="center">
                    <PurchaseButton onClick={handleSubmit(createSubscription)} disabled={!stripe}>
                      Change plan to {selectedPlan.title}
                    </PurchaseButton>
                  </Stack>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {!currentSubscriptionId ? (
            <>
              <Grid item xs={2} display={{ xs: 'none', lg: 'block' }}>
                <VerticalDivider />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextField {...register('name')} label="Organization name" variant="outlined" fullWidth />
                <CardContainer>
                  <CreditCardImage src={creditCardFrom} />
                  <CardElementContainer>
                    <Box>
                      <Label>Card Number</Label>
                      <ElementContainer>
                        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                      </ElementContainer>
                    </Box>

                    <Stack gap={3} direction="row" mt={1}>
                      <Box width={140}>
                        <Label>Expiry date</Label>
                        <ElementContainer>
                          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                        </ElementContainer>
                      </Box>
                      <Box width={110}>
                        <Label>Security code</Label>
                        <ElementContainer>
                          <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                        </ElementContainer>
                      </Box>
                    </Stack>
                  </CardElementContainer>
                </CardContainer>
                <SecureStripPaymentImg src={secureStripePaymentImg} />
                <PurchaseButton onClick={handleSubmit(createSubscription)} disabled={!stripe}>
                  Purchase
                </PurchaseButton>
              </Grid>
            </>
          ) : null}
        </Grid>
        <TrustedByImg src={trustedByImg} alt="Trusted By" />
      </Box>
    </>
  )
}

const PurchaseButton = styled(ButtonOrange)`
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  color: black;
  padding: 18px 15px;
  width: 320px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`

const CardContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  border: 1px solid #ccc;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
`

const ApplyPromo = styled('a')`
  color: #0a58ba;
  display: inline-block;
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px;
`

const CreditCardImage = styled('img')`
  max-width: 100%;
  height: auto;
`

const CardElementContainer = styled('div')`
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
`

const Typography = styled(MuiTypography)`
  font-family: Poppins;
`

const SecureStripPaymentImg = styled('img')`
  height: 22px;
  display: block;
  margin: 16px auto;
`

const VerticalDivider = styled('div')`
  height: 100%;
  background-color: #979797;
  width: 1px;
  margin: 0 auto;
`

const Label = styled('label')`
  font-size: 11px;
  margin-bottom: 4px;
  z-index: 10;
  position: relative;
  color: #666666;
  font-family: Poppins;
`

const ElementContainer = styled('div')`
  position: relative;
  z-index: 10;
  padding: 14px;
  background-color: #f6f6f6;
  border-radius: 15px;
  margin-top: 4px;
`

const TrustedByImg = styled('img')`
  width: 100%;
  max-width: 800px;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export default CheckoutForm
