import { Agent } from '@reaction-club-types/core'
import authActions from '@reducers/auth/actions'
import bugSnag from '../../libs/bugSnag'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import apiAuth from '../auth/apiAuth'
import AuthDispatcher from '../auth/dispatcher'
import actions from './actions'

export default {
  getMySelf(): DispatcherResponse<Agent | void> {
    return async (dispatch) => {
      try {
        const me = await apiAuth.me()
        dispatch(authActions.setIsAdmin(me.role === 'owner' || me.role === 'admin'))
        dispatch(actions.setMe(me))
        bugSnag.setUser(me.user_id, '', me.name)

        const hasUserInDataLayer = (window.dataLayer || []).some((d) => !!d.user_id)
        if (!hasUserInDataLayer && window.dataLayer) {
          window.dataLayer.push({
            user_id: me.user_id,
          })
        }
        return me
      } catch (e) {
        const status = e.response?.status
        if (status === 401) {
          console.error('getMySelf', '401 error', '=> logout')
          await dispatch(AuthDispatcher.logout())
        }
      }
    }
  },
}
