import { Box, Grid, Typography } from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import React from 'react'
import { compose } from 'redux'
import ReactionAvatar from '../../../../components/assets/ReactionAvatar'

type OwnProps = {
  user: any
}
type Props = OwnProps & WithStyles<typeof styles>
const Profile: React.FC<Props> = ({
  classes,
  user: {
    name,
    // emails,
    customFields: { role, avatar_url, company_name },
  },
}) => {
  return (
    <Box className={classes.profileContainer}>
      <Grid container direction={'column'} justifyContent={'center'}>
        <ReactionAvatar asset={{ url: avatar_url }} style={{ width: 136, height: 136 }} />
        <Typography className={classes.coachName}>{name}</Typography>
        <Typography className={classes.groupName}>{`${company_name || ''} ${role}`}</Typography>
        {/*<Grid item>*/}
        {/*  <Grid container direction={'column'} style={{ marginTop: 73 }}>*/}
        {/*    {emails.map(email => (*/}
        {/*      <Grid item style={{ marginBottom: 15 }}>*/}
        {/*        <Typography className={classes.label}>Email</Typography>*/}
        {/*        <Typography className={classes.text}>{email.address}</Typography>*/}
        {/*      </Grid>*/}
        {/*    ))}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  )
}

const styles = createStyles({
  profileContainer: {
    padding: '40px 20px',
    background: '#f3f3f3',
    maxWidth: 300,
    borderBottom: '1px solid #d4d4d4',
  },
  coachName: {
    fontSize: 16,
    fontWeight: 800,
    marginTop: 43,
    marginBottom: 10,
    lineHeight: 1,
    fontFamily: 'Lato',
    letterSpacing: '1',
    whiteSpace: 'nowrap',
  },
  groupName: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Lato',
    letterSpacing: '0.6px',
  },
  label: {
    color: '#5F6164',
    fontFamily: 'Lato',
    fontSize: 12,
    lineHeight: 1,
    marginBottom: 6,
  },
  text: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 1.1,
  },
})

export default compose(withStyles(styles))(Profile)
