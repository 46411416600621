import { Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import { RouteComponentProps } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import EventInfo from './event/EventInfo'

interface Props extends RouteComponentProps<{ id: string }> {}

function EventPage(props: Props) {
  const { match } = props
  const { id } = match.params

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(EventDispatcher.getById(id))
    dispatch(EventDispatcher.getRecordings(id))
  })

  const { event } = useSelector((state: ReduxState) => ({
    event: state.events[id],
  }))

  return (
    <PaperContainer>
      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={!event}
        renderError={(error) => (
          <Typography variant={'h2'} align={'center'} color={'error'}>
            {error}
          </Typography>
        )}
        renderLoading={() => <ReactionLoader />}
        renderEmpty={() => (
          <Typography variant={'h2'} align={'center'} color={'error'}>
            Event is not found
          </Typography>
        )}
        render={() => <EventInfo event={event} />}
      />
    </PaperContainer>
  )
}

export default EventPage
