import _ from 'lodash'
import actions from './actions'
import './reducer'

export default dispatch =>
  bounded({
    async addSuccessNotify(message) {
      dispatch(actions.addSuccessNotify(message))
    },
    async addErrorNotify(message) {
      dispatch(actions.addErrorNotify(message))
    },
    async addWarningNotify(message) {
      dispatch(actions.addWarningNotify(message))
    },
    async makeEmptyNotify() {
      dispatch(actions.makeEmptyNotify({ message: '' }))
    },
  })

function bounded(object) {
  _.keys(object).forEach(key => {
    object[key] = object[key].bind(object)
  })
  return object
}

function timer() {
  return new Promise(resolve => setTimeout(resolve, 1000))
}
