import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LinkModal from './modals/LinkModal'

interface Props extends RouteComponentProps<{ id: string }> {}

const Link = ({ match }: Props) => {
  const { id } = match.params || {}

  return <LinkModal invitation_id={id} />
}

export default Link
