import { Grid, Box, Button } from '@mui/material'
import { withStyles, createStyles } from '@mui/styles'
import categoryDispatcher from '@reducers/categories/dispatcher'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxState } from '@reducers/index'
import CN from 'classnames'
import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import tickInCircleIcon from '../assets/tick-in-circle.svg'
import triangleIcon from '../assets/triangle.svg'
import CoachWrapper from '../components/CoachWrapper'

const categories = ['fitness', 'nutrition', 'mindfulness']

const ChooseCategory = ({
  classes,
  categories: categoriesObj,
  getTypes,
  type_ids,
  offerCategory,
  setOfferCategory,
  setOfferSubcategory,
}: ExtractConnectType<typeof connectStore>) => {
  const subcategories = categoriesObj[offerCategory]

  React.useEffect(() => {
    categories.forEach(getTypes)
    // if (!offerCategory) {
    //   setOfferCategory(categories[0])
    // }
  }, [])

  React.useEffect(() => {
    // if (!offerSubcategory && subcategories) {
    //   setOfferSubcategory(subcategories.types[0].id)
    // }
  }, [categoriesObj])

  const handleCategoryChange = category => {
    setOfferCategory(category)
    setOfferSubcategory([])
  }

  const handlePressType = (type_id: string) => {
    let resultTypes: string[]
    if (type_ids.includes(type_id)) resultTypes = _.filter(type_ids, item => item !== type_id)
    else resultTypes = type_ids.concat(type_id)

    setOfferSubcategory(resultTypes)
  }

  return (
    <CoachWrapper title="Choose Category" titleWidth={109}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content} justifyContent="center" alignItems="flex-start">
            {categories.map(category => {
              const isCategoryActive = offerCategory === category

              return (
                <Box className={classes.categoryBtnWrapper}>
                  <Button
                    className={CN(classes.categoryBtn, { [classes.activeCategoryBtn]: isCategoryActive })}
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                    {isCategoryActive && <img src={triangleIcon} className={classes.triangle} alt="" />}
                  </Button>
                  {isCategoryActive && (
                    <Grid container className={classes.content} justifyContent="center" alignItems="center" direction="column">
                      {subcategories?.types.map(({ id, name }) => {
                        const isSubcategoryActive = type_ids.includes(id)

                        return (
                          <Button
                            className={CN(classes.subcategoryBtn, {
                              [classes.activeSubcategoryBtn]: isSubcategoryActive,
                            })}
                            onClick={() => handlePressType(id)}
                          >
                            {name}
                            {isSubcategoryActive && (
                              <img src={tickInCircleIcon} className={classes.tickInCircle} alt="" />
                            )}
                          </Button>
                        )
                      })}
                    </Grid>
                  )}
                </Box>
              )
            })}
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Link
            className={classes.btn}
            to="/coaches/invite/external/choose-service-type"
            style={{ textDecoration: 'none', color: '#323232' }}
            onClick={e => {
              if (_.isEmpty(type_ids) || !offerCategory) {
                e.preventDefault()
              }
            }}
          >
            Next
          </Link>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    categories: state.categories,
    offerCategory: state.coaches.offer.category,
    type_ids: state.coaches.offer.type_ids,
  }),
  (dispatch: ReduxDispatch) => ({
    getTypes: (category: string) => dispatch(categoryDispatcher.getCategoryTypes(category)),
    setOfferCategory: (category: string) => dispatch(coachesDispatcher.actions.setOfferCategory(category)),
    setOfferSubcategory: (types: string[]) => dispatch(coachesDispatcher.actions.setOfferSubcategory(types)),
  }),
)

const styles = createStyles({
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  categoryBtnWrapper: {
    '&:not(:last-child)': {
      marginRight: 13,
    },
  },
  categoryBtn: {
    backgroundColor: '#F4F4F4',
    padding: '17px',
    minWidth: 180,
    width: '100%',
    minHeight: 60,
    borderRadius: '8px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#F9B22D',
    },
  },
  activeCategoryBtn: {
    backgroundColor: '#323232',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#474646',
    },
    position: 'relative',
  },
  triangle: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    bottom: '-13px',
    left: '50%',
  },
  subcategoryBtn: {
    padding: '17px',
    width: '100%',
    minHeight: 60,
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    fontWeight: 'bold',
    '&:first-child': {
      marginTop: 33,
    },
    '&:not(:last-child)': {
      marginBottom: 22,
    },
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#F9B22D',
    },
  },
  activeSubcategoryBtn: {
    borderColor: '#F9B22D',
    position: 'relative',
  },
  tickInCircle: {
    position: 'absolute',
    top: 11,
    right: 14,
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(ChooseCategory)
