/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { ReactionEvent } from '@reaction-club-types/core'
import { APIEventUpdatePayload } from '@reducers/events/api'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import React from 'react'
import validate from 'validate.js'
import SubmitButton from '../../../modals/components/SubmitButton'
import EventCoachSelect from '../eventCreate/components/EventCoachSelect'
import EventDateSelect from '../eventCreate/components/EventDateSelect'
import EventTimeSelect from '../eventCreate/components/EventTimeSelect'
import EventFormInput from '../eventCreate/components/elements/EventFormInput'

interface Errors {
  name?: string
  description?: string
  coach_id?: string
  duration?: string
  location_formatted?: string
  time?: string
}

interface State {
  name: string
  description: string
  coach_id: string
  location: string
  participant_limit?: number
  time: Moment
  errors: Errors
  submitErrorMessage?: string
}

interface Props {
  event: ReactionEvent
  onUpdate(payload: APIEventUpdatePayload): Promise<any>
}

class EventUpdateForm extends React.PureComponent<Props, State> {
  state: State = {
    name: '',
    description: '',
    coach_id: '',
    location: '',
    time: moment(),
    // playbook_instance_id: null,
    errors: {},
    submitErrorMessage: '',
  }

  constructor(props: Props) {
    super(props)

    const { event } = props
    this.state.name = event.name
    this.state.coach_id = event.coach_id
    this.state.location = event.location_formatted
    this.state.participant_limit = event.participant_limit
    this.state.description = event.description
    this.state.time = moment(event.time)
  }

  render() {
    const { name, description, coach_id, location, time, errors, participant_limit } = this.state
    const { event } = this.props
    const { workout_type } = event

    return (
      <div
        css={css`
          max-width: 800px;
          margin-right: -10px;
        `}
      >
        <Grid
          container
          css={css`
            & > .MuiGrid-item {
              padding: 0 10px;
            }
          `}
        >
          <Grid item sm={6}>
            <EventFormInput value={name} label={'Name'} onChange={this.handleNameChange} error={!!errors.name} />
          </Grid>

          <Grid item sm={6}>
            <EventCoachSelect coach_id={coach_id} onChange={this.handleCoachChange} isErrored={!!errors.coach_id} />
          </Grid>

          <Grid item sm={6}>
            <EventDateSelect value={time} onChange={this.handleTimeChange} isErrored={!!errors.time} />
          </Grid>

          <Grid item sm={6}>
            <EventTimeSelect value={time} onChange={this.handleTimeChange} error={errors.time} />
          </Grid>

          <Grid item sm={12}>
            <EventFormInput
              value={description}
              label={'Description'}
              multiline
              onChange={this.handleDescriptionChange}
              error={!!errors.description}
              css={css`
                textarea {
                  min-height: 60px;
                }
              `}
            />
          </Grid>

          <Grid item sm={6}>
            <EventFormInput
              value={participant_limit || ''}
              label={'Participant Limit (optional)'}
              type={'number'}
              onChange={this.handleParticipantLimitChange}
              error={!!errors.description}
            />
          </Grid>

          <Grid item sm={6}>
            {workout_type !== 'live_streaming' && (
              <EventFormInput
                value={location}
                label={'Location (optional)'}
                onChange={this.handleLocationChange}
                error={!!errors.location_formatted}
              />
            )}
          </Grid>
        </Grid>

        <SubmitButton title={'Update'} onSubmit={this.handleSubmit} />
      </div>
    )
  }

  handleNameChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.name
    this.setState({ name: e.target.value, errors })
  }

  handleDescriptionChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.description
    this.setState({ description: e.target.value, errors })
  }

  handleParticipantLimitChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ participant_limit: parseInt(e.target.value) || undefined, errors })
  }

  handleLocationChange: StandardInputProps['onChange'] = (e) => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ location: e.target.value, errors })
  }

  handleCoachChange = (coach_id: string) => {
    const { errors } = this.state
    delete errors.coach_id
    this.setState({ coach_id, errors })
  }

  handleTimeChange = (time: Moment) => {
    const { errors } = this.state
    delete errors.time
    this.setState({ time, errors })
  }

  handleSubmit = async () => {
    const { coach_id, description, location, name, time, submitErrorMessage, participant_limit } = this.state

    if (submitErrorMessage) {
      this.setState({ submitErrorMessage: '' })
    }

    const form: APIEventUpdatePayload = {
      coach_id,
      description,
      location_formatted: location,
      name,
      time: moment(time).toISOString(),
      participant_limit,
    }

    if (!this.isValid(form)) {
      return null
    }

    await this.props.onUpdate(form)
  }

  isValid = (form: APIEventUpdatePayload): boolean => {
    const errors: Errors = validate(form, validationSchema) || {}

    const nowMoment = moment()
    if (moment(form.time).isBefore(nowMoment)) {
      errors.time = 'You cannot select time, which already passed'
    }

    // if (!form.coach_id) {
    //   errors.coach_id = 'Coach is not set'
    // }

    this.setState({ errors })

    return _.isEmpty(errors)
  }
}

export default EventUpdateForm

const validationSchema = {
  name: {
    presence: {
      message: 'Name is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Name at least %{count} letters',
    },
  },
  description: {
    presence: {
      message: 'Description is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Description at least %{count} letters',
    },
  },
}
