import appTypes from '@reducers/app/types'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types, { IChallenge } from './types'

interface State extends IChallenge, StoreUpdatedObject {}

const initialState: State = {
  name: '',
  storeUpdatedAt: undefined,
}

export default function challengeReducer(
  state = SeamlessImmutable(initialState),
  action: ReduxAction,
): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET: {
      const item: IChallenge = action.payload
      return state.merge({
        ...item,
        storeUpdatedAt: Date.now(),
      })
    }

    default:
      return state
  }
}
