import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Employee } from '@reaction-club-types/core'
import _ from 'lodash'
import React, { useMemo } from 'react'
import EmployeeItem from './EmployeeItem'

const useStyles = makeStyles({
  employeeList: {
    padding: '10px 0',
    overflowY: 'auto',
    maxHeight: 396,
    '&::-webkit-scrollbar-track': {
      background: 'red',
    },
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
})

interface Props {
  employees: Employee[]
  subText?: string
  onSelect: (user_id: string) => any
}

function EmployeeList(props: Props) {
  const { subText, onSelect } = props
  const classes = useStyles()
  let { employees } = props

  const listOrdered = useMemo(() => {
    return _.orderBy(employees, 'points', 'desc')
  }, [employees])

  return (
    <Grid className={classes.employeeList} container>
      {listOrdered.map((employee) => (
        <EmployeeItem
          employee={employee}
          subText={subText}
          onSelect={onSelect}
          key={`group-list-employee-${employee.user_id}`}
        />
      ))}
    </Grid>
  )
}

export default EmployeeList
