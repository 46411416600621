import { DispatcherResponse } from '../../typings/ReduxDispatch'
import playbookActions from '../playbooks/actions'
import playbookApi from '../playbooks/api'
import actions from './actions'
import './reducer'

export default {
  ...actions,
  getPlaybooks(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { category, name, type_ids } = getState().changeProgram
      const playbooks = await playbookApi.getPlaybooks({
        category: category?.toLocaleLowerCase() || '',
        name,
        offset: 0,
        type_ids,
      })
      dispatch(playbookActions.setPlaybooks(playbooks || []))
      dispatch(actions.setPlaybooks(playbooks || []))
    }
  },
  getPlaybooksNext(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { category, name, type_ids, playbooks: fetchedPlaybooks } = getState().changeProgram
      const playbooks = await playbookApi.getPlaybooks({
        category: category?.toLocaleLowerCase() || '',
        name,
        offset: fetchedPlaybooks.length,
        type_ids,
      })
      dispatch(playbookActions.setPlaybooks(playbooks))
      dispatch(actions.setNext(playbooks))
    }
  },
}
