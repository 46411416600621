/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { Employee, ReactionEvent } from '@reaction-club-types/core'
import apiEmployees from '@reducers/employees/apiEmployees'
import React, { useEffect, useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import EventModalStyled from './elements/EventModalStyled'
import EmployeeList from './user/EmployeeList'

interface Props {
  isVisible: boolean
  event: ReactionEvent
  onClose(): any
  onUserSelect: (user_id: string) => any
  exclude?: { user_id: string }[]
}

function EventUserAddModal(props: Props) {
  const { isVisible, onClose, onUserSelect, exclude, event } = props
  const { name } = event

  const [searchStr, setSearchStr] = useState('')
  const [searchApplied, setSearchApplied] = useState('')
  const [employees, setList] = useState<Employee[]>([])
  // const dispatch = useDispatch<ReduxDispatch>()
  // const { employees: list } = useSelector((state: ReduxState) => ({
  //   employees: state.employees.list,
  // }))

  const refreshFilterAction = useAsyncHandler(async () => {
    const isFilterNew = searchApplied !== searchStr

    const name = searchStr || ''
    const data = await apiEmployees.getList({
      name,
      offset: 0,
    })
    setSearchApplied(name)
    setList(data.employees)
  })

  useEffect(() => {
    if (!isVisible) return
    if (refreshFilterAction.isLoading) return
    // if (refreshFilterAction.isDone) return
    refreshFilterAction.execute()
  }, [searchStr, refreshFilterAction.isLoading, isVisible])

  return (
    <EventModalStyled isVisible={isVisible} onClose={onClose}>
      <div>
        <Typography
          variant={'h2'}
          css={css`
            margin-bottom: 15px;
          `}
        >
          Register members to {name}
        </Typography>
        <Box marginBottom={'10px'} paddingRight={'20px'}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <TextField
              placeholder={'Search...'}
              fullWidth
              onChange={(e) => {
                setSearchStr(e.target.value)
                refreshFilterAction.reset()
              }}
            />

            {/* {refreshFilterAction.isLoading ? ( */}
            {/*  <Grid container justifyContent={'center'}> */}
            {/*    <Loader /> */}
            {/*  </Grid> */}
            {/* ) : null} */}
          </Grid>
        </Box>
        <div
          css={css`
            min-height: 200px;
            max-height: 200px;
            overflow-y: scroll;
          `}
        >
          <EmployeeList list={employees} exclude={exclude} onSelect={onUserSelect} nameFilter={searchStr} />
        </div>
      </div>
    </EventModalStyled>
  )
}

export default EventUserAddModal
