import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import StockDispatcher, { ApiPayloadStockCreate } from '@reducers/stocks/dispatcher'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import StockForm from './stockCreate/StockForm'

const useStyles = makeStyles(
  {
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: 32,
    },
  },
  {
    classNamePrefix: 'StockCreate',
  },
)

interface Props extends RouteComponentProps {}
interface PropsInner extends Props {}

const StockCreate = (props: PropsInner) => {
  const { history } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleCreate = async (form: ApiPayloadStockCreate) => {
    await dispatch(StockDispatcher.createStock(form))
    history.push('/stocks')
  }

  return (
    <PaperContainer>
      <Typography className={classes.title}>Create New Reward</Typography>
      <Grid item lg={6} md={8} sm={12}>
        <StockForm onExecute={handleCreate} />
      </Grid>
    </PaperContainer>
  )
}

export default StockCreate
