/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Avatar, Grid } from '@mui/material'
import { ReduxState } from '@reducers/index'
import GroupDispatcher from '@reducers/playbookInstances/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import LinkOpenPage from '../../../../components/LinkOpenPage'
import Loader from '../../../../components/Loader'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  group_id: string
}

function GroupReference(props: Props) {
  const { group_id } = props

  const dispatch = useDispatch<ReduxDispatch>()
  const { group } = useSelector((state: ReduxState) => ({ group: state.playbookInstances[group_id] }))
  const groupFetch = useAsyncFetch(async () => dispatch(GroupDispatcher.get(group_id, { isForce: false })))

  return (
    <div>
      <RendererStatusSplit
        statuses={groupFetch}
        isEmpty={!group}
        renderLoading={() => <Loader />}
        render={() => {
          const { name, thumb } = group || {}
          return (
            <Grid container alignItems={'center'}>
              <Avatar src={thumb} />
              <span
                css={css`
                  font-size: 16px;
                  display: inline-block;
                  margin-left: 7px;
                `}
              >
                {name}
              </span>
              <LinkOpenPage to={`/group/${group_id}`} />
            </Grid>
          )
        }}
      />
    </div>
  )
}
export default GroupReference
