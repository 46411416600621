import apiClient from '../../libs/api/libs/apiClient'

const apiDashboard = {
  async getUserDailyLeaderBoard({ offset }: { offset: number }) {
    const { data } = await apiClient.get('/dashboard/users-steps-avg', { params: { offset } })
    return data
  },
  async getMyCompanyStepsRanking() {
    const { data } = await apiClient.get('/dashboard/my-company-steps-ranking')
    return data
  },
  async getCompaniesRanking({ offset }: { offset: number }) {
    const { data } = await apiClient.get('/dashboard/companies-steps-rankings', { params: { offset } })
    return data
  },
  async joinCompanyRanking(body: { is_public: boolean }) {
    const { data } = await apiClient.post('/dashboard/companies-steps-rankings', body)
    return data
  },
  async getCompanyStepsAvg() {
    const { data } = await apiClient.get('/dashboard/company-steps-avg')
    return data
  },
  async getStepsPath() {
    const { data } = await apiClient.get('/dashboard/steps-path')
    return data
  },
  async resetStepsPath() {
    const { data } = await apiClient.post('/dashboard/steps-path/reset')
    return data
  },
}

export default apiDashboard
