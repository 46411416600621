import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CompanyOffice } from '@reaction-club-types/core'
import OfficeDispatcher from '@reducers/offices/dispatcher'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  office: CompanyOffice
  onDelete: () => any
}

function OfficeHeadActions(props: Props) {
  const { office, onDelete } = props
  const { id } = office
  const dispatch = useDispatch<ReduxDispatch>()
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [isEditModalVisible, setEditModalVisibility] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleDelete = async () => {
    const isConfirmed = confirm('Are you sure want to delete this office?')
    if (!isConfirmed) return null
    await dispatch(OfficeDispatcher.deleteOffice(id))
    onDelete()
    handleCloseMenu()
  }

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  const handleUpdateModalOpen = () => {
    setEditModalVisibility(true)
    handleCloseMenu()
  }
  const handleUpdateModalClose = () => setEditModalVisibility(false)

  return (
    <div>
      {/*<EventUpdateModal event={event} isVisible={isEditModalVisible} onClose={handleUpdateModalClose} />*/}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isMenuVisible}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {/* <MenuItem onClick={handleUpdateModalOpen}> */}
        {/*  <ListItemIcon> */}
        {/*    <EditIcon color={'primary'} fontSize={'small'} /> */}
        {/*  </ListItemIcon> */}
        {/*  <Typography variant="inherit">Edit Event</Typography> */}
        {/* </MenuItem> */}

        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit">Delete Office</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default OfficeHeadActions
