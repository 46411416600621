/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid, Typography } from '@mui/material'
import { CompanyOffice } from '@reaction-club-types/core'
import React from 'react'
import { Link } from 'react-router-dom'
import CompanyLogo from '../../../../../components/CompanyLogo'

const cssLinkOpen = css`
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
`

interface Props {
  office: CompanyOffice
}
function CompanyOfficeItem(props: Props) {
  const { office } = props
  const { id, name, address, asset } = office

  const logo = asset?.url

  return (
    <Grid item sm={6} xs={12} md={4} lg={3}>
      <Box marginLeft={5} marginRight={5} marginBottom={'10px'}>
        <Link to={`/office/${id}`}>
          <Grid
            container
            alignItems={'center'}
            direction={'column'}
            css={css`
              position: relative;
              text-decoration: none;

              &:hover .css-${cssLinkOpen.name} {
                display: block;
              }
            `}
          >
            <CompanyLogo url={logo} />
            <Typography>{name}</Typography>
            <Typography
              variant={'subtitle1'}
              css={css({
                fontSize: 12,
                lineHeight: '14px',
                color: '#979797',
              })}
              align={'center'}
            >
              {address || '-'}
            </Typography>

            <div css={cssLinkOpen}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </div>
          </Grid>
        </Link>
      </Box>
    </Grid>
  )
}

export default CompanyOfficeItem
