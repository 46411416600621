import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    DELETE: '',
  },
  { prefix: 'TEAMS__' },
)

export interface ICompanyTeam {
  name: string
  description?: string
}