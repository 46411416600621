/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
  className?: string
}

function SectionContainer(props: Props) {
  const { children, className } = props

  return (
    <div
      css={css`
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        padding: 10px;
        height: 100%;
      `}
      className={className}
    >
      {children}
    </div>
  )
}

export default SectionContainer
