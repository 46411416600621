import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  display: flex;
  height: 53px;
  align-items: center;
  justify-content: space-between;
  background: rgba(217, 217, 217, 0.25);
  padding: 0 65px 0 24px;

  span {
    font-weight: 800;
  }

  margin-bottom: 30px;
  background: #F9B22D33;
`

const Group = styled.section`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`

const GroupItem = styled(Item)`
  background: #D9D9D9;
  margin: 0;

  &:nth-child(2) {
    background: rgba(217, 217, 217, 0.1)
  }
`

const Header = styled.h2`
  font-weight: 800;
  font-size: 16px;
  font-family: Poppins;
  margin-bottom: 10px;
`

const arr = (new Array(3)).fill(0)

const BalanceTab = () => {

  return (
    <Container>
      <Item>
        <span>Available Budget</span>
        <span>$28</span>
      </Item>
      <Header>Budget added</Header>
      <Group>
        {arr.map(() => (
          <GroupItem>
            <span>May 22nd email@email.com</span>
            <span>$500</span>
          </GroupItem>
        ))}
      </Group>
      <Header>Rewards claimed</Header>
      <Group>
        {arr.map(() => (
          <GroupItem>
            <span>May 22nd email@email.com</span>
            <span>$500</span>
          </GroupItem>
        ))}
      </Group>
    </Container>
  )
}

export default BalanceTab