/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxStateGroupsItem } from '@reducers/playbookInstances/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../components/Loader'
import useModalState from '../../hooks/useModalState'
import InviteEmployeesToGroupModal from '../../modals/invites/InviteEmployeesToGroupModal'
import PlaybookInstanceDispatcher from '../../reducers/playbookInstances/dispatcher'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import inviteMembersIcon from './assets/inviteMembersIcon.svg'
import Employees from './employees/Employees'

interface Props {
  group: ReduxStateGroupsItem
}

function GroupEmployees(props: Props) {
  const { group } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const inviteModalState = useModalState(false)
  const { id, employees } = group

  const fetchEmployees = useAsyncFetch(async () => {
    await dispatch(PlaybookInstanceDispatcher.getInstanceEmployees(group.id))
  }, [group.id])

  return (
    <React.Fragment>
      <InviteEmployeesToGroupModal {...inviteModalState} group_id={id} />
      <RendererStatusSplit
        statuses={fetchEmployees}
        isEmpty={_.isEmpty(employees)}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderEmpty={() => (
          <Grid
            container
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            css={css({
              height: '100%',
              border: '1px solid #D4D4D4',
              cursor: 'pointer',
              outline: 'none',
              borderRadius: 5,
            })}
            onClick={inviteModalState.onOpen}
          >
            <Grid style={{ textAlign: 'center' }}>
              <img src={inviteMembersIcon} alt="" />
              <Typography
                css={css({
                  marginTop: 15,
                  fontSize: 18,
                  fontWeight: 800,
                })}
              >
                Invite Members
              </Typography>
            </Grid>
          </Grid>
        )}
        render={() => {
          return (
            <Employees
              handleClickOnInviteMember={inviteModalState.onOpen}
              employees={employees}
              instance_id={group.id}
            />
          )
        }}
      />
    </React.Fragment>
  )
}

export default GroupEmployees
