/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import _ from 'lodash'
import React from 'react'
import Select, { Props as SelectProps } from 'react-select'
import downChevron from '../../../../../modals/assets/downChevron.png'

interface Props {
  value: string | undefined
  options: SelectProps['options']
  isErrored?: boolean
  placeholder?: string
  onChange(value: string | undefined): any
  components?: SelectProps['components']
  isOpened?: boolean
}

export default function EventFormSelect(props: Props) {
  const { value, onChange, isErrored, placeholder = '', components, options = [], isOpened } = props

  const handleChange: SelectProps['onChange'] = (item) => {
    onChange(item?.value as string)
  }

  const itemSelected = _.find(options, (item) => item.value === value)

  let controlStyle: { borderColor?: string } = {}
  if (isErrored) {
    controlStyle.borderColor = 'red'
  }

  return (
    <Select
      value={itemSelected}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      menuIsOpen={isOpened}
      styles={{
        control: (base) => ({
          ...base,
          padding: '5px',
          borderRadius: '10px',
          ...controlStyle,
        }),
        input: (base) => ({
          ...base,
          fontWeight: 'bold',
          fontFamily: 'Poppins',
          fontSize: '16px',
        }),
        menu: (base) => ({ ...base, zIndex: 666 }),
        singleValue: (base) => ({ ...base, overflow: 'visible', fontFamily: 'Poppins' }),
      }}
      components={{
        DropdownIndicator: () => (
          <img
            src={downChevron}
            alt={'down'}
            css={css`
              width: 25px;
              height: 25px;
            `}
          />
        ),
        IndicatorSeparator: () => null,
        ...components,
      }}
    />
  )
}
