import { IChallenge, IChallengeApp } from '@reducers/challenge/types'
import apiClient from '../../libs/api/libs/apiClient'

const apiChallenge = {
  async getChallenge(id: string): Promise<IChallenge> {
    const { data } = await apiClient.get<IChallenge>(`/challenges/v2/challenge/${id}`)
    return data
  },
  async getChallengeApplicationById(id: string): Promise<IChallengeApp> {
    const { data } = await apiClient.get<IChallengeApp>(`/challenges/v2/challenge-app/${id}`)
    return data
  },
  async getChallengesGlobalRank(type: string): Promise<any> {
    const { data } = await apiClient.get<any>(`/challenges/v2/global-rank/${type}`)
    return data
  },
  async clearHistory(id: string): Promise<any> {
    const { data } = await apiClient.delete<any>(`/challenges/v2/challenge-history/${id}`)
    return data
  },

  async updateChallenge(appId, params: IChallenge): Promise<IChallenge> {
    const { data } = await apiClient.post<IChallenge>(`/challenges/v2/settings/save/${appId}`, params)
    return data
  },

  async deleteChallenge(id: string): Promise<void> {
    await apiClient.post<IChallenge>(`/challenges/v2/disable-challenge/${id}`)
  },

  async terminateChallenge(id: string): Promise<void> {
    await apiClient.delete<IChallenge>(`/challenges/v2/terminate/${id}`)
  },

  async getChallengeGoals(id: string): Promise<any> {
    const { data } = await apiClient.get<any>(`/challenge-goals/${id}`)
    return data
  },

  async setChallengeGoals(id: string, data): Promise<void> {
    await apiClient.post<any>(`/challenge-goals/${id}`, data)
  },

  async getChallengeGoalSettings(id: string): Promise<any> {
    const { data } = await apiClient.get<any>(`/challenge-goal-settings/${id}`)
    return data
  },

  async setChallengeGoalSettings(id: string, data): Promise<void> {
    await apiClient.post<any>(`/challenge-goal-settings/${id}`, data)
  },

  async generateLandmarks(params): Promise<any> {
    return apiClient.get<any>(`/suggest-landmarks`, {
      params,
    })
  },
}

export default apiChallenge
