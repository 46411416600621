import { Grid, Typography, Checkbox } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'
import { ProgramMode } from '../../components/modals/components/ChooseTrainingMode'

const useStyles = makeStyles({
  item: {
    borderRadius: 5,
    padding: '20px',
    boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.15)',
    marginBottom: 20,
    width: '100%',
  },
  modeName: {
    fontSize: 16,
    fontWeight: 600,
  },
  modeSubText: {
    fontSize: 14,
    fontWeight: 200,
  },
})

export interface Props {
  mode: ProgramMode
  title: string
  description: string
  isChecked: boolean
  onCheck(mode: ProgramMode): any
  children?: ReactNode
}

function ModeItem(props: Props) {
  const { isChecked, onCheck, mode, title, description, children } = props

  const classes = useStyles()

  const handleClick = () => {
    onCheck(mode)
  }

  return (
    <Grid className={classes.item} item xs={12}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Checkbox checked={isChecked} onChange={handleClick} />
        <Grid item style={{ marginLeft: 30, width: '80%' }}>
          <Typography className={classes.modeName}>{title}</Typography>
          <Typography className={classes.modeSubText}>{description}</Typography>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ModeItem
