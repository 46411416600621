import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReduxState } from '@reducers/index'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import GroupCoachesList from './groupCoaches/GroupCoachesList'
import PrimaryCoach from './groupCoaches/PrimaryCoach'

const styles = {
  caption: {
    fontSize: 24,
    fontWeight: 800,
    marginBottom: 50,
    width: '100%',
  },
}

class GroupCoaches extends React.PureComponent<
  RouteComponentProps<{ instance_id: string }> & ExtractConnectType<typeof storeConnect> & WithStyles<typeof styles>
> {
  state = {
    isLoading: false,
  }

  render() {
    const { instance, classes } = this.props
    const { isLoading } = this.state
    if (isLoading || !instance) return <p>loading</p>
    return (
      <Grid container>
        <Typography className={classes.caption}>Manage Group Coach</Typography>
        <Grid item xs={7}>
          <PrimaryCoach instance={instance} />
          <GroupCoachesList
            instance={instance}
            handleMakePrimary={this.handleMakePrimary}
            handleCoachKick={this.handleCoachKick}
            handleChangeRole={this.handleChangeRole}
          />
        </Grid>
      </Grid>
    )
  }

  handleMakePrimary = (coach_id: string) => {
    this.props.makeCoachPrimary(coach_id)
  }

  handleCoachKick = (coach_id: string) => {
    this.props.kickCoach(coach_id)
  }

  handleChangeRole = (coach_id: string, role: string) => {
    this.props.changeCoachRole(coach_id, role)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })
    const { instance } = this.props
    try {
      if (!instance) {
        await this.props.getInstance()
        await this.props.getInstanceCoaches()
      } else if (!instance.coaches) {
        await this.props.getInstanceCoaches()
      }
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

export const GROUP_COACHES_URL = (instance_id: string): string => `/groups/${instance_id}/coaches`

const storeConnect = connect(
  (state: ReduxState, { match }: RouteComponentProps<{ instance_id: string }>) => ({
    instance: state.playbookInstances[match.params.instance_id],
  }),
  (dispatch: ReduxDispatch, { match }: RouteComponentProps<{ instance_id: string }>) => ({
    getInstance: () => dispatch(PlaybookInstancesDispatcher.get(match.params.instance_id)),
    getInstanceCoaches: () => dispatch(PlaybookInstancesDispatcher.getInstanceCoaches(match.params.instance_id)),
    makeCoachPrimary: (coach_id: string) =>
      dispatch(PlaybookInstancesDispatcher.makeCoachPrimary(match.params.instance_id, coach_id)),
    kickCoach: (coach_id: string) =>
      dispatch(PlaybookInstancesDispatcher.kickCoach(match.params.instance_id, coach_id)),
    changeCoachRole: (coach_id: string, role: string) =>
      dispatch(PlaybookInstancesDispatcher.changeCoachRole({ instance_id: match.params.instance_id, coach_id, role })),
  }),
)

export default compose(withRouter, withStyles(styles), storeConnect)(GroupCoaches)
