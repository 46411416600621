import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { WithStyles, withStyles, createStyles } from '@mui/styles'
import coachesDispatcher from '@reducers/coaches/dispatcher'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import PlaybookInstanceDispatcher from '@reducers/playbookInstances/dispatcher'
import CN from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CoachWrapper from '../components/CoachWrapper'

const CooseGroup = ({ classes, showModal, offerGroupId, history, getPlaybookInstances, playbookInstances }) => {
  const [type, setType] = React.useState('group')
  const [activeGroupId, setActiveGroupId] = React.useState(null)

  const groups = Object.values(playbookInstances)

  const handleSubmit = () => {
    if (type === 'group' && activeGroupId) {
      showModal({
        modalType: MODAL_TYPES.INVITE_GROUP_COACH,
        modalProps: { instance: playbookInstances[activeGroupId] },
      })
    }
  }

  React.useEffect(() => {
    if (offerGroupId) {
      history.replace('/coaches/invite/internal/submit-champion-request')
    }
    getPlaybookInstances()
  }, [])

  React.useEffect(() => {
    if (type === 'group' && !activeGroupId && groups.length) {
      setActiveGroupId(groups[0].id)
    }
  }, [playbookInstances])

  return (
    <CoachWrapper title="Choose group" titleWidth={178}>
      <Grid container direction="column" justifyContent="space-between">
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.content} justifyContent="center" alignItems="end" direction="column">
            {/* <FormControl component="fieldset"> */}
            {/*  <Typography className={classes.title}>I want to:</Typography> */}
            {/*  <RadioGroup aria-label="type" name="type" value={type} onChange={(e, opt) => setType(opt)}> */}
            {/*    <FormControlLabel value="group" control={<Radio />} label="Find a champions for a specific group:" /> */}
            {/*    {type === 'group' && groups.length > 0 && ( */}
            <Box style={{ marginBottom: 40 }}>
              {groups.map(({ id, name }) => {
                return (
                  <Button
                    className={CN(classes.groupBtn, { [classes.activeGroupBtn]: id === activeGroupId })}
                    onClick={() => setActiveGroupId(id)}
                  >
                    {name}
                  </Button>
                )
              })}
            </Box>
            {/*    )} */}
            {/*    <FormControlLabel */}
            {/*      value="company" */}
            {/*      control={<Radio />} */}
            {/*      label="Invite employees to run their own wellness groups" */}
            {/*    /> */}
            {/*  </RadioGroup> */}
            {/* </FormControl> */}
          </Grid>
        </Box>
        <Box className={classes.btnWrapper}>
          <Button className={classes.btn} onClick={handleSubmit}>
            Send Invite
          </Button>
        </Box>
      </Grid>
    </CoachWrapper>
  )
}

const mapStateToProps = state => ({
  offerGroupId: state.coaches.offer.groupId,
  playbookInstances: state.playbookInstances,
})
const mapDispatchToProps = dispatch => ({
  showModal: param => dispatch(modalActions.showModal(param)),
  getPlaybookInstances: () => dispatch(PlaybookInstanceDispatcher.getPlaybookInstances()),
})
const connectStore = connect(mapStateToProps, mapDispatchToProps)

const styles = createStyles({
  contentWrapper: {
    padding: '102px 102px 0 102px',
  },
  content: {
    lineHeight: '23px',
    color: '#231F20',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 28,
    fontSize: '17px',
  },
  groupBtn: {
    backgroundColor: '#F4F4F4',
    padding: '17px',
    minWidth: 140,
    minHeight: 60,
    borderRadius: '8px',
    fontWeight: 'bold',
    marginBottom: 12,
    '&:not(:last-child)': {
      marginRight: 12,
    },
    '&:hover': {
      backgroundColor: '#F9B22D',
    },
  },
  activeGroupBtn: {
    backgroundColor: '#F9B22D',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
  btnWrapper: {
    margin: '0 50px 50px auto',
  },
  btn: {
    backgroundColor: '#F9B22D',
    padding: '15px',
    minWidth: 145,
    borderRadius: '25px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffbe49',
    },
  },
})

export default compose(connectStore, withStyles(styles))(CooseGroup)
