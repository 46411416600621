import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { ReactionEvent } from '@reaction-club-types/core'
import React, { useState } from 'react'
import useModalState from '../../../../hooks/useModalState'
import EventUpdateModal from '../../../../modals/EventUpdateModal'
import EventDeleteModal from '../../modals/EventDeleteModal'

interface Props {
  event: ReactionEvent
  onDelete: () => any
}

function EventInfoHeadActions(props: Props) {
  const { event, onDelete } = props
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [isEditModalVisible, setEditModalVisibility] = useState(false)
  const eventDeleteModal = useModalState()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  const handleUpdateModalOpen = () => {
    setEditModalVisibility(true)
    handleCloseMenu()
  }
  const handleDeleteModalOpen = () => {
    eventDeleteModal.onOpen()
    handleCloseMenu()
  }

  const handleUpdateModalClose = () => setEditModalVisibility(false)

  return (
    <div>
      <EventUpdateModal event={event} isVisible={isEditModalVisible} onClose={handleUpdateModalClose} />
      <EventDeleteModal event={event} {...eventDeleteModal} onDelete={onDelete} />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isMenuVisible}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={handleUpdateModalOpen}>
          <ListItemIcon>
            <EditIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit">Edit Event</Typography>
        </MenuItem>

        <MenuItem onClick={handleDeleteModalOpen}>
          <ListItemIcon>
            <DeleteIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit">Delete Event</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default EventInfoHeadActions
