import { Grid, Modal, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PlaybookInstanceDispatcher from '@reducers/playbookInstances/dispatcher'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonOrange from '../components/ButtonOrange'
import ModeItem from './progrmMode/ModeItem'
import ModeItemAuto from './progrmMode/ModeItemAuto'

export type ProgramMode = 'manual' | 'auto' | 'self'

const useStyles = makeStyles({
  container: {
    maxWidth: 384,
  },
  caption: {
    fontSize: 18,
    fontWeight: 800,
    marginBottom: 40,
  },
  button: {
    height: 48,
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: 30,
    position: 'relative',
    minWidth: 290,
  },
})

interface Props {
  instance: PlaybookInstance
  isVisible: boolean
  onClose(): any
}

function ProgramModeSelectModal(props: Props) {
  const { instance, isVisible, onClose } = props
  let actualMode: ProgramMode = 'manual'
  if (instance.is_self_driven) actualMode = 'self'
  else if (instance.program_id_auto) actualMode = 'auto'

  const dispatch = useDispatch()
  const [mode, setMode] = useState<ProgramMode>(actualMode)
  const [program_id, setProgram] = useState(instance.program_id_auto)

  const momentProgram = instance.program_start_at ? moment(instance.program_start_at) : moment()
  const [program_time, setTime] = useState(momentProgram.format('yyyy-MM-DDTHH:mm'))

  const classes = useStyles()
  if (!isVisible) return null // prevent fetching extra data

  const isButtonDisabled = mode === 'auto' && !program_id
  const isManualModeEnabled = !!instance?.coaches?.length

  const handleSaveClick = async () => {
    if (mode === 'auto' && !program_id) return null
    await dispatch<any>(
      PlaybookInstanceDispatcher.updateMode({
        instance_id: instance.id,
        mode,
        program_id,
        time: program_time,
      }),
    )

    onClose()
  }

  return (
    <Modal open={isVisible} onClose={onClose} className={classes.modal}>
      <Paper className={classes.paper}>
        <Grid container className={classes.container}>
          <Typography className={classes.caption}>Program Modes</Typography>
          <>
            {isManualModeEnabled && (
              <ModeItem
                mode={'manual'}
                title={'Manual'}
                description={'Coach decide the plan for each user'}
                isChecked={mode === 'manual'}
                onCheck={setMode}
              />
            )}

            <ModeItem
              mode={'self'}
              title={'User Choice'}
              description={'Each user decides on their own plan.'}
              isChecked={mode === 'self'}
              onCheck={setMode}
            />

            <ModeItemAuto
              isChecked={mode === 'auto'}
              onCheck={setMode}
              playbook_id={instance.playbook_id}
              time={program_time}
              onTimeChange={setTime}
              program_id={program_id}
              onProgramChange={setProgram}
            />

            <ButtonOrange
              className={classes.button}
              size={'large'}
              disabled={isButtonDisabled}
              onClick={handleSaveClick}
            >
              Save
            </ButtonOrange>
          </>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default ProgramModeSelectModal
