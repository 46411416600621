import { Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { withStyles, WithStyles } from '@mui/styles'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactionAvatar from '../../../components/assets/ReactionAvatar'

interface Props {
  coach: Coach
  handleMakePrimary(coach_id: string): any
  handleCoachKick(coach_id: string): any
  handleChangeRole(coach_id: string, role: string): any
}

const styles = {
  listItem: {
    background: '#F4F4F4',
    padding: '15px 20px',
    borderRadius: 5,
    marginBottom: 5,
  },
  menuItem: {
    color: '#fff',
    fontSize: 14,
    margin: '5px 0',
    '&:hover': {
      color: '#F89A00',
    },
  },
  red: {
    color: 'red',
    '&:hover': {
      color: 'red',
    },
  },
  role: {
    fontSize: 14,
    color: '#F9B22D',
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
}

function GroupCoachItem(props: Props & WithStyles<typeof styles>) {
  const { classes, coach, createMockDirectRoom } = props

  let [buttonRef, setButtonRef] = useState()
  let [isMenuVisible, setIsMenuVisible] = useState(false)

  const handlePrimary = (coach_id: string) => async () => {
    await props.handleMakePrimary(coach_id)
    setIsMenuVisible(false)
  }

  const handleKick = (coach_id: string) => async () => {
    await props.handleCoachKick(coach_id)
    setIsMenuVisible(false)
  }

  const changeRole = (coach_id: string, role: string) => async () => {
    await props.handleChangeRole(coach_id, role)
    setIsMenuVisible(false)
  }

  return (
    <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={classes.listItem}>
      <Grid item>
        <Grid container alignItems={'center'} direction={'row'}>
          <Box
            onClick={() => createMockDirectRoom('mock-room-id', coach.chat_user_id)}
            style={{ width: 'fit-content' }}
          >
            <Link to={`/chats/direct/mock-room-id`} style={{ textDecoration: 'none' }}>
              <ReactionAvatar asset={{ url: coach.avatar_url }} style={{ width: 40, height: 40 }} />
            </Link>
          </Box>
          <Typography style={{ marginLeft: 15 }}>{coach.name}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={'row'} alignItems={'center'}>
          <Typography className={classes.role}>{coach.group_role === 'admin' ? 'Admin' : 'Coach'}</Typography>
          <button type="button" className={classes.button}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => setIsMenuVisible(true)}
              disableFocusRipple
              style={{ padding: 0 }}
              ref={ref => setButtonRef(ref)}
              size="large">
              <MoreVertIcon htmlColor={'#AEAEAE'} />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={buttonRef}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={isMenuVisible}
              onClose={() => setIsMenuVisible(false)}
              PaperProps={{
                style: {
                  width: 200,
                  backgroundColor: '#2D2D2D',
                },
              }}
            >
              <MenuItem>
                <Typography variant="inherit" className={classes.menuItem} onClick={handlePrimary(coach.user_id)}>
                  Make Primary
                </Typography>
              </MenuItem>
              <MenuItem onClick={changeRole(coach.user_id, coach.group_role === 'admin' ? 'default' : 'admin')}>
                <Typography variant="inherit" className={classes.menuItem}>
                  {coach.group_role === 'admin' ? 'Make Default' : 'Make Admin'}
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography
                  variant="inherit"
                  className={[classes.menuItem, classes.red].join(' ')}
                  onClick={handleKick(coach.user_id)}
                >
                  Kick Coach
                </Typography>
              </MenuItem>
            </Menu>
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({
  createMockDirectRoom: (roomId, userId) => dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
})
const connectStore = connect(null, mapDispatchToProps)
export default connectStore(withStyles(styles)(GroupCoachItem))
