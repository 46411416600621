import { Radio, FormControl, FormControlLabel, Grid, RadioGroup, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/company/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Help from '../../../components/Help'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import SectionContainer from '../components/SectionContainer'
import CompanyEmails from './components/CompanyEmails'
import IdentifiersUpload from './verification/IdentifiersUpload'

const connectStyles = withStyles({
  stickerContainer: {
    minHeight: 250,
  },
  stickerCaption: {
    fontSize: 18,
    fontWeight: 800,
  },
  subText: {
    fontSize: 10,
    fontWeight: 200,
  },

  radioNoteContainer: {
    paddingLeft: 42,
    marginTop: -10,
    marginBottom: 10,
  },

  radioNoteText: {
    fontSize: 12,
    color: '#a5a5a5',
  },
})

interface Props {}
interface PropsInner extends ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}
interface State {
  auth_type: null | string
}

class SettingVerification extends React.PureComponent<PropsInner, State> {
  state: State = {
    auth_type: null,
  }

  constructor(props) {
    super(props)

    const auth_type = props.company.config?.auth_type || null
    this.state.auth_type = auth_type
  }

  render() {
    const { classes } = this.props
    const { auth_type } = this.state

    return (
      <SectionContainer>
        <Grid container direction={'column'} justifyContent={'space-between'} className={classes.stickerContainer}>
          <Grid item>
            <Typography className={classes.stickerCaption}>Employee Verification</Typography>
            <Typography className={classes.subText}>Users must verify that they are from company</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup aria-label="gender" name="gender1" value={auth_type || ''} onChange={this.handleChange}>
                <FormControlLabel value="" control={<Radio />} label="Disabled" />
                <div className={classes.radioNoteContainer}>
                  <span className={classes.radioNoteText}>Any user with invitation can access company.</span>
                </div>

                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label={
                    <>
                      Corporate Email
                      <Help
                        title="Corporate email verification"
                        subtitle="Two-step verification with work emails"
                        text="By selecting this option, employees will be required to verify their work email address in order to access the system. Only email domains that you have specified will be allowed. Once an employee enters their work email, they will receive a verification code that they need to enter to access the app. It is important to note that Reaction does not store any data related to employees' work email addresses used in this verification process."
                      />
                    </>
                  }
                />
                <div className={classes.radioNoteContainer}>
                  <span className={classes.radioNoteText}>
                    User must verify corporate email. Reaction Club doesn't store corporate user emails.
                  </span>
                </div>

                {auth_type === 'email' ? <CompanyEmails /> : null}

                <FormControlLabel value="number" control={<Radio />} label="Employee number" />
                <div className={classes.radioNoteContainer}>
                  <span className={classes.radioNoteText}>
                    User must input employee number to access company. Only one number available per account.
                  </span>
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
          <IdentifiersUpload isVisible={auth_type === 'number'} />
        </Grid>
      </SectionContainer>
    )
  }

  handleChange = (ev, type: string) => {
    this.setState({
      auth_type: type,
    })
    this.props.setAuthType(type)
  }

  componentDidUpdate(prevProps: Readonly<PropsInner>) {
    const prevAuthType = prevProps.company.config?.auth_type
    const currAuthType = this.props.company.config?.auth_type

    if (prevAuthType !== currAuthType) {
      this.setState({
        auth_type: currAuthType,
      })
    }
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    // me: state.me,
    company: state.company,
  }),
  (dispatch: ReduxDispatch) => ({
    setAuthType: (type) => dispatch(CompanyDispatcher.setAuthType(type)),
  }),
)

export default compose<PropsInner, Props>(connectStyles, connectStore)(SettingVerification)
