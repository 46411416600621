/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxStateReactionEventItem } from '@reducers/events/reducer'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useClipboard } from 'use-clipboard-copy'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import EventModalStyled from './elements/EventModalStyled'
import EventModalText from './elements/EventModalText'
import EventModalTitle from './elements/EventModalTitle'

interface Props {
  event: ReduxStateReactionEventItem
  isVisible: boolean
  onClose(): any
}

function EventHostLinkCreateModal(props: Props) {
  const { isVisible, event } = props
  const { time, zak_token, url } = event
  const clipboard = useClipboard()

  const dispatch = useDispatch<ReduxDispatch>()
  const isTimeOk = useMemo(() => {
    if (!isVisible) return null
    const timeMoment = moment(time)
    return moment().isAfter(timeMoment.clone().add(-30, 'minutes'))
  }, [isVisible])

  const hostLink = useMemo(() => {
    if (!zak_token) return null
    return `${url}?zak=${zak_token}`
  }, [url, zak_token])

  const linkFetch = useAsyncFetch(async () => {
    if (!isTimeOk) return null
    await dispatch(EventDispatcher.createZakToken(event.id))
  }, [isTimeOk])

  useEffect(() => {
    if (!hostLink) return
    clipboard.copy(hostLink)
  }, [hostLink, linkFetch])

  return (
    <EventModalStyled {...props}>
      {isTimeOk ? (
        <RendererStatusSplit
          statuses={linkFetch}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          render={() => (
            <div>
              <EventModalTitle>Host link is copied</EventModalTitle>

              <EventModalText>
                The link is valid for 2 hours before the session starts. If it’s not working use ‘claim host’ and type:
                1111
              </EventModalText>
            </div>
          )}
        />
      ) : (
        <div>
          <EventModalTitle>Host link can be activated only 30 minutes before the event start time</EventModalTitle>
          <EventModalText>You can use or copy the host link only 30 minutes before the event start time</EventModalText>
        </div>
      )}
    </EventModalStyled>
  )
}

export default EventHostLinkCreateModal
