import { Grid, Menu, MenuItem, Radio, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import _ from 'lodash'
import React from 'react'
import { downIcon } from '../../pages/reports/assets/facade'

const styles = {
  filterButton: {
    marginRight: 5,
    fontWeight: 600,
  },
  menuItem: {
    color: '#323232',
    fontSize: 14,
    fontWeight: 600,
    // margin: '5px 0',
    paddingLeft: 8,
    '&:hover': {
      color: '#F89A00',
      fontWeight: 600,
    },
  },
  menuItemWrapper: {
    marginBottom: 11,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}

interface SelectFilter {
  value: string
  name: string
}

interface Props {
  filters: SelectFilter[]
  defaultFilter: string
  onChange(value: string): any
}

class RoundSelectFilter extends React.PureComponent<Props & WithStyles<typeof styles>> {
  actionRef: HTMLDivElement | null | undefined

  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
      selectedFilter: props.defaultFilter,
    }
  }

  state = {
    isMenuOpen: false,
    selectedFilter: '',
  }

  render() {
    const { filters, classes } = this.props
    const { isMenuOpen, selectedFilter } = this.state
    const { name: selectedFilterName } = _.find(filters, { value: selectedFilter }) as SelectFilter
    return (
      <Grid
        item
        ref={ref => {
          this.actionRef = ref
        }}
      >
        <Grid container alignItems={'center'}>
          <Grid item onClick={() => this.setState({ isMenuOpen: true })} style={{ cursor: 'pointer' }}>
            <Grid container>
              <Typography className={classes.filterButton}>{selectedFilterName}</Typography>
              <img src={downIcon} alt="downIcon" />
            </Grid>
          </Grid>
          <Menu
            id="long-menu"
            anchorEl={this.actionRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ transform: 'translate(5px, 20px)' }}
            keepMounted
            open={isMenuOpen}
            onClose={() => this.setState({ isMenuOpen: false })}
            PaperProps={{
              style: {
                width: 222,
                // height: 222,
                backgroundColor: '#fff',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
                // transform: 'translate(13px, 20px)',
              },
            }}
            MenuListProps={{
              style: {
                padding: '20px 0 20px 0',
              },
            }}
          >
            {filters.map(item => (
              <MenuItem className={classes.menuItemWrapper} key={item.value} onClick={this.handleChange(item.value)}>
                <Radio checked={selectedFilter === item.value} style={{ padding: 0 }} />
                <Typography variant="inherit" className={classes.menuItem}>
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
    )
  }

  handleChange = value => () => {
    this.setState({ selectedFilter: value, isMenuOpen: false })
    this.props.onChange(value)
  }
}

export default withStyles(styles)(RoundSelectFilter) as any
