/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  children: string
  className?: string
}

function SectionTitle(props: Props) {
  const { children, className } = props

  return (
    <Typography
      variant={'h2'}
      css={css`
        font-size: 16px;
      `}
      className={className}
    >
      {children}
    </Typography>
  )
}

export default SectionTitle
