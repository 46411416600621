import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import { Manrope } from '../../helpers/commonStyles'
import NavContainer from './components/NavContainer'
import Employees from './Employees'
import CompanyTeams from '../settings/pages/teams/CompanyTeams'

const useStyles = makeStyles(
  {
    container: {
      fontFamily: Manrope,
    },
  },
  {
    classNamePrefix: 'MembersAndTeams',
  },
)

const prefix = '/members'

function MembersAndTeams() {
  const classes = useStyles()

  return (
    <PaperContainer className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NavContainer />
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route path={`${prefix}/members`} component={Employees} />
            <Route path={`${prefix}/teams`} component={CompanyTeams} />
            <Redirect to={`${prefix}/members`} />
          </Switch>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

export default MembersAndTeams
