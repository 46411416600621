import { EventRecording } from '@reaction-club-types/core'
import invariant from 'invariant'
import storeHelpers, { createLazy } from '../../libs/storeHelpers'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import { actions as listActions } from '../lists/reducer'
import api, { APIEventCreatePayload, APIEventUpdatePayload } from './api'
import { actions } from './reducer'

class Dispatcher {
  actions = actions

  getEvents(params?: { dateStart?: string; dateEnd?: string; offset?: number }): DispatcherResponse {
    return async (dispatch) => {
      const data = await api.getEvents(params)
      const { events } = data
      dispatch(actions.setEvents(events))

      if (events.length >= 100)
        // recursive fetch, because companies like microsoft might have more than 100 events per month.
        return dispatch(
          this.getEvents({
            ...params,
            offset: 100,
          }),
        )
    }
  }

  getPublicEvents(params?): DispatcherResponse {
    return async (dispatch) => {
      const { events } = await api.getPublicEvents(params)
      dispatch(actions.setEvents(events))
    }
  }

  getReports({ date }: { date?: string }): DispatcherResponse {
    return async (dispatch) => {
      const events = await api.getReports({ date })
      dispatch(actions.setEvents(events))
    }
  }

  getById(event_id: string): DispatcherResponse {
    return async (dispatch) => {
      const event = await api.getById(event_id)
      dispatch(actions.setEvents(event))
    }
  }

  getParticipants(event_id: string, options: DispatcherOptions): DispatcherResponse {
    const { isForce } = options || {}
    return createLazy({
      timeout: storeHelpers.TTL.HOUR,
      isForce,
      onFindStoreUpdatedAt(state) {
        return state.events[event_id]?.participantsUpdatedAt
      },
      async onExecute(dispatch) {
        const participants = await api.getParticipants(event_id)
        dispatch(actions.setParticipants({ event_id, participants }))
      },
    })
  }

  addParticipant(event_id: string, payload: { user_id: string }): DispatcherResponse {
    return async (dispatch) => {
      await api.addParticipant(event_id, payload)
      dispatch(this.getParticipants(event_id, { isForce: true }))
    }
  }

  removeParticipant(event_id: string, payload: { user_id: string }): DispatcherResponse {
    return async (dispatch) => {
      await api.removeParticipant(event_id, payload)
      dispatch(this.getParticipants(event_id, { isForce: true }))
    }
  }

  getRecordings(event_id: string): DispatcherResponse {
    return async (dispatch) => {
      const recordings = await api.getRecordings(event_id)
      dispatch(actions.setRecordings({ event_id, recordings }))
    }
  }

  deleteRecording({ event_id, key }: Pick<EventRecording, 'event_id' | 'key'>): DispatcherResponse {
    return async (dispatch) => {
      invariant(event_id, 'event_id invalid')
      await api.deleteRecording(event_id, key)
      dispatch(this.getRecordings(event_id))
    }
  }

  createEvent(payload: APIEventCreatePayload): DispatcherResponse {
    return async (dispatch) => {
      const data = await api.createEvent(payload)
      if (data.event_id) {
        dispatch(this.getById(data.event_id))
      }
      return data
    }
  }

  checkInUser(event_id: string, user: { user_id: string; status: boolean }): DispatcherResponse {
    return async (dispatch) => {
      const data = await api.checkInUsers(event_id, user)
      dispatch(this.getParticipants(event_id, { isForce: true }))
      return data
    }
  }

  updateEvent(event_id: string, payload: APIEventUpdatePayload): DispatcherResponse {
    return async (dispatch) => {
      const data = await api.updateEvent(event_id, payload)
      dispatch(this.getById(event_id))
      return data
    }
  }

  getChildEvent(event_id: string) {
    return async (dispatch) => {
      const event = await api.getChildEvent(event_id)
      if (event) dispatch(actions.setEvents([event]))
      dispatch(actions.setChildEvent({ event_id, event }))
    }
  }

  deleteEvent(event_id: string, params?: { spawnNext?: boolean }): DispatcherResponse {
    return async (dispatch) => {
      const data = await api.deleteEvent(event_id, params)
      dispatch(actions.deleteEvent(event_id))
    }
  }

  spawnNext(event_id: string) {
    return async (dispatch) => {
      await api.spawnNextEvent(event_id)
      await dispatch(this.getById(event_id))
      await dispatch(this.getChildEvent(event_id))
    }
  }

  changeNextTime(event_id: string, payload: { time: string }) {
    return async (dispatch) => {
      await api.changeNextTime(event_id, payload)
      await dispatch(this.getById(event_id))
    }
  }

  createZakToken(event_id: string) {
    return async (dispatch) => {
      const data = await api.createZakToken(event_id)
      await dispatch(actions.setZakToken({ event_id, zak_token: data.token }))
    }
  }

  getEventPenalties(): DispatcherResponse {
    return async (dispatch) => {
      const list = await api.getEventPenalties()
      dispatch(listActions.setEventPenalties(list))
    }
  }

  releasePenalty(params: { event_id: string; user_id: string }): DispatcherResponse {
    return async (dispatch) => {
      const result = await api.eventPenaltyRelease(params)
      await dispatch(this.getEventPenalties())
    }
  }
}

const EventDispatcher = new Dispatcher()
export default EventDispatcher
