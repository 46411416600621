/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactElement } from 'react'
import ButtonOrange from '../../components/ButtonOrange'

interface Props {
  children: ReactElement | string
  onClick: () => any
  isLoading?: boolean
}

function ButtonOrangeSquare(props: Props) {
  const { children, onClick, isLoading } = props

  return (
    <ButtonOrange
      onClick={onClick}
      css={css`
        font-family: Manrope;
        border-radius: 8px;
        min-width: 160px;
        line-height: 21px;
        font-size: 18px;
        font-weight: bold;
        padding: 12px 40px;
      `}
      isLoading={isLoading}
    >
      {children}
    </ButtonOrange>
  )
}

export default ButtonOrangeSquare
