import { Moment } from 'moment/moment'
import api from './apiStats'
import { actions } from './reducer'

class Dispatcher {
  getChallengesLeaderBoard() {
    return async (dispatch) => {
      const data = await api.getChallengesLeaderBoard()
      dispatch(actions.setChallengesLeaderBoard(data))
    }
  }

  getChallengesSummary() {
    return async (dispatch) => {
      const list = await api.getChallengesSummary()
      dispatch(actions.setChallengesSummary(list))
    }
  }

  getUsersPointsLeaderboard() {
    return async (dispatch) => {
      const list = await api.getUsersPointsLeaderboard({
        offset: 0,
      })
      dispatch(actions.setUserPointsList(list))
    }
  }
}

const LeaderboardDispatcher = new Dispatcher()
export default LeaderboardDispatcher
