import appTypes from '@reducers/app/types'
import SeamlessImmutable from 'seamless-immutable'
import ACTION_TYPE, {
  DashboardAction,
  ParticipationItemData,
  BreakdownItem,
  ReviewsItem,
  ActiveMembersItem,
  TopMomentsItem,
} from './types'

interface DashboardState {
  participation?: ParticipationItemData[]
  breakdown?: BreakdownItem[]
  reviews?: ReviewsItem
  activeMembers?: ActiveMembersItem[]
  topMoments?: TopMomentsItem[]
}

const initialState: DashboardState = {}

export default function reducer(state = initialState, action: DashboardAction): DashboardState {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case ACTION_TYPE.SET_PARTICIPATION: {
      return {
        ...state,
        participation: action.payload.dates,
      }
    }
    case ACTION_TYPE.SET_BREAKDOWN: {
      return {
        ...state,
        breakdown: action.payload,
      }
    }
    case ACTION_TYPE.SET_REVIEWS: {
      return {
        ...state,
        reviews: action.payload,
      }
    }
    case ACTION_TYPE.SET_ACTIVE_MEMBERS: {
      return {
        ...state,
        activeMembers: action.payload,
      }
    }
    case ACTION_TYPE.SET_TOP_MOMENTS: {
      return {
        ...state,
        topMoments: action.payload,
      }
    }
    default:
      return state
  }
}
