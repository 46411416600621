import React from 'react'

const LeftArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      top: 20,
      right: 20,
      padding: '5px',
      cursor: 'pointer',
      position: 'absolute',
    }}
  >
    <div
      style={{
        padding: 3,
        border: 'solid #fff',
        display: 'inline-block',
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(135deg)',
      }}
    />
  </div>
)

export default LeftArrow
