// EMPLOYEE REQUESTS

import {prefix_web} from "./api"
import {prefix_agent} from "./api"

export default {
  /**
   * Get detail about one Employee
   * @param employeeId - employee id number
   * @returns Object - inside detail about employee
   */
  async getEmployeeById(employeeId) {
    const result = await this.apiClient.get(prefix_agent + 'employee/' + employeeId)
    return result.data
  },
  /**
   * Get employee list
   * @returns Object - contain employee array inside
   */
  async getEmployees() {
    const result = await this.apiClient.get(prefix_agent + 'employees')
    return result.data
  },
  /**
   * Unassigned program with employee
   * @param userId - employee number id
   * @returns object - contain status success or failed
   */
  async deleteEmployeeProgram(userId) {
    const result = await this.apiClient.post(prefix_agent + 'employee/' + userId + '/program/delete', {employee_id: userId})
    return result.data
  },
  /**
   * Unassigned coach with employee
   * @param employeeId
   * @returns {Promise<*>}
   */
  async deleteEmployeeCoach(employeeId) {
    const result = await this.apiClient.delete(prefix_agent + 'employee/' + employeeId + '/coach')
    return result.data
  },
  /**
   * Assigned coach to employee
   * @param employeeId
   * @param coachId
   * @returns {Promise<*>}
   */
  async assignedCoachToEmployee(employeeId, coachId) {
    const result = await this.apiClient.post(prefix_agent+ 'employee/' + employeeId + '/coach', {coach_id: coachId})
    return result.data
  },
  async setEmployeeProgram(employeeId, programIds) {

  },
  /**
   * Get employee program
   * @param employeeId
   * @returns {Promise<*>}
   */
  async getProgramByEmployeeId(employeeId) {
    const result = await this.apiClient.get(prefix_agent + 'employee/' + employeeId + '/program')
    return result.data
  },
  /**
   * Make deactivate active milestone
   * @returns {Promise<void>}
   */
  async deactivateMilestone() {
    console.log('Deactivate milestone don`t implemented ')
    // const result = await this.apiClient.put('employee/milestone/update')
    // return result.data
  },
  /**
   * Update coach detail
   * @param employeeId
   * @param employeeUpdate - object contain update information
   * @returns {Promise<*>}
   */
  async updateEmployee(employeeId, employeeUpdate) {
    const result = await this.apiClient.post(prefix_agent + 'employee/' + employeeId , employeeUpdate)
    return result.data
  },
  async getActiveEmployeeDevices(employeeId) {
    const result = await this.apiClient.get(prefix_agent + 'employee/'+ employeeId + '/devices')
    return result.data
  },
}