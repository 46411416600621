/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import useModalState from '../../hooks/useModalState'
import InviteEmployeesModal from '../../modals/invites/InviteEmployeesModal'
import PlusButton from '../buttons/PlusButton'
import ButtonOrange from '../ButtonOrange'

interface Props {
  title?: string
  className?: string
  type?: 'button' | 'link'
}
function InviteEmployeesButton(props: Props) {
  const { title = 'Invite member', className, type } = props
  const modalState = useModalState()

  return (
    <React.Fragment>
      {type === 'button' ? (
        <ButtonOrange onClick={modalState.onOpen} className={className}>
          {title}
        </ButtonOrange>
      ) : (
        <PlusButton onClick={modalState.onOpen} title={title} className={className} />
      )}
      <InviteEmployeesModal {...modalState} />
    </React.Fragment>
  )
}

export default InviteEmployeesButton
