import { Grid } from '@mui/material'
import React from 'react'
import starIcon from '../assets/star.svg'
import emptyStarIcon from '../assets/star_empty.svg'

interface Props {
  number: number
}

const Stars = ({ number }: Props) => {
  let stars = []
  for (let i = 0; i < 5; i++) {
    stars.push(
      <img key={`star-${i}`} style={{ marginRight: 1 }} alt="star" src={number > i ? starIcon : emptyStarIcon} />,
    )
  }
  return (
    <div>
      <Grid container>{stars}</Grid>
    </div>
  )
}

export default Stars
