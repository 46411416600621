import { Button, Grid, Typography, TextField, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AssetFull } from '@reaction-club-types/core'
import CompanyDispatcher from '@reducers/company/dispatcher'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ModalStyled from '../../../elements/ModalStyled'
import useActionHandler from '../../../hooks/useAsyncHandler'
import apiAssets from '../../../libs/api/apiAssets'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import CompanyModalAsset from './compoenent/CompanyModalAsset'

const useStyles = makeStyles({
  title: {
    marginBottom: 32,
    fontSize: 24,
    lineHeight: 'inherit',
  },
  inputLabel: {
    color: '#323232',
    fontSize: 16,
  },
  inputName: {
    marginBottom: 32,
    width: '100%',
    height: 50,
    color: '#323232',
    fontSize: 16,
  },
  btn: {
    borderRadius: 25,
    padding: 10,
    fontSize: 18,
    width: '100%',
  },
})

interface Props {
  isVisible: boolean
  onClose: () => any
}

function ModalCreateCompany(props: Props) {
  const { isVisible, onClose } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    reValidateMode: 'onChange',
  })

  const nameFormField = register('name', {
    validate(value) {
      if (!value) return 'Name is required'
      return true
    },
  })

  const assetAction = useActionHandler(
    async (file: File) => {
      const asset = await apiAssets.upload(file)
      return asset
    },
    {
      isRetryAllowed: true,
    },
  )

  const createAction = useActionHandler(async (data: { name: string }) => {
    const companyPayload: { name: string; asset?: AssetFull | null } = { name: data.name, asset: assetAction.data }
    await dispatch(CompanyDispatcher.createCompany(companyPayload))
  })

  const handlePressSave = () => handleSubmit(onSubmit)()
  const onSubmit = async (data: { name: string }) => {
    if (assetAction.isLoading) return null
    await createAction.onAction(data)
  }

  const isButtonDisabled = assetAction.isLoading || createAction.isLoading

  return (
    <ModalStyled isVisible={isVisible} onClose={onClose} showCloseButton>
      <Box sx={{ paddingTop: '10px' }}>
        <Grid container justifyContent="center">
          <Grid item xs>
            <Typography variant="h1" className={classes.title}>
              Create a company
            </Typography>
            <CompanyModalAsset assetUploader={assetAction} />
            <TextField
              className={classes.inputName}
              label="Company name"
              variant="outlined"
              error={!!errors.name?.message}
              required
              {...nameFormField}
              InputLabelProps={{ className: classes.inputLabel }}
              InputProps={{
                style: {
                  height: 50,
                  color: '#323232',
                },
              }}
            />
            <Button
              variant={'contained'}
              color={'primary'}
              className={classes.btn}
              onClick={handlePressSave}
              disabled={isButtonDisabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalStyled>
  )
}

export default ModalCreateCompany
