/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Delete as DeleteIcon, FileDownload as FileDownloadIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import apiChallenge from '@reducers/challenge/api'
import ChallengeDispatcher from '@reducers/challenge/dispatcher'
import { IChallengeApp } from '@reducers/challenge/types'
import { ReduxState } from '@reducers/index'
import modalActions from '@reducers/modal/actions'
import { MODAL_TYPES } from '@reducers/modal/types'
import TeamDispatcher from '@reducers/teams/dispatcher'
import _, { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import Container from '../../components/Container'
import ReactionLoader from '../../components/ReactionLoader'
import { arrowBackIcon } from '../../components/paperContainer/assets/facade'
import { secondsToTime } from '../../helpers/utils'
import { RemoveChallenge } from '../groups/RemoveChallenge'
import Charts from '../groups/groups/Charts'
import LeaderboardListSteps from '../leaderboard/leaderboards/LeaderboardListSteps'
import Challenge from './components/Challenge'
import Map from './components/Map'
import CompaniesLeaderBoard from './components/companiesLeaderboard/CompaniesLeaderBoard'

interface Props {
  match: any
}

const spacing = 5

const cssSeparator = css`
  height: 1px;
  background-color: rgba(89, 88, 88, 1);
  width: 100%;
  margin: 0 0 20px;
`

const cssBanner = css`
  background-color: #e9e9e9;
  margin: 15px -30px 50px;
  padding: 15px 30px;
`

const useStyles = makeStyles({
  backImage: {
    width: 7,
    height: 13,
    marginRight: 15,
  },
  backText: {
    fontSize: 14,
    color: '#000',
    lineHeight: '100%',
  },
  backContainer: {
    cursor: 'pointer',
  },
  icon: {
    width: 32,
    marginRight: 20,
  },
  title: {},
  bannerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
})

const Dashboard: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const { match } = props
  const { id: challengeId } = match.params || {}
  const [challenge, setChallenge] = useState<IChallengeApp>()
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isTimeUnit = useMemo(() => challenge?.result_type === 'time', [challenge?.result_type])
  const { company } = useSelector((state: ReduxState) => ({
    company: state.company,
  }))
  const isBlocked = company?.subscriptionPlan?.status === 'BLOCKED'

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleClearHistory = async () => {
    if (!confirm('Are you sure want to clear this challenge history?')) return null
    await dispatch(ChallengeDispatcher.clearHistory(challengeId))
    const res = await apiChallenge.getChallenge(challengeId)
    setChallenge(res)
  }

  const handleOpenExportSteps = (type) => () => {
    dispatch(
      modalActions.showModal({
        modalType: MODAL_TYPES.EXPORT_STEPS,
        modalProps: { challengeId, type },
      }),
    )
  }

  const dataFetchChallengesAction = useAsyncFetch(async () => {
    await dispatch(TeamDispatcher.getList({ isForce: false }))
    const res = await apiChallenge.getChallenge(challengeId)
    setChallenge(res)
  })

  const type = challenge?.type_data.name

  const renderFutureChallenge = () => {
    if (!challenge.repeat) return null

    return <Challenge type="future" challenge={challenge} />
  }

  return (
    <RendererStatusSplit
      statuses={dataFetchChallengesAction}
      isEmpty={_.isEmpty(challenge)}
      renderLoading={() => <ReactionLoader marginTop={40} />}
      render={() => {
        if (_.isEmpty(challenge)) {
          return null
        }

        return (
          <Container>
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  className={classes.backContainer}
                  onClick={history.goBack}
                >
                  <img src={arrowBackIcon} alt="back icon" className={classes.backImage} />
                  <Typography className={classes.backText}>Back</Typography>
                </Grid>
              </Grid>
            </Grid>
            <div css={cssBanner}>
              <Grid container justifyContent="space-between">
                <Grid item className={classes.bannerTitle}>
                  <img src={challenge.icon_url} className={classes.icon} />
                  <Typography className={classes.title} fontSize={18} fontWeight="bold">
                    {challenge.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <RemoveChallenge challengeId={challengeId} />
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={spacing} alignItems={'stretch'}>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Challenge type="current" challenge={challenge} />
                  {renderFutureChallenge()}
                </Grid>
              </Grid>
              {isEmpty(challenge.dailyReports) ? null : (
                <Grid item xs={12} maxHeight={500} pb={20}>
                  <div css={cssSeparator} />
                  <Typography fontWeight="bold" fontSize={16}>
                    Daily average {type}
                  </Typography>
                  <Typography fontSize={12}>Per member</Typography>
                  <Typography mb={4} fontSize={24}>
                    {isTimeUnit ? secondsToTime(challenge?.valueAvg) : challenge?.valueAvg || 0}
                  </Typography>

                  <Charts
                    type={challenge.type_data.name}
                    unitType={challenge.result_type}
                    backgroundColor={challenge.background_color}
                    details={challenge?.dailyReports || []}
                    maxBars={20}
                  />
                </Grid>
              )}

              <React.Fragment>
                <Grid xs={12} item>
                  <div css={cssSeparator} />
                </Grid>
                <Grid item flexGrow={1}>
                  <CompaniesLeaderBoard type={challenge.type_data.name} unitType={challenge.result_type} />
                </Grid>
              </React.Fragment>

              <Grid item>
                <Map challenge={challenge} isSteps={challenge.type_data.name === 'steps'} />
              </Grid>

              <Grid item xs={12}>
                <div css={cssSeparator} />
                <Grid container alignItems={'center'}>
                  <Grid container sm={6} justifyContent={'space-between'}>
                    <Typography variant={'h1'} mr={20}>
                      Leaderboard
                    </Typography>
                  </Grid>
                  <Grid sm={6} container justifyContent="flex-end">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleMenuButtonClick}
                      size="large"
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      getContentAnchorEl={null}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      open={isMenuVisible}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        style: {
                          width: 300,
                        },
                      }}
                    >
                      <MenuItem onClick={handleClearHistory}>
                        <ListItemIcon>
                          <DeleteIcon color={'primary'} fontSize={'small'} />
                        </ListItemIcon>
                        <Typography variant="inherit">Clear History</Typography>
                      </MenuItem>

                      <MenuItem onClick={handleOpenExportSteps('users')}>
                        <ListItemIcon>
                          <FileDownloadIcon color={'primary'} fontSize={'small'} />
                        </ListItemIcon>
                        <Typography variant="inherit">Export members data</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleOpenExportSteps('teams')}>
                        <ListItemIcon>
                          <FileDownloadIcon color={'primary'} fontSize={'small'} />
                        </ListItemIcon>
                        <Typography variant="inherit">Export teams data</Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>

                <Box pt={4}>
                  <LeaderboardListSteps challenge={challenge} isFreeTrial={isBlocked} />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )
      }}
    />
  )
}

export default Dashboard
