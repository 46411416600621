import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { makeStyles } from '@mui/styles'
import { CompanyStockOrder } from '@reaction-club-types/core'
import StockDispatcher from '@reducers/stocks/dispatcher'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Immutable } from 'seamless-immutable'

const useStyles = makeStyles(
  {
    filterButton: {
      cursor: 'pointer',
      marginRight: 5,
      fontWeight: 600,
    },
    menuItem: {
      color: '#323232',
      fontSize: 14,
      fontWeight: 600,
      margin: '5px 0',
      paddingLeft: 8,
      '&:hover': {
        color: '#F89A00',
        fontWeight: 600,
      },
    },
  },
  {
    classNamePrefix: 'OrderItemMenu',
  },
)

interface Props {
  order: CompanyStockOrder | Immutable<CompanyStockOrder>
}

function OrderItemMenu(props: Props) {
  const anchorRef = useRef<any>()
  const [isMenuOpen, setMenuVisible] = useState(false)

  const { order } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const { id, status } = order
  if (status === 'canceled') return null

  const handleCancel = () => {
    setMenuVisible(false)
    dispatch(StockDispatcher.cancelOrder(id))
  }

  const handleCompleteOrder = () => {
    setMenuVisible(false)
    dispatch(StockDispatcher.completeOrder(id))
  }

  const handleMenuOpen = () => setMenuVisible(true)
  const handleMenuClose = () => setMenuVisible(false)

  return (
    <Grid item>
      <Grid container alignItems={'center'}>
        <IconButton
          onClick={handleMenuOpen}
          disableFocusRipple
          style={{ padding: 0 }}
          ref={anchorRef}
          size="large">
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={() => anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ transform: 'translate(-10px, 0)' }}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {status !== 'completed' && (
            <MenuItem onClick={handleCompleteOrder}>
              <Typography variant="inherit" className={classes.menuItem}>
                Mark As Completed
              </Typography>
            </MenuItem>
          )}

          <MenuItem onClick={handleCancel}>
            <Typography variant="inherit" className={classes.menuItem}>
              Cancel Order
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

export default OrderItemMenu
