import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { makeStyles } from '@mui/styles'
import { CompanyStock } from '@reaction-club-types/core'
import StockDispatcher from '@reducers/stocks/dispatcher'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Immutable } from 'seamless-immutable'
import StockEditModal from '../modals/StockEditModal'

const useStyles = makeStyles(
  {
    filterButton: {
      cursor: 'pointer',
      marginRight: 5,
      fontWeight: 600,
    },
    menuItem: {
      fontSize: 14,
      fontWeight: 600,
      margin: '5px 0',
      paddingLeft: 8,
      '&:hover': {
        color: '#F89A00',
        fontWeight: 600,
      },
    },
  },
  {
    classNamePrefix: 'OrderItemMenu',
  },
)

interface Props {
  stock: CompanyStock | Immutable<CompanyStock>
}

function StockItemMenu(props: Props) {
  const anchorRef = useRef<any>()
  const [isMenuOpen, setMenuVisible] = useState(false)
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const { stock } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { is_visible } = stock

  const handleMenuOpen = () => setMenuVisible(true)
  const handleMenuClose = () => setMenuVisible(false)

  const handleDeleteItem = () => {
    setMenuVisible(false)
    if (!confirm('Are you sure want to delete item?')) return null
    dispatch(StockDispatcher.deleteStock(stock.id))
  }

  const handleModify = () => {
    setMenuVisible(false)
    setEditModalVisible(true)
  }

  const handleMakeItVisible = () => {
    setMenuVisible(false)
    dispatch(StockDispatcher.setStockVisibility(stock.id, true))
  }

  const handleMakeItHidden = () => {
    setMenuVisible(false)
    dispatch(StockDispatcher.setStockVisibility(stock.id, false))
  }

  const handleCloseEditModal = () => setEditModalVisible(false)

  return (
    <Grid item>
      <StockEditModal stock={stock} isVisible={isEditModalVisible} onClose={handleCloseEditModal} />
      <Grid container alignItems={'center'}>
        <IconButton
          onClick={handleMenuOpen}
          disableFocusRipple
          style={{ padding: 0 }}
          ref={anchorRef}
          size="large">
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={() => anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ transform: 'translate(-10px, 0)' }}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleModify}>
            <Typography variant="inherit" className={classes.menuItem}>
              Modify
            </Typography>
          </MenuItem>

          {is_visible ? (
            <MenuItem onClick={handleMakeItHidden}>
              <Typography variant="inherit" className={classes.menuItem}>
                Hide Item
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleMakeItVisible}>
              <Typography variant="inherit" className={classes.menuItem}>
                Make it visible
              </Typography>
            </MenuItem>
          )}

          <MenuItem onClick={handleDeleteItem}>
            <Typography variant="inherit" className={classes.menuItem} color={'error'}>
              Delete Item
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

export default StockItemMenu
