/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Grid, Typography } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import React, { useMemo } from 'react'
import { secondsToTime } from '../../../../../helpers/utils'
import useFormattedNumber from '../../../hooks/useFormattedNumber'

interface Props {
  company: Company & { value: number; rank: string | number; type: string }
  isOwn: boolean
  unitType: 'time' | 'integer'
}

function CompaniesBoardItem(props: Props) {
  const { company, isOwn, unitType } = props
  let { name, value, rank, logo } = company

  rank = parseInt(rank as string)

  const rankColor = useMemo(() => {
    if (rank === 1) return '#F5962C'
    if (rank === 2) return '#D9D9D9'
    if (rank === 3) return '#863C3E'

    return null
  }, [rank])

  const valueFormatted = unitType === 'time' ? secondsToTime(value) : useFormattedNumber(value)

  return (
    <div
      css={css`
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 7px 10px 7px 13px;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
      `}
    >
      <Grid container alignItems={'center'}>
        {rankColor && (
          <div
            style={{ backgroundColor: rankColor }}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 10px;
            `}
          />
        )}

        <div
          css={css`
            margin-right: 10px;
          `}
        >
          <Typography
            fontWeight={'bold'}
            css={css`
              font-size: 22px;
            `}
          >
            {rank}
          </Typography>
        </div>

        <div
          css={css`
            margin-right: 10px;
            font-family: 'Mulish';
          `}
        >
          <Avatar src={logo} />
        </div>

        <Grid
          item
          flexGrow={1}
          css={css`
            width: 1px;
          `}
        >
          <Typography
            fontWeight={'bold'}
            css={css`
              font-size: 14px;
              font-family: 'Mulish';
            `}
          >
            {name}
          </Typography>
        </Grid>

        <Typography
          variant={'caption'}
          fontWeight={'bold'}
          css={css`
            padding-left: 10px;
            font-size: 14px;
          `}
        >
          {valueFormatted}
        </Typography>
      </Grid>
    </div>
  )
}

export default CompaniesBoardItem
